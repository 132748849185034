import './App.css';
import Header from "./components/Header";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./Keycloak";
import React from "react";
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Home from "./components/Home";
import {ApplicationContextProvider} from './components/ApplicationContext.js';
import Footer from "./components/Footer";
import Accounts from "./components/Account/Accounts";
import AccountDetail from "./components/Account/AccountDetail";
import AccountNew from "./components/Account/AccountNew";
import SalesOrders from "./components/SalesOrder/SalesOrders";
import SalesOrderDetail from "./components/SalesOrder/SalesOrderDetail";
import SalesOrderNew from "./components/SalesOrder/SalesOrderNew";
import StorageOrders from "./components/StorageOrder/StorageOrders";
import StorageOrderDetail from "./components/StorageOrder/StorageOrderDetail";
import StorageOrderNew from "./components/StorageOrder/StorageOrderNew";
import Products from "./components/Product/Products";
import ProductDetail from "./components/Product/ProductDetail";
import ProductNew from "./components/Product/ProductNew";
import SKUs from "./components/SKU/SKUs";
import SKUDetail from "./components/SKU/SKUDetail";
import SKUNew from "./components/SKU/SKUNew";
import UserDetail from "./components/User/UserDetail";
import PrivateRoute from "./components/PrivateRoute";
import ListOfValues from "./components/ListOfValue/ListOfValues";
import PackageTypes from "./components/PackageType/PackageTypes";
import Barcodes from "./components/Barcode/Barcodes";
import Reports from "./components/Report/Reports";
import AlcoholLicenseDetail from "./components/AlcoholLicense/AlcoholLicenseDetail";
import {authorization} from './integration/Api.js';
import {useState} from "react";
import backgroundImage from './assets/background.jpg';
const backgroundStyle={
  content:"",
  position: 'fixed',
  top:0,
  left:0,
  bottom:0,
  right:0,
  width: '100%',
  height: '100%',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  opacity: 0.1,
  zIndex: -1,
}
function App(){
  const [menuItemsAuthorization,setMenuItemsAuthorization]=useState([]);
  const [menuSubItemsAuthorization,setMenuSubItemsAuthorization]=useState([]);
  const [userMenuAuthorization,setUserMenuAuthorization]=useState([{"name":"login","label":"Login"}]);
  const [userDataAuthorization,setUserDataAuthorization]=useState({});
  const [listOfAuthorizedNames,setListOfAuthorizedNames]=useState([]);
  const [menuConfigAuthorization,setMenuConfigAuthorization]=useState({});
  const [sessionExpired,setSessionExpired]=useState(false);

  const getAuthorization=()=>{
    authorization()
    .then((response)=>{
        let data=JSON.parse(response);
        console.log(data);
        if('error' in data){
          console.log('error',data.error);
        }
        else{
          if('menuItems' in data){
            if(data.menuItems.length>0){
              setMenuItemsAuthorization(old=>data.menuItems);
              let listOfNames=[];
              let menuSubItems={};
              data.menuItems.forEach((item)=>{
                if('menuSubItems' in item){
                  item.menuSubItems.forEach((sub_item)=>{
                    listOfNames.push(sub_item.name);
                    menuSubItems[sub_item.name]=sub_item;
                  })
                }
              });
              setListOfAuthorizedNames(old=>listOfNames);
              setMenuSubItemsAuthorization(old=>menuSubItems);
            }
          }
          if('userMenu' in data){
            if(data.userMenu.length>0){
              setUserMenuAuthorization(old=>[...old,...data.userMenu]);
            }
          }
          if('userData' in data){
            setUserDataAuthorization(old=>data.userData);
          }
          if('menuConfig' in data){
            setMenuConfigAuthorization(old=>data.menuConfig);
          }
        }
    });
  }

  const callbackKeyCloack=(e)=>{
    if(e==='onAuthSuccess'){
      localStorage.setItem('sessionExpiredMessage','');
      localStorage.setItem('previousEvent','onAuthSuccess');
      getAuthorization();
    }
    else if(e==='onAuthRefreshError'){
      localStorage.setItem('sessionExpiredMessage',' (Session Expired!)');
      localStorage.setItem('previousEvent','onAuthRefreshError');
      setSessionExpired(old=>true);
    }
    else if(e=='onReady'){
      if(localStorage.getItem('previousEvent')==='onAuthRefreshError'){
        localStorage.setItem('previousEvent','onReady');
      }
      else{
        localStorage.setItem('sessionExpiredMessage','');
      }
    }
  };

  return(
    <div>
      <div style={backgroundStyle}></div>
      <div className="App">
        <ReactKeycloakProvider authClient={keycloak} onEvent={callbackKeyCloack}>
          <ApplicationContextProvider userDataAuthorization={userDataAuthorization}>
            <Router>
              <Footer userDataAuthorization={userDataAuthorization}></Footer>
              <Header sessionExpired={sessionExpired} menuItemsAuthorization={menuItemsAuthorization} userDataAuthorization={userDataAuthorization} userMenuAuthorization={userMenuAuthorization} menuConfigAuthorization={menuConfigAuthorization}/>
                <Routes>
                  <Route exact path="/"                         element={<Home authMenuSubItem={menuSubItemsAuthorization['home']} userDataAuthorization={userDataAuthorization} menuConfigAuthorization={menuConfigAuthorization}/>}/>
                  <Route path="/accounts"                       element={<PrivateRoute childComponent={Accounts}             authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('accounts')}          authMenuSubItem={menuSubItemsAuthorization['accounts']}          userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/account/:slug/:context"         element={<PrivateRoute childComponent={AccountDetail}        authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('account')}           authMenuSubItem={menuSubItemsAuthorization['account']}           userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/account/new"                    element={<PrivateRoute childComponent={AccountNew}           authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('account_new')}       authMenuSubItem={menuSubItemsAuthorization['account_new']}       userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/sales_orders"                   element={<PrivateRoute childComponent={SalesOrders}          authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('sales_orders')}      authMenuSubItem={menuSubItemsAuthorization['sales_orders']}            userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/sales_order/:slug/:context"     element={<PrivateRoute childComponent={SalesOrderDetail}     authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('sales_order')}       authMenuSubItem={menuSubItemsAuthorization['sales_order']}             userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/sales_order/new"                element={<PrivateRoute childComponent={SalesOrderNew}        authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('sales_order_new')}   authMenuSubItem={menuSubItemsAuthorization['sales_order_new']}         userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/storage_orders"                 element={<PrivateRoute childComponent={StorageOrders}        authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('storage_orders')}    authMenuSubItem={menuSubItemsAuthorization['storage_orders']}            userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/storage_order/:slug/:context"   element={<PrivateRoute childComponent={StorageOrderDetail}   authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('storage_order')}     authMenuSubItem={menuSubItemsAuthorization['storage_order']}             userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/storage_order/new"              element={<PrivateRoute childComponent={StorageOrderNew}      authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('storage_order_new')} authMenuSubItem={menuSubItemsAuthorization['storage_order_new']}         userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/products"                       element={<PrivateRoute childComponent={Products}             authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('products')}          authMenuSubItem={menuSubItemsAuthorization['products']}          userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/product/:slug/:context"         element={<PrivateRoute childComponent={ProductDetail}        authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('product')}           authMenuSubItem={menuSubItemsAuthorization['product']}           userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/product/new"                    element={<PrivateRoute childComponent={ProductNew}           authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('product_new')}       authMenuSubItem={menuSubItemsAuthorization['product_new']}       userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/skus"                           element={<PrivateRoute childComponent={SKUs}                 authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('skus')}              authMenuSubItem={menuSubItemsAuthorization['skus']}              userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/sku/:slug/:context"             element={<PrivateRoute childComponent={SKUDetail}            authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('sku')}               authMenuSubItem={menuSubItemsAuthorization['sku']}               userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/sku/new"                        element={<PrivateRoute childComponent={SKUNew}               authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('sku_new')}           authMenuSubItem={menuSubItemsAuthorization['sku_new']}           userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/user/:slug/:context"            element={<PrivateRoute childComponent={UserDetail}           authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('user')}              authMenuSubItem={menuSubItemsAuthorization['user']}              userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/list_of_values"                 element={<PrivateRoute childComponent={ListOfValues}         authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('list_of_values')}    authMenuSubItem={menuSubItemsAuthorization['list_of_values']}    userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/package_types"                  element={<PrivateRoute childComponent={PackageTypes}         authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('package_types')}     authMenuSubItem={menuSubItemsAuthorization['package_types']}     userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/barcodes"                       element={<PrivateRoute childComponent={Barcodes}             authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('barcodes')}          authMenuSubItem={menuSubItemsAuthorization['barcodes']}          userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/reports"                        element={<PrivateRoute childComponent={Reports}              authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('reports')}           authMenuSubItem={menuSubItemsAuthorization['reports']}           userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="/alcohol_licenses"               element={<PrivateRoute childComponent={AlcoholLicenseDetail} authClient={keycloak} authMenuItem={listOfAuthorizedNames.includes('alcohol_licenses')}  authMenuSubItem={menuSubItemsAuthorization['alcohol_licenses']}  userDataAuthorization={userDataAuthorization}/>}/>
                  <Route path="*"                               element={<PrivateRoute authClient={keycloak} authMenuItem={false}/>}/>
                </Routes>
            </Router>
          </ApplicationContextProvider>
        </ReactKeycloakProvider>
      </div>
    </div>
  );
}

export default App;
