import {useEffect,useState} from "react";
import {useNavigate} from 'react-router-dom';
import {ButtonGroup,Button,Stack} from '@mui/material';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const list_name='account_sales_orders';

const AccountDetailSalesOrders=({pageTab,parent,globalData,userDataAuthorization})=>{
    const navigate=useNavigate();
    const [loading,setLoading]=useState(true);
    const [rows,setRows]=useState([]);
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    useEffect(()=>{
        let isSubscribed=true;
        if(parent&&Object.keys(parent).length>0){
            if(parent.orders&&Object.keys(parent.orders).length>0){
                let orders=[];
                parent.orders.forEach(element=>{
                    if(element.type==='Sales'){
                        orders.push(element);
                    }
                });
                setRows(old=>orders);
            }
            setLoading(false);
        }
        return ()=>(isSubscribed=false);
    },[parent.orders,pageTab]);

    const handleClickListButton=(event)=>{
        switch(event.target.name){
            case "create_sales_order":
                navigate('/sales_order/new',{state:{account:parent}});
                break;
            default:
                break;
        }
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
        <div className="page-content">
            <ButtonGroup size="small" aria-label="small button group">
                {Object.keys(pageTab).length>0&&Object.keys(pageTab.actions).map((action)=>{
                    return(pageTab.actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{pageTab.actions[action].label}</Button>);
                })}
            </ButtonGroup>
            <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        editMode="row"
                        initialState={{
                            sorting:{
                                sortModel:[{field:'reference',sort:'asc'}],
                            },
                        }}
                        rows={rows}
                        columns={columns[list_name](list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </Stack>
        </div>);
}

export default AccountDetailSalesOrders;
