import React,{createContext,useState,useEffect} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {getDBList} from '../integration/DatabaseManager.js';
import {listPreDefaultFilter,listPreDefaultSorting} from '../utilities/DataDictionary.js';
const ApplicationContext=createContext();

const ApplicationContextProvider=({children,userDataAuthorization})=>{
    const [globalData,setGlobalData]=useState(null);
    const {keycloak}=useKeycloak();
    const isLoggedIn=keycloak.authenticated;

    const updateGlobalData=(newValue)=>{
        setGlobalData(newValue);
    };

    useEffect(()=>{
        if(isLoggedIn&&Object.keys(userDataAuthorization).length>0){
            let globalObject={listOfValues:{},listOfValuesRel:{},dataGridParams:userDataAuthorization.datagrid_params||{}}
            getDBList({entityType:'ListOfValues',entityParams:['status=Active']})
            .then((data)=>{
                console.log('data',data);
                if('error' in data){
                    console.log('error',data.error);
                }
                else{
                    if(data.length>0){
                        let listOfValues={};
                        data.forEach(e=>{
                            if(!listOfValues.hasOwnProperty(e.type)){
                                listOfValues[e.type]=[];
                            }
                            listOfValues[e.type].push(e.value);
                        });
                        globalObject.listOfValues=listOfValues;
                        globalObject.filterModel={};
                        for(const list in listPreDefaultFilter){
                            globalObject.filterModel[list]=listPreDefaultFilter[list];
                        }
                        globalObject.sortModel={};
                        for(const list in listPreDefaultSorting){
                            globalObject.sortModel[list]=listPreDefaultSorting[list];
                        }

                        getDBList({entityType:'ListOfValuesRel',entityParams:['status=Active']})
                        .then((data)=>{
                            console.log('data',data);
                            if('error' in data){
                                console.log('error',data.error);
                            }
                            else{
                                let listOfValuesRel={};
                                data.forEach(e=>{
                                    if(!listOfValuesRel.hasOwnProperty(e.parent_type)){
                                        listOfValuesRel[e.parent_type]={};
                                    }
                                    if(!listOfValuesRel[e.parent_type].hasOwnProperty(e.parent_value)){
                                        listOfValuesRel[e.parent_type][e.parent_value]={}
                                    }
                                    if(!listOfValuesRel[e.parent_type][e.parent_value].hasOwnProperty(e.child_type)){
                                        listOfValuesRel[e.parent_type][e.parent_value][e.child_type]=[]
                                    }
                                    listOfValuesRel[e.parent_type][e.parent_value][e.child_type].push(e.child_value);
                                });
                                globalObject.listOfValuesRel=listOfValuesRel;
                            }
                            console.log('globalObject',globalObject);
                            setGlobalData(old=>globalObject);
                        });
                    }
                }
            });
        }
    },[isLoggedIn,userDataAuthorization]);

    return(
        <ApplicationContext.Provider value={{globalData,updateGlobalData}}>
            {children}
        </ApplicationContext.Provider>
    );
};

export {ApplicationContext,ApplicationContextProvider};
