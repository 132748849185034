import {useEffect,useState,useContext} from "react";
import {Backdrop,CircularProgress} from '@mui/material';
import {ApplicationContext} from './ApplicationContext.js';

const PrivateRoute=(props)=>{
    const [open,setOpen]=useState(false);
    const [authorizationError,setAuthorizationError]=useState(!props.authMenuItem);
    const [authenticationError,setAuthenticationError]=useState(!props.authClient.authenticated); 
    const {globalData,updateGlobalData}=useContext(ApplicationContext);

    useEffect(()=>{
        if(props.authClient.authenticated){
            setAuthenticationError(old=>false);
        }
        if(props.authMenuItem){
            setAuthorizationError(old=>false);
        }

        if(!props.authMenuItem||!props.authClient.authenticated){
            setOpen(true);
            let int=setInterval(()=>{
                setOpen(false);
                clearInterval(int);
            },2000);
            return()=>{clearInterval(int);};
        }
        else{
            setOpen(false);
        }
    },[props.authMenuItem,props.authClient.authenticated]);

    const dropdown=()=>{
        return <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={open}><CircularProgress color="inherit"/></Backdrop>;
    }

    const notAuthorized=()=>{
        return open?<div></div>:<div>Invalid Page or Access not Allowed</div>;
    }

    const notAuthenticated=()=>{
        return open?<div></div>:<div>User not Authenticated - Please Login</div>;
    }

    return <>
        {dropdown()}
        {globalData!==null&&(authenticationError?notAuthenticated():authorizationError?notAuthorized():<props.childComponent authMenuSubItem={props.authMenuSubItem} userDataAuthorization={props.userDataAuthorization} globalData={globalData}/>)}
    </>
    
};

export default PrivateRoute;