import {useEffect,useState} from "react";
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import {List,ListItem,ListItemText,Tooltip,Autocomplete,Switch,FormGroup,Backdrop,CircularProgress,FormControlLabel,Divider,Box,Tabs,Tab,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,IconButton,InputAdornment} from '@mui/material';
import {ArrowCircleUp,ArrowCircleDown,ArrowCircleRight} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
dayjs.locale('fi')
import 'dayjs/locale/fi';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import InfoIcon from '@mui/icons-material/Info';
import RecordInfo from "../RecordInfo.js"
import Country from '../Country.js'
import {sortPageTabs,prepareGetData,prepareDefaultData,validate_required_fields,prepareErrorFields,DefaultConfig,time_to_date_ui,time_to_ui,sales_order_delivery_price,dayjs_to_db,adjustDateLocale,currentTimestamp} from '../../utilities/Utilities.js';
import {getDBItem,updateDBItem,getDBList} from '../../integration/DatabaseManager.js';
import SalesOrderDetailItems from "./SalesOrderDetailItems.js";
import {status_color,entity_object} from '../../utilities/DataDictionary.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {invoicing} from '../../integration/Api.js'
import {enUS} from '@mui/x-date-pickers/locales';
import Audit from '../Audit.js';
const localeText=enUS.components.MuiLocalizationProvider.defaultProps.localeText;
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='error'
const required_field_error='Required Field';
const dataPreparedGet=prepareDefaultData('default_value_get','sales_order');
const dataError=prepareErrorFields('sales_order');
let non_editable_status=['Canceled'];
let editable_delivery_status=['', 'New', 'Routed'];
let send_invoice_status=['Submitted','Invoiced','INVOICING_INTEGRATION_ERROR'];
let pendingPaymentOrder=[];
let exciseOrder=[];
import {columns} from '../../utilities/Entities';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
let account_business_id='';
let pagehistory=null;
const SalesOrderDetail=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [label,setLabel]=useState('-');
    const {slug}=useParams();
    const [pageTab,setPageTab]=useState(0);
    const [pageTabs,setPageTabs]=useState({});
    const [isChanged,setIsChanged]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);  
    const navigate=useNavigate();
    const location=useLocation();
    const [readOnly,setReadOnly]=useState(true);
    const [updates,setUpdates]=useState({});
    const [showMoreInfo,setShowMoreInfo]=useState(false);
    const [recordInfo,setRecordInfo]=useState(false);
    const [actions,setActions]=useState({});
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [openDialog,setOpenDialog]=useState(false);
    const [openDialogUpdateSubmitted,setOpenDialogUpdateSubmitted]=useState(false);
    const [openDialogSubmit,setOpenDialogSubmit]=useState(false);
    const [openDialogSubmitExcise,setOpenDialogSubmitExcise]=useState(false);
    const {globalData}=useContext(ApplicationContext);
    const [refreshPageTab,setRefreshPageTab]=useState(false);

    const handleClickMoreInfo=()=>{
        setRecordInfo(true);
    };

    const recordInfoCallback=()=>{
        setRecordInfo(false);
    };

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const orderItemCallback=(mode)=>{
        if(mode==='refresh'){
            getRecord(authMenuSubItem.query);
        }
    }
    
    const handleChangeTab=(event,newValue)=>{
        event.currentTarget.blur();
        setPageTab(newValue);
        pagehistory=event.target.name;
        navigate(location.pathname.replace(location.pathname.split('/')[3],pageTabs[event.target.name].path));
    };

    const getRecord=(query)=>{
        let bNoRecord=false;
        getDBItem({entityType:query,entityId:slug})
        .then((data)=>{
            console.log('Order',data);
            if('error' in data){
		console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setReadOnly(true);
                setSnackOpened(true);
	        }
            else{
                if(data!=null){
                    if('type' in data && data['type']==='Sales'){
                        setRow(old=>prepareGetData(data,'sales_order'));
                        account_business_id=data.account.business_id;
                        if(authMenuSubItem){
                            setLabel(authMenuSubItem.label);
                            setReadOnly(!('edit' in authMenuSubItem.actions));
                        }
                    }
                    else{
                        bNoRecord=true;
                    }
                }
                else{
                    bNoRecord=true;
                }
            }

            if(bNoRecord){
                alertMessage='Record not found or not accessible';
                alertSeverity='error'
                setReadOnly(true);
                setSnackOpened(true);
            }
        });
    };

    const handleSendInvoice=()=>{
        setOpenBackdrop(true);
        invoicing({entityType:authMenuSubItem.query,entityId:slug,entityData:row})
        .then((response)=>{
            let data=JSON.parse(response);
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
            }
            else{
                alertSeverity='success'
                alertMessage='Invoice created with success!!!';
            }
            setSnackOpened(true);
            setOpenBackdrop(false);
            getRecord(authMenuSubItem.query);
        });
    };

    const handleDialogConfirmUpdateSubmitted=()=>{
        setOpenDialogUpdateSubmitted(false);
        updateSubmittedRecord();
    }

    const updateSubmittedRecord=()=>{
        setOpenBackdrop(true);
        updateDBItem({entityType:authMenuSubItem.query,entityId:slug,entityData:{updated_at:currentTimestamp(),status:'Draft'}})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
            }
            else{
                alertSeverity='success'
                alertMessage='Submitted Sales Order ready for update!';
            }
            getRecord(authMenuSubItem.query);
            setIsChanged(false);
            setSnackOpened(true);
            setOpenBackdrop(false);
            setRefreshPageTab(true);
        });
    };

    const updateRecord=(row_updateds)=>{
        setOpenBackdrop(true);
        updateDBItem({entityType:authMenuSubItem.query,entityId:slug,entityData:row_updateds})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
            }
            else{
                alertSeverity='success'
                alertMessage='Sales Order updated with success!!!';
            }
            getRecord(authMenuSubItem.query);
            setIsChanged(false);
            setSnackOpened(true);
            setOpenBackdrop(false);
            setRefreshPageTab(true);
        });
    };

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)&&(slug!==undefined&&slug!==null&&slug!=='')&&location){
            setActions(authMenuSubItem.actions);
            getRecord(authMenuSubItem.query);
            setPageTabs(old=>sortPageTabs(authMenuSubItem.pageTabs));
            if(!location.pathname.includes(authMenuSubItem.defaultTabPath)||(pagehistory&&(pagehistory!==authMenuSubItem.defaultTabPath))){
                for(let key in authMenuSubItem.pageTabs){
                    if(location.pathname.includes(authMenuSubItem.pageTabs[key].path)){
                        setPageTab(authMenuSubItem.pageTabs[key].index);
                        break;
                    }
                }                
            }
            else if('state' in location){
                if((location.state)&&('message' in location.state)){
                    alertMessage=location.state.message.alertMessage;
                    alertSeverity=location.state.message.alertSeverity;
                    setSnackOpened(true);
                }
            }
            setLabel(authMenuSubItem.label);
        }
    },[authMenuSubItem,slug,userDataAuthorization,location]);

    const handleClickSave=(new_row,new_updates)=>{
        account_business_id='';
        if(validate_required_fields(new_row,'sales_order',setError,setErrorMessage,dataError,required_field_error)){
            if (new_updates['status'] == null && new_row['status'] == 'Submitted'){
		new_updates={status:'Draft'}
	    }
            updateRecord(new_updates);
            setUpdates(old=>{});
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        setIsChanged(false);
        getRecord(authMenuSubItem.query);
        setUpdates(old=>{});
        setError(dataError.error);
        setErrorMessage(dataError.message);
    }

    const handleChange=(event)=>{
        setIsChanged(true);
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        setUpdates(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
    };

    const handleClickDrilldown=(field_name)=>{
        if(field_name==='account_name'){
            window.open('/account/'+row.account_id+'/orders','_blank');
        }
    }

    const handleClickButton=(event)=>{
        switch(event.target.name){
            case "update":
                if(row.delivery_status==='New'){
                    setOpenDialogUpdateSubmitted(true);
                }
                else{
                    setReadOnly(false);
                }
                break;
            case "cancel_sales_order":
                setOpenDialog(true);
                break;
            case "submit_sales_order":
                submitSalesOrder();
                break;
            default: 
                break;
        }
    }

    const submitSalesOrder=()=>{
        setOpenBackdrop(true);
        getDBList({entityType:'Order',entityParams:["SearchExpression=([status]='Overdue' or [status]='Payment Reminder Sent' or [status]='On Debit Collection') and ([account_id]="+row.account_id+")"]})
        .then((data)=>{
            if('error' in data){
		console.log('error',data.error);
                setOpenBackdrop(false);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
	        } 
            else{
                setOpenBackdrop(false);
                if(data.length>0){
                    pendingPaymentOrder=JSON.parse(JSON.stringify(data));
                    setOpenDialogSubmit(old=>true);
                }
                else{
                    handleDialogConfirmSubmit();
                }
            }
        });
    };

    const handleDialogConfirmSubmit=()=>{
        setOpenDialogSubmit(false);
        if(row.account_business_id!==''&&row.account_business_id!==null&&row.account_business_id!==undefined){
            account_business_id=row.account_business_id;
            submitSalesOrderExcise();
        }
        else{
            if(account_business_id===''||account_business_id===null||account_business_id===undefined){
                alertSeverity='error';
                alertMessage='The Account "'+row.account_name+'" does not have a valid Business Id and without it the Sales Order cannot be submitted. Please update the Account Business Id with a valid information and reload the Sales Order page before retrying to submit.';
                setSnackOpened(true);
            }
            else{
                submitSalesOrderExcise();
            }
        }
    }

    const submitSalesOrderExcise=()=>{
        if(!row.export){
            setOpenBackdrop(true);
            getDBList({entityType:'AlcoholLicense',entityParams:['business_id='+account_business_id]})
            .then((data)=>{
                if('error' in data){
                    console.log('error',data.error);
                    setOpenBackdrop(false);
                    alertSeverity='error'
                    alertMessage=data.error;
                    setSnackOpened(true);
                } 
                else{
                    setOpenBackdrop(false);
                    if(data.length>0){
                        if(data.length===1){
                            handleDialogConfirmSubmitExcise(data[0].license_id);
                        }
                        else{
                            exciseOrder=JSON.parse(JSON.stringify(data));
                            setOpenDialogSubmitExcise(old=>true);//select excise
                        }
                    }
                    else{
                        exciseOrder=[];
                        setOpenDialogSubmitExcise(old=>true);//ask if continue without
                    }
                }
            });
        }
        else{
            handleDialogConfirmSubmitExcise(row.excise_number);
        }
    };

    const handleDialogConfirmSubmitExcise=(license_id)=>{
        if(license_id==''||license_id==null||license_id==undefined){
            license_id='NotValid';
        }
        setRow(prevState=>({...prevState,excise_number:license_id}));
        handleClickSave(row,{status:'Submitted',excise_number:license_id,account_business_id:account_business_id});
        setOpenDialogSubmitExcise(false);
    }

    const handleDialogConfirm=()=>{
        handleClickSave(row,{status:'Canceled'});
        setOpenDialog(false);
    }

    const handleClickCalculateDeliveryPrice=()=>{
        let delivery_price_info=sales_order_delivery_price(row.total_price,row.shipping_addr_city);
        alertSeverity='info'
        alertMessage=delivery_price_info.message;
        setSnackOpened(true);
        handleChangeList(delivery_price_info.price,'delivery_price');
    }

    const handleChangeDate=(fieldValue,fieldName)=>{
        setIsChanged(true);
        if(fieldValue&&!isNaN(fieldValue.$D)){
            setRow(prevState=>({...prevState,[fieldName]:fieldValue}));   
            setUpdates(prevState=>({...prevState,[fieldName]:dayjs_to_db(fieldValue)}));
            if(error[fieldName]){
                setError(prevState=>({...prevState,[fieldName]:false}));
                setErrorMessage(prevState=>({...prevState,[fieldName]:''}));
            }
        }
        else{
            setRow(prevState=>({...prevState,[fieldName]:'NaN-NaN-NaN'}));
            setUpdates(prevState=>({...prevState,[fieldName]:null}));
        }
    }

    const handleChangeSwitch=(event)=>{
        const {checked}=event.target;
        handleChange(event={target:{name:event.target.name,value:checked}});
        if('events' in entity_object.sales_order.fields[event.target.name]){
            if('onChange' in entity_object.sales_order.fields[event.target.name].events){
                entity_object.sales_order.fields[event.target.name].events.onChange(event.target.value,handleChangeList);
            }
        }
        if('drive_dynamic_required' in entity_object.sales_order.fields[event.target.name]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[event.target.name]=event.target.value;
            for(const [key,value] of Object.entries(entity_object.sales_order.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.sales_order.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
        if(event.target.name==='export'&&checked){
            if(!row.tax_free){
                handleChangeSwitch(event={target:{name:'tax_free',checked:true}});
            }
        }
    }

    const pageTabCallback=(command)=>{
        if(command==='refreshed'){
            setRefreshPageTab(false);
        }
    }

    const handleChangeCountry=(fieldValue,fieldName)=>{
        if(fieldValue){
            handleChangeList(fieldValue.country,fieldName);
            handleChangeList(fieldValue.phone,'delivery_contact_phone_country_code');
        }
    }

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);

        if('events' in entity_object.sales_order.fields[fieldName]){
            if('onChange' in entity_object.sales_order.fields[fieldName].events){
                entity_object.sales_order.fields[fieldName].events.onChange(fieldValue,handleChangeList,row);
            }
        }

        if('drive_dynamic_required' in entity_object.sales_order.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.sales_order.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.sales_order.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
    };

    const handleDialogCancel=()=>{
        setOpenDialog(false);
    }
    const handleDialogCancelUpdateSubmitted=()=>{
        setOpenDialogUpdateSubmitted(false);
    }
    const handleDialogCancelSubmit=()=>{
        setOpenDialogSubmit(false);
    }
    const handleDialogCancelSubmitExcise=()=>{
        setOpenDialogSubmitExcise(false);
    }
    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }
    const picklistLinkCallback=(selectedRow)=>{
        handleDialogConfirmSubmitExcise(selectedRow.license_id);
    }

    return (
        <div className="page">
            <Dialog fullWidth={true} maxWidth={'lg'} open={openDialogUpdateSubmitted} onClose={handleDialogCancelUpdateSubmitted}>
                <DialogTitle>{"Submitted Order Update"}</DialogTitle>
                <DialogContent>{"Confirm Update of Submitted Order (#"+row.reference+") ?"}</DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancelUpdateSubmitted}>Cancel</Button>
                    <Button onClick={handleDialogConfirmUpdateSubmitted} autoFocus variant="contained">Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} maxWidth={'lg'} open={openDialog} onClose={handleDialogCancel}>
                <DialogTitle>{"Order Cancelation"}</DialogTitle>
                <DialogContent>{"Confirm the Cancelation of the current Order (#"+row.reference+") ?"}</DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel}>Cancel</Button>
                    <Button onClick={handleDialogConfirm} autoFocus variant="contained">Confirm</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'lg'} open={openDialogSubmit} onClose={handleDialogCancelSubmit}>
                <DialogTitle>{"Order Submit - Warning"}</DialogTitle>
                <DialogContent>
                    {"The current customer '"+row.account_name+"' has Order(s) pending payment. Would you like to proceed with the operation?"}
                    <List sx={{width:'100%',maxWidth:360}}>
                        {pendingPaymentOrder.map((order)=>(
                            <ListItem key={order.reference} disableGutters>
                                <ListItemText primary={`Order: ${order.reference} (Status: ${order.status})`} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancelSubmit}>Cancel</Button>
                    <Button onClick={handleDialogConfirmSubmit} autoFocus variant="contained">Confirm</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'lg'} open={openDialogSubmitExcise} onClose={handleDialogCancelSubmitExcise}>
                <DialogTitle>{exciseOrder.length>0?"Order Submit - Excise Number":"Order Submit - Warning"}</DialogTitle>
                <DialogContent>
                    {exciseOrder.length>0?"Select the correct Excise Number from the list below:":"There is no valid Excise Number for this Account. Would you like to proceed with the operation?"}
                    {exciseOrder.length>0&&
                        <div style={{height:'40vh',width:'100%'}}>
                            <DataGrid
                                sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                                rows={exciseOrder}
                                columns={columns.excise_order(picklistLinkCallback)}
                                density="compact"
                                slots={{
                                    toolbar:CustomToolbar,
                                }}
                                componentsProps={{
                                    toolbar:{
                                        showQuickFilter:true,
                                        quickFilterProps:{debounceMs:250},
                                    },
                                }}
                            />
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancelSubmitExcise}>Cancel</Button>
                    {exciseOrder.length===0&&<Button onClick={()=>{handleDialogConfirmSubmitExcise('')}} autoFocus variant="contained">Confirm</Button>}
                </DialogActions>
            </Dialog>
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            {recordInfo&&<RecordInfo recordInfoCallback={recordInfoCallback} row={row} entity_name={'sales_order'} globalData={globalData} userDataAuthorization={userDataAuthorization}></RecordInfo>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.reference}</span></Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        {!readOnly&&<Button disabled={!isChanged} name="save" variant="outlined" onClick={()=>{handleClickSave(row,updates)}}>Save</Button>}
                        {!readOnly&&<Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>}
                        {!readOnly&&<Button name="calculate_delivery_price" variant="outlined" onClick={handleClickCalculateDeliveryPrice}>Calculate Delivery Price</Button>}
                        {readOnly&&send_invoice_status.includes(row.status)&&<Button name="send_invoice" variant="outlined" onClick={handleSendInvoice}>Send Invoice</Button>}
                        {Object.keys(actions).map((action)=>{
		            return (
			        !(action === 'submit_sales_order' && row.status === 'Submitted')
		    	        && !non_editable_status.includes(row.status)
			        && editable_delivery_status.includes(row.delivery_status)
			        && actions[action].type==='button'
			        && readOnly
			        && <Button key={action} name={action} variant="outlined" color={(action==='update'&&row.delivery_status==='New')?'warning':(action==='cancel_sales_order'?'error':action==='submit_sales_order'?'secondary':'primary')} onClick={handleClickButton}>{(action==='update'&&row.delivery_status==='New')?'Update Submitted Order':actions[action].label}</Button>);
                        })}
                    </ButtonGroup>
                    {readOnly&&
                    <IconButton onClick={handleClickMoreInfo} color="primary" style={{position:'absolute',right:'40px'}} size="small">
                        <Tooltip title="More Information on the Record">
                            <InfoIcon fontSize="inherit"/>
                        </Tooltip>
                    </IconButton>}
                    {readOnly&&
                    <IconButton onClick={()=>{
                        setRow(old=>dataPreparedGet);
                        getRecord(authMenuSubItem.query)}} color="primary" style={{position:'absolute',right:'15px'}} size="small">
                        <Tooltip title="Refresh the current Record">
                            <RefreshIcon fontSize="inherit"/>
                            </Tooltip>
                    </IconButton>}
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="reference"
                            name="reference"
                            type={entity_object.sales_order.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.reference.label}
                            value={row.reference}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{color:status_color[row.status],backgroundColor:readOnlyBackgroundColor}}}
                            id="status"
                            name="status"
                            type={entity_object.sales_order.fields.status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.status.label}
                            value={row.status}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="delivery_status"
                            name="delivery_status"
                            type={entity_object.sales_order.fields.delivery_status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_status.label}
                            value={row.delivery_status}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{
                                readOnly:true,                                    
                                endAdornment:(
                                    row.id!==''&&
                                    <InputAdornment position="end">
                                        <IconButton onClick={(e)=>handleClickDrilldown('account_name')} color="primary">
                                            <ArrowCircleRight/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required={entity_object.sales_order.fields.account_id.required}
                            error={error.account_id}
                            helperText={errorMessage.account_id}
                            id="account_name"
                            name="account_name"
                            type={entity_object.account.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.joins.account.fields.name.label}
                            value={row.account.name}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                         {!(row.export||!row.tax_free)&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="price"
                            name="price"
                            type={entity_object.sales_order.fields.price.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.price.label}
                            value={row.price}
                            margin="normal"/>}
                        {(row.export||!row.tax_free)&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="price_included_vat"
                            name="price_included_vat"
                            type={entity_object.sales_order.fields.price_included_vat.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.price_included_vat.label}
                            value={row.price_included_vat}
                            margin="normal"/>}
                        {!row.palpa_free&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="palpa_value"
                            name="palpa_value"
                            type={entity_object.sales_order.fields.palpa_value.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.palpa_value.label}
                            value={row.palpa_value}
                            margin="normal"/>}
                        {row.palpa_free&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="palpa_value"
                            name="palpa_value"
                            type={entity_object.sales_order.fields.palpa_value.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.palpa_value.label}
                            value={'0.00'}
                            margin="normal"/>}
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:readOnly}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:''}}}
                            id="delivery_price"
                            name="delivery_price"
                            type={entity_object.sales_order.fields.delivery_price.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_price.label}
                            value={row.delivery_price}
                            margin="normal"
                            onChange={(e)=>handleChangeList(e.target.value,e.target.name)}/>
                        {!(row.export||!row.tax_free)&&<Tooltip title={entity_object.sales_order.fields.total_price.tooltip}>
                            <TextField
                                style={{marginTop:"0px",marginBottom:"8px"}}
                                InputProps={{readOnly:true}}
                                sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                                id="total_price"
                                name="total_price"
                                type={entity_object.sales_order.fields.total_price.ui_type}
                                fullWidth
                                variant="outlined"
                                size="small"
                                label={entity_object.sales_order.fields.total_price.label}
                                value={row.total_price}
                                margin="normal"/>
                            </Tooltip>}
                        {(row.export||!row.tax_free)&&<Tooltip title={entity_object.sales_order.fields.total_price_included_vat.tooltip}>
                            <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="total_price_included_vat"
                            name="total_price_included_vat"
                            type={entity_object.sales_order.fields.total_price_included_vat.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.total_price_included_vat.label}
                            value={row.total_price_included_vat}
                            margin="normal"/>
                            </Tooltip>}
                    </Stack>
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?'Hide More Info':'Show More Info'}</Button>
                    {showMoreInfo&&<>
                        <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                            <TextField
                                InputProps={{readOnly:readOnly}}
                                inputProps={{maxLength:500}}
                                sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                multiline={true}
                                rows={5}
                                style={{marginTop:"0px",marginBottom:"8px"}}
                                id="description"
                                name="description"
                                type={entity_object.sales_order.fields.description.ui_type}
                                fullWidth
                                variant="outlined"
                                size="small"
                                label={entity_object.sales_order.fields.description.label}
                                value={row.description}
                                margin="normal"
                                onChange={handleChange}/>
                        </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.tax_color} gutterBottom variant="body1">Order Tax</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={false}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            error={error.excise_number}
                            helperText={errorMessage.excise_number}
                            id="excise_number"
                            name="excise_number"
                            type={entity_object.sales_order.fields.excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.excise_number.label}
                            value={row.excise_number}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:readOnly}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            id="warehouse_excise_number"
                            name="warehouse_excise_number"
                            type={entity_object.sales_order.fields.warehouse_excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.warehouse_excise_number.label}
                            value={row.warehouse_excise_number}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='export' checked={row.export} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.sales_order.fields.export.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='palpa_free' checked={row.palpa_free} onChange={handleChangeSwitch} disabled={true}/>} label={entity_object.sales_order.fields.palpa_free.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='tax_free' checked={row.tax_free} onChange={handleChangeSwitch} disabled={(readOnly||entity_object.sales_order.fields.tax_free.readonly(row))}/>} label={entity_object.sales_order.fields.tax_free.label}/>
                        </FormGroup>
                        <Autocomplete
                            style={{marginTop:"0px",width:'50%'}}
                            fullWidth
                            readOnly={(readOnly||entity_object.sales_order.fields.tax_free_reason_code.readonly(row))}
                            options={globalData.listOfValues.TAX_FREE_REASON_CODE||[]}
                            getOptionLabel={(option)=>option}
                            id="tax_free_reason_code"
                            name="tax_free_reason_code"
                            type={entity_object.sales_order.fields.tax_free_reason_code.ui_type}
                            size="small"
                            value={row.tax_free_reason_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'tax_free_reason_code');}}
                            renderInput={(params)=>(
                                <TextField {...params}
                                sx={{div:{backgroundColor:(readOnly||entity_object.sales_order.fields.tax_free_reason_code.readonly(row))?readOnlyBackgroundColor:""}}} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sales_order.fields.tax_free_reason_code.required(row)}
                                    error={error.tax_free_reason_code}
                                    helperText={errorMessage.tax_free_reason_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sales_order.fields.tax_free_reason_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.invoice_color} gutterBottom variant="body1">Order Invoicing</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.invoice_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.payment_terms.required}
                            error={error.payment_terms}
                            helperText={errorMessage.payment_terms}
                            id="payment_terms"
                            name="payment_terms"
                            type={entity_object.sales_order.fields.payment_terms.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.payment_terms.label}
                            value={row.payment_terms}
                            margin="normal"
                            onChange={handleChange}/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            readOnly={readOnly}
                            options={globalData.listOfValues.INVOICE_CHANNEL||[]}
                            getOptionLabel={(option)=>option}
                            id="invoice_channel"
                            name="invoice_channel"
                            type={entity_object.sales_order.fields.invoice_channel.ui_type}
                            size="small"
                            value={row.invoice_channel}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'invoice_channel');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sales_order.fields.invoice_channel.required}
                                    error={error.invoice_channel}
                                    helperText={errorMessage.invoice_channel}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sales_order.fields.invoice_channel.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.sales_order.fields.invoice_email.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.sales_order.fields.invoice_email.readonly(row))}}
                            required={entity_object.sales_order.fields.invoice_email.required(row)}
                            error={error.invoice_email}
                            helperText={errorMessage.invoice_email}
                            id="invoice_email"
                            name="invoice_email"
                            type={entity_object.sales_order.fields.invoice_email.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoice_email.label}
                            value={row.invoice_email}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="invoicing_number"
                            name="invoicing_number"
                            type={entity_object.sales_order.fields.invoicing_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoicing_number.label}
                            value={row.invoicing_number}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="invoicing_date"
                            name="invoicing_date"
                            type={entity_object.sales_order.fields.invoicing_date.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoicing_date.label}
                            value={adjustDateLocale(row.invoicing_date)}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.invoice_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='no_invoicing' checked={row.no_invoicing} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.sales_order.fields.no_invoicing.label}/>
                        </FormGroup>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.sales_order.fields.invoice_e_number.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.sales_order.fields.invoice_e_number.readonly(row))}}
                            required={entity_object.sales_order.fields.invoice_e_number.required(row)}
                            error={error.invoice_e_number}
                            helperText={errorMessage.invoice_e_number}
                            id="invoice_e_number"
                            name="invoice_e_number"
                            type={entity_object.sales_order.fields.invoice_e_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoice_e_number.label}
                            value={row.invoice_e_number}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.sales_order.fields.invoice_e_operator.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.sales_order.fields.invoice_e_operator.readonly(row))}}
                            required={entity_object.sales_order.fields.invoice_e_operator.required(row)}
                            error={error.invoice_e_operator}
                            helperText={errorMessage.invoice_e_operator}
                            id="invoice_e_operator"
                            name="invoice_e_operator"
                            type={entity_object.sales_order.fields.invoice_e_operator.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoice_e_operator.label}
                            value={row.invoice_e_operator}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.sales_order.fields.order_number.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.sales_order.fields.order_number.readonly(row))}}
                            required={entity_object.sales_order.fields.order_number.required(row)}
                            error={error.order_number}
                            helperText={errorMessage.order_number}
                            id="order_number"
                            name="order_number"
                            type={entity_object.sales_order.fields.order_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.order_number.label}
                            value={row.order_number}
                            margin="normal"
                            onChange={handleChange}/>


                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.delivery_color} gutterBottom variant="body1">Order Delivery</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi' localeText={localeText}>
                            <MobileDatePicker
                                sx={{width:'100%',marginTop:"0px",marginBottom:"0px",div:{marginBottom:"8px",backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                readOnly={readOnly}
                                slotProps={{textField:{
                                    required:entity_object.sales_order.fields.date_of_arrival.required,
                                    margin:"normal",
                                    size:"small",
                                    variant:"outlined",
                                    error:error.date_of_arrival,
                                    helperText:errorMessage.date_of_arrival}}}
                                label={entity_object.sales_order.fields.date_of_arrival.label}
                                value={dayjs(row.date_of_arrival)}
                                onChange={(fieldValue)=>handleChangeDate(fieldValue,'date_of_arrival')}/>
                        </LocalizationProvider>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            readOnly={readOnly}
                            options={globalData.listOfValues.DELIVER_METHOD||[]}
                            getOptionLabel={(option)=>option}
                            id="delivery_method"
                            name="delivery_method"
                            type={entity_object.account.fields.delivery_method.ui_type}
                            size="small"
                            value={row.delivery_method}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'delivery_method');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sales_order.fields.delivery_method.required}
                                    error={error.delivery_method}
                                    helperText={errorMessage.delivery_method}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sales_order.fields.delivery_method.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="delivery_contact_name"
                            name="delivery_contact_name"
                            type={entity_object.sales_order.fields.delivery_contact_name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_name.label}
                            value={row.delivery_contact_name}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="delivery_date"
                            name="delivery_date"
                            type={entity_object.sales_order.fields.delivery_date.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_date.label}
                            value={adjustDateLocale(row.delivery_date)}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Country 
                            name={'delivery_contact_phone_country'} 
                            value={row.delivery_contact_phone_country} 
                            label={entity_object.sales_order.fields.delivery_contact_phone_country.label}
                            readOnly={readOnly} 
                            required={entity_object.sales_order.fields.delivery_contact_phone_country.required}
                            error={error.delivery_contact_phone_country}
                            helperText={errorMessage.delivery_contact_phone_country}
                            type={entity_object.sales_order.fields.delivery_contact_phone_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeCountry(newValue,'delivery_contact_phone_country');}}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="delivery_contact_phone_country_code"
                            name="delivery_contact_phone_country_code"
                            type={entity_object.sales_order.fields.delivery_contact_phone_country_code.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_phone_country_code.label}
                            value={row.delivery_contact_phone_country_code}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.delivery_contact_phone.required}
                            error={error.delivery_contact_phone}
                            helperText={errorMessage.delivery_contact_phone}
                            id="delivery_contact_phone"
                            name="delivery_contact_phone"
                            type={entity_object.sales_order.fields.delivery_contact_phone.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_phone.label}
                            value={row.delivery_contact_phone}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="delivery_contact_email"
                            name="delivery_contact_email"
                            type={entity_object.sales_order.fields.delivery_contact_email.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_email.label}
                            value={row.delivery_contact_email}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.billing_address_color} gutterBottom variant="body1">Order Billing Address</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.billing_addr_street.required(row)}
                            error={error.billing_addr_street}
                            helperText={errorMessage.billing_addr_street}
                            id="billing_addr_street"
                            name="billing_addr_street"
                            type={entity_object.sales_order.fields.billing_addr_street.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_street.label}
                            value={row.billing_addr_street}
                            margin="normal"
                            onChange={handleChange}/>
                        <Country 
                            name={'billing_addr_country'}
                            value={row.billing_addr_country} 
                            label={entity_object.sales_order.fields.billing_addr_country.label}
                            readOnly={readOnly} 
                            required={entity_object.sales_order.fields.billing_addr_country.required}
                            error={error.billing_addr_country}
                            helperText={errorMessage.billing_addr_country}
                            type={entity_object.sales_order.fields.billing_addr_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeList(newValue.country,'billing_addr_country');}}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="billing_addr_specifier"
                            name="billing_addr_specifier"
                            type={entity_object.sales_order.fields.billing_addr_specifier.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_specifier.label}
                            value={row.billing_addr_specifier}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.billing_addr_city.required(row)}
                            error={error.billing_addr_city}
                            helperText={errorMessage.billing_addr_city}
                            id="billing_addr_city"
                            name="billing_addr_city"
                            type={entity_object.sales_order.fields.billing_addr_city.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_city.label}
                            value={row.billing_addr_city}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.billing_addr_zip.required(row)}
                            error={error.billing_addr_zip}
                            helperText={errorMessage.billing_addr_zip}
                            id="billing_addr_zip"
                            name="billing_addr_zip"
                            type={entity_object.sales_order.fields.billing_addr_zip.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_zip.label}
                            value={row.billing_addr_zip}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="billing_addr_subdivision"
                            name="billing_addr_subdivision"
                            type={entity_object.sales_order.fields.billing_addr_subdivision.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_subdivision.label}
                            value={row.billing_addr_subdivision}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'20px',backgroundColor:entity_object.sales_order.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={2}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="billing_addr_comments"
                            name="billing_addr_comments"
                            type={entity_object.sales_order.fields.billing_addr_comments.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_comments.label}
                            value={row.billing_addr_comments}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.shipping_address_color} gutterBottom variant="body1">Order Shipping Address</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.shipping_addr_street.required}
                            error={error.shipping_addr_street}
                            helperText={errorMessage.shipping_addr_street}
                            id="shipping_addr_street"
                            name="shipping_addr_street"
                            type={entity_object.sales_order.fields.shipping_addr_street.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_street.label}
                            value={row.shipping_addr_street}
                            margin="normal"
                            onChange={handleChange}/>
                        <Country 
                            name={'shipping_addr_country'}
                            value={row.shipping_addr_country} 
                            label={entity_object.sales_order.fields.shipping_addr_country.label}
                            readOnly={readOnly} 
                            required={entity_object.sales_order.fields.shipping_addr_country.required}
                            error={error.shipping_addr_country}
                            helperText={errorMessage.shipping_addr_country}
                            type={entity_object.sales_order.fields.shipping_addr_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeList(newValue.country,'shipping_addr_country');}}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="shipping_addr_specifier"
                            name="shipping_addr_specifier"
                            type={entity_object.sales_order.fields.shipping_addr_specifier.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_specifier.label}
                            value={row.shipping_addr_specifier}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.shipping_addr_city.required}
                            error={error.shipping_addr_city}
                            helperText={errorMessage.shipping_addr_city}
                            id="shipping_addr_city"
                            name="shipping_addr_city"
                            type={entity_object.sales_order.fields.shipping_addr_city.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_city.label}
                            value={row.shipping_addr_city}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sales_order.fields.shipping_addr_zip.required}
                            error={error.shipping_addr_zip}
                            helperText={errorMessage.shipping_addr_zip}
                            id="shipping_addr_zip"
                            name="shipping_addr_zip"
                            type={entity_object.sales_order.fields.shipping_addr_zip.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_zip.label}
                            value={row.shipping_addr_zip}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="shipping_addr_subdivision"
                            name="shipping_addr_subdivision"
                            type={entity_object.sales_order.fields.shipping_addr_subdivision.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_subdivision.label}
                            value={row.shipping_addr_subdivision}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'20px',backgroundColor:entity_object.sales_order.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={2}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="shipping_addr_comments"
                            name="shipping_addr_comments"
                            type={entity_object.sales_order.fields.shipping_addr_comments.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_comments.label}
                            value={row.shipping_addr_comments}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    </>}
                </Box>
            </div>
            <Box sx={{bgcolor:'none'}}>
                <Tabs
                    value={pageTab}
                    onChange={handleChangeTab}
                    sx={{"& button:hover":{backgroundColor:'lightgrey'},"& button:focus":{backgroundColor:'white'},"& .MuiTab-root.Mui-selected":{backgroundColor:'white'},}}
                    variant="scrollable"
                    scrollButtons="auto">
                    {Object.keys(pageTabs).map((item)=>{
                        return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                    })}
                </Tabs>
                {Object.keys(pageTabs).length>0&&('items' in pageTabs)&&pageTab===pageTabs['items'].index&&<SalesOrderDetailItems order_status={row.status} tax_free={row.export||row.tax_free} order_palpa_free={row.palpa_free} order_id={row.id} order_logistics_id={row.logistics_id} orderItemCallback={orderItemCallback} pageTab={('items' in pageTabs)?pageTabs['items']:{}} globalData={globalData} userDataAuthorization={userDataAuthorization}></SalesOrderDetailItems>}
                {Object.keys(pageTabs).length>0&&('audit' in pageTabs)&&pageTab===pageTabs['audit'].index&&<Audit record_id={row.id} pageTab={('audit' in pageTabs)?pageTabs['audit']:{}} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} entity_name='sales_order' globalData={globalData} userDataAuthorization={userDataAuthorization}></Audit>}
            </Box>
        </div>
    );
}

export default SalesOrderDetail;
