import {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {Box,Grid,Button,Typography} from '@mui/material';
import {useKeycloak} from "@react-keycloak/web";

const Home=({menuConfigAuthorization})=>{
    const {keycloak}=useKeycloak();
    const isLoggedIn=keycloak.authenticated;
    const navigate=useNavigate();

    useEffect(()=>{
        if(isLoggedIn&&localStorage.getItem('handleLoginClicked')==='Y'&&menuConfigAuthorization&&Object.keys(menuConfigAuthorization).length>0){
            localStorage.setItem('handleLoginClicked','N');
            navigate(menuConfigAuthorization.landingPage);
        }
    },[isLoggedIn,menuConfigAuthorization]);

    const handleLogin=()=>{
        localStorage.setItem('handleLoginClicked','Y');
        keycloak.login();
    }
    
    const connected=()=>{
        return <Typography color={'blue'} variant="h3" gutterBottom>Connected</Typography>;
    }
    const desconnected=()=>{
        return <Typography color={'red'} variant="h3" gutterBottom>Not Connected {localStorage.getItem('sessionExpiredMessage')}</Typography>
    }

    return (
        <Box sx={{p:2}}>
            <Grid
                container
                direction="column"
                rowSpacing={4}
                justifyContent="center"
                alignItems="center">
                <Grid style={{marginTop:'50px'}} item xs={1}>
                    {isLoggedIn?connected():desconnected()}
                </Grid>
                <Grid item xs={1}>
                    {!isLoggedIn&&<Button variant="contained" size="large" onClick={handleLogin}>Login</Button>}
                </Grid>
            </Grid>
        </Box>
    )
}

export default Home
