import {useEffect,useState,useCallback} from "react";
import {Box,Typography,ButtonGroup,Button,Stack,Snackbar,Alert} from '@mui/material';
import {GridRowModes,DataGrid,GridToolbarContainer,GridRowEditStopReasons,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {columns} from '../../utilities/Entities';
import {createDBItem,updateDBItem,deleteDBItem} from '../../integration/DatabaseManager.js';
import {dateFns_to_ui,dateFns_to_db} from '../../utilities/Utilities';
import locale from 'date-fns/locale/fi';
import {format,parseISO} from 'date-fns';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
//import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
let alertMessage='';
let alertSeverity='error'
let new_id_count=0;

const ProductDetailBatches=({parent,pageTab})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [snackOpened,setSnackOpened]=useState(false);
    const [selection,setSelection]=useState([]);
    const [actions,setActions]=useState({});
    const [label,setLabel]=useState('');
    const [rowModesModel,setRowModesModel]=useState({});
    const {globalData}=useContext(ApplicationContext);

    const handleRowEditStop=(params,event)=>{
        if(params.reason===GridRowEditStopReasons.rowFocusOut){
            event.defaultMuiPrevented=true;
        }
    };

    const handleProcessRowUpdateError=useCallback((error)=>{
            console.log('handleProcessRowUpdateError',error);
        },[]
    );

    const handleRowModesModelChange=(newRowModesModel)=>{
        setRowModesModel(newRowModesModel);
    };

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    useEffect(()=>{
        if(pageTab&&Object.keys(pageTab).length>0&&parent&&Object.keys(parent).length>0){
            setActions(pageTab.actions);
            let data=parent.batches;
            if(data.length>0){
                for(let lov in data){
                    data[lov].id_temp=data[lov].id;
                    if(data[lov].production_date!==null){
                        data[lov].production_date=parseISO(data[lov].production_date.substring(0,10));
                    }
                    if(data[lov].expiration_date!==null){
                        data[lov].expiration_date=parseISO(data[lov].expiration_date.substring(0,10));
                    }
                }
                setRows(old=>data);
            }
            setLoading(false);
        }   
    },[pageTab,parent]);

    const handleClickListButton=(event)=>{
        switch(event.target.name){
            case "add_batch":
                const id_temp='New-'+new_id_count;
                let production_date=dateFns_to_db(new Date());
                let today_date=new Date();
                today_date.setDate(today_date.getDate()+today_date.getDate()+parent.min_usage_days);
                let expiration_date=dateFns_to_db(today_date);
                let new_row={id_temp,status:'Active',reference:'(auto generated)',product_id:parent.id,quantity:1,production_date:parseISO(production_date),expiration_date:parseISO(expiration_date),description:'',isNew:true};
                let new_set=[new_row].concat(rows);
                setRows((oldRows)=>new_set);
                setRowModesModel((oldModel)=>({
                    ...oldModel,
                    [id_temp]:{mode:GridRowModes.Edit,fieldToFocus:'quantity'},
                }));
                new_id_count++;
                break;
            default: 
                break;
        }
    }

    const processRowUpdate=useCallback(
        async(newRow)=>{
            if('production_date' in newRow){
                if(newRow.production_date!==null){
                    newRow.production_date__db=dateFns_to_db(newRow.production_date);
                    newRow.production_date=dateFns_to_ui(newRow.production_date);
                }
            }
            if('expiration_date' in newRow){
                if(newRow.expiration_date!==null){
                    newRow.expiration_date__db=dateFns_to_db(newRow.expiration_date);
                    newRow.expiration_date=dateFns_to_ui(newRow.expiration_date);
                }
            }

            let isNew=false;
            if('isNew' in newRow){isNew=newRow.isNew;}
            else{newRow.isNew=false;}
            let temp_id=newRow.id_temp;
            //let bkp_rows=JSON.parse(JSON.stringify(rows));
            let bkp_rows=rows;
            let new_rec={};
            if(isNew){
                const newRecord=await createRecord(newRow);
                const newId=newRecord.id;
                const newReference=newRecord.reference;
                let new_set=[];
                bkp_rows.forEach(element=>{
                    if(typeof element.production_date==='string'){
                        element.production_date=parseISO(element.production_date.substring(0,10));
                    }
                    if(typeof element.expiration_date==='string'){
                        element.expiration_date=parseISO(element.expiration_date.substring(0,10));
                    }
                    if(temp_id===element.id_temp){
                        new_rec={...newRow,id:newId,reference:newReference,isNew:false};
                        new_rec.production_date=parseISO(new_rec.production_date__db);
                        new_rec.expiration_date=parseISO(new_rec.expiration_date__db);
                        new_set.push(new_rec);
                    }
                    else{
                        new_set.push(element);
                    }
                });
                setRows((oldRows)=>new_set);
                setRowModesModel({...rowModesModel,[newId]:{mode:GridRowModes.View}});
                return new_rec;
            }
            else{
                updateRecord(newRow.id,newRow);
                newRow.production_date=parseISO(newRow.production_date__db.substring(0,10));
                newRow.expiration_date=parseISO(newRow.expiration_date__db.substring(0,10));
                return newRow;
            }
        },[rows],
    );

    function createRecord(record){
        return new Promise((resolve)=>{
            let new_record=JSON.parse(JSON.stringify(record))
            delete new_record.id;
            delete new_record.id_temp;
            delete new_record.isNew;
            delete new_record.reference;
            new_record.production_date=new_record.production_date__db;
            new_record.expiration_date=new_record.expiration_date__db;
            delete new_record.production_date__db;
            delete new_record.expiration_date__db;
            console.log('new_record',new_record);
            createDBItem({entityType:'Batch',entityData:new_record})
            .then((data)=>{
                if('error' in data){
                    console.log('error',data.error);
                    alertSeverity='error'
                    alertMessage=data.error;
                    setSnackOpened(true);
                }
                else{
                    console.log('data',data);
                    alertSeverity='success';
                    alertMessage='Batch created with success!!!';
                    setSnackOpened(true);
                    resolve({id:data.id,reference:data.reference});
                }
            });
        });
      }

    const updateRecord=(row_id,newRow)=>{
        let updates=JSON.parse(JSON.stringify(newRow));
        delete updates.created_at;
        delete updates.created_by;
        delete updates.updated_at;
        delete updates.updated_by;
        delete updates.id_temp;
        delete updates.isNew;
        updates.production_date=updates.production_date__db;
        updates.expiration_date=updates.expiration_date__db;
        delete updates.production_date__db;
        delete updates.expiration_date__db;
        console.log('updates',updates);
        updateDBItem({entityType:'Batch',entityId:row_id,entityData:updates})
        .then((data)=>{  
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else if('update' in data){
                console.log('update',data.update);
                alertSeverity='success';
                alertMessage='Batch updated with success!!!';
                setSnackOpened(true);
            }
        });
    };

    const deleteRecord=(record_id)=>{
        deleteDBItem({entityType:'Batch',entityId:record_id})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else if('delete' in data){
                console.log('update',data.delete);
                alertSeverity='success';
                alertMessage='Batch record(s) removed with success!!!';
                setSnackOpened(true);
            }
        });
    };

    const handleSelection=(ids)=>{
        setSelection(old=>ids);
    };

    const handleClickRemove=()=>{
        let list_of_id_temps=JSON.parse(JSON.stringify(selection));
        let list_of_id=[];
        let list_of_id_to_remove_ui=[];
        let rows_after_delete=[];
        let rows_before_delete=rows;
        rows.forEach(element=>{
            if(list_of_id_temps.includes(element.id_temp)){
                if(element.id!==undefined&&element.id!==null&&element.id!==''){
                    list_of_id.push(element.id);
                }
                else{
                    list_of_id_to_remove_ui.push(element.id_temp);
                }
            }
        });
        list_of_id.forEach(function callback(element,index){
            deleteRecord(element);
        });

        rows_before_delete.forEach(element=>{
            if(!list_of_id.includes(element.id)&&!list_of_id_to_remove_ui.includes(element.id_temp)){
                rows_after_delete.push(element);
            }
        });
        setRows((oldRows)=>rows_after_delete);
        setSelection(old=>[]);
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
            <div className="page-content">
                <Snackbar open={snackOpened} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                    <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
                </Snackbar>
                <ButtonGroup size="small" aria-label="small button group">
                {<Button disabled={selection.length<=0} name="remove" variant="outlined" onClick={handleClickRemove}>Remove</Button>}
                    {Object.keys(pageTab).length>0&&Object.keys(pageTab.actions).map((action)=>{
                        return(pageTab.actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{pageTab.actions[action].label}</Button>);
                    })}
                </ButtonGroup>
                <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                    <div style={{height:'75vh',width:'100%'}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                            <DataGrid
                                sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                                getRowId={(row)=>row.id_temp}
                                //onSelectionModelChange={handleSelection}
                                onRowSelectionModelChange={handleSelection}
                                checkboxSelection
                                disableRowSelectionOnClick
                                disableSelectionOnClick
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                experimentalFeatures={{newEditingApi:true}}
                                slots={{
                                    toolbar:CustomToolbar,
                                }}
                                componentsProps={{
                                    toolbar:{
                                        showQuickFilter:true,
                                        quickFilterProps:{debounceMs:250},
                                    },
                                }}
                                initialState={{
                                    sorting:{
                                        sortModel:[{field:'id',sort:'desc'}],
                                    },
                                }}
                                rows={rows}
                                columns={columns.product_batches(setRowModesModel,rowModesModel,rows,setRows,deleteRecord,null,parent)}
                                loading={loading}
                                density="compact"
                            />
                        </LocalizationProvider>
                    </div>
                </Stack>
            </div>
    );
}

export default ProductDetailBatches;
