import {useEffect,useState,useRef,forwardRef} from "react";
import {Box,Snackbar,Alert,Divider,Autocomplete,Stack,TextField,Dialog,Button,DialogTitle,DialogContent,DialogActions} from '@mui/material';
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {getDBList} from '../../integration/DatabaseManager.js';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {NumberField} from '../../utilities/NumberField.js';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
const color1='#d3d3d342';
const color2='#1876d117'
let color=color2;
let alertMessage='';
let alertSeverity='success';
const ERROR_MESSAGE_REQUIRED_FIELD='Required field';

const PaperComponent=forwardRef(function PaperComponent(props,ref){
    return (
        <Draggable handle="#draggable-dialog-product-new-popup-sku" nodeRef={ref}>
            <Paper ref={ref} {...props} />
        </Draggable>
    );
});

const ProductNewPopupSKU=({popupListCallback,newProduct,globalData})=>{
    const [rows,setRows]=useState({});
    const [autoFocus,setAutoFocus]=useState('');
    const nodeRef=useRef(null);
    const [snackOpened,setSnackOpened]=useState(false);
    const [errorMessage,setErrorMessage]=useState({});
    const [error,setError]=useState({});

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const getRecords=()=>{
        getDBList({entityType:'PackageType',entityParams:["SearchExpression=([status]='Active')"]})
        .then((data)=>{
            console.log('data',data);
            if('error' in data){
                console.log('error',data.error);
            }
            else{
                if(data.length>0){
                    let childRecordTemp=[];
                    let parentRecordTemp=[];
                    let rowTemp={};
                    
                    data.forEach(e=>{
                        if(e.parent_id){
                            childRecordTemp.push(e);
                        }
                        else{
                            parentRecordTemp.push(e);
                        }
                    });
                    parentRecordTemp.forEach(e=>{
                        let palpa='';
                        let palpa_value=0.00;
                        if(e.sku_type==='Single'&&e.type==='Keg'){
                            palpa='Other';
                        }
                        else if(e.sku_type==='Single'&&e.type==='Can'){
                            palpa='DepositBasedReturnSystem';
                            palpa_value=0.15;
                        }
                        rowTemp[e.id]={
                            selected:e.product_creation_default,
                            title:generateTitleSKU(e),
                            name:generateNameSKU(e,null),
                            price:'',
                            palpa:palpa,
                            palpa_value:palpa_value,
                            package_type_id:e.id,
                            amount:e.amount,
                            children:[]
                        }
                    });
                    childRecordTemp.forEach(e=>{
                        if(e.parent_id in rowTemp){
                            let palpa=rowTemp[e.parent_id].palpa;
                            let palpa_value=rowTemp[e.parent_id].palpa_value;
                            if(e.sku_type==='Multipack'){
                                palpa_value=parseFloat(parseInt(e.amount)*rowTemp[e.parent_id].palpa_value).toFixed(2);
                            }
                            rowTemp[e.parent_id].children[e.id]={
                                selected:e.product_creation_default,
                                title:generateTitleSKU(e),
                                name:generateNameSKU(e,rowTemp[e.parent_id]),
                                price:'',
                                palpa:palpa,
                                palpa_value:palpa_value,
                                package_type_id:e.id,
                                parent_id:e.parent_id,
                                amount:parseInt(e.amount)
                            };
                        }
                    });
                    setRows(old=>rowTemp);
                }
            }
        });
    };

    useEffect(()=>{
        getRecords();
    },[]);

    const handleClickCancel=()=>{
        popupListCallback(null);
    }

    const handleClickSave=()=>{
        let errorMessageTemp={};
        let errorTemp={};
        let skuStructure=[];
        for(const k in rows){
            if(rows[k].selected){
                let parent=JSON.parse(JSON.stringify(rows[k]));
                if(parent.name===''||parent.name===null){
                    errorTemp['name-'+k]=true;
                    errorMessageTemp['name-'+k]=ERROR_MESSAGE_REQUIRED_FIELD;
                }
                if(parent.palpa===''||parent.palpa===null){
                    errorTemp['palpa-'+k]=true;
                    errorMessageTemp['palpa-'+k]=ERROR_MESSAGE_REQUIRED_FIELD;
                }
                if(parent.palpa_value===''||parent.palpa_value===null){
                    errorTemp['palpa_value-'+k]=true;
                    errorMessageTemp['palpa_value-'+k]=ERROR_MESSAGE_REQUIRED_FIELD;
                }
                if(parent.price===''||parent.price===null){
                    errorTemp['price-'+k]=true;
                    errorMessageTemp['price-'+k]=ERROR_MESSAGE_REQUIRED_FIELD;
                }
                delete parent.children;
                delete parent.selected;
                delete parent.title;
                delete parent.amount;
                parent.children=[];
                for(const j in rows[k].children){
                    if(rows[k].children[j].selected){
                        let child=JSON.parse(JSON.stringify(rows[k].children[j]));
                        if(child.name===''||child.name===null){
                            errorTemp['name-'+j]=true;
                            errorMessageTemp['name-'+j]=ERROR_MESSAGE_REQUIRED_FIELD;
                        }
                        if(child.palpa===''||child.palpa===null){
                            errorTemp['palpa-'+j]=true;
                            errorMessageTemp['palpa-'+j]=ERROR_MESSAGE_REQUIRED_FIELD;
                        }
                        if(child.palpa_value===''||child.palpa_value===null){
                            errorTemp['palpa_value-'+j]=true;
                            errorMessageTemp['palpa_value-'+j]=ERROR_MESSAGE_REQUIRED_FIELD;
                        }
                        if(child.price===''||child.price===null){
                            errorTemp['price-'+j]=true;
                            errorMessageTemp['price-'+j]=ERROR_MESSAGE_REQUIRED_FIELD;
                        }
                        delete child.selected;
                        delete child.title;
                        delete child.parent_id;
                        delete child.amount;
                        parent.children.push(child);
                    }
                }
                skuStructure.push(parent);
            }
        }

        if(Object.keys(errorMessageTemp).length!==0){
            setError(old=>errorTemp);
            setErrorMessage(old=>errorMessageTemp);
            alertSeverity='error';
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
        else{
            popupListCallback(skuStructure);
        }
    }

    const handleChange=(id,e)=>{
        if(snackOpened){setSnackOpened(old=>false);}
        if(e.target.name+'-'+id in error){
            if(error[e.target.name+'-'+id]){
                setError(prevState=>({...prevState,[e.target.name+'-'+id]:false}));
            }
            if(errorMessage[e.target.name+'-'+id]!==''){
                setErrorMessage(prevState=>({...prevState,[e.target.name+'-'+id]:''}));
            }
        }
        setRows(prevState=>({...prevState,[id]:{...prevState[id],[e.target.name]:e.target.value}}));
        for(const k in rows[id].children){
            if(e.target.name==='palpa_value'||e.target.name==='price'){
                handleChangeChild(id,k,{target:{name:e.target.name,value:parseFloat(parseInt(rows[id].children[k].amount)*e.target.value).toFixed(2)}},true);
            }
        }
        setAutoFocus(old=>e.target.name+'-'+id);
    };

    const handleChangeCheckbox=(id,row)=>{
        if(snackOpened){setSnackOpened(old=>false);}
        setRows(prevState=>({...prevState,[id]:{...prevState[id],selected:!row.selected}}));
        if(row.selected){
            setError(prevState=>({...prevState,['name-'+id]:false}));
            setError(prevState=>({...prevState,['palpa-'+id]:false}));
            setError(prevState=>({...prevState,['palpa_value-'+id]:false}));
            setError(prevState=>({...prevState,['price-'+id]:false}));
            setErrorMessage(prevState=>({...prevState,['name-'+id]:''}));
            setErrorMessage(prevState=>({...prevState,['palpa-'+id]:''}));
            setErrorMessage(prevState=>({...prevState,['palpa_value-'+id]:''}));
            setErrorMessage(prevState=>({...prevState,['price-'+id]:''}));
        }
        for(const k in row.children){
            handleChangeCheckboxChild(id,k,row.children[k],!row.selected);
        }
        setAutoFocus(old=>'');
    }

    const handleChangeList=(id,fieldValue,fieldName)=>{
        if(snackOpened){setSnackOpened(old=>false);}
        if(fieldName+'-'+id in error){
            if(error[fieldName+'-'+id]){
                setError(prevState=>({...prevState,[fieldName+'-'+id]:false}));
            }
            if(errorMessage[fieldName+'-'+id]!==''){
                setErrorMessage(prevState=>({...prevState,[fieldName+'-'+id]:''}));
            }
        }
        setRows(prevState=>({...prevState,[id]:{...prevState[id],[fieldName]:fieldValue}}));
        for(const k in rows[id].children){
            handleChangeListChild(id,k,fieldValue,fieldName,true);
        }
        setAutoFocus(old=>fieldName+'-'+id);
    };

    const handleChangeChild=(id,child_id,e,cascade)=>{
        if(snackOpened){setSnackOpened(old=>false);}
        if(e.target.name+'-'+child_id in error){
            if(error[e.target.name+'-'+child_id]){
                setError(prevState=>({...prevState,[e.target.name+'-'+child_id]:false}));
            }
            if(errorMessage[e.target.name+'-'+child_id]!==''){
                setErrorMessage(prevState=>({...prevState,[e.target.name+'-'+child_id]:''}));
            }
        }
        setRows(prevState=>(
            {...prevState,[id]:
                {...prevState[id],children:
                    {...prevState[id].children,[child_id]:
                        {...prevState[id].children[child_id],[e.target.name]:e.target.value}
                    }}}));
        if(!cascade){
            setAutoFocus(old=>e.target.name+'-'+child_id);
        }
    };

    const handleChangeCheckboxChild=(id,child_id,row,selected)=>{
        if(snackOpened){setSnackOpened(old=>false);}
        if(row.selected){
            setError(prevState=>({...prevState,['name-'+child_id]:false}));
            setError(prevState=>({...prevState,['palpa-'+child_id]:false}));
            setError(prevState=>({...prevState,['palpa_value-'+child_id]:false}));
            setError(prevState=>({...prevState,['price-'+child_id]:false}));
            setErrorMessage(prevState=>({...prevState,['name-'+child_id]:''}));
            setErrorMessage(prevState=>({...prevState,['palpa-'+child_id]:''}));
            setErrorMessage(prevState=>({...prevState,['palpa_value-'+child_id]:''}));
            setErrorMessage(prevState=>({...prevState,['price-'+child_id]:''}));
        }
        setRows(prevState=>(
            {...prevState,[id]:
                {...prevState[id],children:
                    {...prevState[id].children,[child_id]:
                        {...prevState[id].children[child_id],selected:selected!==undefined?selected:!row.selected}
                    }}}));
        setAutoFocus(old=>'');
    }

    const handleChangeListChild=(id,child_id,fieldValue,fieldName,cascade)=>{
        if(snackOpened){setSnackOpened(old=>false);}
        setRows(prevState=>(
            {...prevState,[id]:
                {...prevState[id],children:
                    {...prevState[id].children,[child_id]:
                        {...prevState[id].children[child_id],[fieldName]:fieldValue}
                    }}}));
        if(fieldName+'-'+child_id in error){
            if(error[fieldName+'-'+child_id]){
                setError(prevState=>({...prevState,[fieldName+'-'+child_id]:false}));
            }
            if(errorMessage[fieldName+'-'+child_id]!==''){
                setErrorMessage(prevState=>({...prevState,[fieldName+'-'+child_id]:''}));
            }
        }
        if(!cascade){
            setAutoFocus(old=>fieldName+'-'+child_id);
        }
    };

    const generateTitleSKU=(data)=>{
        return 'SKU: '+data.sku_type+' / '+data.type+' / '+data.amount+' / '+data.unit;
    }

    const generateNameSKU=(data,parent)=>{
        let unit='L';
        let amount=data.amount;
        if(data.type==='Keg'){
            amount=parseInt(amount);
        }
        if(data.sku_type==='Multipack'){
            if(parent){
                amount=parseInt(amount)+'x'+parent.amount;
            }
        }
        return newProduct.name + ' ' + newProduct.abv + '% ' + amount + unit;
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={true}
            aria-labelledby="draggable-dialog-product-new-popup-sku"
            PaperComponent={(props)=><PaperComponent ref={nodeRef} {...props}/>}
            onClose={handleClickCancel}>
            <DialogTitle id="draggable-dialog-product-new-popup-sku" style={{cursor:'move'}}>Automatic SKU Structure Creation</DialogTitle>
            <DialogContent style={{paddingLeft:'5px',paddingRight:'5px'}}>
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Box component="form" noValidate autoComplete="off">
                <List sx={{width:'100%',bgcolor:'background.paper'}} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                {Object.keys(rows).map((id)=>{
                    color=color===color1?color2:color1;
                    return (
                        <div key={'div'+id} style={{backgroundColor:color}}>
                            <ListItem key={'ListItem'+id} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                                <Checkbox edge="start" checked={rows[id].selected} tabIndex={-1} disableRipple onChange ={()=>handleChangeCheckbox(id,rows[id])} inputProps={{'aria-labelledby':id}}/>
                                <ListItemText key={'ListItemText'+id} id={id} primary={rows[id].title}/>
                            </ListItem>
                            
                            <Stack key={'Stack'+id} style={{paddingLeft:'10px',paddingRight:'10px'}} direction={{xs:'row',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                                <TextField
                                    autoFocus={autoFocus==='name-'+id}
                                    sx={{input:{fontSize:'small'},width:'40%'}}
                                    id={'name-'+id}
                                    name={'name'}
                                    required={true}
                                    error={error['name-'+id]}
                                    helperText={errorMessage['name-'+id]}
                                    variant="outlined"
                                    size="small"
                                    value={rows[id].name}
                                    label={'SKU Name'}
                                    margin="normal"
                                    onChange={(e)=>handleChange(id,e)}/>
                                <Autocomplete
                                    style={{marginTop:"0px"}}
                                    sx={{width:'30%'}}
                                    options={globalData.listOfValues.PALPA||[]}
                                    getOptionLabel={(option)=>option}
                                    id={'palpa-'+id}
                                    name={'palpa'}
                                    size="small"
                                    value={rows[id].palpa}
                                    onChange={(event,newValue)=>{handleChangeList(id,newValue,'palpa');}}
                                    renderInput={(params)=>(
                                        <TextField {...params}
                                            autoFocus={autoFocus==='palpa-'+id}
                                            sx={{input:{fontSize:'small'}}}
                                            style={{marginTop:"0px"}}
                                            required={true}
                                            error={error['palpa-'+id]}
                                            helperText={errorMessage['palpa-'+id]}
                                            size="small"
                                            variant="outlined"
                                            label={'SKU palpa'}
                                            margin="normal"
                                        />
                                    )}
                                />
                                <NumberField
                                    autoFocus={autoFocus==='palpa_value-'+id}
                                    sx={{input:{fontSize:'small'},width:'15%'}}
                                    id={'palpa_value-'+id}
                                    name={'palpa_value'}
                                    required={true}
                                    error={error['palpa_value-'+id]}
                                    helperText={errorMessage['palpa_value-'+id]}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={rows[id].palpa_value}
                                    label={'SKU Palpa Value'}
                                    margin="normal"
                                    onChange={(e)=>handleChange(id,e)}/>
                                <NumberField
                                    autoFocus={autoFocus==='price-'+id}
                                    sx={{input:{fontSize:'small'},width:'15%'}}
                                    id={'price-'+id}
                                    name={'price'}
                                    required={true}
                                    error={error['price-'+id]}
                                    helperText={errorMessage['price-'+id]}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={rows[id].price}
                                    label={'SKU Price'}
                                    margin="normal"
                                    onChange={(e)=>handleChange(id,e)}/>
                            </Stack>
                            
                            <List sx={{width:'100%',bgcolor:'background.paper'}} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                                {Object.keys(rows[id].children).map((child_id)=>{
                                    return (
                                        <div key={'div'+child_id} style={{backgroundColor:color}}>
                                            <ListItem key={'ListItem'+child_id} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                                                <ListItemIcon style={{minWidth:'unset'}} color="primary">
                                                    <ArrowRightAltIcon />
                                                </ListItemIcon>
                                                <Checkbox edge="start" checked={rows[id].children[child_id].selected} tabIndex={-1} disableRipple inputProps={{'aria-labelledby':child_id}} onChange={()=>handleChangeCheckboxChild(id,child_id,rows[id].children[child_id])}/>
                                                <ListItemText key={'ListItemText'+child_id} id={child_id} primary={rows[id].children[child_id].title}/>
                                            </ListItem>
                                            <Stack key={'Stack'+child_id} style={{paddingLeft:'10px',paddingRight:'10px'}} direction={{xs:'row',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                                                <TextField
                                                    autoFocus={autoFocus==='name-'+child_id}
                                                    sx={{input:{fontSize:'small'},width:'40%'}}
                                                    id={'name-'+child_id}
                                                    name={'name'}
                                                    required={true}
                                                    error={error['name-'+child_id]}
                                                    helperText={errorMessage['name-'+child_id]}
                                                    variant="outlined"
                                                    size="small"
                                                    value={rows[id].children[child_id].name}
                                                    label={'SKU Name'}
                                                    margin="normal"
                                                    onChange={(e)=>handleChangeChild(id,child_id,e,false)}/>
                                                <Autocomplete
                                                    style={{marginTop:"0px"}}
                                                    sx={{width:'30%'}}
                                                    options={globalData.listOfValues.PALPA||[]}
                                                    getOptionLabel={(option)=>option}
                                                    id={'palpa-'+child_id}
                                                    name={'palpa'}
                                                    size="small"
                                                    value={rows[id].children[child_id].palpa}
                                                    onChange={(event,newValue)=>{handleChangeListChild(id,child_id,newValue,'palpa',false);}}
                                                    renderInput={(params)=>(
                                                        <TextField {...params}
                                                            autoFocus={autoFocus==='palpa-'+child_id}
                                                            sx={{input:{fontSize:'small'}}}
                                                            style={{marginTop:"0px"}}
                                                            required={true}
                                                            error={error['palpa-'+child_id]}
                                                            helperText={errorMessage['palpa-'+child_id]}
                                                            size="small"
                                                            variant="outlined"
                                                            label={'SKU palpa'}
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                                <NumberField
                                                    autoFocus={autoFocus==='palpa_value-'+child_id}
                                                    sx={{input:{fontSize:'small'},width:'15%'}}
                                                    id={'palpa_value-'+child_id}
                                                    name={'palpa_value'}
                                                    required={true}
                                                    error={error['palpa_value-'+child_id]}
                                                    helperText={errorMessage['palpa_value-'+child_id]}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    value={rows[id].children[child_id].palpa_value}
                                                    label={'SKU Palpa Value'}
                                                    margin="normal"
                                                    onChange={(e)=>handleChangeChild(id,child_id,e,false)}/>
                                                <NumberField
                                                    autoFocus={autoFocus==='price-'+child_id}
                                                    sx={{input:{fontSize:'small'},width:'15%'}}
                                                    id={'price-'+child_id}
                                                    name={'price'}
                                                    required={true}
                                                    error={error['price-'+child_id]}
                                                    helperText={errorMessage['price-'+child_id]}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    value={rows[id].children[child_id].price}
                                                    label={'SKU Price'}
                                                    margin="normal"
                                                    onChange={(e)=>handleChangeChild(id,child_id,e,false)}/>
                                            </Stack>
                                        </div>
                                    );
                                })}
                            </List>
                            <Divider component="li"/>
                        </div>
                    );
                })}
                </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className="btn" onClick={handleClickSave} variant="contained">Save</Button>
                <Button className="btn" onClick={handleClickCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProductNewPopupSKU;
