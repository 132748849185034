import {useEffect,useState,useCallback} from "react";
import {Box,Typography,ButtonGroup,Button,Stack,Snackbar,Alert} from '@mui/material';
import {GridRowModes,DataGrid,GridToolbarContainer,GridRowEditStopReasons,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {getDBList,createDBItem,updateDBItem,deleteDBItem} from '../../integration/DatabaseManager.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
let alertMessage='';
let alertSeverity='error'
let new_id_count=0;
const list_name='barcodes';

const Barcodes=({authMenuSubItem,userDataAuthorization,globalData})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [snackOpened,setSnackOpened]=useState(false);
    const [selection,setSelection]=useState([]);
    const [actions,setActions]=useState({});
    const [label,setLabel]=useState('');
    const [rowModesModel,setRowModesModel]=useState({});
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);
    const [filterModel,setFilterModel]=useState(globalData.filterModel[list_name]);
    const [sortModel,setSortModel]=useState(globalData.sortModel[list_name]);

    const handleRowEditStop=(params,event)=>{
        if(params.reason===GridRowEditStopReasons.rowFocusOut){
            event.defaultMuiPrevented=true;
        }
    };

    const handleProcessRowUpdateError=useCallback((error)=>{
            console.log('handleProcessRowUpdateError',error);
        },[]
    );

    const handleRowModesModelChange=(newRowModesModel)=>{
        setRowModesModel(newRowModesModel);
    };

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const getRecords=(query,query_args)=>{
        console.log('query',query);
        getDBList({entityType:query})
        .then((data)=>{
            console.log('data',data);
            if('error' in data){
                console.log('error',data.error);
            }
            else if(data.length>0){
                if(data.length>100){//normall it is over 100K, and 100 are loaded in first page.
                    let start_data=data.slice(0,100);
                    for(let start in start_data){
                        start_data[start].id_temp=start_data[start].id;
                    }
                    setRows(old=>start_data);
                    setLoading(false);
                    let end_data=data.slice(100,data.length);
                    for(let end in end_data){
                        end_data[end].id_temp=end_data[end].id;
                    }
                    setRows(old=>start_data.concat(end_data));
                }
                else{
                    for(let lov in data){
                        data[lov].id_temp=data[lov].id;
                    }
                    setRows(old=>data);
                }
            }
            setLoading(false);
        });
    };

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&authMenuSubItem){
            getRecords(authMenuSubItem.query,authMenuSubItem.query_args);
            setLabel(authMenuSubItem.label);
            setActions(authMenuSubItem.actions);
        }   
    },[authMenuSubItem,userDataAuthorization]);

    const handleClickListButton=(event)=>{
        switch(event.target.name){
            case "new_barcode":
                const id_temp='New-'+new_id_count;
                let new_row={id_temp,
                    name:'',
                    status:'Available',
                    product_sku_name:'',
                    comments:'',
                    isNew:true};
                let new_set=[new_row].concat(JSON.parse(JSON.stringify(rows)));
                setRows((oldRows)=>new_set);
                setRowModesModel((oldModel)=>({
                    ...oldModel,
                    [id_temp]:{mode:GridRowModes.Edit,fieldToFocus:'name'},
                }));
                new_id_count++;
                break;
            default: 
                break;
        }
    }

    const processRowUpdate=useCallback(
        async(newRow)=>{
            let isNew=false;
            if('isNew' in newRow){isNew=newRow.isNew;}
            else{newRow.isNew=false;}
            let temp_id=newRow.id_temp;
            let bkp_rows=JSON.parse(JSON.stringify(rows));
            let new_rec={};
            if(isNew){
                const newId=await createRecord(newRow);
                let new_set=[];
                bkp_rows.forEach(element=>{
                    if(temp_id===element.id_temp){
                        new_rec={...JSON.parse(JSON.stringify(newRow)),id:newId,isNew:false}
                        new_set.push(new_rec);
                    }
                    else{
                        new_set.push(element);
                    }
                });
                setRows((oldRows)=>new_set);
                setRowModesModel({...rowModesModel,[newId]:{mode:GridRowModes.View}});
                return new_rec;
            }
            else{
                updateRecord(newRow.id,newRow);
                return newRow;
            }
        },[rows],
    );

    function createRecord(record){
        return new Promise((resolve)=>{
            let new_record=JSON.parse(JSON.stringify(record))
            delete new_record.id;
            delete new_record.id_temp;
            delete new_record.isNew;
            delete new_record.product_sku_name;
            createDBItem({entityType:authMenuSubItem.query,entityData:new_record})
            .then((data)=>{
                if('error' in data){
                    console.log('error',data.error);
                    alertSeverity='error'
                    alertMessage=data.error;
                    setSnackOpened(true);
                }
                else{
                    console.log('insert',data);
                    alertSeverity='success';
                    alertMessage='Barcode created with success!!!';
                    setSnackOpened(true);
                    resolve(data.id);
                }
            });
        });
      }

    const updateRecord=(row_id,newRow)=>{
        let updates=JSON.parse(JSON.stringify(newRow));
        delete updates.created_at;
        delete updates.created_by;
        delete updates.updated_at;
        delete updates.updated_by;
        delete updates.id_temp;
        delete updates.isNew;
        delete updates.product_sku_name;
        updateDBItem({entityType:authMenuSubItem.query,entityId:row_id,entityData:updates})
        .then((data)=>{  
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else{
                console.log('update',data);
                alertSeverity='success';
                alertMessage='Barcode updated with success!!!';
                setSnackOpened(true);
            }
        });
    };

    const deleteRecord=(record_id)=>{
        deleteDBItem({entityType:authMenuSubItem.query, entityId:record_id}) 
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else{
                console.log('update',data);
                alertSeverity='success';
                alertMessage='Barcode(s) removed with success!!!';
                setSnackOpened(true);
            }
        });
    };

    const handleSelection=(ids)=>{
        setSelection(old=>ids);
    };

    const handleClickRemove=()=>{
        let list_of_id_temps=JSON.parse(JSON.stringify(selection));
        let list_of_id=[];
        let list_of_id_to_remove_ui=[];
        let rows_after_delete=[];
        let rows_before_delete=JSON.parse(JSON.stringify(rows));
        rows.forEach(element=>{
            if(list_of_id_temps.includes(element.id_temp)){
                if(element.id!==undefined&&element.id!==null&&element.id!==''){
                    list_of_id.push(element.id);
                }
                else{
                    list_of_id_to_remove_ui.push(element.id_temp);
                }
            }
        });
        list_of_id.forEach(function callback(element,index){
            deleteRecord(element);
        });

        rows_before_delete.forEach(element=>{
            if(!list_of_id.includes(element.id)&&!list_of_id_to_remove_ui.includes(element.id_temp)){
                rows_after_delete.push(element);
            }
        });
        setRows((oldRows)=>rows_after_delete);
        setSelection(old=>[]);
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
        <div className="page">
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}</Typography>
            </Box>
            <div className="page-content">
                <Snackbar open={snackOpened} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                    <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
                </Snackbar>
                <ButtonGroup size="small" aria-label="small button group">
                    {<Button disabled={selection.length<=0} name="remove" variant="outlined" onClick={handleClickRemove}>Remove</Button>}
                    {Object.keys(actions).map((action)=>{
                        return(actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{actions[action].label}</Button>);
                    })}
                </ButtonGroup>
                <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                    <div style={{height:'75vh',width:'100%'}}>
                        <DataGrid
                            sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                            getRowId={(row)=>row.id_temp}
                            //onSelectionModelChange={handleSelection}
                            onRowSelectionModelChange={handleSelection}
                            checkboxSelection
                            disableRowSelectionOnClick
                            disableSelectionOnClick
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            experimentalFeatures={{newEditingApi:true}}
                            slots={{
                                toolbar:CustomToolbar,
                            }}
                            componentsProps={{
                                toolbar:{
                                    showQuickFilter:true,
                                    quickFilterProps:{debounceMs:250},
                                },
                            }}
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel)=>{
                                setFilterModel(newFilterModel);
                                updateGlobalData({...globalData,filterModel:{...globalData.filterModel,[list_name]:newFilterModel}});
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel)=>{
                                setSortModel(newSortModel);
                                updateGlobalData({...globalData,sortModel:{...globalData.sortModel,[list_name]:newSortModel}});
                            }}
                            rows={rows}
                            columns={columns[list_name](setRowModesModel,rowModesModel,rows,setRows,deleteRecord,globalData.listOfValues,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                            loading={loading}
                            density="compact"
                            onColumnWidthChange={(params,event,details)=>{
                                let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                                updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                                saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                            }}
                        />
                    </div>
                </Stack>
            </div>
        </div>
    );
}

export default Barcodes;
