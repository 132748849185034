import {useEffect,useState,useCallback} from "react";
import {Box,Typography,ButtonGroup,Button,Stack,Snackbar,Alert} from '@mui/material';
import {GridRowModes,DataGrid,GridToolbarContainer,GridRowEditStopReasons,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {columns} from '../../utilities/Entities';
import {createDBItem,updateDBItem,deleteDBItem,getDBList} from '../../integration/DatabaseManager.js';
import {dateFns_to_ui,dateFns_to_db} from '../../utilities/Utilities';
import locale from 'date-fns/locale/fi';
import {format,parseISO} from 'date-fns';
import SKUPickList from "../SKU/SKUPickList.js";
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
let alertMessage='';
let alertSeverity='error'
let new_id_count=0;
let editable_status=['INVOICING_INTEGRATION_ERROR','Draft','LOGISTICS_INTEGRATION_ERROR']
const list_name='storage_order_items';

const StorageOrderDetailItems=({order_id,order_status,pageTab,globalData,userDataAuthorization})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);
    const [selection,setSelection]=useState([]);
    const [actions,setActions]=useState({});
    const [label,setLabel]=useState('');
    const [rowModesModel,setRowModesModel]=useState({});
    const [lovValues,setLovValues]=useState({});
    const [openSKUPickList,setOpenSKUPickList]=useState(false);
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    const handleRowEditStop=(params,event)=>{
        if(params.reason===GridRowEditStopReasons.rowFocusOut){
            event.defaultMuiPrevented=true;
        }
    };

    const handleProcessRowUpdateError=useCallback((error)=>{
            console.log('handleProcessRowUpdateError',error);
        },[]
    );

    const handleRowModesModelChange=(newRowModesModel)=>{
        setRowModesModel(newRowModesModel);
    };

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const getRecords=(isSubscribed,query,query_args)=>{
        getDBList({entityType:query,entityParams:['order_id='+order_id]})
        .then((data)=>{
            if(isSubscribed){
                if('error' in data){console.log('error',data.error);} 
                else{
                    if(data.length>0){
                        console.log('data',data);
                        data.forEach(element => {
                            element.id_temp=element.id;
                            if(element.production_date&&element.production_date!==null&&element.production_date!==''){
                                element.production_date=parseISO(element.production_date.substring(0,10));
                            }
                            if(element.expiration_date&&element.expiration_date!==null&&element.expiration_date!==''){
                                element.expiration_date=parseISO(element.expiration_date.substring(0,10));
                            }
                        });
                        setRows(old=>data);
                    }
                    else{
                        setRows(old=>[]);
                    }
                }
                setLoading(false);
            }
        });
    };

    useEffect(()=>{
        let isSubscribed=true;
        if((pageTab&&Object.keys(pageTab).length>0&order_id!==undefined&&order_id!==null&&order_id!=='')&&(order_status!==undefined&&order_status!==null&&order_status!=='')){
            setLoading(true);
            getRecords(isSubscribed,pageTab.query,pageTab.query_args);
            setActions(pageTab.actions);
        }
        return ()=>(isSubscribed=false);
    },[order_id,pageTab,order_status]);

    const handleClickListButton=(event)=>{
        switch(event.target.name){
            case "add_storage_item":
                setOpenSKUPickList(true);
                break;
            default: 
                break;
        }
    }

    const pickListCallback=(picklist_name,picklist_record,update)=>{
        console.log('picklist_record',picklist_record);
        if(picklist_name==='add_sku'){
            if(Object.keys(picklist_record).length>0){
                createRecord(picklist_record);
            }
            setOpenSKUPickList(false);
        }
    };

    const createRecord=(record)=>{
        let production_date=dateFns_to_db(new Date());
        let expiration_date=dateFns_to_db(new Date());
        let new_record={
            status:'Draft',
            sku_id:record.id,
            sku_name:record.name,
            order_id:order_id,
            quantity:1,
            production_date:parseISO(production_date),
            expiration_date:parseISO(expiration_date),
            description:''
        }
        console.log('new_record',new_record);
        createDBItem({entityType:pageTab.query,entityData:new_record})
        .then((data)=>{  
            console.log('data',data);
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
            }
            else{
                alertSeverity='success';
                alertMessage='Storage Item created with success!!!';
            }
            getRecords(true,pageTab.query,pageTab.query_args);
            setSnackOpened(true);
        });
    };

    const updateRecord=(row_id,newRow)=>{
        let updates=JSON.parse(JSON.stringify(newRow));
        console.log('updates',updates);
        updateDBItem({entityType:'OrderItem',entityId:row_id,entityData:updates})
        .then((data)=>{  
            console.log('data',data);
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
            }
            else{
                alertSeverity='success';
                alertMessage='Storage Item updated with success!!!';
            }
            setSnackOpened(true);
        });
    };

    const onCellEditCommit=(params,params2)=>{
        let row_update={};
        if(params.production_date!==params2.production_date){
            row_update.production_date=dateFns_to_db(params.production_date);
        }
        else if(params.expiration_date!==params2.expiration_date){
            row_update.expiration_date=dateFns_to_db(params.expiration_date);
        }
        else if(params.quantity!==params2.quantity){
            row_update.quantity=params.quantity||0;
        }
        else if(params.description!==params2.description){
            row_update.description=params.description;
        }
        else if(params.batch_number!==params2.batch_number){
            row_update.batch_number=params.batch_number;
        }
        if(Object.keys(row_update).length>0){
            updateRecord(params.id,row_update);
        }
        return params;
    };

    const deleteRecord=(record_id)=>{
        deleteDBItem({entityType:'OrderItem',entityId:record_id})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else if('delete' in data){
                console.log('update',data.delete);
                alertSeverity='success';
                alertMessage='Storage Item record(s) removed with success!!!';
                setSnackOpened(true);
            }
        });
    };

    const handleSelection=(ids)=>{
        setSelection(old=>ids);
    };

    const handleClickRemove=()=>{
        let list_of_id_temps=JSON.parse(JSON.stringify(selection));
        let list_of_id=[];
        let list_of_id_to_remove_ui=[];
        let rows_after_delete=[];
        let rows_before_delete=rows;
        rows.forEach(element=>{
            if(list_of_id_temps.includes(element.id_temp)){
                if(element.id!==undefined&&element.id!==null&&element.id!==''){
                    list_of_id.push(element.id);
                }
                else{
                    list_of_id_to_remove_ui.push(element.id_temp);
                }
            }
        });
        list_of_id.forEach(function callback(element,index){
            deleteRecord(element);
        });

        rows_before_delete.forEach(element=>{
            if(!list_of_id.includes(element.id)&&!list_of_id_to_remove_ui.includes(element.id_temp)){
                rows_after_delete.push(element);
            }
        });
        setRows((oldRows)=>rows_after_delete);
        setSelection(old=>[]);
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
            <div className="page-content">
                {openSKUPickList&&<SKUPickList pickListCallback={pickListCallback} query_param={pageTab.actions['add_storage_item']} order_items={rows} prevent_duplicated={false} globalData={globalData} userDataAuthorization={userDataAuthorization}></SKUPickList>}
                <Snackbar open={snackOpened} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                    <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
                </Snackbar>
                <ButtonGroup size="small" aria-label="small button group">
                    {editable_status.includes(order_status)&&<Button disabled={selection.length<=0} name="remove" variant="outlined" onClick={handleClickRemove}>Remove</Button>}
                    {editable_status.includes(order_status)&&Object.keys(pageTab).length>0&&Object.keys(pageTab.actions).map((action)=>{
                        return(pageTab.actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{pageTab.actions[action].label}</Button>);
                    })}
                </ButtonGroup>
                <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                    <div style={{height:'75vh',width:'100%'}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                            <DataGrid
                                sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                                processRowUpdate={onCellEditCommit}
                                onProcessRowUpdateError={(e)=>{console.log('e',e)}}
                                onRowSelectionModelChange={handleSelection}
                                checkboxSelection={editable_status.includes(order_status)}
                                disableRowSelectionOnClick
                                disableSelectionOnClick
                                rows={rows}
                                columns={columns[list_name](!editable_status.includes(order_status),list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                                loading={loading}
                                density="compact"
                                slots={{
                                    toolbar:CustomToolbar,
                                }}
                                componentsProps={{
                                    toolbar:{
                                        showQuickFilter:true,
                                        quickFilterProps:{debounceMs:250},
                                    },
                                }}
                                onColumnWidthChange={(params,event,details)=>{
                                    let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                                    updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                                    saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </Stack>
            </div>
    );
}

export default StorageOrderDetailItems;
