import keycloak from "../Keycloak";

export async function authorization(){
    let url='/api/authorization';
    try{
        const fetchResponse=await fetch(url,{
            method:"POST",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
        });
        const response=await fetchResponse.json();
        return JSON.stringify(response);
    } 
    catch(e){
        console.log("Error - authorization");
        console.log(e);
    }
}

export async function invoicing(body){
    let url='/api/send_invoice';
    try{
        const fetchResponse=await fetch(url,{
            method:"POST",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
	        body:JSON.stringify(body),
        });
        const response=await fetchResponse.json();
        return JSON.stringify(response);
    } 
    catch(e){
        console.log("Error - invoicing");
        console.log(e);
    }
}

export async function generate_report(body){
    let responseUI={};
    let url='/api/generate_report/'+body.reportType;
    if(body.reportParams!==undefined&&body.reportParams.length>0){
        for (let reportParam in body.reportParams){
            let paramDelimiter='&';
            if(reportParam==0){paramDelimiter='?';}
            url+=paramDelimiter+body.reportParams[reportParam];
        }
    }
    try{
        const fetchResponse=await fetch(url,{
            method:"GET",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            }
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - generate_report");
        console.log(e);
        responseUI={error:"Error - generate_report: "+e.toString()};
    }
    finally{
        return responseUI
    }
}

export async function get_public_holidays(body){
    let responseUI=[];
    let url='/api/get_public_holidays/'+body.year;
    if(body.reportParams!==undefined&&body.reportParams.length>0){
        for (let reportParam in body.reportParams){
            let paramDelimiter='&';
            if(reportParam==0){paramDelimiter='?';}
            url+=paramDelimiter+body.reportParams[reportParam];
        }
    }
    try{
        const fetchResponse=await fetch(url,{
            method:"GET",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            }
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - get_public_holidays");
        console.log(e);
    }
    finally{
        return responseUI
    }
}

export async function check_duplicated(body){
    let url='/api/check_duplicated';
    try{
        const fetchResponse=await fetch(url,{
            method:"POST",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
	        body:JSON.stringify(body),
        });
        const response=await fetchResponse.json();
        return JSON.parse(JSON.stringify(response));
    } 
    catch(e){
        console.log("Error - check_duplicated");
        console.log(e);
    }
}

export async function getStockBalance(body){
    let url='/api/get_stockbalance';
    try{
        const fetchResponse=await fetch(url,{
            method:"POST",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
	        body:JSON.stringify(body),
        });
        const response=await fetchResponse.json();
        return JSON.parse(JSON.stringify(response));
    } 
    catch(e){
        console.log("Error - getStockBalance");
        console.log(e);
    }
}

export async function refresh_alcohol_licenses(body){
    let url='/api/refresh_alcohol_licenses';
    let responseUI={};
    try{
        const fetchResponse=await fetch(url,{
            method:"POST",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
	        body:JSON.stringify(body),
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    } 
    catch(e){
        cconsole.log("Error - refresh_alcohol_licenses");
        console.log(e);
        responseUI={error:"Error - refresh_alcohol_licenses: "+e.toString()};
    }
    finally{
        return responseUI
    }
}