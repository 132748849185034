import {useEffect,useState} from "react";
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import {Tooltip,FormGroup,FormControlLabel,Switch,Divider,Box,Tabs,Tab,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,IconButton,InputAdornment,Backdrop,CircularProgress} from '@mui/material';
import Autocomplete,{createFilterOptions} from '@mui/material/Autocomplete';
import {ArrowCircleUp,ArrowCircleDown,ArrowCircleRight} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import RecordInfo from "../RecordInfo"
import {sortPageTabs,prepareGetData,validate_required_fields,prepareDefaultData,prepareErrorFields,DefaultConfig} from '../../utilities/Utilities';
import {getDBItem,createDBItem,updateDBItem} from '../../integration/DatabaseManager.js';
import ProductPickList from "../Product/ProductPickList";
import PackageTypesPicklist from "../PackageType/PackageTypesPickList";
import BarcodePickList from "../Barcode/BarcodePickList"
import SKUDetailMultipacks from "./SKUDetailMultipacks";
import SKUDetailStockBalance from "./SKUDetailStockBalance";
import SKUDetailSalesOrderItem from "./SKUDetailSalesOrderItem";
import SKUDetailStorageOrderItem from "./SKUDetailStorageOrderItem";
import {entity_object} from '../../utilities/DataDictionary';
import Barcode from 'react-barcode';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import Audit from '../Audit.js';
const filter=createFilterOptions();
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='error'
const required_field_error='Required Field';
const hideMoreInfoLabel='Hide More Info';
const showMoreInfoLabel='Show More Info';
let listOfTags=[];
const dataPreparedGet=prepareDefaultData('default_value_get','sku');
const dataError=prepareErrorFields('sku');
let pagehistory=null;
const SKUDetail=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [label,setLabel]=useState('-');
    const {slug}=useParams();
    const [pageTab,setPageTab]=useState(0);
    const [pageTabs,setPageTabs]=useState({});
    const [isChanged,setIsChanged]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);  
    const navigate=useNavigate();
    const location=useLocation();
    const [readOnly,setReadOnly]=useState(true);
    const [updates,setUpdates]=useState({});
    const [showMoreInfo,setShowMoreInfo]=useState(false);
    const [recordInfo,setRecordInfo]=useState(false);
    const [actions,setActions]=useState({});
    const [pickControl,setPickControl]=useState({add_product:false,package_type:false,barcode:false});
    const [refreshPageTab,setRefreshPageTab]=useState(false);
    const [lovValuesDynamic,setLovValuesDynamic]=useState({PRODUCT_TAG:[]});
    const [productTags,setProductTags]=useState([]);
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    //const classes=entity_object.sku.useStyles();
    const {globalData}=useContext(ApplicationContext);

    const handleClickPick=(picklist_name)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:true}));
    };

    const pickListCallback=(picklist_name,picklist_record)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:false}));
        if(Object.keys(picklist_record).length!==0){
            console.log('picklist_record',picklist_record);
            if(picklist_name==='add_product'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.product_id!==picklist_record.id){
                    updated_row.product=picklist_record;
                    updated_row.product_id=picklist_record.id;
                    setRow(old=>(updated_row));
                    setProductTags(old=>picklist_record.tags||[]);
                    setError(prevState=>({...prevState,product_id:false}));
                    setErrorMessage(prevState=>({...prevState,product_id:''}));
                    
                    let event={target:{name:'temperature_max',value:picklist_record.temperature_max|''}}
                    handleChange(event);
                    event={target:{name:'temperature_min',value:picklist_record.temperature_min|''}}
                    handleChange(event);
                    event={target:{name:'description',value:picklist_record.description|''}}
                    handleChange(event);
                    event={target:{name:'min_usage_days',value:picklist_record.min_usage_days|''}}
                    handleChange(event);
                    event={target:{name:'description_internal',value:picklist_record.description_internal|''}}
                    handleChange(event);
                }
            }
            else if(picklist_name==='package_type'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.product_id!==picklist_record.id){
                    updated_row.pack_type=picklist_record.type;
                    //updated_row.pack_size=picklist_record.size;
                    updated_row.pack_unit=picklist_record.unit;
                    updated_row.pack_amount=picklist_record.amount;
                    updated_row.width=picklist_record.width;
                    updated_row.height=picklist_record.height;
                    updated_row.length=picklist_record.length;
                    updated_row.weight=picklist_record.weight;
                    setRow(old=>(updated_row));
                    let event={target:{name:'pack_type',value:picklist_record.type}}
                    handleChange(event);
                    //event={target:{name:'pack_size',value:picklist_record.size}}
                    //handleChange(event);
                    event={target:{name:'pack_unit',value:picklist_record.unit}}
                    handleChange(event);
                    event={target:{name:'pack_amount',value:picklist_record.amount}}
                    handleChange(event);

                    event={target:{name:'width',value:picklist_record.width}}
                    handleChange(event);
                    event={target:{name:'height',value:picklist_record.height}}
                    handleChange(event);
                    event={target:{name:'length',value:picklist_record.length}}
                    handleChange(event);
                    event={target:{name:'weight',value:picklist_record.weight}}
                    handleChange(event);
                }
            }
            else if(picklist_name==='barcode'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.gtin_id!==picklist_record.id){
                    updated_row.gtin_id=picklist_record.id;
                    updated_row.gtin.name=picklist_record.name;
                    setRow(old=>(updated_row));
                    let event={target:{name:'gtin_id',value:picklist_record.id}}
                    handleChange(event);
                }
            }
        }
    };

    
    const handleClickMoreInfo=()=>{
        setRecordInfo(true);
    };

    const recordInfoCallback=()=>{
        setRecordInfo(false);
    };

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const handleChangeTab=(event,newValue)=>{
        event.currentTarget.blur();
        setPageTab(newValue);
        pagehistory=event.target.name;
        navigate(location.pathname.replace(location.pathname.split('/')[3],pageTabs[event.target.name].path));
    };

    const getRecord=(query)=>{
        getDBItem({entityType:query,entityId:slug})
        .then((data)=>{  
            if('error' in data){
                console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setReadOnly(true);
                setSnackOpened(true);
            }
            else{
                if(data!=null){
                    console.log('records',data);
                    setRow(old=>prepareGetData(data,'sku'));
                    if(authMenuSubItem){
                        setLabel(authMenuSubItem.label);
                        setReadOnly(!('edit' in authMenuSubItem.actions));
                    }
                    if(data.parent_sku_id!==null&&data.parent_sku_id!==''){
                        getDBItem({entityType:query,entityId:data.parent_sku_id})
                        .then((parentData)=>{
                            setRow(prevState=>({...prevState,parent_sku:{name:parentData.name,palpa_value:parentData.palpa_value}}));
                        });
                    }
                    setProductTags(old=>data.tags||[]);
                    setRefreshPageTab(true);
                }
                else{
                    alertMessage='Record not found or not accessible';
                    alertSeverity='error'
                    setReadOnly(true);
                    setSnackOpened(true);
                }
            }
        });
    };

    const updateRecord=()=>{
        setOpenBackdrop(true);
        let row_update={};
        if(updates!==undefined&&updates!==null){
            if(Object.keys(updates).length>0){
                row_update=JSON.parse(JSON.stringify(updates));
            }
        }
        let tags=[];
        productTags.forEach(element=>{
            if(typeof element!=='string'){
                if('inputValue' in element){
                    tags.push(element.inputValue);
                }
                else if('title' in element){
                    tags.push(element.title);
                }
            }
            else{
                tags.push(element);
            }
        });
        row_update.tags=tags;
        console.log('row_update',row_update);
        updateDBItem({entityType:authMenuSubItem.query,entityId:slug,entityData:row_update})
        .then((data)=>{  
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                if(typeof data.error==='object'){
                    alertMessage=JSON.stringify(data.error);
                }
                else{
                    alertMessage=data.error;
                }
            }
            else{
                alertSeverity='success'
                alertMessage='SKU updated with success!!!';
            }
            setSnackOpened(true);
            setIsChanged(false);
            setReadOnly(true);
            getDBItem({entityType:authMenuSubItem.query,entityId:slug});
            setRefreshPageTab(true);
            setOpenBackdrop(false);
        });
    };

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)&&(slug!==undefined&&slug!==null&&slug!=='')&&location){
            setActions(authMenuSubItem.actions);
            getRecord(authMenuSubItem.query);
            setPageTabs(old=>sortPageTabs(authMenuSubItem.pageTabs));
            if(!location.pathname.includes(authMenuSubItem.defaultTabPath)||(pagehistory&&(pagehistory!==authMenuSubItem.defaultTabPath))){
                for(let key in authMenuSubItem.pageTabs){
                    if(location.pathname.includes(authMenuSubItem.pageTabs[key].path)){
                        setPageTab(authMenuSubItem.pageTabs[key].index);
                        break;
                    }
                }                
            }
            else if('state' in location){
                if((location.state)&&('message' in location.state)){
                    alertMessage=location.state.message.alertMessage;
                    alertSeverity=location.state.message.alertSeverity;
                    setSnackOpened(true);
                }
            }
            setLabel(authMenuSubItem.label);
            listOfTags=globalData.listOfValues.PRODUCT_TAG||[];
            let tagValues=[];
            listOfTags.forEach(element=>{
                tagValues.push({title:element});
            });
            setLovValuesDynamic({PRODUCT_TAG:tagValues});//separated to be updated as new values are inserted
        }
        return ()=>(setPageTab(old=>0));
    },[authMenuSubItem,slug,userDataAuthorization,location]);

    const handleClickSave=()=>{
        if(validate_required_fields(row,'sku',setError,setErrorMessage,dataError,required_field_error)){
            updateRecord();
            setUpdates(old=>{});
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        setIsChanged(false);
        getRecord(authMenuSubItem.query);
        setUpdates(old=>{});
        setError(dataError.error);
        setErrorMessage(dataError.message);
    }

    const handleChange=(event)=>{
        setIsChanged(true);
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        setUpdates(prevState=>({...prevState,[event.target.name]:event.target.value}));

        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }

        if('events' in entity_object.sku.fields[event.target.name]){
            if('onChange' in entity_object.sku.fields[event.target.name].events){
                entity_object.sku.fields[event.target.name].events.onChange(event.target.value,handleChangeList,JSON.parse(JSON.stringify(row)));
            }
        }
    };

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);

        if('events' in entity_object.sku.fields[fieldName]){
            if('onChange' in entity_object.sku.fields[fieldName].events){
                entity_object.sku.fields[fieldName].events.onChange(fieldValue,handleChangeList,JSON.parse(JSON.stringify(row)));
            }
        }

        if('drive_dynamic_required' in entity_object.sku.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.sku.fields)){
                if(key!==fieldName){
                    if('required' in value){
                        if(typeof value.required==='function'){
                            let required=entity_object.sku.fields[key].required(row_update);
                            setError(prevState=>({...prevState,[key]:required&&(row_update[key]==='')}));
                            setErrorMessage(prevState=>({...prevState,[key]:required&&(row_update[key]==='')?required_field_error:''}));
                        }
                    }
                }
            }
        }
    };

    const updateDynamicListOfValues=(value)=>{
        let row_insert={type:'PRODUCT_TAG',status:'Active',sorting_order:0,value:value};
        createDBItem({entityType:'ListOfValues',entityData:row_insert})
        .then((data)=>{  
            if('error' in data){console.log('error',data.error);}
            else{
                console.log('insert',data);
                let newId=data.id;
                console.log('newId',newId);
                listOfTags.push(value);
                let tagValues=JSON.parse(JSON.stringify(lovValuesDynamic.PRODUCT_TAG));
                tagValues.push({title:value});
                setLovValuesDynamic(prevState=>({...prevState,PRODUCT_TAG:tagValues}));
            }
        });
    };

    const handleClickButton=(event)=>{
        switch(event.target.name){
            case "update":
                setReadOnly(false);
                break;
            default: 
                break;
        }
    }

    const handleChangeSwitch=(event)=>{
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
    }

    const handleClickDrilldown=(field_name)=>{
        if(field_name==='product_name'){
            window.open('/product/'+row.product_id+'/product','_blank');
        }
        else if(field_name==='parent_sku_name'){
            window.open('/sku/'+row.parent_sku_id+'/multipacks','_blank');
        }
    }

    const pageTabCallback=(command)=>{
        if(command==='refreshed'){
            setRefreshPageTab(false);
        }
    }

    return (
        <div className="page">
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            {recordInfo&&<RecordInfo recordInfoCallback={recordInfoCallback} row={row} entity_name={'sku'} globalData={globalData} userDataAuthorization={userDataAuthorization}></RecordInfo>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.name}</span></Typography>
            </Box>
            <div className="page-content">
                {pickControl.add_product&&<ProductPickList pickListCallback={pickListCallback} query_param={authMenuSubItem.picklists.product_name} globalData={globalData} userDataAuthorization={userDataAuthorization}></ProductPickList>}
                {pickControl.package_type&&<PackageTypesPicklist pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} sku_type={row.type} globalData={globalData} userDataAuthorization={userDataAuthorization}></PackageTypesPicklist>}
                {pickControl.barcode&&<BarcodePickList pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} globalData={globalData} userDataAuthorization={userDataAuthorization}></BarcodePickList>}
            
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        {!readOnly&&<Button disabled={!isChanged} name="save" variant="outlined" onClick={handleClickSave}>Save</Button>}
                        {!readOnly&&<Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>}
                        {Object.keys(actions).map((action)=>{
                            return(actions[action].type==='button'&&readOnly&&<Button key={action} name={action} variant="outlined" onClick={handleClickButton}>{actions[action].label}</Button>);
                        })}
                    </ButtonGroup>
                    {readOnly&&
                    <IconButton onClick={handleClickMoreInfo} color="primary" style={{position:'absolute',right:'40px'}} size="small">
                        <Tooltip title="More Information on the Record">
                            <InfoIcon fontSize="inherit"/>
                        </Tooltip>
                    </IconButton>}
                    {readOnly&&
                    <IconButton onClick={()=>{
                        setRow(old=>dataPreparedGet);
                        getRecord(authMenuSubItem.query)}} color="primary" style={{position:'absolute',right:'15px'}} size="small">
                        <Tooltip title="Refresh the current Record">
                            <RefreshIcon fontSize="inherit"/>
                            </Tooltip>
                    </IconButton>}
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.name.required}
                            error={error.name}
                            helperText={errorMessage.name}
                            id="name"
                            name="name"
                            type={entity_object.sku.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.name.label}
                            value={row.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            required
                            readOnly={readOnly}
                            options={globalData.listOfValues.SKU_STATUS||[]}
                            getOptionLabel={(option)=>option}
                            id="status"
                            name="status"
                            type={entity_object.sku.fields.status.ui_type}
                            size="small"
                            value={row.status}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'status');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.status.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="reference"
                            name="reference"
                            type={entity_object.sku.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.reference.label}
                            value={row.reference}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="stock_balance"
                            name="stock_balance"
                            type={entity_object.sku.fields.stock_balance.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={row.stock_balance}
                            label={entity_object.sku.fields.stock_balance.label}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor,color:(row.quarantined_balance!==''&row.quarantined_balance!==undefined&&row.quarantined_balance!==0)?'darkred':''}}}
                            InputProps={{readOnly:true}}
                            id="quarantined_balance"
                            name="quarantined_balance"
                            type={entity_object.sku.fields.quarantined_balance.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={row.quarantined_balance}
                            label={entity_object.sku.fields.quarantined_balance.label}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            required={entity_object.sku.fields.type.required}
                            error={error.type}
                            helperText={errorMessage.type}
                            id="type"
                            name="type"
                            type={entity_object.sku.fields.type.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.type.label}
                            value={row.type}
                            margin="normal"/>
                    </Stack>
                    {row.type==='Multipack'&&<>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{
                                readOnly:true,
                                endAdornment:(
                                    <InputAdornment position="end">
                                        <IconButton onClick={(e)=>handleClickDrilldown('parent_sku_name')} color="primary">
                                            <ArrowCircleRight/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required={entity_object.sku.fields.parent_sku_id.required}
                            error={error.parent_sku_id}
                            helperText={errorMessage.parent_sku_id}
                            id="parent_sku_name"
                            name="parent_sku_name"
                            type={entity_object.sku.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.joins.sku.fields.name.label}
                            value={row.parent_sku.name}
                            margin="normal"/>
                    </Stack>
                    </>}
                    {row.product.name!==''&&<>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{
                                readOnly:true,  
                                /*
                                startAdornment:(
                                    !readOnly&&
                                    <InputAdornment position="start">
                                        <IconButton onClick={(e)=>handleClickPick('add_product')}>
                                            <AccountCircle/>
                                        </IconButton>
                                    </InputAdornment>
                                ), */
                                endAdornment:(
                                    readOnly&&
                                    <InputAdornment position="end">
                                        <IconButton onClick={(e)=>handleClickDrilldown('product_name')} color="primary">
                                            <ArrowCircleRight/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required={entity_object.sku.fields.product_id.required}
                            error={error.product_id}
                            helperText={errorMessage.product_id}
                            id="product_name"
                            name="product_name"
                            type={entity_object.product.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.joins.product.fields.name.label}
                            value={row.product.name}
                            margin="normal"
                            onChange={handleChange}/>
                         <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='sellable' checked={row.sellable} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.sku.fields.sellable.label}/>
                        </FormGroup>
                    </Stack>
                    </>}
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={readOnly}
                            sx={{span:{color:readOnly?'#1976d2':""}}}
                            fullWidth
                            freeSolo
                            multiple
                            options={lovValuesDynamic.PRODUCT_TAG}
                            id="tags"
                            name="tags"
                            type={entity_object.sku.fields.tags.ui_type}
                            size="small"
                            value={productTags}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{
                                setIsChanged(true);
                                newValue.forEach(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            if(!listOfTags.includes(element.inputValue)){
                                                updateDynamicListOfValues(element.inputValue);
                                            }
                                        }
                                    }
                                });
                                setProductTags(newValue);
                            }}
                            filterOptions={(options,params)=>{
                                const filtered=filter(options,params);
                                const {inputValue}=params;
                                const isExisting=options.some((option)=>inputValue===option.title);
                                if(inputValue!==''&&!isExisting){
                                    filtered.push({
                                        inputValue,
                                        title:`Add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            getOptionLabel={(option)=>{
                                if(typeof option==='string'){
                                  return option;
                                }
                                if(option.inputValue){
                                  return option.inputValue;
                                }
                                return option.title;
                            }}
                            renderOption={(props,option)=>{
                                let bAdd=true;
                                productTags.every(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            bAdd=element.inputValue!==option.title;
                                        }
                                        else if('title' in element){
                                            bAdd=element.title!==option.title;
                                        }
                                    }
                                    return bAdd;
                                });
                                if(!productTags.includes(option.title)&&bAdd){
                                    return <li {...props}>{option.title}</li>;
                                }
                            }}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.sku.fields.tags.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?hideMoreInfoLabel:showMoreInfoLabel}</Button>
                    {showMoreInfo&&<>
                    <Divider /*className={classes.storage}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.storage_color} gutterBottom variant="body1">Product Info</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.storage_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={true}
                            sx={{span:{color:'#1976d2'}}}
                            fullWidth
                            multiple
                            freeSolo
                            options={['']}
                            id="product_categories"
                            name="product_categories"
                            type={entity_object.product.fields.categories.ui_type}
                            size="small"
                            value={row.product.categories}
                            ChipProps={{color:"primary"}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.categories.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.storage_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="product_temperature_min"
                            name="product_temperature_min"
                            type={entity_object.product.fields.temperature_min.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_min.label}
                            value={row.product.temperature_min}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="product_temperature_max"
                            name="product_temperature_max"
                            type={entity_object.product.fields.temperature_max.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_max.label}
                            value={row.product.temperature_max}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="product_min_usage_days"
                            name="product_min_usage_days"
                            type={entity_object.product.fields.min_usage_days.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.min_usage_days.label}
                            value={row.product.min_usage_days}
                            margin="normal"/>
                    </Stack>    
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.storage_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}> 
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch disabled name='product_track_batches' checked={row.product.track_batches}/>} label={entity_object.product.fields.track_batches.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch disabled name='product_track_expiration_date' checked={row.product.track_expiration_date}/>} label={entity_object.product.fields.track_expiration_date.label}/>
                        </FormGroup>
                    </Stack>
                    <Divider /*className={classes.package}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.package_color} gutterBottom variant="body1">Package</Typography></Divider>
                    {!readOnly&&<Button name="package_type" variant="outlined" onClick={(e)=>handleClickPick('package_type')}>Load Package Type</Button>}
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.package_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>                        
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={readOnly}
                            sx={{span:{color:readOnly?'#1976d2':""}}}
                            fullWidth
                            options={globalData.listOfValues.PACKAGE_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="pack_type"
                            name="pack_type"
                            type={entity_object.sku.fields.pack_type.ui_type}
                            size="small"
                            value={row.pack_type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'pack_type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    required={entity_object.sku.fields.pack_type.required}
                                    error={error.pack_type}
                                    helperText={errorMessage.pack_type}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.pack_type.label}
                                    margin="normal"
                                />
                            )}
                        />
                        {/*
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.pack_size.required}
                            error={error.pack_size}
                            helperText={errorMessage.pack_size}
                            id="pack_size"
                            name="pack_size"
                            type={entity_object.sku.fields.pack_size.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.pack_size.label}
                            value={row.pack_size}
                            margin="normal"
                            onChange={handleChange}/>
                        */}
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={readOnly}
                            sx={{span:{color:readOnly?'#1976d2':""}}}
                            fullWidth
                            options={globalData.listOfValues.PACKAGE_UNIT||[]}
                            getOptionLabel={(option)=>option}
                            id="pack_unit"
                            name="pack_unit"
                            type={entity_object.sku.fields.pack_unit.ui_type}
                            size="small"
                            value={row.pack_unit}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'pack_unit');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    required={entity_object.sku.fields.pack_unit.required}
                                    error={error.pack_unit}
                                    helperText={errorMessage.pack_unit}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.pack_unit.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.pack_amount.required}
                            error={error.pack_amount}
                            helperText={errorMessage.pack_amount}
                            id="pack_amount"
                            name="pack_amount"
                            type={entity_object.sku.fields.pack_amount.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.pack_amount.label}
                            value={row.pack_amount}
                            margin="normal"
                            onChange={handleChange}/>
                    {/*</Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.package_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>*/}
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.width.required}
                            error={error.width}
                            helperText={errorMessage.width}
                            id="width"
                            name="width"
                            type={entity_object.sku.fields.width.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.width.label}
                            value={row.width}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.height.required}
                            error={error.height}
                            helperText={errorMessage.height}
                            id="height"
                            name="height"
                            type={entity_object.sku.fields.height.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.height.label}
                            value={row.height}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.length.required}
                            error={error.length}
                            helperText={errorMessage.length}
                            id="length"
                            name="length"
                            type={entity_object.sku.fields.length.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.length.label}
                            value={row.length}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.weight.required}
                            error={error.weight}
                            helperText={errorMessage.weight}
                            id="weight"
                            name="weight"
                            type={entity_object.sku.fields.weight.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.weight.label}
                            value={row.weight}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.palpa}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.palpa_color} gutterBottom variant="body1">Palpa</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.palpa_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            sx={{span:{color:(readOnly||entity_object.sku.fields.palpa.readonly(row))?'#1976d2':""}}}
                            readOnly={(readOnly||entity_object.sku.fields.palpa.readonly(row))}
                            fullWidth
                            options={globalData.listOfValues.PALPA||[]}
                            getOptionLabel={(option)=>option}
                            id="palpa"
                            name="palpa"
                            type={entity_object.sku.fields.palpa.ui_type}
                            size="small"
                            value={row.palpa}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'palpa');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:(readOnly||entity_object.sku.fields.palpa.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.sku.fields.palpa.required(row)}
                                    error={error.palpa}
                                    helperText={errorMessage.palpa}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sku.fields.palpa.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.sku.fields.palpa_value.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.sku.fields.palpa_value.readonly(row))}}
                            required={entity_object.sku.fields.palpa_value.required(row)}
                            error={error.palpa_value}
                            helperText={errorMessage.palpa_value}
                            id="palpa_value"
                            name="palpa_value"
                            type={entity_object.sku.fields.palpa_value.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.palpa_value.label}
                            value={row.palpa_value}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.price}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.price_color} gutterBottom variant="body1">Pricing</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sku.style.price_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.sku.fields.price.required}
                            error={error.price}
                            helperText={errorMessage.price}
                            id="price"
                            name="price"
                            type={entity_object.sku.fields.price.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.price.label}
                            value={row.price}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="currency_code"
                            name="currency_code"
                            type={entity_object.sku.fields.currency_code.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.currency_code.label}
                            value={row.currency_code}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.barcode}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sku.style.barcode_color} gutterBottom variant="body1">Barcode</Typography></Divider>
                    {!readOnly&&<Button color={error.gtin_id?'error':'primary'} name="barcode" variant="outlined" onClick={(e)=>handleClickPick('barcode')}>{row.gtin.name===''?'Add Barcode':'Update Barcode'}</Button>}
                    <Stack style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'10px',backgroundColor:entity_object.sku.style.barcode_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        {row.gtin.name!==''&&<Barcode value={row.gtin.name} height={25}/>}
                        {error.gtin_id&&<Typography color='error' gutterBottom variant="h6">Barcode is Required on the SKU!</Typography>}
                    </Stack>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            inputProps={{maxLength:500}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.sku.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description_internal"
                            name="description_internal"
                            type={entity_object.sku.fields.description_internal.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sku.fields.description_internal.label}
                            value={row.description_internal}
                            margin="normal"
                            onChange={handleChange}/>
                </Stack></>}
                </Box>
            </div>
            {row.id!==''&&
            <Box sx={{bgcolor:'none'}}>
                <Tabs
                    value={pageTab}
                    onChange={handleChangeTab}
                    sx={{"& button:hover":{backgroundColor:'lightgrey'},"& button:focus":{backgroundColor:'white'},"& .MuiTab-root.Mui-selected":{backgroundColor:'white'},}}
                    variant="scrollable"
                    scrollButtons="auto">
                    {Object.keys(pageTabs).map((item)=>{
                        if(item==='audit'||item==='stockbalance'||item==='salesorderitem'||item==='storageorderitem'){
                            return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                        }
                        else if(item==='single'&&row.type==='Single'||row.type==='Multipack'){
                            return;
                        }
                        else{
                            return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                        }
                    })}
                </Tabs>
                {Object.keys(pageTabs).length>0&&('audit' in pageTabs)&&(row.type==='Single'?pageTab:parseInt(pageTab))===pageTabs['audit'].index&&<Audit record_id={row.id} pageTab={('audit' in pageTabs)?pageTabs['audit']:{}} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} entity_name='sku' globalData={globalData} userDataAuthorization={userDataAuthorization}></Audit>}
                {Object.keys(pageTabs).length>0&&('stockbalance' in pageTabs)&&(row.type==='Single'?pageTab:parseInt(pageTab))===pageTabs['stockbalance'].index&&<SKUDetailStockBalance parent={row} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} globalData={globalData} userDataAuthorization={userDataAuthorization}></SKUDetailStockBalance>}
                {Object.keys(pageTabs).length>0&&('salesorderitem' in pageTabs)&&(row.type==='Single'?pageTab:parseInt(pageTab))===pageTabs['salesorderitem'].index&&<SKUDetailSalesOrderItem parent={row} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} globalData={globalData} userDataAuthorization={userDataAuthorization}></SKUDetailSalesOrderItem>}
                {Object.keys(pageTabs).length>0&&('storageorderitem' in pageTabs)&&(row.type==='Single'?pageTab:parseInt(pageTab))===pageTabs['storageorderitem'].index&&<SKUDetailStorageOrderItem parent={row} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} globalData={globalData} userDataAuthorization={userDataAuthorization}></SKUDetailStorageOrderItem>}
                {row.type==='Single'&&Object.keys(pageTabs).length>0&&('multipacks' in pageTabs)&&pageTab===pageTabs['multipacks'].index&&<SKUDetailMultipacks parent={row} pageTab={('multipacks' in pageTabs)?pageTabs['multipacks']:{}} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} globalData={globalData} userDataAuthorization={userDataAuthorization}></SKUDetailMultipacks>}
            </Box>}
        </div>
    );
}

export default SKUDetail;
