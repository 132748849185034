import {useEffect,useState} from "react";
import {ButtonGroup,Button,Stack,Snackbar,Alert,Backdrop,CircularProgress} from '@mui/material';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities.js';
import {status_color,entity_object} from '../../utilities/DataDictionary.js';
import {getDBList,createDBItem,updateDBItem,deleteDBItem} from '../../integration/DatabaseManager.js';
import SKUPickList from "../SKU/SKUPickList.js";
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
let alertMessage='';
let alertSeverity='error'
let editable_status=['INVOICING_INTEGRATION_ERROR','Draft','LOGISTICS_INTEGRATION_ERROR']
const list_name='sales_order_items';

const SalesOrderDetailItems=({order_id,order_status,order_logistics_id,order_palpa_free,tax_free,pageTab,orderItemCallback,globalData,userDataAuthorization})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);
    const [openSKUPickList,setOpenSKUPickList]=useState(false);
    const [selection,setSelection]=useState([]);
    const [readOnly,setReadOnly]=useState(false);
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const getRecords=(isSubscribed,query,query_args)=>{
        setOpenBackdrop(true);
        getDBList({entityType:query,entityParams:['order_id='+order_id]})
        .then((data)=>{
            if(isSubscribed){
                if('error' in data){console.log('error',data.error);} 
                else{
                    if(data.length>0){
                        console.log('data',data);
                        setRows(old=>data);
                    }
                    else{
                        setRows(old=>[]);
                    }
                }
                setOpenBackdrop(false);
                setLoading(false);
            }
        });
    };

    useEffect(()=>{
        let isSubscribed=true;
        if((order_id!==undefined&&order_id!==null&&order_id!=='')&&(order_status!==undefined&&order_status!==null&&order_status!=='')){
            setLoading(true);
            getRecords(isSubscribed,pageTab.query,pageTab.query_args);
        }
        return ()=>(isSubscribed=false);
    },[order_id,pageTab,order_status,order_logistics_id]);

    const handleClickListButton=(event)=>{
        switch(event.target.name){
            case "add_sku":
                setOpenSKUPickList(true);
                break;
            case "update":
                if(order_status!=='Draft'){
                    setReadOnly(true);
                }
                break;
            default: 
                break;
        }
    }

    const pickListCallback=(picklist_name,picklist_record,update)=>{
        console.log('picklist_record',picklist_record);
        if(picklist_name==='add_sku'){
            if(Object.keys(picklist_record).length>0){
                createRecord(picklist_record);
            }
            setOpenSKUPickList(false);
        }
        else if(picklist_name==='add_sku_more'){
            if(Object.keys(picklist_record).length>0){
                updateRecord(update.id,update.row);
            }
            setOpenSKUPickList(false);
        }
    };

    const createRecord=(sku)=>{
        setOpenBackdrop(true);
        let row_insert={
            status:entity_object.sales_order_item.fields.status.default_value_new,
            sku_id:sku.id,
            order_id:order_id,
            quantity:0,
            quantity_delivered:0,
            unit_price:sku.price,
            total_price:0,
            final_price:0,
            vat:sku.product.vat,
            vat_price:0,
            final_price_included_vat:0,
            sku_unit_palpa_value:sku.palpa_value,
            sku_total_palpa_value:0
        }
        console.log('createRecord',row_insert);
        createDBItem({entityType:pageTab.query,entityData:row_insert})
        .then((data)=>{  
            if('error' in data){
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else{
                console.log(data);
                let row_update={
                    quantity:1,
                    quantity_delivered:0,//git #142
                    total_price:1*data.unit_price,
                    final_price:1*data.unit_price,
                    vat_price:1*data.vat*data.unit_price/100,
                    final_price_included_vat:(1*data.unit_price)+(1*data.vat*data.unit_price/100),
                    sku_total_palpa_value:order_palpa_free?0.00:1*data.sku_unit_palpa_value
                }
                updateRecord(data.id,row_update);
            }
        });
    };

    const updateRecord=(order_item_id,row_update)=>{
        setOpenBackdrop(true);
        console.log('row_update',row_update);
        updateDBItem({entityType:pageTab.query,entityId:order_item_id,entityData:row_update})
        .then((data)=>{
            if('error' in data){
                setOpenBackdrop(false);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else{
                alertSeverity='success';
                alertMessage='Sales Order Item updated with success!!!';
                setSnackOpened(true);
                getRecords(true,pageTab.query,pageTab.query_args);
                orderItemCallback('refresh');
            }
        });
    };
    
    const onCellEditCommit=(params,params2)=>{
        let row_update={};
        if(params.quantity!==params2.quantity){
            row_update.quantity=params.quantity||0;
        }
        else if(params.unit_price!==params2.unit_price){
            row_update.unit_price=params.unit_price||0;
        }
        else if(params.description!==params2.description){
            row_update.description=params.description;
        }
        if(Object.keys(row_update).length>0){
            updateRecord(params.id,row_update);
        }
        return params;
    };

    const deleteRecord=(order_item_id,refresh)=>{
        setOpenBackdrop(true);
        deleteDBItem({entityType:pageTab.query,entityId:order_item_id})
        .then((data)=>{
            if('error' in data){
                setOpenBackdrop(false);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }else{
                alertSeverity='success';
                alertMessage='Product Item(s) removed with success!!!';
                setSnackOpened(true);
                if(refresh){
                    getRecords(true,pageTab.query,pageTab.query_args);
                    orderItemCallback('refresh');
                }
            }
        });
      };

    const handleSelection=(ids)=>{
        setSelection(old=>ids);
    };

    const handleClickRemove=()=>{
        selection.forEach(function callback(element,index){
            deleteRecord(element,index===selection.length-1);
        });
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
        <div className="page-content">
            {openSKUPickList&&<SKUPickList pickListCallback={pickListCallback} query_param={pageTab.actions['add_sku']} order_items={rows} prevent_duplicated={true} globalData={globalData} userDataAuthorization={userDataAuthorization}></SKUPickList>}
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            <Snackbar open={snackOpened} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <ButtonGroup size="small" aria-label="small button group">
                {editable_status.includes(order_status)&&order_logistics_id===null&&<Button disabled={selection.length<=0} name="remove" variant="outlined" onClick={handleClickRemove}>Remove</Button>}
                {/*order_status==='Draft'&&<Button name="update" variant="outlined" onClick={handleClickListButton}>Update</Button>*/}
                {Object.keys(pageTab).length>0&&Object.keys(pageTab.actions).map((action)=>{
                    return(editable_status.includes(order_status)&&order_logistics_id===null&&pageTab.actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{pageTab.actions[action].label}</Button>);
                })}
            </ButtonGroup>
            <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        processRowUpdate={onCellEditCommit}
                        onProcessRowUpdateError={(e)=>{console.log('e',e)}}
                        onRowSelectionModelChange={handleSelection}
                        checkboxSelection={editable_status.includes(order_status)&&order_logistics_id===null}
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        rows={rows}
                        columns={columns[list_name](!(editable_status.includes(order_status)&&order_logistics_id===null),tax_free,order_palpa_free,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </Stack>
        </div>);
}

export default SalesOrderDetailItems;
