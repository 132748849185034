import {useEffect,useState} from "react";
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import {Tooltip,Switch,FormGroup,Backdrop,CircularProgress,FormControlLabel,Divider,Box,Tabs,Tab,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,IconButton,InputAdornment} from '@mui/material';
import {ArrowCircleUp,ArrowCircleDown,ArrowCircleRight} from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
dayjs.locale('fi')
import 'dayjs/locale/fi';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import RecordInfo from "../RecordInfo.js"
import {sortPageTabs,prepareGetData,prepareDefaultData,validate_required_fields,prepareErrorFields,DefaultConfig,time_to_date_ui,time_to_ui,storage_order_delivery_price,dayjs_to_ui,dayjs_to_db,adjustDateLocale} from '../../utilities/Utilities.js';
import {getDBItem,updateDBItem} from '../../integration/DatabaseManager.js';
import StorageOrderDetailItems from "./StorageOrderDetailItems.js";
import {status_color,entity_object} from '../../utilities/DataDictionary.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {enUS} from '@mui/x-date-pickers/locales';
import Audit from '../Audit.js';
import StorageOrderShippingReport from './StorageOrderShippingReport.js';
const localeText=enUS.components.MuiLocalizationProvider.defaultProps.localeText
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='error'
const required_field_error='Required Field';
const dataPreparedGet=prepareDefaultData('default_value_get','storage_order');
const dataError=prepareErrorFields('storage_order');
let editable_status=['INVOICING_INTEGRATION_ERROR','Draft','LOGISTICS_INTEGRATION_ERROR']
let enable_shipping_report_status=['Submitted'];
let pagehistory=null;
const StorageOrderDetail=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [label,setLabel]=useState('-');
    const {slug}=useParams();
    const [pageTab,setPageTab]=useState(0);
    const [pageTabs,setPageTabs]=useState({});
    const [isChanged,setIsChanged]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);  
    const navigate=useNavigate();
    const location=useLocation();
    const [readOnly,setReadOnly]=useState(true);
    const [updates,setUpdates]=useState({});
    const [showMoreInfo,setShowMoreInfo]=useState(false);
    const [recordInfo,setRecordInfo]=useState(false);
    const [actions,setActions]=useState({});
    /*const classes=entity_object.storage_order.useStyles();*/
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [openDialog,setOpenDialog]=useState(false);
    const {globalData}=useContext(ApplicationContext);
    const [refreshPageTab,setRefreshPageTab]=useState(false);
    const [showShippingReport,setShowShippingReport]=useState(false);

    const handleClickShippingReport=()=>{
        setShowShippingReport(true);
    };

    const shippingReportCallback=()=>{
        setShowShippingReport(false);
    };

    const handleClickMoreInfo=()=>{
        setRecordInfo(true);
    };

    const recordInfoCallback=()=>{
        setRecordInfo(false);
    };

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const pageTabCallback=(command)=>{
        if(command==='refreshed'){
            setRefreshPageTab(false);
        }
    }

    const orderItemCallback=(mode)=>{
        if(mode==='refresh'){
            getRecord(authMenuSubItem.query);
        }
    }
    
    const handleChangeTab=(event,newValue)=>{
        event.currentTarget.blur();
        setPageTab(newValue);
        pagehistory=event.target.name;
        navigate(location.pathname.replace(location.pathname.split('/')[3],pageTabs[event.target.name].path));
    };

    const getRecord=(query)=>{
        let bNoRecord=false;
        getDBItem({entityType:query,entityId:slug})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setReadOnly(true);
                setSnackOpened(true);
	        }
            else{
                if(data!=null){
                    console.log('data',JSON.parse(JSON.stringify(data)));
                    if('type' in data && data['type']==='Storage'){
                        setRow(old=>prepareGetData(data,'storage_order'));
                        if(authMenuSubItem){
                            setLabel(authMenuSubItem.label);
                            setReadOnly(!('edit' in authMenuSubItem.actions));
                        }
                    }
                    else{
                        bNoRecord=true;
                    }
                    
                }
                else{
                    bNoRecord=true;
                }
            }

            if(bNoRecord){
                alertMessage='Record not found or not accessible';
                alertSeverity='error'
                setReadOnly(true);
                setSnackOpened(true);
            }
        });
    };

    const updateRecord=(row_updateds)=>{
        setOpenBackdrop(true);
        updateDBItem({entityType:authMenuSubItem.query,entityId:slug,entityData:row_updateds})
        .then((data)=>{
            console.log('data',data);
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
            }
            else{
                alertSeverity='success'
                alertMessage='Storage Order updated with success!!!';
            }
            getRecord(authMenuSubItem.query);
            setSnackOpened(true);
            setIsChanged(false);
            setOpenBackdrop(false);
            setRefreshPageTab(true);
        });
    };

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)&&(slug!==undefined&&slug!==null&&slug!=='')&&location){
            setActions(authMenuSubItem.actions);
            getRecord(authMenuSubItem.query);
            setPageTabs(old=>sortPageTabs(authMenuSubItem.pageTabs));
            if(!location.pathname.includes(authMenuSubItem.defaultTabPath)||(pagehistory&&(pagehistory!==authMenuSubItem.defaultTabPath))){
                for(let key in authMenuSubItem.pageTabs){
                    if(location.pathname.includes(authMenuSubItem.pageTabs[key].path)){
                        setPageTab(authMenuSubItem.pageTabs[key].index);
                        break;
                    }
                }                
            }
            else if('state' in location){
                if((location.state)&&('message' in location.state)){
                    alertMessage=location.state.message.alertMessage;
                    alertSeverity=location.state.message.alertSeverity;
                    setSnackOpened(true);
                }
            }
            setLabel(authMenuSubItem.label);
        }
    },[authMenuSubItem,slug,userDataAuthorization,location]);

    const handleClickSave=(new_row,new_updates)=>{
        if(validate_required_fields(new_row,'storage_order',setError,setErrorMessage,dataError,required_field_error)){
            updateRecord(new_updates);
            setUpdates(old=>{});
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        setIsChanged(false);
        getRecord(authMenuSubItem.query);
        setUpdates(old=>{});
        setError(dataError.error);
        setErrorMessage(dataError.message);
    }

    const handleChange=(event)=>{
        setIsChanged(true);
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        setUpdates(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
    };

    const handleClickDrilldown=(field_name)=>{
        if(field_name==='account_name'){
            window.open('/account/'+row.account_id+'/orders','_blank');
        }
    }

    const handleClickButton=(event)=>{
        switch(event.target.name){
            case "update":
                setReadOnly(false);
                break;
            case "cancel_storage_order":
                setOpenDialog(true);
                break;
            case "submit_storage_order":
                handleClickSave(row,{status:'Submitted'});
                break;
            default: 
                break;
        }
    }

    const handleDialogConfirm=()=>{
        handleClickSave(row,{status:'Canceled'});
        setOpenDialog(false);
    }

    const handleChangeDate=(fieldValue,fieldName)=>{
        setIsChanged(true);
        if(fieldValue&&!isNaN(fieldValue.$D)){
            setRow(prevState=>({...prevState,[fieldName]:fieldValue}));   
            setUpdates(prevState=>({...prevState,[fieldName]:dayjs_to_db(fieldValue)}));
            if(error[fieldName]){
                setError(prevState=>({...prevState,[fieldName]:false}));
                setErrorMessage(prevState=>({...prevState,[fieldName]:''}));
            }
        }
        else{
            setRow(prevState=>({...prevState,[fieldName]:'NaN-NaN-NaN'})); 
            setUpdates(prevState=>({...prevState,[fieldName]:null}));
        }
    }

    const handleChangeSwitch=(event)=>{
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
        if('events' in entity_object.storage_order.fields[event.target.name]){
            if('onChange' in entity_object.storage_order.fields[event.target.name].events){
                entity_object.storage_order.fields[event.target.name].events.onChange(event.target.value,handleChangeList);
            }
        }

        if('drive_dynamic_required' in entity_object.storage_order.fields[event.target.name]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[event.target.name]=event.target.value;
            for(const [key,value] of Object.entries(entity_object.storage_order.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.storage_order.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
    }

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);

        if('events' in entity_object.storage_order.fields[fieldName]){
            if('onChange' in entity_object.storage_order.fields[fieldName].events){
                entity_object.storage_order.fields[fieldName].events.onChange(fieldValue,handleChangeList,row);
            }
        }

        if('drive_dynamic_required' in entity_object.storage_order.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.storage_order.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.storage_order.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
    };

    const handleDialogCancel=()=>{
        setOpenDialog(false);
    }

    return (
        <div className="page">
            <Dialog fullWidth={true} maxWidth={'lg'} open={openDialog} onClose={handleDialogCancel}>
                <DialogTitle>{"Storage Order Cancelation"}</DialogTitle>
                <DialogContent>{"Confirm the Cancelation of the current Storage Order (#"+row.reference+")"}</DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel}>Cancel</Button>
                    <Button onClick={handleDialogConfirm} autoFocus variant="contained">Confirm</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            {recordInfo&&<RecordInfo recordInfoCallback={recordInfoCallback} row={row} entity_name={'storage_order'} globalData={globalData} userDataAuthorization={userDataAuthorization}></RecordInfo>}
            {showShippingReport&&<StorageOrderShippingReport shippingReportCallback={shippingReportCallback} row={row}></StorageOrderShippingReport>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.reference}</span></Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        {!readOnly&&<Button disabled={!isChanged} name="save" variant="outlined" onClick={()=>{handleClickSave(row,updates)}}>Save</Button>}
                        {!readOnly&&<Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>}
                        {Object.keys(actions).map((action)=>{
                            return((action==='submit_storage_order'?!row.pre_sales:true)&&editable_status.includes(row.status)&&actions[action].type==='button'&&readOnly&&<Button key={action} name={action} variant="outlined" color={action==='cancel_storage_order'?'error':action==='submit_storage_order'?'secondary':'primary'} onClick={handleClickButton}>{actions[action].label}</Button>);
                        })}
                        {enable_shipping_report_status.includes(row.status)&&<Button name="shipping_report" variant="outlined" onClick={()=>{handleClickShippingReport()}}>Generate Shipping Report</Button>}
                    </ButtonGroup>
                    {readOnly&&
                    <IconButton onClick={handleClickMoreInfo} color="primary" style={{position:'absolute',right:'40px'}} size="small">
                        <Tooltip title="More Information on the Record">
                            <InfoIcon fontSize="inherit"/>
                        </Tooltip>
                    </IconButton>}
                    {readOnly&&
                    <IconButton onClick={()=>{
                        setRow(old=>dataPreparedGet);
                        getRecord(authMenuSubItem.query)}} color="primary" style={{position:'absolute',right:'15px'}} size="small">
                        <Tooltip title="Refresh the current Record">
                            <RefreshIcon fontSize="inherit"/>
                            </Tooltip>
                    </IconButton>}
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="reference"
                            name="reference"
                            type={entity_object.storage_order.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.reference.label}
                            value={row.reference}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{color:status_color[row.status],backgroundColor:readOnlyBackgroundColor}}}
                            id="status"
                            name="status"
                            type={entity_object.storage_order.fields.status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.status.label}
                            value={row.status}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="delivery_status"
                            name="delivery_status"
                            type={entity_object.storage_order.fields.delivery_status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.delivery_status.label}
                            value={row.delivery_status}
                            margin="normal"/>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi' localeText={localeText}>
                            <MobileDatePicker
                                sx={{width:'100%',marginTop:"0px",marginBottom:"0px",div:{marginBottom:"8px",backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                readOnly={readOnly}
                                slotProps={{textField:{
                                    required:entity_object.storage_order.fields.date_of_arrival.required,
                                    margin:"normal",
                                    size:"small",
                                    variant:"outlined",
                                    error:error.date_of_arrival,
                                    helperText:errorMessage.date_of_arrival}}}
                                label={entity_object.storage_order.fields.date_of_arrival.label}
                                value={dayjs(row.date_of_arrival)}
                                onChange={(fieldValue)=>handleChangeDate(fieldValue,'date_of_arrival')}/>
                        </LocalizationProvider>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{
                                readOnly:true,                                    
                                endAdornment:(
                                    row.id!==''&&
                                    <InputAdornment position="end">
                                        <IconButton onClick={(e)=>handleClickDrilldown('account_name')} color="primary">
                                            <ArrowCircleRight/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required={entity_object.storage_order.fields.account_id.required}
                            error={error.account_id}
                            helperText={errorMessage.account_id}
                            id="account_name"
                            name="account_name"
                            type={entity_object.account.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.joins.account.fields.name.label}
                            value={row.account.name}
                            margin="normal"/>
                    </Stack>
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?'Hide More Info':'Show More Info'}</Button>
                    {showMoreInfo&&
                    <>
                    <Divider /*className={classes.tax}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.storage_order.style.tax_color} gutterBottom variant="body1">Order Tax</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.storage_order.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:readOnly}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            required={entity_object.storage_order.fields.excise_number.required}
                            error={error.excise_number}
                            helperText={errorMessage.excise_number}
                            id="excise_number"
                            name="excise_number"
                            type={entity_object.storage_order.fields.excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.excise_number.label}
                            value={row.excise_number}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:readOnly}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            required={entity_object.storage_order.fields.warehouse_excise_number.required}
                            error={error.warehouse_excise_number}
                            helperText={errorMessage.warehouse_excise_number}
                            id="warehouse_excise_number"
                            name="warehouse_excise_number"
                            type={entity_object.storage_order.fields.warehouse_excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.warehouse_excise_number.label}
                            value={row.warehouse_excise_number}
                            margin="normal"
                            onChange={handleChange}/>
                        <FormGroup style={{paddingLeft:'10px',width:'50%'}}>
                            <FormControlLabel control={<Switch name='tax_free' checked={row.tax_free} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.storage_order.fields.tax_free.label}/>
                            <FormControlLabel control={<Switch name='pre_sales' checked={row.pre_sales} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.storage_order.fields.pre_sales.label}/>
                        </FormGroup>
                    </Stack>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.storage_order.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack></>}
                </Box>
            </div>
            <Box sx={{bgcolor:'none'}}>
                <Tabs
                    value={pageTab}
                    onChange={handleChangeTab}
                    sx={{"& button:hover":{backgroundColor:'lightgrey'},"& button:focus":{backgroundColor:'white'},"& .MuiTab-root.Mui-selected":{backgroundColor:'white'},}}
                    variant="scrollable"
                    scrollButtons="auto">
                    {Object.keys(pageTabs).map((item)=>{
                        return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                    })}
                </Tabs>
                {Object.keys(pageTabs).length>0&&('items' in pageTabs)&&pageTab===pageTabs['items'].index&&<StorageOrderDetailItems order_status={row.status} order_id={row.id} orderItemCallback={orderItemCallback} pageTab={('items' in pageTabs)?pageTabs['items']:{}} globalData={globalData} userDataAuthorization={userDataAuthorization}></StorageOrderDetailItems>}
                {Object.keys(pageTabs).length>0&&('audit' in pageTabs)&&pageTab===pageTabs['audit'].index&&<Audit record_id={row.id} pageTab={('audit' in pageTabs)?pageTabs['audit']:{}} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} entity_name='storage_order' globalData={globalData} userDataAuthorization={userDataAuthorization}></Audit>}
            </Box>
        </div>
    );
}

export default StorageOrderDetail;
