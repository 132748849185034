import * as React from 'react';
import {useState,useEffect} from "react";
import {List,ListItemText,Divider,Stack,Grid,Badge,Menu,Container,Avatar,Tooltip,MenuItem,AppBar,Box,Toolbar,IconButton,Typography} from '@mui/material';
import MuiListItem from "@mui/material/ListItem";
import {withStyles} from '@mui/styles';
import LinkMUI from '@mui/material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications.js';
import MenuIcon from '@mui/icons-material/Menu.js';
import DeleteIcon from '@mui/icons-material/Delete.js';
import About from './About.js';
import logo from '../assets/logo.png';
import {Link,useNavigate,useLocation} from "react-router-dom";
import {getDBList,updateDBItem,deleteDBItem,deleteDBList} from '../integration/DatabaseManager.js';
import {useKeycloak} from "@react-keycloak/web";
import {currentTimestamp} from '../utilities/Utilities.js';
import {io} from 'socket.io-client';
const socket=io(process.env.REACT_APP_SOCKET_HOST||'http://localhost:4000',{autoConnect:false,rejectUnauthorized:process.env.REACT_APP_REJECT_UNAUTHORIZED==='true',secure:true});
let got_first_notification=false;

const ListItem=withStyles({
  root:{
    "&:hover":{
      backgroundColor:"#d3d3d35e"
    }
  },
  selected: {}
})(MuiListItem);

function Header({sessionExpired,menuItemsAuthorization,userDataAuthorization,userMenuAuthorization,menuConfigAuthorization}){
  const [anchorElNav,setAnchorElNav]=useState(null);
  const [anchorElUser,setAnchorElUser]=useState(null);
  const [anchorElNotifications,setAnchorElNotifications]=useState(null);
  const [menuItems,setMenuItems]=useState(menuItemsAuthorization);
  const [userSettings,setUserSettings]=useState([]);
  const [userData,setUserData]=useState(userDataAuthorization);
  const [menuConfig,setMenuConfig]=useState(menuConfigAuthorization);
  const {keycloak}=useKeycloak();
  const isLoggedIn=keycloak.authenticated;
  const location=useLocation();
  const [selectedIndex,setSelectedIndex]=useState(0);
  const navigate=useNavigate();
  const [notificationsCount,setNotificationsCount]=useState(0);
  const [notifications,setNotifications]=useState([]);
  const [openAbout,setOpenAbout]=useState(false);

  const getNotificationRecords=(user_login)=>{
    getDBList({entityType:'Notification',entityParams:["SearchExpression=([user_login]='"+user_login+"')"]})
    .then((data)=>{
      console.log('data',data);
      if('error' in data){
          console.log('error',data.error);
      } 
      else {
        if(data.length>0){
          let unread=data.filter(record=>!record.read);
          setNotificationsCount(unread.length);
          setNotifications(old=>data);
          }
          else{
            setNotificationsCount(0);
            setNotifications(old=>[]);
            handleCloseUserNotification();
          }
        }
    });
  };

  const updateNotificationRecords=(notification)=>{
    let update={read:true,read_at:currentTimestamp()}
    console.log('update',update);
    updateDBItem({entityType:'Notification',entityId:notification.id,entityData:update})
    .then((data)=>{
        if('error' in data){
            console.log('error',data.error);
        }
        getNotificationRecords(userDataAuthorization.user_login);
        notificationNavigate(notification);
    });
  };

  const removeNotificationRecords=(notification_id)=>{
    deleteDBItem({entityType:'Notification',entityId:notification_id})
    .then((data)=>{
      if('error' in data){
        console.log('error',data.error);
      }
      getNotificationRecords(userDataAuthorization.user_login);
    });
  };
  
  useEffect(()=>{
    if(Object.keys(menuItemsAuthorization).length>0){
      menuItemsAuthorization.forEach(item=>{
        if(location.pathname.split('/')[1].indexOf(item.code)>=0){
          setSelectedIndex(old=>item.index);
        }
      });
      setMenuItems(old=>menuItemsAuthorization);
    }

    if(Object.keys(menuConfigAuthorization).length>0){
      setMenuConfig(old=>menuConfigAuthorization);
    }
    
    if(Object.keys(userMenuAuthorization).length>0){
      let userSettingsTemp=userMenuAuthorization;
      userSettingsTemp.forEach(item=>{
        if(item.name==='login'){item['visible']=!isLoggedIn}
        else if(item.name==='logout'){item['visible']=isLoggedIn}
        else{item['visible']=true}
      });
      setUserSettings(old=>userSettingsTemp);
    }

    if(Object.keys(userDataAuthorization).length>0){
      if(sessionExpired){
        handleCloseUserMenu('logout','/');
       }

      socket.connect();
      setUserData(old=>userDataAuthorization);
      if(!got_first_notification){
        got_first_notification=true;
        getNotificationRecords(userDataAuthorization.user_login);
      }

      function onNotification(){
        getNotificationRecords(userDataAuthorization.user_login);
      }

      socket.emit('userData',{socket_id:socket.id,user_login:userDataAuthorization.user_login});
      socket.on('notification',onNotification);

      return()=>{
        socket.off('notification',onNotification);
        socket.disconnect();
      };
     
    }
  },[sessionExpired,menuItemsAuthorization,userDataAuthorization,isLoggedIn,userMenuAuthorization,location,menuConfigAuthorization,socket]);

  const handleItemSelect=(event,index)=>{
    handleCloseNavMenu();
    setSelectedIndex(index);
  };

  const handleOpenNavMenu=(event)=>{
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu=(event)=>{
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotifications=(event)=>{
    if(notifications.length>0){
      setAnchorElNotifications(event.currentTarget);
    }
  };

  const handleCloseNavMenu=()=>{
    setAnchorElNav(null);
  };

  const handleCloseUserMenu=(name,path)=>{
    switch(name){
      case "logout":
        navigate('/');
        socket.disconnect();
        keycloak.logout();
        break;
      case "login":
        keycloak.login();
        break;
      case "about":
        setOpenAbout(true);
        break;
      default:
        if(path!==undefined&&path!=='backdropClick'){
          setSelectedIndex(100);
          let drilldown_id=userData.user_id;
          navigate(path+drilldown_id+'/user');
        }
        break;
    }
    setAnchorElUser(null);
  };

  const aboutCallback=()=>{
    setOpenAbout(false);
  };

  const handleCloseUserNotification=()=>{
    setAnchorElNotifications(null);
  }

  const handleNotificationClick=(notification)=>{
    if(!notification.read){
      updateNotificationRecords(notification);
    }
    else{
      notificationNavigate(notification);
    }
  }

  const notificationNavigate=(notification)=>{
    if(notification.url){
      navigate(notification.url);
    }
    setAnchorElNotifications(null);
  }
  
  const handleRemoveNotificationAll=()=>{
    deleteDBList({entityType:'Notification',entityParams:["SearchExpression=([user_login]='"+userDataAuthorization.user_login+"')"]})
    .then((data)=>{
      console.log('data',data);
      if('error' in data){
          console.log('error',data.error);
      }
      getNotificationRecords(userDataAuthorization.user_login);
      handleCloseUserNotification();
    });
  }

  return (<>
    {openAbout&&<About aboutCallback={aboutCallback}></About>}
    <AppBar position="static" style={{backgroundColor:menuConfig.header_color||'gray'/*'#007301' menuConfig.header_color*/}}>
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <Box sx={{flexGrow:1}}>
            {isLoggedIn&&
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{color:"white"}}>
              <MenuIcon/>
            </IconButton>}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{vertical:'bottom',horizontal:'left'}}
              keepMounted
              transformOrigin={{vertical:'top',horizontal:'left'}}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}>
              {menuItems.map((menu,index)=>{
                return (menu.visible&&
                  <MenuItem key={menu.name} onClick={event=>handleItemSelect(event,index)}>
                      <Link to={menu.path} style={selectedIndex===index?{textDecoration:"underline",textDecoration:"none",color:"#1976d2",fontSize:"20px",borderBottom:"1px solid transparent","&:hover":{color:"#1976d2"}}:{textDecoration:"none",color:"#1976d2",fontSize:"20px",width:'100%',borderBottom:"1px solid transparent","&:hover":{color:"#1976d2"}}}>
                        {menu.label}
                      </Link>
                  </MenuItem>);
                })}
            </Menu>
          </Box>
          <Box sx={{flexGrow:1}}>
          <div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
              <Typography style={{marginTop:"10px",color:'white'}} variant="h6" gutterBottom>Brainiac - CoolHead Brew</Typography>
          </div> 
          </Box>
          {isLoggedIn&&
          <Box sx={{flexGrow:0}} style={{marginRight:'10px'}}>
            <Tooltip title={'Notifications'}>
              <IconButton onClick={handleOpenNotifications} sx={{p:0}}>
                <Badge id="notification_badge" color="secondary" badgeContent={notificationsCount}>
                  <Avatar 
                    sx={{bgcolor:menuConfig.header_icon_bgcolor||'white'}}
                    style={{width:"38px",height:"38px"}}
                    alt={'Notifications'}>
                    <NotificationsIcon/>
                  </Avatar>
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{mt:'45px'}}
              id="menu-notification"
              anchorEl={anchorElNotifications}
              anchorOrigin={{vertical:'top',horizontal:'right'}}
              keepMounted
              transformOrigin={{vertical:'top',horizontal:'right'}}
              open={Boolean(anchorElNotifications)}
              onClose={handleCloseUserNotification}>
              <Grid 
                  key="notification_grid_header"
                  container 
                  style={{alignItems:"center",justifyContent:"center"}}>
                  <Typography variant="h6" gutterBottom>Notifications</Typography>
              </Grid>
              <Divider/>
              <Stack spacing={1}>
                <List dense={true}>
                  {notifications.map((notification)=>{
                    let message=notification.message;
                    return <ListItem
                        style={{backgroundColor:notification.read?'#d3d3d34f':'None'}}
                        key={notification.id}
                        secondaryAction={
                          <Tooltip title="Delete Notification" placement="right-end">
                            <IconButton sx={{color:'red'}} edge="end" aria-label="Remove" onClick={()=>removeNotificationRecords(notification.id)}>
                                <DeleteIcon/>
                            </IconButton>
                            </Tooltip>
                        }>
                          <ListItemText
                            primary={
                              <LinkMUI 
                                href="#"
                                component="button"
                                style={{color:notification.read?'#1976d291':'#1976d2'}}
                                onClick={()=>handleNotificationClick(notification)}
                                underline="hover">
                              {notification.title}
                            </LinkMUI>}
                            secondary={<span style={{color:notification.read?'':'black'}} /*className="reset-styles"*/ dangerouslySetInnerHTML={{ __html:message}} />}
                          />
                    </ListItem>
                  })}
                </List>
              </Stack>
              <Divider/>
              <Grid
                key="notification_grid_footer"
                container 
                style={{alignItems:"center",justifyContent:"center"}}>
                <LinkMUI 
                  href="#"
                  component="button"
                  style={{padding:'10px',color:'red',alignItems:"center",justifyContent:"center"}}
                  onClick={handleRemoveNotificationAll}
                  underline="hover">
                  {'Remove All Notifications'}
                </LinkMUI>
              </Grid>
            </Menu>
          </Box>}

          <Box sx={{flexGrow:0}}>
            <Tooltip title={'role_label' in userData?'Options':'Login'}>
              <IconButton onClick={handleOpenUserMenu} sx={{p:0}}>
                <Avatar 
                  src={logo}
                  style={{width:"45px",height:"45px",WebkitFilter:"invert(100%)"}}
                  alt={'user_name' in userData?userData.user_name:''}>
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{mt:'45px'}}
              id="menu-user"
              anchorEl={anchorElUser}
              anchorOrigin={{vertical:'top',horizontal:'right'}}
              keepMounted
              transformOrigin={{vertical:'top',horizontal:'right'}}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {userSettings.map((setting)=>{
                return (setting.visible&&
                  <MenuItem key={setting.name} onClick={()=>handleCloseUserMenu(setting.name,setting.path)}>
                    <Typography textAlign="center">{setting.label}</Typography>
                  </MenuItem>);
              })}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </>
  );
}
export default Header;
