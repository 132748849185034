import {useRef} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions,Box,Stack,TextField,Typography} from '@mui/material';
//import Paper from "@material-ui/core/Paper";
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {entity_object} from '../../utilities/DataDictionary';

const PaperComponent=(props)=>{
    const nodeRef=useRef(null);
    return (
      <Draggable cancel=".btn" nodeRef={nodeRef}>
        <Paper style={{height:'85vh'}} ref={nodeRef} {...props} />
      </Draggable>
    );
  }

const ErrorLogDetail=({errorLogDetailCallback,row})=>{
    
    const handleClickClose=()=>{
        errorLogDetailCallback();
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={true}
            PaperComponent={PaperComponent}
            onClose={handleClickClose}>
            <DialogTitle style={{cursor:'move'}}>Information about the Log</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off">
                    <Stack direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                        <TextField
                            InputProps={{readOnly:true}}
                            style={{marginTop:"0px"}}
                            id="id"
                            name="id"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.id.label}
                            value={row.id}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="created_by"
                            name="created_by"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.created_by.label}
                            value={row.created_by}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="created_at_ui"
                            name="created_at_ui"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.created_at.label}
                            value={row.created_at_ui}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="level"
                            name="level"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.level.label}
                            value={row.level||''}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="status"
                            name="status"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.status.label}
                            value={row.status||''}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'5px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                        <TextField
                            style={{marginTop:"0px"}}
                            InputProps={{readOnly:true}}
                            id="source"
                            name="source"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.source.label}
                            value={row.source||''}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'5px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:true}}
                            style={{marginTop:'0px'}}
                            multiline={true}
                            rows={2}
                            id="message"
                            name="message"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.message.label}
                            value={row.message||''}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'5px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:true}}
                            style={{marginTop:'0px'}}
                            multiline={true}
                            rows={5}
                            id="stack_trace"
                            name="stack_trace"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.stack_trace.label}
                            value={row.stack_trace||''}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'5px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:true}}
                            style={{marginTop:'0px'}}
                            multiline={true}
                            rows={5}
                            id="request_data"
                            name="request_data"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={entity_object.error_log.fields.request_data.label}
                            value={row.request_data||''}
                            margin="normal"/>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className="btn" onClick={handleClickClose} variant="contained">Close</Button>
             </DialogActions>
        </Dialog>
    );
}

export default ErrorLogDetail;
