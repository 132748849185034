import * as React from 'react';
import {useKeycloak} from "@react-keycloak/web";

function Footer({userDataAuthorization}){
  const {keycloak}=useKeycloak();
  const isLoggedIn=keycloak.authenticated;

  return (
    <>
    {isLoggedIn&&
      <div style={{position:'fixed',bottom:'1px',textAlign:'center',width:'100%',backgroundColor:'lightgray',zIndex:'1000'}}>
          {userDataAuthorization.role_label}: {userDataAuthorization.user_name}{' (login: '}{userDataAuthorization.user_login}{')'}
      </div>}
    </>
  );
}
export default Footer;
