import {useEffect,useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Tabs,Tab,Tooltip,Autocomplete,Switch,FormGroup,FormControlLabel,Divider,Box,Backdrop,CircularProgress,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,InputAdornment,IconButton} from '@mui/material';
import {AccountCircle,ArrowCircleUp,ArrowCircleDown,ArrowCircleRight} from '@mui/icons-material';
import {sortPageTabs,prepareGetData,prepareDefaultData,validate_required_fields,prepareErrorFields,DefaultConfig,time_to_date_ui,time_to_ui,storage_order_delivery_price,dayjs_to_ui,dayjs_to_db,adjustDateLocale} from '../../utilities/Utilities.js';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter,GridToolbarExport} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities.js';
import {entity_object} from '../../utilities/DataDictionary.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {generate_report} from '../../integration/Api.js';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {enUS} from '@mui/x-date-pickers/locales';
dayjs.locale('fi')
import 'dayjs/locale/fi';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
const localeText=enUS.components.MuiLocalizationProvider.defaultProps.localeText;
const dataPreparedGet=prepareDefaultData('default_value_new','report');
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
let alertMessage='';
let alertSeverity='error';
const report_total_colums={
    Palpa:['sku_palpa','units_delivered','total_palpa_value'],
    Valvira:['litres','price'],
    Sales:['order_amount']
}
const list_name='report';
const Reports=({authMenuSubItem,userDataAuthorization,globalData})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [rows,setRows]=useState([]);
    const [rowsAll,setRowsAll]=useState([]);
    const [total,setTotal]=useState({});
    const [loading,setLoading]=useState(false);
    const [detail,setDetail]=useState(false);
    const [label,setLabel]=useState('-');
    const [snackOpened,setSnackOpened]=useState(false);
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)){
            setLabel(authMenuSubItem.label);
        }
    },[authMenuSubItem,userDataAuthorization]);

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const handleChange=(event)=>{
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
    };

    const handleChangeDetailValvira=(event)=>{
        setDetail(prevState=>event.target.checked);
    };

    const handleChangeDetailPalpa=(event)=>{
        if(event.target.checked){
            let rows=rowsAll;
            let id_temp=0;
            let total={};
            report_total_colums.Palpa.forEach(t=>{
                total[t]="0.00";
            });
            rows.forEach(e=>{
                e.id_temp=id_temp;
                id_temp++;
                if(Object.keys(total).length>0){
                    Object.keys(total).forEach(a=>{
                        total[a]=(parseFloat(total[a])+parseFloat(e[a])).toFixed(2);
                    });
                }
            });
            setRows(old=>rows.length>0?rows:[]);
            setTotal(old=>total);
        }
        else{
            let rows=[];
            rowsAll.forEach(e=>{
                if(!e.export){rows.push(e);}
            });
            let id_temp=0;
            let total={};
            if(row.type in report_total_colums){
                report_total_colums[row.type].forEach(t=>{
                    total[t]="0.00";
                });
            }
            rows.forEach(e=>{
                e.id_temp=id_temp;
                id_temp++;
                if(Object.keys(total).length>0){
                    Object.keys(total).forEach(a=>{
                        total[a]=(parseFloat(total[a])+parseFloat(e[a])).toFixed(2);
                    });
                }
            });
            setRows(old=>rows.length>0?rows:[]);
            setTotal(old=>total);
        }
        setDetail(prevState=>event.target.checked);
    };

    const handleChangeList=(fieldValue,fieldName)=>{
        if(fieldName==='type'){
            setRows(old=>[]);
        }
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);
        setDetail(false);
    };

    const handleChangeDate=(fieldValue,fieldName)=>{
        if(fieldValue&&!isNaN(fieldValue.$D)){
            setRow(prevState=>({...prevState,[fieldName]:fieldValue}));
        }
        else{
            setRow(prevState=>({...prevState,[fieldName]:'NaN-NaN-NaN'}));
        }
    }

    const handleClickGenerateReport=()=>{
        setLoading(true);
        setOpenBackdrop(true);
        generate_report({reportType:row.type,reportParams:["start_date="+dayjs_to_db(row.start_date)+" 00:00:00","end_date="+dayjs_to_db(row.end_date)+" 00:00:00"]})
        .then((data)=>{
            console.log(data);
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
                setSnackOpened(true);
            }
            else{
                setRowsAll(old=>data.length>0?data:[]);
                let rows=data;
                if(row.type==='Palpa'){
                    rows=[];
                    data.forEach(e=>{
                        if(!e.export){rows.push(e);}
                    });
                }
                let id_temp=0;
                let total={};
                if(row.type in report_total_colums){
                    report_total_colums[row.type].forEach(t=>{
                        total[t]="0.00";
                    });
                }
                let color='darkblue';
                let last_gtin='';
                rows.forEach(e=>{
                    e.id_temp=id_temp;
                    if(row.type==='Sales by Product Account'){
                        if(e.gtin!==last_gtin){
                            color=color=='darkblue'?'darkgreen':'darkblue';
                        }
                        last_gtin=e.gtin;
                        e.color=color;
                    }
                    else if(row.type==='Sales by Category'){
                        if(e.category===null){
                            color='darkorange';
                            last_gtin=null;
                        }
                        else if(e.sub_category===null){
                            if(e.category!==last_gtin){
                                color=color=='darkblue'?'darkgreen':'darkblue';
                            }
                            last_gtin=e.category;
                        }
                        else{
                            console.log('e',e);
                            console.log('- ',e.category+e.sub_category);
                            console.log('- ',last_gtin);
                            if(e.category+e.sub_category!==last_gtin){
                                color=color=='darkblue'?'darkgreen':'darkblue';
                            }
                            last_gtin=e.category+e.sub_category;
                        }
                        e.color=color;
                    }
                    
                    id_temp++;
                    if(Object.keys(total).length>0){
                        Object.keys(total).forEach(a=>{
                            total[a]=(parseFloat(total[a])+parseFloat(e[a])).toFixed(2);
                        });
                    }
                });
                setRows(old=>rows.length>0?rows:[]);
                setTotal(old=>total);
            }
            setLoading(false);
            setOpenBackdrop(false);
        });
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
            <GridToolbarExport ></GridToolbarExport>
          </GridToolbarContainer>
        );
    }

    return (<>
        <div className="page">
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            {<Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.type}</span></Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        <Button disabled={row.type===''||row.type===null||row.type===undefined} name="generate" variant="outlined" onClick={handleClickGenerateReport}>Generate Report</Button>                        
                    </ButtonGroup>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            options={globalData.listOfValues.REPORT_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="type"
                            name="type"
                            type={entity_object.report.fields.type.ui_type}
                            size="small"
                            value={row.type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'type');}}
                            renderInput={(params)=>(
                                <TextField {...params}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.report.fields.type.required}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.report.fields.type.label}
                                    margin="normal"
                                />
                            )}
                        />
                        
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi' localeText={localeText}>
                            <MobileDatePicker
                                sx={{width:'100%',marginTop:"0px",marginBottom:"0px",div:{marginBottom:"8px"}}}
                                slotProps={{textField:{
                                    required:entity_object.report.fields.start_date.required,
                                    margin:"normal",
                                    size:"small",
                                    variant:"outlined"}}}
                                label={entity_object.report.fields.start_date.label}
                                value={row.start_date}
                                onChange={(fieldValue)=>handleChangeDate(fieldValue,'start_date')}/>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi' localeText={localeText}>
                            <MobileDatePicker
                                sx={{width:'100%',marginTop:"0px",marginBottom:"0px",div:{marginBottom:"8px"}}}
                                slotProps={{textField:{
                                    required:entity_object.report.fields.end_date.required,
                                    margin:"normal",
                                    size:"small",
                                    variant:"outlined"}}}
                                label={entity_object.report.fields.end_date.label}
                                value={row.end_date}
                                onChange={(fieldValue)=>handleChangeDate(fieldValue,'end_date')}/>
                        </LocalizationProvider>
                    </Stack>
                </Box>
            </div>
        </div>
        <div className="page-content">
            {row.type==='Valvira'&&
                <FormGroup>
                    <FormControlLabel control={<Switch checked={detail} onChange={handleChangeDetailValvira}/>} label="Detail"/>
                </FormGroup>
            }
            {row.type==='Palpa'&&Object.keys(rowsAll).length>0&&
                <FormGroup>
                    <FormControlLabel control={<Switch checked={detail} onChange={handleChangeDetailPalpa}/>} label="Sales outside of Finland"/>
                </FormGroup>
            }
            <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                <div style={{height:'60vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        editMode="row"
                        getRowId={(row)=>row.id_temp}
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        initialState={{
                            sorting:{
                                sortModel:[{field:'name',sort:'asc'}],
                            },
                        }}
                        rows={rows}
                        columns={columns[list_name](row.type,detail,total,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </Stack>
        </div>
        </>
    );
}

export default Reports;
