import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
var styleElem = document.head.appendChild(document.createElement("style"));
styleElem.innerHTML = "body:before {background: black;}";

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);