// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color:#d3d3d354;
}

@media print {
    body::after {
        content:none!important;
        background:none!important;
    }
}

.page{
    padding-top:5px;
    padding-bottom:5px;
}

.page-content{
    padding:10px;
    box-shadow:rgb(84 88 91 / 25%) 0px 4px 16px, rgb(52 52 55 / 5%) 0px 8px 32px;
    overflow:hidden;
    margin-bottom:10px;
    background:#fff;
}

.body-title{
    color: darkmagenta;
}

.reset-styles * {
    all: unset;
    box-sizing: border-box;
    display: revert;
    font-family: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    list-style: none;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI;QACI,sBAAsB;QACtB,yBAAyB;IAC7B;AACJ;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,4EAA4E;IAC5E,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,eAAe;IACf,oBAAoB;IACpB,cAAc;IACd,SAAS;IACT,UAAU;IACV,SAAS;IACT,qBAAqB;IACrB,gBAAgB;EAClB","sourcesContent":["body {\n    background-color:#d3d3d354;\n}\n\n@media print {\n    body::after {\n        content:none!important;\n        background:none!important;\n    }\n}\n\n.page{\n    padding-top:5px;\n    padding-bottom:5px;\n}\n\n.page-content{\n    padding:10px;\n    box-shadow:rgb(84 88 91 / 25%) 0px 4px 16px, rgb(52 52 55 / 5%) 0px 8px 32px;\n    overflow:hidden;\n    margin-bottom:10px;\n    background:#fff;\n}\n\n.body-title{\n    color: darkmagenta;\n}\n\n.reset-styles * {\n    all: unset;\n    box-sizing: border-box;\n    display: revert;\n    font-family: inherit;\n    color: inherit;\n    margin: 0;\n    padding: 0;\n    border: 0;\n    text-decoration: none;\n    list-style: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
