import {useEffect,useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Tooltip,IconButton,Box,Button,ButtonGroup,Typography,Stack} from '@mui/material';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import {columns} from '../../utilities/Entities.js';
import {getDBList} from '../../integration/DatabaseManager.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
const list_name='sales_orders';

const SalesOrders=({authMenuSubItem,userDataAuthorization,globalData})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [label,setLabel]=useState('');
    const [actions,setActions]=useState({});
    const navigate=useNavigate();
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);
    const [filterModel,setFilterModel]=useState(globalData.filterModel[list_name]);
    const [sortModel,setSortModel]=useState(globalData.sortModel[list_name]);

    const getRecords=(query,query_args)=>{
        getDBList({entityType:query,entityParams:query_args})
        .then((data)=>{
            console.log('data',data);
            if('error' in data){
		        console.log('error',data.error);
	        } 
            else{
                if(data.length>0){
                    setRows(old=>data);
                }
            }
            setLoading(false);
        });
    };

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&authMenuSubItem){
            getRecords(authMenuSubItem.query,authMenuSubItem.query_args);
            setLabel(authMenuSubItem.label);
            setActions(authMenuSubItem.actions);
        }   
    },[authMenuSubItem,userDataAuthorization]);

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    const handleClickListButton=(event)=>{
        if(event.target.name==='create_new_sales_order'){
            navigate('/sales_order/new/');
        }
    }

    return (
        <div className="page">
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}</Typography>
            </Box>
            <IconButton onClick={()=>{
                setRows(old=>[]);
                getRecords(authMenuSubItem.query,authMenuSubItem.query_args);}} color="primary" style={{position:'absolute',right:'15px',paddingTop:'10px'}} size="small">
                <Tooltip title="Refresh the List">
                    <RefreshIcon fontSize="inherit"/>
                </Tooltip>
            </IconButton>
            <div className="page-content">
                <ButtonGroup size="small" aria-label="small button group">
                    {Object.keys(actions).map((action)=>{
                        return(actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{actions[action].label}</Button>);
                    })}
                </ButtonGroup>
                <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                    <div style={{height:'70vh',width:'100%'}}>
                        <DataGrid
                            sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                            editMode="row"
                            rows={rows}
                            columns={columns[list_name](list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                            loading={loading}
                            density="compact"
                            slots={{
                                toolbar:CustomToolbar,
                            }}
                            componentsProps={{
                                toolbar:{
                                    showQuickFilter:true,
                                    quickFilterProps:{debounceMs:250},
                                },
                            }}
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel)=>{
                                setFilterModel(newFilterModel);
                                updateGlobalData({...globalData,filterModel:{...globalData.filterModel,[list_name]:newFilterModel}});
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel)=>{
                                setSortModel(newSortModel);
                                updateGlobalData({...globalData,sortModel:{...globalData.sortModel,[list_name]:newSortModel}});
                            }}
                            onColumnWidthChange={(params,event,details)=>{
                                let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                                updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                                saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                            }}
                        />
                    </div>
                </Stack>
            </div>
        </div>
    );
}

export default SalesOrders;
