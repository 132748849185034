import {useNavigate} from 'react-router-dom';
import {useEffect,useState} from "react";
import {getDBList} from '../../integration/DatabaseManager.js';
import {ButtonGroup,Button,Stack} from '@mui/material';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
const list_name='skus';

const ProductDetailSKUs=({parent,pageTab,globalData,userDataAuthorization})=>{
    const navigate=useNavigate();
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);

    const getRecords=(isSubscribed)=>{
        getDBList({entityType:'SKU',entityParams:["SearchExpression=([product_id]="+parent.id+")"]})
        .then((data)=>{
            if(isSubscribed){
                if('error' in data){console.log('error',data.error);}
                else{
                    if(data.length>0){
                        console.log('records',data);
                        setRows(old=>data);
                    }
                    else{
                        setRows([]);
                    }
                }
                setLoading(false);
            }
        });
    };
    
    useEffect(()=>{
        let isSubscribed=true;
        if(Object.keys(parent).length>0){
            if(parent.id!==undefined&&parent.id!==null&&parent.id!==''){
                getRecords(isSubscribed);
            }
        }
        return ()=>(isSubscribed=false);
    },[parent.id]);

    const handleClickListButton=(event)=>{
        switch(event.target.name){
            case "add_sku_single":
                navigate('/sku/new',{state:{product:parent}});
                break;
            default:
                break;
        }
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
        <div className="page-content">
            <ButtonGroup size="small" aria-label="small button group">
                {Object.keys(pageTab).length>0&&Object.keys(pageTab.actions).map((action)=>{
                    return(pageTab.actions[action].type==='button'&&<Button key={action} name={action} variant="outlined" onClick={handleClickListButton}>{pageTab.actions[action].label}</Button>);
                })}
            </ButtonGroup>
            <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        editMode="row"
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        initialState={{
                            sorting:{
                                sortModel:[{field:'name',sort:'asc'}],
                            },
                        }}
                        loading={loading}
                        rows={rows}
                        columns={columns[list_name](list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </Stack>
        </div>);
}

export default ProductDetailSKUs;
