import {useEffect,useState} from "react";
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import {Tooltip,Switch,FormGroup,FormControlLabel,Backdrop,CircularProgress,Autocomplete,Divider,Box,Tabs,Tab,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,IconButton} from '@mui/material';
import {ArrowCircleUp,ArrowCircleDown} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import RecordInfo from "../RecordInfo"
import {sortPageTabs,prepareGetData,prepareDefaultData,prepareErrorFields,validate_required_fields,DefaultConfig,time_to_ui,time_to_date_ui} from '../../utilities/Utilities';
import {getDBItem,updateDBItem} from '../../integration/DatabaseManager.js';
import AccountDetailSalesOrders from "./AccountDetailSalesOrders";
import {entity_object} from '../../utilities/DataDictionary';
import Country from '../Country.js';
import Audit from '../Audit.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='error'
const required_field_error='Required Field';
const dataPreparedGet=prepareDefaultData('default_value_get','account');
const dataError=prepareErrorFields('account');
let pagehistory=null;
const AccountDetail=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [label,setLabel]=useState('-');
    const {slug}=useParams();
    const [pageTab,setPageTab]=useState(0);
    const [pageTabs,setPageTabs]=useState({});
    const [isChanged,setIsChanged]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);  
    const navigate=useNavigate();
    const location=useLocation();
    const [readOnly,setReadOnly]=useState(true);
    const [updates,setUpdates]=useState({});
    const [showMoreInfo,setShowMoreInfo]=useState(false);
    const [recordInfo,setRecordInfo]=useState(false);
    const [actions,setActions]=useState({});
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const {globalData}=useContext(ApplicationContext);
    const [refreshPageTab,setRefreshPageTab]=useState(false);

    const handleClickMoreInfo=()=>{
        setRecordInfo(true);
    };

    const recordInfoCallback=()=>{
        setRecordInfo(false);
    };

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const handleChangeSwitch=(event)=>{
        const {checked}=event.target;
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
        if('events' in entity_object.account.fields[event.target.name]){
            if('onChange' in entity_object.account.fields[event.target.name].events){
                entity_object.account.fields[event.target.name].events.onChange(event.target.value,handleChangeList);
            }
        }
        if('drive_dynamic_required' in entity_object.account.fields[event.target.name]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[event.target.name]=event.target.value;
            for(const [key,value] of Object.entries(entity_object.account.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.account.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
        if(event.target.name==='export'&&checked){
            if(!row.tax_free){
                handleChangeSwitch(event={target:{name:'tax_free',checked:true}});
            }
        }
    }
    
    const handleChangeTab=(event,newValue)=>{
        event.currentTarget.blur();
        setPageTab(newValue);
        pagehistory=event.target.name;
        navigate(location.pathname.replace(location.pathname.split('/')[3],pageTabs[event.target.name].path));
    };

    const getRecord=(query)=>{
        getDBItem({entityType:query,entityId:slug})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setReadOnly(true);
                setSnackOpened(true);
	        }
            else{
                if(data!=null){
                    console.log('data',JSON.parse(JSON.stringify(data)));
                    setRow(old=>prepareGetData(data,'account'));
                    if(authMenuSubItem){
                        setLabel(authMenuSubItem.label);
                        setReadOnly(!('edit' in authMenuSubItem.actions));
                    }
                }
                else{
                    alertMessage='Record not found or not accessible';
                    alertSeverity='error'
                    setReadOnly(true);
                    setSnackOpened(true);
                }
            }
        });
    };

    const updateRecord=()=>{
        console.log('updates',updates);
        setOpenBackdrop(true);
        updateDBItem({entityType:authMenuSubItem.query,entityId:slug,entityData:updates})
        .then((data)=>{  
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
	        }
            else{
                alertSeverity='success'
                alertMessage='Account updated with success!!!';
            }
            getRecord(authMenuSubItem.query);
            setIsChanged(false);
            setSnackOpened(true);
            setOpenBackdrop(false);
            setRefreshPageTab(true);
        });
    }

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)&&(slug!==undefined&&slug!==null&&slug!=='')&&location){
            setActions(authMenuSubItem.actions);
            getRecord(authMenuSubItem.query);
            setPageTabs(old=>sortPageTabs(authMenuSubItem.pageTabs));
            if(!location.pathname.includes(authMenuSubItem.defaultTabPath)||(pagehistory&&(pagehistory!==authMenuSubItem.defaultTabPath))){
                for(let key in authMenuSubItem.pageTabs){
                    if(location.pathname.includes(authMenuSubItem.pageTabs[key].path)){
                        setPageTab(authMenuSubItem.pageTabs[key].index);
                        break;
                    }
                }                
            }
            else if('state' in location){
                if((location.state)&&('message' in location.state)){
                    alertMessage=location.state.message.alertMessage;
                    alertSeverity=location.state.message.alertSeverity;
                    setSnackOpened(true);
                }
            }
            setLabel(authMenuSubItem.label);
        }
    },[authMenuSubItem,slug,userDataAuthorization,location]);

    const handleClickSave=()=>{
        if(validate_required_fields(row,'account',setError,setErrorMessage,dataError,required_field_error)){
            updateRecord();
            setUpdates(old=>{});
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        setIsChanged(false);
        getRecord(authMenuSubItem.query);
        setUpdates(old=>{});
        setError(dataError.error);
        setErrorMessage(dataError.message);
    }

    const pageTabCallback=(command)=>{
        if(command==='refreshed'){
            setRefreshPageTab(false);
        }
    }

    const handleChange=(event)=>{
        setIsChanged(true);
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        setUpdates(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
    };

    const handleChangeCountry=(fieldValue,fieldName)=>{
        if(fieldValue){
            handleChangeList(fieldValue.country,fieldName);
            handleChangeList(fieldValue.phone,'delivery_contact_phone_country_code');
        }
    }

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);

        if('events' in entity_object.account.fields[fieldName]){
            if('onChange' in entity_object.account.fields[fieldName].events){
                entity_object.account.fields[fieldName].events.onChange(fieldValue,handleChangeList);
            }
        }

        if('drive_dynamic_required' in entity_object.account.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.account.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.account.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
    };

    const handleClickButton=(event)=>{
        switch(event.target.name){
            case "update":
                setReadOnly(false);
                break;
            default: 
                break;
        }
    }

    return (
        <div className="page">
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            {recordInfo&&<RecordInfo recordInfoCallback={recordInfoCallback} row={row} entity_name={'account'} globalData={globalData} userDataAuthorization={userDataAuthorization}></RecordInfo>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.name}</span></Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        {!readOnly&&<Button disabled={!isChanged} name="save" variant="outlined" onClick={handleClickSave}>Save</Button>}
                        {!readOnly&&<Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>}
                        {Object.keys(actions).map((action)=>{
                            return(actions[action].type==='button'&&readOnly&&<Button key={action} name={action} variant="outlined" onClick={handleClickButton}>{actions[action].label}</Button>);
                        })}
                    </ButtonGroup>
                    {readOnly&&
                    <IconButton onClick={handleClickMoreInfo} color="primary" style={{position:'absolute',right:'40px'}} size="small">
                        <Tooltip title="More Information on the Record">
                            <InfoIcon fontSize="inherit"/>
                        </Tooltip>
                    </IconButton>}
                    {readOnly&&
                    <IconButton onClick={()=>{
                        setRow(old=>dataPreparedGet);
                        getRecord(authMenuSubItem.query)}} color="primary" style={{position:'absolute',right:'15px'}} size="small">
                        <Tooltip title="Refresh the current Record">
                            <RefreshIcon fontSize="inherit"/>
                            </Tooltip>
                    </IconButton>}
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:readOnly}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            required={entity_object.account.fields.name.required}
                            error={error.name}
                            helperText={errorMessage.name}
                            id="name"
                            name="name"
                            type={entity_object.account.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.name.label}
                            value={row.name}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:readOnly}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            id="legal_name"
                            name="legal_name"
                            type={entity_object.account.fields.legal_name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.legal_name.label}
                            value={row.legal_name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="reference"
                            name="reference"
                            type={entity_object.account.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.reference.label}
                            value={row.reference}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="phone"
                            name="phone"
                            type={entity_object.account.fields.phone.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.phone.label}
                            value={row.phone}
                            margin="normal"
                            onChange={handleChange}/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            required
                            readOnly={readOnly}
                            options={globalData.listOfValues.ACCOUNT_LANGUAGE||[]}
                            getOptionLabel={(option)=>option}
                            id="language"
                            name="language"
                            type={entity_object.account.fields.language.ui_type}
                            size="small"
                            value={row.language}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'language');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.account.fields.language.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            required
                            readOnly={readOnly}
                            options={globalData.listOfValues.ACCOUNT_STATUS||[]}
                            getOptionLabel={(option)=>option}
                            id="status"
                            name="status"
                            type={entity_object.account.fields.status.ui_type}
                            size="small"
                            value={row.status}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'status');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.account.fields.status.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="website"
                            name="website"
                            type={entity_object.account.fields.website.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.website.label}
                            value={row.website}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.account.fields.business_id.required}
                            error={error.business_id}
                            helperText={errorMessage.business_id}
                            id="business_id"
                            name="business_id"
                            type={entity_object.account.fields.business_id.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.business_id.label}
                            value={row.business_id}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="financing_agreement_id"
                            name="financing_agreement_id"
                            type={entity_object.account.fields.financing_agreement_id.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.financing_agreement_id.label}
                            value={row.financing_agreement_id}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            readOnly={readOnly}
                            sx={{span:{color:readOnly?'#1976d2':""}}}
                            fullWidth
                            multiple
                            options={globalData.listOfValues.ACCOUNT_TYPE||[]}
                            id="type"
                            name="type"
                            size="small"
                            value={row.type}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.account.fields.type.label}
                                    margin="normal"/>
                            )}
                        />
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            readOnly={readOnly}
                            options={globalData.listOfValues.ACCOUNT_CATEGORY||[]}
                            getOptionLabel={(option)=>option}
                            id="category"
                            name="category"
                            type={entity_object.account.fields.category.ui_type}
                            size="small"
                            value={row.category}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'category');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.account.fields.category.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            required
                            readOnly={(readOnly||entity_object.account.fields.sub_category.readonly(row,globalData))}
                            options={(row.category in globalData.listOfValuesRel.ACCOUNT_CATEGORY)?(globalData.listOfValuesRel.ACCOUNT_CATEGORY[row.category].ACCOUNT_SUB_CATEGORY):[]}
                            getOptionLabel={(option)=>option}
                            id="sub_category"
                            name="sub_category"
                            type={entity_object.account.fields.sub_category.ui_type}
                            size="small"
                            value={row.sub_category}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'sub_category');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:(readOnly||entity_object.account.fields.sub_category.readonly(row,globalData))?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    required={entity_object.account.fields.sub_category.required(row)}
                                    error={error.sub_category}
                                    helperText={errorMessage.sub_category}
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.account.fields.sub_category.label}
                                    margin="normal"
                                />
                            )}
                        />

                    </Stack>
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?'Hide More Info':'Show More Info'}</Button>
                    {showMoreInfo&&<>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.account.style.finance_color} gutterBottom variant="body1">Finance</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.finance_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="bank_account"
                            name="bank_account"
                            type={entity_object.account.fields.bank_account.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.bank_account.label}
                            value={row.bank_account}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="bank_bic"
                            name="bank_bic"
                            type={entity_object.account.fields.bank_bic.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.bank_bic.label}
                            value={row.bank_bic}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.account.style.tax_color} gutterBottom variant="body1">Tax</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.account.fields.excise_number.required}
                            error={error.excise_number}
                            helperText={errorMessage.excise_number}
                            id="excise_number"
                            name="excise_number"
                            type={entity_object.account.fields.excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.excise_number.label}
                            value={row.excise_number}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="warehouse_excise_number"
                            name="warehouse_excise_number"
                            type={entity_object.account.fields.warehouse_excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.warehouse_excise_number.label}
                            value={row.warehouse_excise_number}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='export' checked={row.export} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.account.fields.export.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='palpa_free' checked={row.palpa_free} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.account.fields.palpa_free.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='tax_free' checked={row.tax_free} onChange={handleChangeSwitch} disabled={(readOnly||entity_object.account.fields.tax_free.readonly(row))}/>} label={entity_object.account.fields.tax_free.label}/>
                        </FormGroup>
                        <Autocomplete
                            style={{marginTop:"0px",width:'50%'}}
                            fullWidth
                            readOnly={(readOnly||entity_object.account.fields.tax_free_reason_code.readonly(row))}
                            options={globalData.listOfValues.TAX_FREE_REASON_CODE||[]}
                            getOptionLabel={(option)=>option}
                            id="tax_free_reason_code"
                            name="tax_free_reason_code"
                            type={entity_object.account.fields.tax_free_reason_code.ui_type}
                            size="small"
                            value={row.tax_free_reason_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'tax_free_reason_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:(readOnly||entity_object.account.fields.tax_free_reason_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.account.fields.tax_free_reason_code.required(row)}
                                    error={error.tax_free_reason_code}
                                    helperText={errorMessage.tax_free_reason_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.account.fields.tax_free_reason_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.account.style.invoice_color} gutterBottom variant="body1">Invoicing</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.invoice_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.account.fields.payment_terms.required}
                            error={error.payment_terms}
                            helperText={errorMessage.payment_terms}
                            id="payment_terms"
                            name="payment_terms"
                            type={entity_object.account.fields.payment_terms.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.payment_terms.label}
                            value={row.payment_terms}
                            margin="normal"
                            onChange={handleChange}/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            readOnly={readOnly}
                            options={globalData.listOfValues.INVOICE_CHANNEL||[]}
                            getOptionLabel={(option)=>option}
                            id="invoice_channel"
                            name="invoice_channel"
                            type={entity_object.account.fields.invoice_channel.ui_type}
                            size="small"
                            value={row.invoice_channel}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'invoice_channel');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.account.fields.invoice_channel.required}
                                    error={error.invoice_channel}
                                    helperText={errorMessage.invoice_channel}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.account.fields.invoice_channel.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.account.fields.invoice_email.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.account.fields.invoice_email.readonly(row))}}
                            required={entity_object.account.fields.invoice_email.required(row)}
                            error={error.invoice_email}
                            helperText={errorMessage.invoice_email}
                            id="invoice_email"
                            name="invoice_email"
                            type={entity_object.account.fields.invoice_email.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.invoice_email.label}
                            value={row.invoice_email}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.invoice_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='no_invoicing' checked={row.no_invoicing} onChange={handleChangeSwitch} disabled={readOnly}/>} label={entity_object.account.fields.no_invoicing.label}/>
                        </FormGroup>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.account.fields.invoice_e_number.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.account.fields.invoice_e_number.readonly(row))}}
                            required={entity_object.account.fields.invoice_e_number.required(row)}
                            error={error.invoice_e_number}
                            helperText={errorMessage.invoice_e_number}
                            id="invoice_e_number"
                            name="invoice_e_number"
                            type={entity_object.account.fields.invoice_e_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.invoice_e_number.label}
                            value={row.invoice_e_number}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(readOnly||entity_object.account.fields.invoice_e_operator.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(readOnly||entity_object.account.fields.invoice_e_operator.readonly(row))}}
                            required={entity_object.account.fields.invoice_e_operator.required(row)}
                            error={error.invoice_e_number}
                            helperText={errorMessage.invoice_e_number}
                            id="invoice_e_operator"
                            name="invoice_e_operator"
                            type={entity_object.account.fields.invoice_e_operator.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.invoice_e_operator.label}
                            value={row.invoice_e_operator}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="invoice_e_edi_id"
                            name="invoice_e_edi_id"
                            type={entity_object.account.fields.invoice_e_edi_id.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.invoice_e_edi_id.label}
                            value={row.invoice_e_edi_id}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.account.style.delivery_color} gutterBottom variant="body1">Delivery</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            readOnly={readOnly}
                            options={globalData.listOfValues.DELIVER_METHOD||[]}
                            getOptionLabel={(option)=>option}
                            id="delivery_method"
                            name="delivery_method"
                            type={entity_object.account.fields.delivery_method.ui_type}
                            size="small"
                            value={row.delivery_method}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'delivery_method');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.account.fields.delivery_method.required}
                                    error={error.delivery_method}
                                    helperText={errorMessage.delivery_method}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.account.fields.delivery_method.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="delivery_contact_name"
                            name="delivery_contact_name"
                            type={entity_object.account.fields.delivery_contact_name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.delivery_contact_name.label}
                            value={row.delivery_contact_name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Country 
                            name={'delivery_contact_phone_country'}
                            value={row.delivery_contact_phone_country} 
                            label={entity_object.account.fields.delivery_contact_phone_country.label}
                            readOnly={readOnly} 
                            required={entity_object.account.fields.delivery_contact_phone_country.required}
                            error={error.delivery_contact_phone_country}
                            helperText={errorMessage.delivery_contact_phone_country}
                            type={entity_object.account.fields.delivery_contact_phone_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeCountry(newValue,'delivery_contact_phone_country');}}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="delivery_contact_phone_country_code"
                            name="delivery_contact_phone_country_code"
                            type={entity_object.account.fields.delivery_contact_phone_country_code.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.delivery_contact_phone_country_code.label}
                            value={row.delivery_contact_phone_country_code}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.account.fields.delivery_contact_phone.required}
                            error={error.delivery_contact_phone}
                            helperText={errorMessage.delivery_contact_phone}
                            id="delivery_contact_phone"
                            name="delivery_contact_phone"
                            type={entity_object.account.fields.delivery_contact_phone.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.delivery_contact_phone.label}
                            value={row.delivery_contact_phone}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="delivery_contact_email"
                            name="delivery_contact_email"
                            type={entity_object.account.fields.delivery_contact_email.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.delivery_contact_email.label}
                            value={row.delivery_contact_email}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.account.style.billing_address_color} gutterBottom variant="body1">Billing Address</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="billing_addr_street"
                            name="billing_addr_street"
                            type={entity_object.account.fields.billing_addr_street.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.billing_addr_street.label}
                            value={row.billing_addr_street}
                            margin="normal"
                            onChange={handleChange}/>
                        <Country 
                            name={'billing_addr_country'}
                            value={row.billing_addr_country} 
                            label={entity_object.account.fields.billing_addr_country.label}
                            readOnly={readOnly} 
                            required={entity_object.account.fields.billing_addr_country.required}
                            error={error.billing_addr_country}
                            helperText={errorMessage.billing_addr_country}
                            type={entity_object.account.fields.billing_addr_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeList(newValue.country,'billing_addr_country');}}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="billing_addr_specifier"
                            name="billing_addr_specifier"
                            type={entity_object.account.fields.billing_addr_specifier.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.billing_addr_specifier.label}
                            value={row.billing_addr_specifier}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="billing_addr_city"
                            name="billing_addr_city"
                            type={entity_object.account.fields.billing_addr_city.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.billing_addr_city.label}
                            value={row.billing_addr_city}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="billing_addr_zip"
                            name="billing_addr_zip"
                            type={entity_object.account.fields.billing_addr_zip.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.billing_addr_zip.label}
                            value={row.billing_addr_zip}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="billing_addr_subdivision"
                            name="billing_addr_subdivision"
                            type={entity_object.account.fields.billing_addr_subdivision.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.billing_addr_subdivision.label}
                            value={row.billing_addr_subdivision}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'20px',backgroundColor:entity_object.account.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={2}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="billing_addr_comments"
                            name="billing_addr_comments"
                            type={entity_object.account.fields.billing_addr_comments.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.billing_addr_comments.label}
                            value={row.billing_addr_comments}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.account.style.shipping_address_color} gutterBottom variant="body1">Shipping Address</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.account.fields.shipping_addr_street.required}
                            error={error.shipping_addr_street}
                            helperText={errorMessage.shipping_addr_street}
                            id="shipping_addr_street"
                            name="shipping_addr_street"
                            type={entity_object.account.fields.shipping_addr_street.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.shipping_addr_street.label}
                            value={row.shipping_addr_street}
                            margin="normal"
                            onChange={handleChange}/>
                        <Country 
                            name={'shipping_addr_country'}
                            value={row.shipping_addr_country} 
                            label={entity_object.account.fields.shipping_addr_country.label}
                            readOnly={readOnly} 
                            required={entity_object.account.fields.shipping_addr_country.required}
                            error={error.shipping_addr_country}
                            helperText={errorMessage.shipping_addr_country}
                            type={entity_object.account.fields.shipping_addr_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeList(newValue.country,'shipping_addr_country');}}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.account.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="shipping_addr_specifier"
                            name="shipping_addr_specifier"
                            type={entity_object.account.fields.shipping_addr_specifier.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.shipping_addr_specifier.label}
                            value={row.shipping_addr_specifier}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.account.fields.shipping_addr_city.required}
                            error={error.shipping_addr_city}
                            helperText={errorMessage.shipping_addr_city}
                            id="shipping_addr_city"
                            name="shipping_addr_city"
                            type={entity_object.account.fields.shipping_addr_city.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.shipping_addr_city.label}
                            value={row.shipping_addr_city}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.account.fields.shipping_addr_zip.required}
                            error={error.shipping_addr_zip}
                            helperText={errorMessage.shipping_addr_zip}
                            id="shipping_addr_zip"
                            name="shipping_addr_zip"
                            type={entity_object.account.fields.shipping_addr_zip.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.shipping_addr_zip.label}
                            value={row.shipping_addr_zip}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="shipping_addr_subdivision"
                            name="shipping_addr_subdivision"
                            type={entity_object.account.fields.shipping_addr_subdivision.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.shipping_addr_subdivision.label}
                            value={row.shipping_addr_subdivision}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'20px',backgroundColor:entity_object.account.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={2}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="shipping_addr_comments"
                            name="shipping_addr_comments"
                            type={entity_object.account.fields.shipping_addr_comments.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.shipping_addr_comments.label}
                            value={row.shipping_addr_comments}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            inputProps={{maxLength:500}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.account.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.account.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                </>}
                </Box>
            </div>
            <Box sx={{bgcolor:'none'}}>
                <Tabs
                    value={pageTab}
                    onChange={handleChangeTab}
                    sx={{"& button:hover":{backgroundColor:'lightgrey'},"& button:focus":{backgroundColor:'white'},"& .MuiTab-root.Mui-selected":{backgroundColor:'white'},}}
                    variant="scrollable"
                    scrollButtons="auto">
                    {Object.keys(pageTabs).map((item)=>{
                        return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                    })}
                </Tabs>
                {Object.keys(pageTabs).length>0&&('sales_orders' in pageTabs)&&pageTab===pageTabs['sales_orders'].index&&<AccountDetailSalesOrders parent={row} pageTab={('sales_orders' in pageTabs)?pageTabs['sales_orders']:{}} globalData={globalData} userDataAuthorization={userDataAuthorization}></AccountDetailSalesOrders>}
                {Object.keys(pageTabs).length>0&&('audit' in pageTabs)&&pageTab===pageTabs['audit'].index&&<Audit record_id={row.id} pageTab={('audit' in pageTabs)?pageTabs['audit']:{}} refreshPageTab={refreshPageTab} pageTabCallback={pageTabCallback} entity_name='account' globalData={globalData} userDataAuthorization={userDataAuthorization}></Audit>}
            </Box>
        </div>
    );
}

export default AccountDetail;
