import {useEffect,useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Switch,FormGroup,FormControlLabel,InputAdornment,IconButton,Box,Backdrop,CircularProgress,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert} from '@mui/material';
import Autocomplete,{createFilterOptions} from '@mui/material/Autocomplete';
import {ArrowCircleUp,ArrowCircleDown,ArrowCircleRight,AccountCircle} from '@mui/icons-material';
import {createDBItem,getDBList} from '../../integration/DatabaseManager.js';
import AccountPickList from "../Account/AccountPickList.js";
import {entity_object,status_color} from '../../utilities/DataDictionary.js';
import {DefaultConfig,prepareDefaultData,prepareSetData,validate_required_fields,prepareErrorFields} from '../../utilities/Utilities.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {check_duplicated} from '../../integration/Api.js';
import ProductNewPopupSKU from './ProductNewPopupSKU.js';
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='success';
const required_field_error='Required Field';
const filter=createFilterOptions();
let listOfTags=[];
const dataPreparedGet=prepareDefaultData('default_value_new','product');
const dataError=prepareErrorFields('product');
const DEFAULT_ACCOUNT_REFERENCE=process.env.COOLHEAD_BREW_REFERENCE||'V-391';
let duplicated_name={};

const ProductNew=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const [label,setLabel]=useState('-');
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);
    const navigate=useNavigate();
    const [pickControl,setPickControl]=useState({account_name:false});
    const [lovValuesDynamic,setLovValuesDynamic]=useState({PRODUCT_TAG:[]});
    const [productTags,setProductTags]=useState([]);
    const {globalData}=useContext(ApplicationContext);
    const [openDialog,setOpenDialog]=useState(false);
    const [openProductNewPopupSKU,setOpenProductNewPopupSKU]=useState(false);

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    useEffect(()=>{
        if(Object.keys(authMenuSubItem).length>0){
            setLabel(authMenuSubItem.label);
            listOfTags=globalData.listOfValues.PRODUCT_TAG||[];
            let tagValues=[];
            listOfTags.forEach(element=>{
                tagValues.push({title:element});
            });
            setLovValuesDynamic({PRODUCT_TAG:tagValues});//separated to be updated as new values are inserted
            getDBList({entityType:'Account',entityParams:['reference='+DEFAULT_ACCOUNT_REFERENCE]})
            .then((data)=>{
                console.log('getDBList',data);
                if('error' in data){
                    console.log('error',data.error);
                }
                else{
                    if(data!=null&&data.length>0){
                        let updated_row=JSON.parse(JSON.stringify(row));
                        updated_row.account.name=data[0].name;
                        updated_row.account_id=data[0].id;
                        console.log('updated_row',updated_row);
                        setRow(old=>(updated_row));
                        let event={target:{name:'account_id',value:data[0].id}}
                        handleChange(event);
                    }
                    else{
                        alertSeverity='error'
                        alertMessage='Manufacturer "CoolHead Brew" (reference "'+DEFAULT_ACCOUNT_REFERENCE+'") not found or not accessible';
                        setSnackOpened(true);
                    }
                }
            });
        }
    },[authMenuSubItem]);

    const updateDynamicListOfValues=(value)=>{
        let row_insert={type:'PRODUCT_TAG',status:'Active',sorting_order:0,value:value};
        createDBItem({entityType:'ListOfValues',entityData:row_insert})
        .then((data)=>{ 
            if('error' in data){console.log('error',data.error);}
            else{
                console.log('insert',data);
                let newId=data.id;
                console.log('newId',newId);
                listOfTags.push(value);
                let tagValues=JSON.parse(JSON.stringify(lovValuesDynamic.PRODUCT_TAG));
                tagValues.push({title:value});
                setLovValuesDynamic(prevState=>({...prevState,PRODUCT_TAG:tagValues}));
            }
        });
    };

    const preCreateRecord=()=>{
        duplicated_name={};
        check_duplicated({entity:"Product",field:"name",value:row.name,id:''})
        .then((data)=>{
            console.log('data',data);
            duplicated_name=data;
            if(duplicated_name.similar_entries_count>0){
                setOpenDialog(old=>true);
            }
            else{
                //createRecord();
                setStructureSKU();
            }
        });
    }

    const setStructureSKU=()=>{
        handleDialogCancel();
        setOpenProductNewPopupSKU(old=>true);
    }

    const createRecord=(skuStructure)=>{
        setOpenBackdrop(true);
        let row_create=prepareSetData(JSON.parse(JSON.stringify(row)),'product');
        let tags=[];
        productTags.forEach(element=>{
            if(typeof element!=='string'){
                if('inputValue' in element){
                    tags.push(element.inputValue);
                }
                else if('title' in element){
                    tags.push(element.title);
                }
            }
            else{
                tags.push(element);
            }
        });
        row_create.tags=tags;
        row_create.skuStructure=skuStructure;
        console.log('row_create',row_create);
        createDBItem({entityType:authMenuSubItem.query,entityData:row_create})
        .then((data)=>{
            setOpenBackdrop(false);
            let newId=null;
            console.log('data',data);
            if('error' in data){
                alertSeverity='error'
                alertMessage=data.error;
                if('id' in data){newId=data.id;}
            }
            else{
                newId=data.id;
                alertSeverity='success';
                alertMessage='Product created with success!!!';
            }
            if(newId){
                let message={alertSeverity:alertSeverity,alertMessage:alertMessage};
                navigate('/product/'+newId+'/skus',{state:{message:message}});
            }
            else{
                setSnackOpened(true);
            }
        });
    };

    const handleClickSave=()=>{
        if(validate_required_fields(row,'product',setError,setErrorMessage,dataError,required_field_error)){
            preCreateRecord();
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const popupListCallback=(skuStructure)=>{
        console.log('popupListCallback',skuStructure);
        setOpenProductNewPopupSKU(old=>false);
        if(skuStructure){
            createRecord(skuStructure);
        }
    }

    const pickListCallback=(picklist_name,picklist_record)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:false}));
        if(Object.keys(picklist_record).length!==0){
            if(picklist_name==='account_name'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.account_id!==picklist_record.id){
                    updated_row.account.name=picklist_record.name;
                    updated_row.account_id=picklist_record.id;
                    setRow(old=>(updated_row));
                    let event={target:{name:'account_id',value:picklist_record.id}}
                    handleChange(event);
                }
            }
        }
    };

    const handleChangeSwitch=(event)=>{
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
    }

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);
        if('events' in entity_object.product.fields[fieldName]){
            if('onChange' in entity_object.product.fields[fieldName].events){
                entity_object.product.fields[fieldName].events.onChange(row,fieldValue,handleChangeList,handleChange);
            }
        }
        if('drive_dynamic_required' in entity_object.product.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.product.fields)){
                if(key!==fieldName){
                    if('required' in value){
                        if(typeof value.required==='function'){
                            let required=entity_object.product.fields[key].required(row_update);
                            setError(prevState=>({...prevState,[key]:required&&(row_update[key]==='')}));
                            setErrorMessage(prevState=>({...prevState,[key]:required&&(row_update[key]==='')?required_field_error:''}));
                        }
                    }
                }
            }
        }
    };

    const handleClickPick=(picklist_name)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:true}));
    };

    const handleClickCancel=()=>{
        navigate(-1);
    }

    const handleChange=(event)=>{
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
        if('events' in entity_object.product.fields[event.target.name]){
            if('onChange' in entity_object.product.fields[event.target.name].events){
                entity_object.product.fields[event.target.name].events.onChange(row,event.target.value,handleChangeList,handleChange);
            }
        }
    };

    const handleDialogCancel=()=>{
        setOpenDialog(false);
    }
    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return (
        <div className="page">
            {openProductNewPopupSKU&&<ProductNewPopupSKU popupListCallback={popupListCallback} newProduct={row} globalData={globalData}></ProductNewPopupSKU>}
            {pickControl.account_name&&<AccountPickList pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} label={'Manufacturers'} globalData={globalData} userDataAuthorization={userDataAuthorization}></AccountPickList>}
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Dialog fullWidth={true} maxWidth={'lg'} open={openDialog} onClose={handleDialogCancel} PaperProps={{style:{minHeight:'75vh'}}}>
            <DialogTitle sx={{color:'darkorange'}}>{"Warning - Possible Duplicated Record ("+duplicated_name.similar_entries_count+")"}</DialogTitle>
                <DialogContent>
                    {"The Name of this Product ("+row.name+") has potential duplicated record(s). Would you like to proceed with the operation?"}
                    <div style={{height:'50vh',width:'100%'}}>
                        <DataGrid
                            sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                            editMode="row"
                            rows={duplicated_name.similar_entries}
                            columns={columns.product_duplicated_name}
                            density="compact"
                            slots={{
                                toolbar:CustomToolbar,
                            }}
                            componentsProps={{
                                toolbar:{
                                    showQuickFilter:true,
                                    quickFilterProps:{debounceMs:250},
                                },
                            }}
                        />
                    </div>
                    {"(minimum score: "+(duplicated_name.similar_entries_thredshold*100).toFixed(2) +"%)"}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel}>Cancel</Button>
                    <Button onClick={setStructureSKU} autoFocus variant="contained">Confirm</Button>
                    {/*<Button onClick={createRecord} autoFocus variant="contained">Confirm</Button>*/}
                </DialogActions>
            </Dialog>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}</Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                <ButtonGroup size="small">
                        <Button name="save" variant="outlined" onClick={handleClickSave}>Save</Button>
                        <Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>
                    </ButtonGroup>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.product.fields.name.required}
                            error={error.name}
                            helperText={errorMessage.name}
                            id="name"
                            name="name"
                            type={entity_object.product.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.name.label}
                            value={row.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="reference"
                            name="reference"
                            type={entity_object.product.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={row.reference}
                            label={entity_object.product.fields.reference.label}
                            margin="normal"/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            options={globalData.listOfValues.PRODUCT_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="type"
                            name="type"
                            type={entity_object.product.fields.type.ui_type}
                            size="small"
                            value={row.type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.product.fields.type.required}
                                    error={error.type}
                                    helperText={errorMessage.type}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.type.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="vat"
                            name="vat"
                            type={entity_object.product.fields.vat.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.vat.label}
                            value={row.vat}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{color:status_color[row.status],backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            required
                            id="status"
                            name="status"
                            type={entity_object.product.fields.status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.status.label}
                            value={row.status}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(entity_object.product.fields.abv.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(entity_object.product.fields.abv.readonly(row))}}
                            required={entity_object.product.fields.abv.required(row)}
                            error={error.abv}
                            helperText={errorMessage.abv}
                            id="abv"
                            name="abv"
                            type={entity_object.product.fields.abv.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.abv.label}
                            value={row.abv}
                            margin="normal"
                            onChange={handleChange}/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(entity_object.product.fields.yeast_type.readonly(row))?'#1976d2':""}}}
                            readOnly={(entity_object.product.fields.yeast_type.readonly(row))}
                            options={globalData.listOfValues.YEAST_TYPE||[]}
                            getOptionLabel={(option)=>option}
                            id="yeast_type"
                            name="yeast_type"
                            type={entity_object.product.fields.yeast_type.ui_type}
                            size="small"
                            value={row.yeast_type}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'yeast_type');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    //required={entity_object.product.fields.yeast_type.required}
                                    sx={{div:{backgroundColor:(entity_object.product.fields.yeast_type.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.yeast_type.required(row)}
                                    error={error.yeast_type}
                                    helperText={errorMessage.yeast_type}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.yeast_type.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.product.fields.min_usage_days.required}
                            error={error.min_usage_days}
                            helperText={errorMessage.min_usage_days}
                            id="min_usage_days"
                            name="min_usage_days"
                            type={entity_object.product.fields.min_usage_days.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={row.min_usage_days}
                            label={entity_object.product.fields.min_usage_days.label}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{div:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{
                                readOnly:true,
                                /*                                    
                                startAdornment:(
                                    <InputAdornment position="start">
                                        <IconButton onClick={(e)=>handleClickPick('account_name')}>
                                            <AccountCircle/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                                */
                            }}
                            required={entity_object.product.fields.account_id.required}
                            error={error.account_id}
                            helperText={errorMessage.account_id}
                            id="account_name"
                            name="account_name"
                            type={entity_object.account.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.joins.account.fields.name.label}
                            value={row.account.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.product.fields.temperature_min.required}
                            error={error.temperature_min}
                            helperText={errorMessage.temperature_min}
                            id="temperature_min"
                            name="temperature_min"
                            type={entity_object.product.fields.temperature_min.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_min.label}
                            value={row.temperature_min}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.product.fields.temperature_max.required}
                            error={error.temperature_max}
                            helperText={errorMessage.temperature_max}
                            id="temperature_max"
                            name="temperature_max"
                            type={entity_object.product.fields.temperature_max.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.temperature_max.label}
                            value={row.temperature_max}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            multiple
                            options={globalData.listOfValues.PRODUCT_CATEGORY||[]}
                            id="categories"
                            name="categories"
                            type={entity_object.product.fields.categories.ui_type}
                            size="small"
                            value={row.categories}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'categories');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.categories.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            freeSolo
                            multiple
                            options={lovValuesDynamic.PRODUCT_TAG}
                            id="tags"
                            name="tags"
                            type={entity_object.product.fields.tags.ui_type}
                            size="small"
                            value={productTags}
                            ChipProps={{color:"primary"}}
                            onChange={(event,newValue)=>{
                                newValue.forEach(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            if(!listOfTags.includes(element.inputValue)){
                                                updateDynamicListOfValues(element.inputValue);
                                            }
                                        }
                                    }
                                });
                                setProductTags(newValue);
                            }}
                            filterOptions={(options,params)=>{
                                const filtered=filter(options,params);
                                const {inputValue}=params;
                                const isExisting=options.some((option)=>inputValue===option.title);
                                if(inputValue!==''&&!isExisting){
                                    filtered.push({
                                        inputValue,
                                        title:`Add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            getOptionLabel={(option)=>{
                                if(typeof option==='string'){
                                  return option;
                                }
                                if(option.inputValue){
                                  return option.inputValue;
                                }
                                return option.title;
                            }}
                            renderOption={(props,option)=>{
                                let bAdd=true;
                                productTags.every(element=>{
                                    if(typeof element!=='string'){
                                        if('inputValue' in element){
                                            bAdd=element.inputValue!==option.title;
                                        }
                                        else if('title' in element){
                                            bAdd=element.title!==option.title;
                                        }
                                    }
                                    return bAdd;
                                });
                                if(!productTags.includes(option.title)&&bAdd){
                                    return <li {...props}>{option.title}</li>;
                                }
                            }}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={entity_object.product.fields.tags.label}
                                    margin="normal"/>
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}> 
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='track_batches' checked={row.track_batches} onChange={handleChangeSwitch}/>} label={entity_object.product.fields.track_batches.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='track_expiration_date' checked={row.track_expiration_date} onChange={handleChangeSwitch}/>} label={entity_object.product.fields.track_expiration_date.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='batch_req_for_storage_order' checked={row.batch_req_for_storage_order} onChange={handleChangeSwitch}/>} label={entity_object.product.fields.batch_req_for_storage_order.label}/>
                        </FormGroup>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}> 
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(entity_object.product.fields.tax_group_code.readonly(row))?'#1976d2':""}}}
                            readOnly={(entity_object.product.fields.tax_group_code.readonly(row))}
                            options={globalData.listOfValues.PRODUCT_TAX_GROUP_CODE||[]}
                            getOptionLabel={(option)=>option}
                            id="tax_group_code"
                            name="tax_group_code"
                            type={entity_object.product.fields.tax_group_code.ui_type}
                            size="small"
                            value={row.tax_group_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'tax_group_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    sx={{div:{backgroundColor:(entity_object.product.fields.tax_group_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.tax_group_code.required(row)}
                                    error={error.tax_group_code}
                                    helperText={errorMessage.tax_group_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.tax_group_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(entity_object.product.fields.product_group_code.readonly(row))?'#1976d2':""}}}
                            readOnly={(entity_object.product.fields.product_group_code.readonly(row))}
                            options={(row.tax_group_code in globalData.listOfValuesRel.PRODUCT_TAX_GROUP_CODE)?(globalData.listOfValuesRel.PRODUCT_TAX_GROUP_CODE[row.tax_group_code].PRODUCT_GROUP_CODE):[]}
                            getOptionLabel={(option)=>option}
                            id="product_group_code"
                            name="product_group_code"
                            type={entity_object.product.fields.product_group_code.ui_type}
                            size="small"
                            value={row.product_group_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'product_group_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    //required={entity_object.product.fields.product_group_code.required}
                                    sx={{div:{backgroundColor:(entity_object.product.fields.product_group_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.product_group_code.required(row)}
                                    error={error.product_group_code}
                                    helperText={errorMessage.product_group_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.product_group_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}> 
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            sx={{span:{color:(entity_object.product.fields.customs_tariff_code.readonly(row))?'#1976d2':""}}}
                            readOnly={(entity_object.product.fields.customs_tariff_code.readonly(row))}
                            options={globalData.listOfValues.PRODUCT_CUSTOMS_TARIFF_CODE||[]}
                            getOptionLabel={(option)=>option}
                            id="customs_tariff_code"
                            name="customs_tariff_code"
                            type={entity_object.product.fields.customs_tariff_code.ui_type}
                            size="small"
                            value={row.customs_tariff_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'customs_tariff_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    //required={entity_object.product.fields.customs_tariff_code.required}
                                    sx={{div:{backgroundColor:(entity_object.product.fields.customs_tariff_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    required={entity_object.product.fields.customs_tariff_code.required(row)}
                                    error={error.customs_tariff_code}
                                    helperText={errorMessage.customs_tariff_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.product.fields.customs_tariff_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            inputProps={{maxLength:500}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.product.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description_internal"
                            name="description_internal"
                            type={entity_object.product.fields.description_internal.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.product.fields.description_internal.label}
                            value={row.description_internal}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                </Box>
            </div>
        </div>
    );
}

export default ProductNew;
