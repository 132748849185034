import {useEffect,useState,useRef} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions} from '@mui/material';
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
import {getDBList,getDBItem} from '../../integration/DatabaseManager.js';
import {prepareGetData} from '../../utilities/Utilities';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const list_name='account_picklist';

const PaperComponent=(props)=>{
    const nodeRef=useRef(null);
    return (
      <Draggable cancel=".btn" nodeRef={nodeRef}>
        <Paper ref={nodeRef} {...props} />
      </Draggable>
    );
  }

const AccountPickList=({pickListCallback,authMenuSubItem,label,userDataAuthorization,globalData})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [selected,setSelected]=useState(false);
    const [selectedRow,setSelectedRow]=useState({});
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    const getRecords=(query)=>{
        getDBList({entityType:query})
        .then((data)=>{
            if('error' in data){
                    console.log('error',data.error);
            } 
            else {
                if(data.length>0){
                    setRows(old=>data);
                }
            }
            setLoading(false);
        });
    };

    const getRecord=(query,id)=>{
        getDBItem({entityType:query,entityId:id})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
	        }
            else{
                if(data!=null){
                    console.log('data',JSON.parse(JSON.stringify(data)));
                    pickListCallback('account_name',prepareGetData(data,'account'));
                }
            }
        });
    };

    useEffect(()=>{
        getRecords(authMenuSubItem.picklists.account_name.query,authMenuSubItem.picklists.account_name.query_args);
    },[]);

    const picklistLinkCallback=(selectedRow)=>{
        //pickListCallback('account_name',prepareGetData(selectedRow,'account'));
        getRecord(authMenuSubItem.picklists.account_name.query,selectedRow.id);
    }

    const handleClickCancel=()=>{
        pickListCallback('account_name',{});
    }

    const handleClickSelect=()=>{
        //pickListCallback('account_name',prepareGetData(selectedRow,'account'));
        getRecord(authMenuSubItem.picklists.account_name.query,selectedRow.id);
    }

    const handleDoubleClickRow=(e)=>{
        //pickListCallback('account_name',prepareGetData(e.row,'account'));
        getRecord(authMenuSubItem.picklists.account_name.query,e.row.id);
    }

    const handleChangeRow=(ids)=>{
        setSelected(true);
        const selectedIds=new Set(ids);
        const selectedRows=rows.filter((row)=>selectedIds.has(row.id));
        setSelectedRow(selectedRows[0]);
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={true}
            PaperComponent={PaperComponent}
            onClose={handleClickCancel}>
            <DialogTitle style={{cursor:'move'}}>{label||'Accounts'}</DialogTitle>
            <DialogContent>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        className="btn"
                        onRowDoubleClick={handleDoubleClickRow}
                        onSelectionModelChange={handleChangeRow}
                        editMode="row"
                        rows={rows}
                        columns={columns[list_name](picklistLinkCallback,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        initialState={{
                            filter:{
                                filterModel:{
                                    items:[{
                                        field:'status',
                                        operator:'equals',
                                        value:'Active',
                                     }],
                                }
                            }
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="btn" disabled={!selected} onClick={handleClickSelect} variant="contained">Select</Button>
                <Button className="btn" onClick={handleClickCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AccountPickList;
