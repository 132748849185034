import {useEffect,useState,useRef,forwardRef} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions} from '@mui/material';
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {getDBList,getDBItem} from '../../integration/DatabaseManager.js';
import {prepareGetData} from '../../utilities/Utilities';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
const list_name='skus_picklist';

const PaperComponent=forwardRef(function PaperComponent(props,ref){
    return (
        <Draggable handle="#draggable-dialog-sku-picklist" nodeRef={ref}>
            <Paper ref={ref} {...props} />
        </Draggable>
    );
});

const SKUPickList=({pickListCallback,query_param,order_items,prevent_duplicated,globalData,userDataAuthorization})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [selected,setSelected]=useState(false);
    const [selectedRow,setSelectedRow]=useState({});
    const [openDialog,setOpenDialog]=useState(false);
    const [existingRecord,setExistingRecord]=useState({});
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);
    const nodeRef=useRef(null);

    const getRecords=(query,query_args)=>{
        getDBList({entityType:query,entityParams:["SearchExpression=([sellable]='Y')"]})
        .then((data)=>{
            if('error' in data){console.log('error',data.error);}
            else{
                if(data.length>0){
                    console.log('records',data);
                    setRows(old=>data);
                }
            }
            setLoading(false);
        });
    };

    const getRecord=(query,id)=>{
        getDBItem({entityType:query,entityId:id})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
	        }
            else{
                if(data!=null){
                    console.log('data',JSON.parse(JSON.stringify(data)));
                    pickListCallbackWrapper(prepareGetData(data,'sku'));
                }
            }
        });
    };

    useEffect(()=>{
        getRecords(query_param.query,query_param.query_args);
    },[]);

    const picklistLinkCallback=(selectedRow)=>{
        getRecord(query_param.query,selectedRow.id);
    }

    const handleClickCancel=()=>{
        pickListCallbackWrapper({});
    }

    const handleClickSelect=()=>{
        getRecord(query_param.query,selectedRow.id);
    }

    const handleDoubleClickRow=(e)=>{
        getRecord(query_param.query,e.row.id);
    }

    const pickListCallbackWrapper=(record)=>{
        if(order_items!==undefined&&order_items!==null){
            let sku_already_added=false;
            if(Object.keys(record).length>0&&prevent_duplicated){
                order_items.forEach(element=>{
                    if(element.sku_id===record.id){
                        setExistingRecord(element);
                        sku_already_added=true;
                    }
                });
                if(sku_already_added){
                    setSelectedRow(record);
                    setOpenDialog(true);
                }
                else{
                    pickListCallback('add_sku',record);
                }
            }
            else{
                pickListCallback('add_sku',record);
            }
        }
        else{
            pickListCallback('add_sku',record);
        }
    }

    const handleChangeRow=(ids)=>{
        setSelected(true);
        const selectedIds=new Set(ids);
        const selectedRows=rows.filter((row)=>selectedIds.has(row.id));
        setSelectedRow(selectedRows[0]);
    }

    const handleDialogConfirm=()=>{
        setOpenDialog(false);
        let quantity=existingRecord.quantity+1;
        let update={
            id:existingRecord.id,
            row:{
                quantity:quantity,
                total_price:quantity*existingRecord.unit_price,
                final_price:quantity*existingRecord.unit_price
            }
        }
        pickListCallback('add_sku_more',selectedRow,update);
    }

    const handleDialogCancel=()=>{
        setOpenDialog(false);
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return <>
        <Dialog fullWidth={true} maxWidth={'lg'} open={openDialog} onClose={handleDialogCancel}>
            <DialogTitle>{"Warning"}</DialogTitle>
            <DialogContent>{"The selected SKU '"+selectedRow.name+"' is already added to the Order. Would you like to increment the quantity by 1?"}</DialogContent>
            <DialogActions>
                <Button onClick={handleDialogCancel}>Cancel</Button>
                <Button onClick={handleDialogConfirm} autoFocus variant="contained">Confirm</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={true}
            aria-labelledby="draggable-dialog-sku-picklist"
            PaperComponent={(props)=><PaperComponent ref={nodeRef} {...props}/>}
            onClose={handleClickCancel}>
            <DialogTitle id="draggable-dialog-sku-picklist" style={{cursor:'move'}}>SKUs</DialogTitle>
            <DialogContent>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        className="btn"
                        onRowDoubleClick={handleDoubleClickRow}
                        onSelectionModelChange={handleChangeRow}
                        editMode="row"
                        rows={rows}
                        columns={columns[list_name](picklistLinkCallback,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        initialState={{
                            filter:{
                                filterModel:{
                                    items:[{
                                        field:'status',
                                        operator:'equals',
                                        value:'Active',
                                     }],
                                }
                            }
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="btn" disabled={!selected} onClick={handleClickSelect} variant="contained">Select</Button>
                <Button className="btn" onClick={handleClickCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
        </>
}

export default SKUPickList;
