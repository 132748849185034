import {useEffect,useState} from "react";
import {useNavigate,useLocation} from 'react-router-dom';
import {Tooltip,Autocomplete,Switch,FormGroup,FormControlLabel,Divider,Box,Backdrop,CircularProgress,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,InputAdornment,IconButton} from '@mui/material';
import {AccountCircle,ArrowCircleUp,ArrowCircleDown,ArrowCircleRight} from '@mui/icons-material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
dayjs.locale('fi')
import 'dayjs/locale/fi';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
//import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import AccountPickList from "../Account/AccountPickList.js";
import {createDBItem,getDBList} from '../../integration/DatabaseManager.js';
import {status_color,entity_object} from '../../utilities/DataDictionary.js';
import Country from '../Country.js'
import {DefaultConfig,prepareSetData,prepareDefaultData,validate_required_fields,prepareErrorFields,time_to_date_ui,time_to_ui} from '../../utilities/Utilities.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {enUS} from '@mui/x-date-pickers/locales';
const localeText=enUS.components.MuiLocalizationProvider.defaultProps.localeText
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='success';
const required_field_error='Required Field';
const dataError=prepareErrorFields('storage_order');
const DEFAULT_ACCOUNT_REFERENCE=process.env.COOLHEAD_BREW_REFERENCE||'V-391';
const DEFAULT_LOGISTICS_REFERENCE=process.env.LOGISTICS_ACCOUNT_REFERENCE||'Logistics';
const dataPreparedNew=prepareDefaultData('default_value_new','storage_order');
let logistics_data={account_business_id_alt:'',account_name_alt:'',excise_number_alt:'',shipping_addr_specifier:'',shipping_addr_street:'',shipping_addr_zip:'',shipping_addr_city:'',warehouse_excise_number_alt:''};
const StorageOrderNew=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedNew);
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const [label,setLabel]=useState('-');
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);
    const [pickControl,setPickControl]=useState({account_name:false});
    const location=useLocation();
    const navigate=useNavigate();
    const [showMoreInfo,setShowMoreInfo]=useState(true);
    //const classes=entity_object.storage_order.useStyles();
    const {globalData}=useContext(ApplicationContext);

    useEffect(()=>{
        if(Object.keys(authMenuSubItem).length>0){
            setLabel(authMenuSubItem.label);
            getDBList({entityType:'Account',entityParams:['reference='+DEFAULT_ACCOUNT_REFERENCE]})
            .then((data)=>{
                console.log('getDBList CoolHead',data);
                if('error' in data){
                    console.log('error',data.error);
                }
                else{
                    if(data!=null&&data.length>0){
                        let updated_row=JSON.parse(JSON.stringify(row));
                        updated_row.account.name=data[0].name;
                        updated_row.account_name=data[0].name;
                        updated_row.billing_addr_specifier=data[0].billing_addr_specifier;
                        updated_row.billing_addr_street=data[0].billing_addr_street;
                        updated_row.billing_addr_zip=data[0].billing_addr_zip;
                        updated_row.billing_addr_city=data[0].billing_addr_city;
                        updated_row.account_business_id=data[0].business_id;
                        updated_row.account_id=data[0].id;
                        updated_row.tax_free=data[0].tax_free||false;
                        updated_row.tax_free_reason_code=data[0].tax_free_reason_code;
                        updated_row.excise_number=data[0].excise_number;
                        updated_row.warehouse_excise_number=data[0].warehouse_excise_number;
                        console.log('coolhead',updated_row);
                        setRow(old=>(updated_row));
                        let event={target:{name:'account_id',value:updated_row.account_id}}
                        handleChange(event);
                        event={target:{name:'excise_number',value:updated_row.excise_number}}
                        handleChange(event);
                        event={target:{name:'warehouse_excise_number',value:updated_row.warehouse_excise_number}}
                        handleChange(event);
                        event={target:{name:'tax_free',value:updated_row.tax_free}}
                        handleChange(event);
                    }
                    else{
                        alertSeverity='error'
                        alertMessage='Manufacturer "CoolHead Brew" (reference "'+DEFAULT_ACCOUNT_REFERENCE+'") not found or not accessible';
                        setSnackOpened(true);
                    }
                }
            });
            getDBList({entityType:'Account',entityParams:['reference='+DEFAULT_LOGISTICS_REFERENCE]})
            .then((data)=>{
                console.log('getDBList Logistics',data);
                if('error' in data){
                    console.log('error',data.error);
                }
                else{
                    if(data!=null&&data.length>0){
                        logistics_data={
                            account_business_id_alt:data[0].business_id,
                            account_name_alt:data[0].name,
                            excise_number_alt:data[0].excise_number,
                            shipping_addr_specifier:data[0].billing_addr_specifier,
                            shipping_addr_street:data[0].billing_addr_street,
                            shipping_addr_zip:data[0].billing_addr_zip,
                            shipping_addr_city:data[0].billing_addr_city,
                            warehouse_excise_number_alt:data[0].warehouse_excise_number};
                        console.log('logistics_data',logistics_data);
                        /*
                        setRow(prevState=>({...prevState,
                            account_business_id_alt:data[0].business_id,
                            account_name_alt:data[0].name,
                            excise_number_alt:data[0].excise_number,
                            shipping_addr_specifier:data[0].billing_addr_specifier,
                            shipping_addr_street:data[0].billing_addr_street,
                            shipping_addr_zip:data[0].billing_addr_zip,
                            shipping_addr_city:data[0].billing_addr_city,
                            warehouse_excise_number_alt:data[0].warehouse_excise_number,
                        }));
                        */
                    }
                    else{
                        alertSeverity='error'
                        alertMessage='Logistics account (reference "'+DEFAULT_LOGISTICS_REFERENCE+'") not found or not accessible';
                        setSnackOpened(true);
                    }
                }
            });
            if('state' in location){
                if((location.state)&&('account' in location.state)){
                    console.log('location.state.account',location.state.account);
                    setRow(prevState=>({...prevState,
                        account_id:location.state.account.id,
                        account:location.state.account,
                        tax_free:location.state.account.tax_free,
                        tax_free_reason_code:location.state.account.tax_free_reason_code,
                        excise_number:location.state.account.excise_number,
                        warehouse_excise_number:location.state.account.warehouse_excise_number,
                    }));
                }
            }
        }
    },[authMenuSubItem]);

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    const createRecord=()=>{
        setOpenBackdrop(true);
        let row_create=prepareSetData(JSON.parse(JSON.stringify(row)),'storage_order');
        row_create.account_business_id_alt=logistics_data.account_business_id_alt;
        row_create.account_name_alt=logistics_data.account_name_alt;
        row_create.excise_number_alt=logistics_data.excise_number_alt;
        row_create.shipping_addr_specifier=logistics_data.shipping_addr_specifier;
        row_create.shipping_addr_street=logistics_data.shipping_addr_street;
        row_create.shipping_addr_zip=logistics_data.shipping_addr_zip;
        row_create.shipping_addr_city=logistics_data.shipping_addr_city;
        row_create.warehouse_excise_number_alt=logistics_data.warehouse_excise_number_alt;
        console.log('row_create',row_create);
        
        createDBItem({entityType:authMenuSubItem.query,entityData:row_create})
        .then((data)=>{
            setOpenBackdrop(false);
            let newId=null;
            console.log('data',data);
            if('error' in data){
                alertSeverity='error'
                alertMessage=data.error;
                if('id' in data){newId=data.id;}
            }
            else{
                newId=data.id;
                alertSeverity='success';
                alertMessage='Draft Storage Order created with success!!!';
            }
            if(newId){
                let message={alertSeverity:alertSeverity,alertMessage:alertMessage};
                navigate('/storage_order/'+newId+'/items',{state:{message:message}});
            }
            else{
                setSnackOpened(true);
            }
        });
    };

    const handleClickSave=()=>{
        if(validate_required_fields(row,'storage_order',setError,setErrorMessage,dataError,required_field_error)){
            createRecord();
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        navigate(-1);
    }

    const handleChange=(event)=>{
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
    };

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);
        if('events' in entity_object.storage_order.fields[fieldName]){
            if('onChange' in entity_object.storage_order.fields[fieldName].events){
                entity_object.storage_order.fields[fieldName].events.onChange(fieldValue,handleChangeList,row);
            }
        }

        if('drive_dynamic_required' in entity_object.storage_order.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.storage_order.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.storage_order.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
    };

    const handleChangeDate=(fieldValue,fieldName)=>{
        if(fieldValue&&!isNaN(fieldValue.$D)){
            setRow(prevState=>({...prevState,[fieldName]:fieldValue}));
            if(error[fieldName]){
                setError(prevState=>({...prevState,[fieldName]:false}));
                setErrorMessage(prevState=>({...prevState,[fieldName]:''}));
            }
        }
        else{
            setRow(prevState=>({...prevState,[fieldName]:'NaN-NaN-NaN'})); 
        }
    }

    const handleChangeSwitch=(event)=>{
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
        if('events' in entity_object.storage_order.fields[event.target.name]){
            if('onChange' in entity_object.storage_order.fields[event.target.name].events){
                entity_object.storage_order.fields[event.target.name].events.onChange(event.target.value,handleChangeList);
            }
        }

        if('drive_dynamic_required' in entity_object.storage_order.fields[event.target.name]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[event.target.name]=event.target.value;
            for(const [key,value] of Object.entries(entity_object.storage_order.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.storage_order.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
    }

    const handleClickPick=(picklist_name)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:true}));
    };

    const pickListCallback=(picklist_name,picklist_record)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:false}));
        console.log('picklist_record',picklist_record);
        if(Object.keys(picklist_record).length!==0){
            if(picklist_name==='account_name'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.account_id!==picklist_record.id){
                    updated_row.account_id=picklist_record.id;
                    updated_row.account=picklist_record;
                    updated_row.tax_free=picklist_record.tax_free;
                    updated_row.tax_free_reason_code=picklist_record.tax_free_reason_code;
                    updated_row.excise_number=picklist_record.excise_number;
                    updated_row.warehouse_excise_number=picklist_record.warehouse_excise_number;
                    setRow(old=>(updated_row));
                    setError(prevState=>({...prevState,account_id:false}));
                    setErrorMessage(prevState=>({...prevState,account_id:''}));
                }
            }
        }
    };

    return (
        <div className="page">
            {pickControl.account_name&&<AccountPickList pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} label={'Suppliers'} globalData={globalData} userDataAuthorization={userDataAuthorization}></AccountPickList>}
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}</Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        <Button name="save" variant="outlined" onClick={handleClickSave}>Save</Button>
                        <Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>
                    </ButtonGroup>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="reference"
                            name="reference"
                            type={entity_object.storage_order.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.reference.label}
                            value={row.reference}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{color:status_color[row.status],backgroundColor:readOnlyBackgroundColor}}}
                            id="status"
                            name="status"
                            type={entity_object.storage_order.fields.status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.status.label}
                            value={row.status}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="delivery_status"
                            name="delivery_status"
                            type={entity_object.storage_order.fields.delivery_status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.delivery_status.label}
                            value={row.delivery_status}
                            margin="normal"/>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi' localeText={localeText}>
                            <MobileDatePicker
                                sx={{width:'100%',marginTop:"0px",marginBottom:"0px",div:{marginBottom:"8px"}}}
                                slotProps={{textField:{
                                    required:entity_object.storage_order.fields.date_of_arrival.required,
                                    margin:"normal",
                                    size:"small",
                                    variant:"outlined",
                                    error:error.date_of_arrival,
                                    helperText:errorMessage.date_of_arrival}}}
                                label={entity_object.storage_order.fields.date_of_arrival.label}
                                value={dayjs(row.date_of_arrival)}
                                onChange={(fieldValue)=>handleChangeDate(fieldValue,'date_of_arrival')}/>
                        </LocalizationProvider>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{
                                readOnly:true,                                    
                                endAdornment:(
                                    row.id!==''&&
                                    <InputAdornment position="start">
                                        <IconButton onClick={(e)=>handleClickPick('account_name')}>
                                            <AccountCircle/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required={entity_object.storage_order.fields.account_id.required}
                            error={error.account_id}
                            helperText={errorMessage.account_id}
                            id="account_name"
                            name="account_name"
                            type={entity_object.account.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.joins.account.fields.name.label}
                            value={row.account.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?'Hide More Info':'Show More Info'}</Button>
                    {showMoreInfo&&
                    <>
                    <Divider /*className={classes.tax}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.storage_order.style.tax_color} gutterBottom variant="body1">Order Tax</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.storage_order.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.storage_order.fields.excise_number.required}
                            error={error.excise_number}
                            helperText={errorMessage.excise_number}
                            id="excise_number"
                            name="excise_number"
                            type={entity_object.storage_order.fields.excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.excise_number.label}
                            value={row.excise_number||''}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.storage_order.fields.warehouse_excise_number.required}
                            error={error.warehouse_excise_number}
                            helperText={errorMessage.warehouse_excise_number}
                            id="warehouse_excise_number"
                            name="warehouse_excise_number"
                            type={entity_object.storage_order.fields.warehouse_excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.warehouse_excise_number.label}
                            value={row.warehouse_excise_number||''}
                            margin="normal"
                            onChange={handleChange}/>
                        <FormGroup style={{paddingLeft:'10px',width:'50%'}}>
                            <FormControlLabel control={<Switch name='tax_free' checked={row.tax_free} onChange={handleChangeSwitch}/>} label={entity_object.storage_order.fields.tax_free.label}/>
                            <FormControlLabel control={<Switch name='pre_sales' checked={row.pre_sales} onChange={handleChangeSwitch}/>} label={entity_object.storage_order.fields.pre_sales.label}/>
                        </FormGroup>
                    </Stack>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.storage_order.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.storage_order.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    </>}
                </Box>
            </div>
        </div>
    );
}

export default StorageOrderNew;
