import {useEffect,useState} from "react";
import {useNavigate,useLocation} from 'react-router-dom';
import {Switch,FormGroup,FormControlLabel,Backdrop,CircularProgress,Autocomplete,Divider,Box,Tabs,Tab,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,IconButton} from '@mui/material';
import {ArrowCircleUp,ArrowCircleDown} from '@mui/icons-material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import InfoIcon from '@mui/icons-material/Info';
import RecordInfo from "../RecordInfo"
import {sortPageTabs,prepareGetData,prepareDefaultData,prepareErrorFields,validate_required_fields,DefaultConfig,time_to_ui,adjustDateLocale} from '../../utilities/Utilities';
import {getDBList} from '../../integration/DatabaseManager.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {refresh_alcohol_licenses} from '../../integration/Api.js';
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='error'
const required_field_error='Required Field';
const dataPreparedGet=prepareDefaultData('default_value_get','alcohol_license_refresh_info');
const dataError=prepareErrorFields('alcohol_license_refresh_info');
import {columns} from '../../utilities/Entities';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
const list_name='alcohol_license_detail'
let pagehistory=null;
const AlcoholLicenseDetail=({authMenuSubItem,userDataAuthorization,globalData})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [loading,setLoading]=useState(true);
    const [rows,setRows]=useState([]);
    const [label,setLabel]=useState('-');
    const [pageTab,setPageTab]=useState(0);
    const [pageTabs,setPageTabs]=useState({});
    const [snackOpened,setSnackOpened]=useState(false);  
    const navigate=useNavigate();
    const location=useLocation();
    const [readOnly,setReadOnly]=useState(true);
    const [updates,setUpdates]=useState({});
    const [showMoreInfo,setShowMoreInfo]=useState(false);
    const [recordInfo,setRecordInfo]=useState(false);
    const [actions,setActions]=useState({});
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [error,setError]=useState(dataError.error);
    //const {globalData}=useContext(ApplicationContext);
    const [refreshPageTab,setRefreshPageTab]=useState(false);
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    const recordInfoCallback=()=>{
        setRecordInfo(false);
    };

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }
    
    const handleChangeTab=(event,newValue)=>{
        event.currentTarget.blur();
        setPageTab(newValue);
        pagehistory=event.target.name;
        navigate(location.pathname.replace(location.pathname.split('/')[3],pageTabs[event.target.name].path));
    };

    const getRecord=(query)=>{
        setLoading(old=>true);
        getDBList({entityType:query,})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setSnackOpened(true);
	        }
            else{
                if(data!=null){
                    console.log('data',JSON.parse(JSON.stringify(data)));
                    setRows(old=>data);
                    /*
                    if(data.length===1){
                        setRow(old=>prepareGetData(data[0],'alcohol_license_refresh_info'));
                        if(authMenuSubItem){
                            setLabel(authMenuSubItem.label);
                            setReadOnly(!('edit' in authMenuSubItem.actions));
                        }
                    }
                    */
                }
                else{
                    alertMessage='Record not found or not accessible';
                    alertSeverity='error';
                    setSnackOpened(true);
                }
                setLoading(old=>false);
            }
        });
    };

    useEffect(()=>{
        if((authMenuSubItem!==undefined)&&Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&location){
            setActions(authMenuSubItem.actions);
            getRecord(authMenuSubItem.query);
            setPageTabs(old=>sortPageTabs(authMenuSubItem.pageTabs));
            if(!location.pathname.includes(authMenuSubItem.defaultTabPath)||(pagehistory&&(pagehistory!==authMenuSubItem.defaultTabPath))){
                for(let key in authMenuSubItem.pageTabs){
                    if(location.pathname.includes(authMenuSubItem.pageTabs[key].path)){
                        setPageTab(authMenuSubItem.pageTabs[key].index);
                        break;
                    }
                }                
            }
            else if('state' in location){
                if((location.state)&&('message' in location.state)){
                    alertMessage=location.state.message.alertMessage;
                    alertSeverity=location.state.message.alertSeverity;
                    setSnackOpened(true);
                }
            }
            setLabel(authMenuSubItem.label);
        }
    },[authMenuSubItem,userDataAuthorization,location]);

    const pageTabCallback=(command)=>{
        if(command==='refreshed'){
            setRefreshPageTab(false);
        }
    }

    const handleClickButton=(event)=>{
        switch(event.target.name){
            case "refresh_alcohol_licenses":
                refreshAlcoholLicenses();
                break;
            default: 
                break;
        }
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    const refreshAlcoholLicenses=()=>{
        setOpenBackdrop(old=>true);
        refresh_alcohol_licenses({})
        .then((data)=>{
            console.log('refresh_alcohol_licenses',data);
            if('error' in data){
		        console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setSnackOpened(true);
	        }
            else{
                getRecord(authMenuSubItem.query);
            }
            setOpenBackdrop(old=>false);
        });
    };

    return (
        <div className="page">
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            {recordInfo&&<RecordInfo recordInfoCallback={recordInfoCallback} row={row} entity_name={'alcohol_license_refresh_info'} globalData={globalData} userDataAuthorization={userDataAuthorization}></RecordInfo>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.name}</span></Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        {Object.keys(actions).map((action)=>{
                            return(actions[action].type==='button'&&readOnly&&<Button key={action} name={action} variant="outlined" onClick={handleClickButton}>{actions[action].label}</Button>);
                        })}
                    </ButtonGroup>

                    <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <div style={{height:'75vh',width:'100%'}}>
                            <DataGrid
                                sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                                rows={rows}
                                columns={columns[list_name](list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                                loading={loading}
                                density="compact"
                                slots={{
                                    toolbar:CustomToolbar,
                                }}
                                componentsProps={{
                                    toolbar:{
                                        showQuickFilter:true,
                                        quickFilterProps:{debounceMs:250},
                                    },
                                }}
                                onColumnWidthChange={(params,event,details)=>{
                                    let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                                    updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                                    saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                                }}
                            />
                        </div>
                    </Stack>

                    {/*
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="id"
                            name="id"
                            type={entity_object.alcohol_license_refresh_info.fields.id.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.alcohol_license_refresh_info.fields.id.label}
                            value={row.id}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="created_at"
                            name="created_at"
                            type={entity_object.alcohol_license_refresh_info.fields.created_at.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.alcohol_license_refresh_info.fields.created_at.label}
                            value={adjustDateLocale(row.created_at)}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="refresh_information"
                            name="refresh_information"
                            type={entity_object.alcohol_license_refresh_info.fields.refresh_information.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.alcohol_license_refresh_info.fields.refresh_information.label}
                            value={row.refresh_information}
                            margin="normal"/>
                    </Stack>
                    */}
                </Box>
            </div>
            {/*
            <Box sx={{bgcolor:'none'}}>
                <Tabs
                    value={pageTab}
                    onChange={handleChangeTab}
                    sx={{"& button:hover":{backgroundColor:'lightgrey'},"& button:focus":{backgroundColor:'white'},"& .MuiTab-root.Mui-selected":{backgroundColor:'white'},}}
                    variant="scrollable"
                    scrollButtons="auto">
                    {Object.keys(pageTabs).map((item)=>{
                        return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                    })}
                </Tabs>
                {Object.keys(pageTabs).length>0&&('sales_orders' in pageTabs)&&pageTab===pageTabs['sales_orders'].index&&<AccountDetailSalesOrders parent={row} pageTab={('sales_orders' in pageTabs)?pageTabs['sales_orders']:{}}></AccountDetailSalesOrders>}
            </Box>
            */}
        </div>
    );
}

export default AlcoholLicenseDetail;
