import {useEffect,useState,forwardRef,useRef} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions} from '@mui/material';
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {getDBList,getDBItem} from '../../integration/DatabaseManager.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const list_name='package_types_picklist';

const PaperComponent=forwardRef(function PaperComponent(props,ref){
    return (
        <Draggable handle="#draggable-dialog-package-type-picklist" nodeRef={ref}>
            <Paper ref={ref} {...props} />
        </Draggable>
    );
});

const PackageTypesPickList=({pickListCallback,authMenuSubItem,sku_type,globalData,userDataAuthorization})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [selected,setSelected]=useState(false);
    const [selectedRow,setSelectedRow]=useState({});
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);
    const nodeRef=useRef(null);

    const getRecords=(query,query_args)=>{
        getDBList({entityType:query,entityParams:['sku_type=' + sku_type]})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
            }
            else if(data.length>0){
                console.log('data',data);
                if(data.length>100){//normall it is over 100K, and 100 are loaded in first page.
                    let start_data=data.slice(0,100);
                    for(let start in start_data){
                        start_data[start].id_temp=start_data[start].id;
                    }
                    setRows(old=>start_data);
                    setLoading(false);
                    let end_data=data.slice(100,data.length);
                    for(let end in end_data){
                        end_data[end].id_temp=end_data[end].id;
                    }
                    setRows(old=>start_data.concat(end_data));
                }
                else{
                    for(let lov in data){
                        data[lov].id_temp=data[lov].id;
                    }
                    setRows(old=>data);
                }
            }
            setLoading(false);
        });
    };

    const getRecord=(query,id)=>{
        getDBItem({entityType:query,entityId:id})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
	        }
            else{
                if(data!=null){
                    console.log('data',JSON.parse(JSON.stringify(data)));
                    pickListCallback('package_type',data);
                }
            }
        });
    };

    useEffect(()=>{
        if(Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)&&(sku_type!==undefined&&sku_type!==null&&sku_type!=='')){
            getRecords(authMenuSubItem.picklists.package_type.query,authMenuSubItem.picklists.package_type.query_args);
        }
    },[authMenuSubItem,sku_type]);

    const picklistLinkCallback=(selectedRow)=>{
        getRecord(authMenuSubItem.picklists.package_type.query,selectedRow.id);
    }

    const handleClickCancel=()=>{
        pickListCallback('package_type',{});
    }

    const handleClickSelect=()=>{
        getRecord(authMenuSubItem.picklists.package_type.query,selectedRow.id);
    }

    const handleDoubleClickRow=(e)=>{
        getRecord(authMenuSubItem.picklists.package_type.query,e.row.id);
    }

    const handleChangeRow=(ids)=>{
        setSelected(true);
        const selectedIds=new Set(ids);
        const selectedRows=rows.filter((row)=>selectedIds.has(row.id));
        setSelectedRow(selectedRows[0]);
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter autoFocus style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={true}
            aria-labelledby="draggable-dialog-package-type-picklist"
            PaperComponent={(props)=><PaperComponent ref={nodeRef} {...props}/>}
            onClose={handleClickCancel}>
            <DialogTitle id="draggable-dialog-package-type-picklist" style={{cursor:'move'}}>Package Types</DialogTitle>
            <DialogContent>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        className="btn"
                        onRowDoubleClick={handleDoubleClickRow}
                        onSelectionModelChange={handleChangeRow}
                        editMode="row"
                        rows={rows}
                        columns={columns[list_name](picklistLinkCallback,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="btn" disabled={!selected} onClick={handleClickSelect} variant="contained">Select</Button>
                <Button className="btn" onClick={handleClickCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default PackageTypesPickList;
