import {useEffect,useState,forwardRef,useRef} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions,TextField} from '@mui/material';
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {DataGrid,GridToolbarContainer} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {getDBList,getDBItem} from '../../integration/DatabaseManager.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const list_name='barcodes_picklist';

const PaperComponent=forwardRef(function PaperComponent(props,ref){
    return (
        <Draggable handle="#draggable-dialog-barcode-picklist" nodeRef={ref}>
            <Paper ref={ref} {...props} />
        </Draggable>
    );
});

const BarcodePickList=({pickListCallback,authMenuSubItem,globalData,userDataAuthorization})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [selected,setSelected]=useState(false);
    const [selectedRow,setSelectedRow]=useState({});
    const [filterValue,setFilterValue]=useState('');
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);
    const nodeRef=useRef(null);

    const handleFilterChange=(event)=>{
        setFilterValue(event.target.value);
    };
    const handleSearch=()=>{
        getRecords(authMenuSubItem.picklists.barcode.query,filterValue);
    };

    const getRecords=(query,filterValue)=>{
        let entityParams=['status=Available','sku_id=null','limit=5'];
        if(filterValue!==null&&filterValue!==''&&filterValue!==undefined){
            entityParams.push('name='+filterValue);
        }
        getDBList({entityType:query,entityParams:entityParams})
        .then((data)=>{
            if('error' in data){console.log('error',data.error);}
            else{
                if(data.length>0){
                    console.log('records',data);
                    setRows(old=>data);
                }
            }
            setLoading(false);
        });
    };

    const getRecord=(query,id)=>{
        getDBItem({entityType:query,entityId:id})
        .then((data)=>{
            if('error' in data){
		        console.log('error',data.error);
	        }
            else{
                if(data!=null){
                    console.log('data',JSON.parse(JSON.stringify(data)));
                    pickListCallback('barcode',data);
                }
            }
        });
    };

    useEffect(()=>{
        getRecords(authMenuSubItem.picklists.barcode.query,null);
    },[]);

    const picklistLinkCallback=(selectedRow)=>{
        getRecord(authMenuSubItem.picklists.barcode.query,selectedRow.id);
    }

    const handleClickCancel=()=>{
        pickListCallback('barcode',{});
    }

    const handleClickSelect=()=>{
        getRecord(authMenuSubItem.picklists.barcode.query,selectedRow.id);
    }

    const handleDoubleClickRow=(e)=>{
        getRecord(authMenuSubItem.picklists.barcode.query,e.row.id);
    }

    const handleChangeRow=(ids)=>{
        setSelected(true);
        const selectedIds=new Set(ids);
        const selectedRows=rows.filter((row)=>selectedIds.has(row.id));
        setSelectedRow(selectedRows[0]);
    }

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer style={{marginBottom:"5px"}}>
            <TextField
                style={{marginRight:"5px"}}
                size="small"
                value={filterValue}
                onChange={handleFilterChange}
                placeholder="Search by Barcode"
                variant="outlined"
                InputLabelProps={{shrink:false}}/>
            <Button variant="contained" onClick={handleSearch}>Search</Button>
          </GridToolbarContainer>
        );
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={true}
            aria-labelledby="draggable-dialog-barcode-picklist"
            PaperComponent={(props)=><PaperComponent ref={nodeRef} {...props}/>}
            onClose={handleClickCancel}>
            <DialogTitle id="draggable-dialog-barcode-picklist" style={{cursor:'move'}}>Barcodes</DialogTitle>
            <DialogContent>
                {"The system is only returning the first 5 available records. If looking for a specific Barcode number, please use the Search below:"}
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        className="btn"
                        onRowDoubleClick={handleDoubleClickRow}
                        onSelectionModelChange={handleChangeRow}
                        editMode="row"
                        rows={rows}
                        columns={columns[list_name](picklistLinkCallback,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="btn" disabled={!selected} onClick={handleClickSelect} variant="contained">Select</Button>
                <Button className="btn" onClick={handleClickCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default BarcodePickList;
