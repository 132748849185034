import keycloak from "../Keycloak";

export async function getDBList(body){
    let responseUI={};
    let url = '/api/database/' + body.entityType;
    if(body.entityParams!==undefined&&body.entityParams.length>0){
        for (let entityParam in body.entityParams){
            let paramDelimiter='&';
            if(entityParam==0){paramDelimiter='?';}
            url+=paramDelimiter+body.entityParams[entityParam];
        }
    }
    try{
        const fetchResponse=await fetch(url,{
            method:"GET",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            }
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - getDBList");
        console.log(e);
        responseUI={error:"Error - getDBList: "+e.toString()};
    }
    finally{
        return responseUI
    }
}

export async function getDBItem(body){
    let responseUI={};
    let url='/api/database/'+body.entityType+'/id/'+body.entityId;
    try{
        const fetchResponse=await fetch(url,{
            method:"GET",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            }
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - getDBItem");
        console.log(e);
        responseUI={error:"Error - getDBItem: "+e.toString()};
    }
    finally{
        return responseUI
    }
}

export async function createDBItem(body){
    let responseUI={};
    let url='/api/database/'+body.entityType;
    try{
        const fetchResponse=await fetch(url,{
            method:"POST",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
            body:JSON.stringify(body),
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - createDBItem");
        console.log(e);
        responseUI={error:"Error - createDBItem: "+e.toString()};
    }
    finally{
        return responseUI
    }
}

export async function updateDBItem(body){
    let responseUI={};
    let url='/api/database/'+body.entityType+'/id/'+body.entityId;
    try{
        const fetchResponse=await fetch(url,{
            method:"PUT",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
            body:JSON.stringify(body),
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - updateDBItem");
        console.log(e);
        responseUI={error:"Error - updateDBItem: "+e.toString()};
    }
    finally{
        return responseUI
    }
}

export async function deleteDBItem(body){
    let responseUI={};
    let url='/api/database/'+body.entityType+'/id/'+body.entityId;
    try{
        const fetchResponse=await fetch(url,{
            method:"DELETE",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            },
            body:JSON.stringify(body),
        });
        const response=await fetchResponse.json();
        
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - deleteDBItem");
        console.log(e);
        responseUI={error:"Error - deleteDBItem: "+e.toString()};
    }
    finally{
        return responseUI
    }
}

export async function deleteDBList(body){
    let responseUI={};
    let url = '/api/database/' + body.entityType;
    if(body.entityParams!==undefined&&body.entityParams.length>0){
        for (let entityParam in body.entityParams){
            let paramDelimiter='&';
            if(entityParam==0){paramDelimiter='?';}
            url+=paramDelimiter+body.entityParams[entityParam];
        }
    }
    try{
        const fetchResponse=await fetch(url,{
            method:"DELETE",
            headers:{
                "Accept":"application/json",
                "Content-Type":"application/json",
                Authorization: `Bearer ${keycloak.token}`,
            }
        });
        const response=await fetchResponse.json();
        if(response===undefined||response===null){
            console.log("Error - response is 'undefined'/'null'");
        }
        else{
            responseUI=JSON.parse(JSON.stringify(response));
        }
    }
    catch(e){
        console.log("Error - deleteDBList");
        console.log(e);
        responseUI={error:"Error - deleteDBList: "+e.toString()};
    }
    finally{
        return responseUI
    }
}