import {forwardRef,useRef} from 'react';
import {Link,Dialog,Button,DialogTitle,DialogContent,DialogActions,Typography} from '@mui/material';
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';

const PaperComponent=forwardRef(function PaperComponent(props,ref){
  return (
      <Draggable handle="#draggable-dialog-about" nodeRef={ref}>
          <Paper style={{width:'fit-content'}} ref={ref} {...props} />
      </Draggable>
  );
});

const About=({aboutCallback})=>{
    const nodeRef=useRef(null);
    
    const handleClickClose=()=>{
      aboutCallback();
    }

    return (
        <Dialog
            fullWidth={true}
            
            aria-labelledby="draggable-dialog-about"
            open={true}
            PaperComponent={(props)=><PaperComponent ref={nodeRef} {...props}/>}
            onClose={handleClickClose}>
            <DialogTitle id="draggable-dialog-about" style={{cursor:'move'}}>System Developed by:</DialogTitle>

                

            <DialogContent>
                <Typography variant="h1" style={{width:'fit-content',backgroundColor:'#00224F',color:'white',paddingBottom:'5px',paddingRight:'10px',fontWeight:'500',fontSize:'3.00rem',fontFamily:'IBM Plex Mono'}}>Coda Engineering</Typography>
                <Typography variant="h6" style={{margin:'5px',fontWeight:'300',fontSize:'1.00rem'}}>{'Plartform: '}<span className="body-title">{'Brainiac'}</span></Typography>
                <Typography variant="h6" style={{margin:'5px',fontWeight:'300',fontSize:'1.00rem'}}>{'Version: '}<span className="body-title">{'1.9 (17.09.2024)'}</span></Typography>
                <Typography variant="h6" style={{margin:'5px',fontWeight:'300',fontSize:'1.00rem'}}>{'Website: '}<Link href="https://coda.engineering" target="_blank" underline="hover">coda.engineering</Link></Typography>
            </DialogContent>
            <DialogActions>
                <Button className="btn" onClick={handleClickClose} variant="contained">Close</Button>
             </DialogActions>
        </Dialog>
    );
}

export default About;
