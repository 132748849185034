import {useEffect,useState} from "react";
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import {Tooltip,Box,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,IconButton} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import RecordInfo from "../RecordInfo";
import RefreshIcon from '@mui/icons-material/Refresh';
import {sortPageTabs,DefaultConfig,prepareDefaultData,prepareErrorFields,prepareGetData,validate_required_fields} from '../../utilities/Utilities';
import {getDBItem, updateDBItem} from '../../integration/DatabaseManager.js';
import {entity_object} from '../../utilities/DataDictionary';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
let alertMessage='';
let alertSeverity='error'
const dataPreparedGet=prepareDefaultData('default_value_get','user');
const required_field_error='Required Field';
const dataError=prepareErrorFields('user');
let pagehistory=null;
const UserDetail=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedGet);
    const [label,setLabel]=useState('-');
    const {slug}=useParams();
    const [pageTab,setPageTab]=useState(0);
    const [pageTabs,setPageTabs]=useState({});
    const [isChanged,setIsChanged]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);  
    const navigate=useNavigate();
    const location=useLocation();
    const [readOnly,setReadOnly]=useState(true);
    const [updates,setUpdates]=useState({});
    const [showMoreInfo,setShowMoreInfo]=useState(false);
    const [recordInfo,setRecordInfo]=useState(false);
    const [actions,setActions]=useState({});
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const {globalData}=useContext(ApplicationContext);

    const handleClickMoreInfo=()=>{
        setRecordInfo(true);
    };

    const recordInfoCallback=()=>{
        setRecordInfo(false);
    };

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }
    
    const handleChangeTab=(event,newValue)=>{
        event.currentTarget.blur();
        setPageTab(newValue);
        pagehistory=event.target.name;
        navigate(location.pathname.replace(location.pathname.split('/')[3],pageTabs[event.target.name].path));
    };

    const getRecord=(query)=>{
        getDBItem({entityType:query,entityId:slug})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertMessage=data.error;
                alertSeverity='error';
                setReadOnly(true);
                setSnackOpened(true);
            }
            else{
                console.log('data',data);
                if(data!=null){
                    setRow(old=>prepareGetData(data,'user'));
                    if(authMenuSubItem){
                        setLabel(authMenuSubItem.label);
                        setReadOnly(!('edit' in authMenuSubItem.actions));
                    }
                }
                else{
                    alertMessage='Record not found or not accessible';
                    alertSeverity='error'
                    setReadOnly(true);
                    setSnackOpened(true);
                }
            }
        });
    };

    const updateRecord=()=>{
        updateDBItem({entityType:authMenuSubItem.query,entityId:slug,entityData:updates})
        .then((data)=>{
            if('error' in data){
                console.log('error',data.error);
                alertSeverity='error'
                alertMessage=data.error;
            }
            else{
                alertSeverity='success'
                alertMessage='User updated with success!!!';
            }
            getRecord(authMenuSubItem.query);
            setSnackOpened(true);
            setIsChanged(false);
        });
    };

    useEffect(()=>{
        if(Object.keys(userDataAuthorization).length>0&&Object.keys(authMenuSubItem).length>0&&(authMenuSubItem!==undefined)&&(slug!==undefined&&slug!==null&&slug!=='')&&location){
            setActions(authMenuSubItem.actions);
            getRecord(authMenuSubItem.query);
            setPageTabs(old=>sortPageTabs(authMenuSubItem.pageTabs));
            if(!location.pathname.includes(authMenuSubItem.defaultTabPath)||(pagehistory&&(pagehistory!==authMenuSubItem.defaultTabPath))){
                for(let key in authMenuSubItem.pageTabs){
                    if(location.pathname.includes(authMenuSubItem.pageTabs[key].path)){
                        setPageTab(authMenuSubItem.pageTabs[key].index);
                        break;
                    }
                }                
            }
            setLabel(authMenuSubItem.label);
        }
    },[authMenuSubItem,slug,userDataAuthorization,location]);

    const handleClickSave=()=>{
        if(validate_required_fields(row,'user',setError,setErrorMessage,dataError,required_field_error)){
            updateRecord();
            setUpdates(old=>{});
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        setIsChanged(false);
        getRecord(authMenuSubItem.query);
        setUpdates(old=>{});
        setError(dataError.error);
        setErrorMessage(dataError.message);
    }

    const handleChange=(event)=>{
        setIsChanged(true);
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        setUpdates(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
    };

    const handleClickButton=(event)=>{
        switch(event.target.name){
            case "update":
                setReadOnly(false);
                break;
            default: 
                break;
        }
    }

    return (
        <div className="page">
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            {recordInfo&&<RecordInfo recordInfoCallback={recordInfoCallback} row={row} entity_name={'user'} globalData={globalData} userDataAuthorization={userDataAuthorization}></RecordInfo>}
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}<span className="body-title">{row.name}</span></Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        {!readOnly&&<Button disabled={!isChanged} name="save" variant="outlined" onClick={handleClickSave}>Save</Button>}
                        {!readOnly&&<Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>}
                        {Object.keys(actions).map((action)=>{
                            return(actions[action].type==='button'&&readOnly&&<Button key={action} name={action} variant="outlined" onClick={handleClickButton}>{actions[action].label}</Button>);
                        })}
                    </ButtonGroup>
                    {readOnly&&
                    <IconButton onClick={handleClickMoreInfo} color="primary" style={{position:'absolute',right:'40px'}} size="small">
                        <Tooltip title="More Information on the Record">
                            <InfoIcon fontSize="inherit"/>
                        </Tooltip>
                    </IconButton>}
                    {readOnly&&
                    <IconButton onClick={()=>{
                        setRow(old=>dataPreparedGet);
                        getRecord(authMenuSubItem.query)}} color="primary" style={{position:'absolute',right:'15px'}} size="small">
                        <Tooltip title="Refresh the current Record">
                            <RefreshIcon fontSize="inherit"/>
                            </Tooltip>
                    </IconButton>}
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:readOnly}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            required={entity_object.user.fields.name.required}
                            error={error.name}
                            helperText={errorMessage.name}
                            id="name"
                            name="name"
                            type={entity_object.user.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.user.fields.name.label}
                            value={row.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                   
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            id="phone"
                            name="phone"
                            type={entity_object.user.fields.phone.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.user.fields.phone.label}
                            value={row.phone}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:readOnly}}
                            required={entity_object.user.fields.email.required}
                            error={error.name}
                            helperText={errorMessage.name}
                            id="email"
                            name="email"
                            type={entity_object.user.fields.email.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.user.fields.email.label}
                            value={row.email}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="login"
                            name="login"
                            type={entity_object.user.fields.login.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.user.fields.login.label}
                            value={row.login}
                            margin="normal"
                            onChange={handleChange}/>
                            
                    </Stack>
                    
                    {/*
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?'Hide More Info':'Show More Info'}</Button>
                    {showMoreInfo&&
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            InputProps={{readOnly:readOnly}}
                            sx={{div:{backgroundColor:readOnly?readOnlyBackgroundColor:""}}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            d="description"
                            name="description"
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={field_label.account.description}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>}
                    */}
                </Box>
            </div>
            {/*
            <Box sx={{bgcolor:'none'}}>
                <Tabs
                    value={pageTab}
                    onChange={handleChangeTab}
                    sx={{"& button:hover":{backgroundColor:'lightgrey'},"& button:focus":{backgroundColor:'white'},"& .MuiTab-root.Mui-selected":{backgroundColor:'white'},}}
                    variant="scrollable"
                    scrollButtons="auto">
                    {Object.keys(pageTabs).map((item)=>{
                        return (<Tab name={item} key={item} label={pageTabs[item].label}></Tab>);
                    })}
                </Tabs>
                {Object.keys(pageTabs).length>0&&pageTab===0&&<AccountDetailOrders advisor_company_id={row.id} user_id={userId} pageTab={('orders' in pageTabs)?pageTabs['orders']:{}}></AccountDetailOrders>} 
            </Box>
            */}
        </div>
    );
}

export default UserDetail;
