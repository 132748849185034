import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function Country(props){
    return(
        <Autocomplete
            style={{marginTop:"0px",marginBottom:"8px"}}
            fullWidth
            readOnly={props.readOnly}
            options={countries}
            getOptionLabel={(option)=>{
                let return_option='';
                if(typeof option==='object'){return_option=option.country;}
                else if(option){return_option=option;}
                return return_option;
            }}
            isOptionEqualToValue={(option,value)=>value===option.country}
            id={props.name}
            name={props.name}
            type={props.type}
            size="small"
            value={props.value}
            onChange={props.onChange}
            renderOption={(props,option)=>(
                <Box component="li" sx={{'& > img':{mr:2,flexShrink:0}}} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""/>
                        {option.country} ({option.code}) {option.phone}
                </Box>
            )}
            renderInput={(params)=>(
                <TextField {...params} 
                    sx={{div:{backgroundColor:props.readOnly?props.readOnlyBackgroundColor:""}}}
                    style={{marginTop:"0px",marginBottom:"8px"}}
                    required={props.required}
                    error={props.error}
                    helperText={props.helperText}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label={props.label}
                    margin="normal"
                />
            )}
        />
    );
}

const countries=[
  { code: 'AD', country: 'Andorra', phone: '+376' },
  { code: 'AE', country: 'United Arab Emirates', phone: '+971'},
  { code: 'AF', country: 'Afghanistan', phone: '+93' },
  { code: 'AG', country: 'Antigua and Barbuda',phone: '+1-268'},
  { code: 'AI', country: 'Anguilla', phone: '+1-264' },
  { code: 'AL', country: 'Albania', phone: '+355' },
  { code: 'AM', country: 'Armenia', phone: '+374' },
  { code: 'AO', country: 'Angola', phone: '+244' },
  { code: 'AQ', country: 'Antarctica', phone: '+672' },
  { code: 'AR', country: 'Argentina', phone: '+54' },
  { code: 'AS', country: 'American Samoa', phone: '+1-684' },
  { code: 'AT', country: 'Austria', phone: '+43' },
  { code: 'AU', country: 'Australia', phone: '+61'},
  { code: 'AW', country: 'Aruba', phone: '+297' },
  { code: 'AX', country: 'Alland Islands', phone: '+358' },
  { code: 'AZ', country: 'Azerbaijan', phone: '+994' },
  { code: 'BA', country: 'Bosnia and Herzegovina',phone: '+387'},
  { code: 'BB', country: 'Barbados', phone: '+1-246' },
  { code: 'BD', country: 'Bangladesh', phone: '+880' },
  { code: 'BE', country: 'Belgium', phone: '+32' },
  { code: 'BF', country: 'Burkina Faso', phone: '+226' },
  { code: 'BG', country: 'Bulgaria', phone: '+359' },
  { code: 'BH', country: 'Bahrain', phone: '+973' },
  { code: 'BI', country: 'Burundi', phone: '+257' },
  { code: 'BJ', country: 'Benin', phone: '+229' },
  { code: 'BL', country: 'Saint Barthelemy', phone: '+590' },
  { code: 'BM', country: 'Bermuda', phone: '+1-441' },
  { code: 'BN', country: 'Brunei Darussalam', phone: '+673' },
  { code: 'BO', country: 'Bolivia', phone: '+591' },
  { code: 'BR', country: 'Brazil', phone: '+55' },
  { code: 'BS', country: 'Bahamas', phone: '+1-242' },
  { code: 'BT', country: 'Bhutan', phone: '+975' },
  { code: 'BV', country: 'Bouvet Island', phone: '+47' },
  { code: 'BW', country: 'Botswana', phone: '+267' },
  { code: 'BY', country: 'Belarus', phone: '+375' },
  { code: 'BZ', country: 'Belize', phone: '+501' },
  { code: 'CA', country: 'Canada', phone: '+1'},
  { code: 'CC', country: 'Cocos (Keeling) Islands', phone: '+61'},
  { code: 'CD', country: 'Congo, Democratic Republic of the',phone: '+243'},
  { code: 'CF', country: 'Central African Republic', phone: '+236'},
  { code: 'CG', country: 'Congo, Republic of the', phone: '+242'},
  { code: 'CH', country: 'Switzerland', phone: '+41' },
  { code: 'CI', country: "Cote d'Ivoire", phone: '+225' },
  { code: 'CK', country: 'Cook Islands', phone: '+682' },
  { code: 'CL', country: 'Chile', phone: '+56' },
  { code: 'CM', country: 'Cameroon', phone: '+237' },
  { code: 'CN', country: 'China', phone: '+86' },
  { code: 'CO', country: 'Colombia', phone: '+57' },
  { code: 'CR', country: 'Costa Rica', phone: '+506' },
  { code: 'CU', country: 'Cuba', phone: '+53' },
  { code: 'CV', country: 'Cape Verde', phone: '+238' },
  { code: 'CW', country: 'Curacao', phone: '+599' },
  { code: 'CX', country: 'Christmas Island', phone: '+61' },
  { code: 'CY', country: 'Cyprus', phone: '+357' },
  { code: 'CZ', country: 'Czech Republic', phone: '+420' },
  { code: 'DE', country: 'Germany', phone: '+49'},
  { code: 'DJ', country: 'Djibouti', phone: '+253' },
  { code: 'DK', country: 'Denmark', phone: '+45' },
  { code: 'DM', country: 'Dominica', phone: '+1-767' },
  { code: 'DO', country: 'Dominican Republic', phone: '+1-809' },
  { code: 'DZ', country: 'Algeria', phone: '+213' },
  { code: 'EC', country: 'Ecuador', phone: '+593' },
  { code: 'EE', country: 'Estonia', phone: '+372' },
  { code: 'EG', country: 'Egypt', phone: '+20' },
  { code: 'EH', country: 'Western Sahara', phone: '+212' },
  { code: 'ER', country: 'Eritrea', phone: '+291' },
  { code: 'ES', country: 'Spain', phone: '+34' },
  { code: 'ET', country: 'Ethiopia', phone: '+251' },
  { code: 'FI', country: 'Finland', phone: '+358' },
  { code: 'FJ', country: 'Fiji', phone: '+679' },
  {
    code: 'FK',
    country: 'Falkland Islands (Malvinas)',
    phone: '+500',
  },
  {
    code: 'FM',
    country: 'Micronesia, Federated States of',
    phone: '+691',
  },
  { code: 'FO', country: 'Faroe Islands', phone: '+298' },
  {
    code: 'FR',
    country: 'France',
    phone: '+33',
    suggested: true,
  },
  { code: 'GA', country: 'Gabon', phone: '+241' },
  { code: 'GB', country: 'United Kingdom', phone: '+44' },
  { code: 'GD', country: 'Grenada', phone: '+1-473' },
  { code: 'GE', country: 'Georgia', phone: '+995' },
  { code: 'GF', country: 'French Guiana', phone: '+594' },
  { code: 'GG', country: 'Guernsey', phone: '+44' },
  { code: 'GH', country: 'Ghana', phone: '+233' },
  { code: 'GI', country: 'Gibraltar', phone: '+350' },
  { code: 'GL', country: 'Greenland', phone: '+299' },
  { code: 'GM', country: 'Gambia', phone: '+220' },
  { code: 'GN', country: 'Guinea', phone: '+224' },
  { code: 'GP', country: 'Guadeloupe', phone: '+590' },
  { code: 'GQ', country: 'Equatorial Guinea', phone: '+240' },
  { code: 'GR', country: 'Greece', phone: '+30' },
  {
    code: 'GS',
    country: 'South Georgia and the South Sandwich Islands',
    phone: '+500',
  },
  { code: 'GT', country: 'Guatemala', phone: '+502' },
  { code: 'GU', country: 'Guam', phone: '+1-671' },
  { code: 'GW', country: 'Guinea-Bissau', phone: '+245' },
  { code: 'GY', country: 'Guyana', phone: '+592' },
  { code: 'HK', country: 'Hong Kong', phone: '+852' },
  {
    code: 'HM',
    country: 'Heard Island and McDonald Islands',
    phone: '+672',
  },
  { code: 'HN', country: 'Honduras', phone: '+504' },
  { code: 'HR', country: 'Croatia', phone: '+385' },
  { code: 'HT', country: 'Haiti', phone: '+509' },
  { code: 'HU', country: 'Hungary', phone: '+36' },
  { code: 'ID', country: 'Indonesia', phone: '+62' },
  { code: 'IE', country: 'Ireland', phone: '+353' },
  { code: 'IL', country: 'Israel', phone: '+972' },
  { code: 'IM', country: 'Isle of Man', phone: '+44' },
  { code: 'IN', country: 'India', phone: '+91' },
  {
    code: 'IO',
    country: 'British Indian Ocean Territory',
    phone: '+246',
  },
  { code: 'IQ', country: 'Iraq', phone: '+964' },
  {
    code: 'IR',
    country: 'Iran, Islamic Republic of',
    phone: '+98',
  },
  { code: 'IS', country: 'Iceland', phone: '+354' },
  { code: 'IT', country: 'Italy', phone: '+39' },
  { code: 'JE', country: 'Jersey', phone: '+44' },
  { code: 'JM', country: 'Jamaica', phone: '+1-876' },
  { code: 'JO', country: 'Jordan', phone: '+962' },
  {
    code: 'JP',
    country: 'Japan',
    phone: '+81',
    suggested: true,
  },
  { code: 'KE', country: 'Kenya', phone: '+254' },
  { code: 'KG', country: 'Kyrgyzstan', phone: '+996' },
  { code: 'KH', country: 'Cambodia', phone: '+855' },
  { code: 'KI', country: 'Kiribati', phone: '+686' },
  { code: 'KM', country: 'Comoros', phone: '+269' },
  {
    code: 'KN',
    country: 'Saint Kitts and Nevis',
    phone: '+1-869',
  },
  {
    code: 'KP',
    country: "Korea, Democratic People's Republic of",
    phone: '+850',
  },
  { code: 'KR', country: 'Korea, Republic of', phone: '+82' },
  { code: 'KW', country: 'Kuwait', phone: '+965' },
  { code: 'KY', country: 'Cayman Islands', phone: '+1-345' },
  { code: 'KZ', country: 'Kazakhstan', phone: '+7' },
  {
    code: 'LA',
    country: "Lao People's Democratic Republic",
    phone: '+856',
  },
  { code: 'LB', country: 'Lebanon', phone: '+961' },
  { code: 'LC', country: 'Saint Lucia', phone: '+1-758' },
  { code: 'LI', country: 'Liechtenstein', phone: '+423' },
  { code: 'LK', country: 'Sri Lanka', phone: '+94' },
  { code: 'LR', country: 'Liberia', phone: '+231' },
  { code: 'LS', country: 'Lesotho', phone: '+266' },
  { code: 'LT', country: 'Lithuania', phone: '+370' },
  { code: 'LU', country: 'Luxembourg', phone: '+352' },
  { code: 'LV', country: 'Latvia', phone: '+371' },
  { code: 'LY', country: 'Libya', phone: '+218' },
  { code: 'MA', country: 'Morocco', phone: '+212' },
  { code: 'MC', country: 'Monaco', phone: '+377' },
  {
    code: 'MD',
    country: 'Moldova, Republic of',
    phone: '+373',
  },
  { code: 'ME', country: 'Montenegro', phone: '+382' },
  {
    code: 'MF',
    country: 'Saint Martin (French part)',
    phone: '+590',
  },
  { code: 'MG', country: 'Madagascar', phone: '+261' },
  { code: 'MH', country: 'Marshall Islands', phone: '+692' },
  {
    code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    phone: '+389',
  },
  { code: 'ML', country: 'Mali', phone: '+223' },
  { code: 'MM', country: 'Myanmar', phone: '+95' },
  { code: 'MN', country: 'Mongolia', phone: '+976' },
  { code: 'MO', country: 'Macao', phone: '+853' },
  {
    code: 'MP',
    country: 'Northern Mariana Islands',
    phone: '+1-670',
  },
  { code: 'MQ', country: 'Martinique', phone: '+596' },
  { code: 'MR', country: 'Mauritania', phone: '+222' },
  { code: 'MS', country: 'Montserrat', phone: '+1-664' },
  { code: 'MT', country: 'Malta', phone: '+356' },
  { code: 'MU', country: 'Mauritius', phone: '+230' },
  { code: 'MV', country: 'Maldives', phone: '+960' },
  { code: 'MW', country: 'Malawi', phone: '+265' },
  { code: 'MX', country: 'Mexico', phone: '+52' },
  { code: 'MY', country: 'Malaysia', phone: '+60' },
  { code: 'MZ', country: 'Mozambique', phone: '+258' },
  { code: 'NA', country: 'Namibia', phone: '+264' },
  { code: 'NC', country: 'New Caledonia', phone: '+687' },
  { code: 'NE', country: 'Niger', phone: '+227' },
  { code: 'NF', country: 'Norfolk Island', phone: '+672' },
  { code: 'NG', country: 'Nigeria', phone: '+234' },
  { code: 'NI', country: 'Nicaragua', phone: '+505' },
  { code: 'NL', country: 'Netherlands', phone: '+31' },
  { code: 'NO', country: 'Norway', phone: '+47' },
  { code: 'NP', country: 'Nepal', phone: '+977' },
  { code: 'NR', country: 'Nauru', phone: '+674' },
  { code: 'NU', country: 'Niue', phone: '+683' },
  { code: 'NZ', country: 'New Zealand', phone: '+64' },
  { code: 'OM', country: 'Oman', phone: '+968' },
  { code: 'PA', country: 'Panama', phone: '+507' },
  { code: 'PE', country: 'Peru', phone: '+51' },
  { code: 'PF', country: 'French Polynesia', phone: '+689' },
  { code: 'PG', country: 'Papua New Guinea', phone: '+675' },
  { code: 'PH', country: 'Philippines', phone: '+63' },
  { code: 'PK', country: 'Pakistan', phone: '+92' },
  { code: 'PL', country: 'Poland', phone: '+48' },
  {
    code: 'PM',
    country: 'Saint Pierre and Miquelon',
    phone: '+508',
  },
  { code: 'PN', country: 'Pitcairn', phone: '+870' },
  { code: 'PR', country: 'Puerto Rico', phone: '+1' },
  {
    code: 'PS',
    country: 'Palestine, State of',
    phone: '+970',
  },
  { code: 'PT', country: 'Portugal', phone: '+351' },
  { code: 'PW', country: 'Palau', phone: '+680' },
  { code: 'PY', country: 'Paraguay', phone: '+595' },
  { code: 'QA', country: 'Qatar', phone: '+974' },
  { code: 'RE', country: 'Reunion', phone: '+262' },
  { code: 'RO', country: 'Romania', phone: '+40' },
  { code: 'RS', country: 'Serbia', phone: '+381' },
  { code: 'RU', country: 'Russian Federation', phone: '+7' },
  { code: 'RW', country: 'Rwanda', phone: '+250' },
  { code: 'SA', country: 'Saudi Arabia', phone: '+966' },
  { code: 'SB', country: 'Solomon Islands', phone: '+677' },
  { code: 'SC', country: 'Seychelles', phone: '+248' },
  { code: 'SD', country: 'Sudan', phone: '+249' },
  { code: 'SE', country: 'Sweden', phone: '+46' },
  { code: 'SG', country: 'Singapore', phone: '+65' },
  { code: 'SH', country: 'Saint Helena', phone: '+290' },
  { code: 'SI', country: 'Slovenia', phone: '+386' },
  {
    code: 'SJ',
    country: 'Svalbard and Jan Mayen',
    phone: '+47',
  },
  { code: 'SK', country: 'Slovakia', phone: '+421' },
  { code: 'SL', country: 'Sierra Leone', phone: '+232' },
  { code: 'SM', country: 'San Marino', phone: '+378' },
  { code: 'SN', country: 'Senegal', phone: '+221' },
  { code: 'SO', country: 'Somalia', phone: '+252' },
  { code: 'SR', country: 'Suriname', phone: '+597' },
  { code: 'SS', country: 'South Sudan', phone: '+211' },
  {
    code: 'ST',
    country: 'Sao Tome and Principe',
    phone: '+239',
  },
  { code: 'SV', country: 'El Salvador', phone: '+503' },
  {
    code: 'SX',
    country: 'Sint Maarten (Dutch part)',
    phone: '+1-721',
  },
  {
    code: 'SY',
    country: 'Syrian Arab Republic',
    phone: '+963',
  },
  { code: 'SZ', country: 'Swaziland', phone: '+268' },
  {
    code: 'TC',
    country: 'Turks and Caicos Islands',
    phone: '+1-649',
  },
  { code: 'TD', country: 'Chad', phone: '+235' },
  {
    code: 'TF',
    country: 'French Southern Territories',
    phone: '+262',
  },
  { code: 'TG', country: 'Togo', phone: '+228' },
  { code: 'TH', country: 'Thailand', phone: '+66' },
  { code: 'TJ', country: 'Tajikistan', phone: '+992' },
  { code: 'TK', country: 'Tokelau', phone: '+690' },
  { code: 'TL', country: 'Timor-Leste', phone: '+670' },
  { code: 'TM', country: 'Turkmenistan', phone: '+993' },
  { code: 'TN', country: 'Tunisia', phone: '+216' },
  { code: 'TO', country: 'Tonga', phone: '+676' },
  { code: 'TR', country: 'Turkey', phone: '+90' },
  {
    code: 'TT',
    country: 'Trinidad and Tobago',
    phone: '+1-868',
  },
  { code: 'TV', country: 'Tuvalu', phone: '+688' },
  {
    code: 'TW',
    country: 'Taiwan',
    phone: '+886',
  },
  {
    code: 'TZ',
    country: 'United Republic of Tanzania',
    phone: '+255',
  },
  { code: 'UA', country: 'Ukraine', phone: '+380' },
  { code: 'UG', country: 'Uganda', phone: '+256' },
  {
    code: 'US',
    country: 'United States',
    phone: '+1',
    suggested: true,
  },
  { code: 'UY', country: 'Uruguay', phone: '+598' },
  { code: 'UZ', country: 'Uzbekistan', phone: '+998' },
  {
    code: 'VA',
    country: 'Holy See (Vatican City State)',
    phone: '+379',
  },
  {
    code: 'VC',
    country: 'Saint Vincent and the Grenadines',
    phone: '+1-784',
  },
  { code: 'VE', country: 'Venezuela', phone: '+58' },
  {
    code: 'VG',
    country: 'British Virgin Islands',
    phone: '+1-284',
  },
  {
    code: 'VI',
    country: 'US Virgin Islands',
    phone: '+1-340',
  },
  { code: 'VN', country: 'Vietnam', phone: '+84' },
  { code: 'VU', country: 'Vanuatu', phone: '+678' },
  { code: 'WF', country: 'Wallis and Futuna', phone: '+681' },
  { code: 'WS', country: 'Samoa', phone: '+685' },
  { code: 'XK', country: 'Kosovo', phone: '+383' },
  { code: 'YE', country: 'Yemen', phone: '+967' },
  { code: 'YT', country: 'Mayotte', phone: '+262' },
  { code: 'ZA', country: 'South Africa', phone: '+27' },
  { code: 'ZM', country: 'Zambia', phone: '+260' },
  { code: 'ZW', country: 'Zimbabwe', phone: '+263' },
];