import {forwardRef,useRef} from 'react';
import {Dialog,Button,DialogTitle,DialogContent,DialogActions,Box,Stack,TextField,Typography} from '@mui/material';
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {field_label} from '../utilities/DataDictionary';
import ErrorLogList from './ErrorLog/ErrorLogList';

const PaperComponent=forwardRef(function PaperComponent(props,ref){
    return (
        <Draggable handle="#draggable-dialog-record-info" nodeRef={ref}>
            <Paper ref={ref} {...props} />
        </Draggable>
    );
});

const RecordInfo=({recordInfoCallback,row,entity_name,globalData,userDataAuthorization})=>{
    const nodeRef=useRef(null);
    const handleClickClose=()=>{
        recordInfoCallback();
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={true}
            aria-labelledby="draggable-dialog-record-info"
            PaperComponent={(props)=><PaperComponent ref={nodeRef} {...props}/>}
            onClose={handleClickClose}>
            <DialogTitle id="draggable-dialog-record-info" style={{cursor:'move'}}>Information about the Record</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off">
                    <Stack direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                        <TextField
                            style={{marginTop:"0px"}}
                            InputProps={{readOnly:true}}
                            id="created_by"
                            name="created_by"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={field_label.record_info.created_by}
                            value={row.created_by}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="created_at_ui"
                            name="created_at_ui"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={field_label.record_info.created_at}
                            value={row.created_at_ui}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="id"
                            name="id"
                            fullWidth
                            variant="filled"
                            size="small"
                            label="Id"
                            value={row.id}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'5px',paddingBottom:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                        <TextField
                            style={{marginTop:"0px"}}
                            InputProps={{readOnly:true}}
                            id="updated_by"
                            name="updated_by"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={field_label.record_info.updated_by}
                            value={row.updated_by}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="updated_at_ui"
                            name="updated_at_ui"
                            fullWidth
                            variant="filled"
                            size="small"
                            label={field_label.record_info.updated_at}
                            value={row.updated_at_ui}
                            margin="normal"/>
                        <TextField
                            InputProps={{readOnly:true}}
                            id="entity_name"
                            name="entity_name"
                            fullWidth
                            variant="filled"
                            size="small"
                            label="Entity Name"
                            value={entity_name}
                            margin="normal"/>
                    </Stack>
                    <Typography variant="subtitle1">Record Log</Typography>
                    <ErrorLogList record_id={row.id} entity_name={entity_name} globalData={globalData} userDataAuthorization={userDataAuthorization}></ErrorLogList>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className="btn" onClick={handleClickClose} variant="contained">Close</Button>
             </DialogActions>
        </Dialog>
    );
}

export default RecordInfo;
