//import {makeStyles} from "@material-ui/core";
import {time_to_date_ui,generate_order_date,get_order_date} from './Utilities'
import dayjs from 'dayjs';

export const listPreDefaultFilter={
    accounts:{
        items:[
            {
                field:'status',
                operator:'isAnyOf',
                value:['Active','INVOICING_INTEGRATION_ERROR','LOGISTICS_INTEGRATION_ERROR']
            },
        ],
    },
    products:{
        items:[
            {
                field:'status',
                operator:'equals',
                value:'Active',
            },
        ],
    },
    sales_orders:{
        items:[
            {
                field:'status',
                operator:'isAnyOf',
                value:['Invoiced','Overdue','Payment Reminder Sent','On Debit Collection','Paid','Invalidated','No Invoicing','Submitted','Draft','Active','INVOICING_INTEGRATION_ERROR','LOGISTICS_INTEGRATION_ERROR'],
            },
        ],
    },
    skus:{
        items:[
            {
                field:'status',
                operator:'isAnyOf',
                value:['Active','INVOICING_INTEGRATION_ERROR','LOGISTICS_INTEGRATION_ERROR'],
            },
        ],
    },
    sku_sales_order_item:{
        items:[
            {
                field:'order_status',
                operator:'isAnyOf',
                value:['Submitted','Draft','Active','INVOICING_INTEGRATION_ERROR','LOGISTICS_INTEGRATION_ERROR'],
            },
        ],
    },
}

export const listPreDefaultSorting={
    //No Pre Defaults So Far!
    /*
    accounts:[{
        field:'name',
        sort:'asc',
    }],
    */
}

export const field_label={
    record_info:{
        created_by:"Created By",
        created_at:"Created",
        updated_by:"Last Updated By",
        updated_at:"Last Updated",
    },
    list_of_values:{
        id:"Id",
        type:"Type",
        value:"Value",
        status:"Status",
        sorting_order:"Sorting Order"
    }
}

export const status_color={
    'Active':'darkgreen',
    'Submitted':'darkgreen',
    'Pending':'darkorange',
    'Inactive':'darkred',
    'Canceled':'darkred',
    'Draft':'darkorange',
    'Single':'darkblue',
    'Multipack':'purple',
    'INTEGRATION_ERROR':'darkred',
    'INVOICING_INTEGRATION_ERROR':'darkred',
    'LOGISTICS_INTEGRATION_ERROR':'darkred',
    'Overdue':'darkred',
    'Payment Reminder Sent':'darkred',
    'On Debit Collection':'darkred',
    'Paid':'darkblue'
}

const color_blue='darkblue';
const background_color_blue='rgb(178 178 232 / 11%)';
const color_green='green';
const background_color_green='#caf4ca24';
const color_orange='darkorange';
const background_color_orange='rgb(246 132 31 / 9%)';
const color_red='darkred';
const background_color_red='#ffc0cb26';
const color_purple='purple';
const background_color_purple='rgb(233 192 233 / 11%)';
const color_gold='#c6a700';
const background_color_gold='#f6ebb233';
const default_delivery_start_time='10:00';
const default_delivery_end_time='18:00';

export const entity_object={
    account:{
        style:{
            finance_color:color_blue,
            finance_background_color:background_color_blue,
            tax_color:color_green,
            tax_background_color:background_color_green,
            invoice_color:color_gold,
            invoice_background_color:background_color_gold,
            delivery_color:color_orange,
            delivery_background_color:background_color_orange,
            billing_address_color:color_red,
            billing_address_background_color:background_color_red,
            shipping_address_color:color_purple,
            shipping_address_background_color:background_color_purple
        },
        /*
        useStyles:makeStyles((theme)=>({
            finance:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_blue
                  },
                  "&::after":{
                    borderTop:"solid "+color_blue
                  }
                }
            },
            tax:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_green
                  },
                  "&::after":{
                    borderTop:"solid "+color_green
                  }
                }
            },
            invoice:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_gold
                  },
                  "&::after":{
                    borderTop:"solid "+color_gold
                  }
                }
            },
            delivery:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_orange
                  },
                  "&::after":{
                    borderTop:"solid "+color_orange
                  }
                }
            },
            billing_address:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_red
                  },
                  "&::after":{
                    borderTop:"solid "+color_red
                  }
                }
            },
            shipping_address:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_purple
                  },
                  "&::after":{
                    borderTop:"solid "+color_purple
                  }
                }
            }
        })),
        */
        fields:{
            id:{
                label:'Id',
                default_value_get:'',
                null_to:''
            },
            name:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            legal_name:{
                label:'Legal Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            financing_agreement_id:{
                label:'Financing Agreement Id',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            category:{
                label:'Category',
                ui_type:'text',
                null_to:null,
                default_value_new:null,
                default_value_get:null,
                events:{
                    onChange:function(value,handleChangeList){
                        handleChangeList(null,'sub_category');
                    }
                },
                
            },
            sub_category:{
                label:'Sub Category',
                ui_type:'text',
                null_to:null,
                default_value_new:null,
                default_value_get:null,
                readonly:function(row,globalData){
                    return (row.category===null)||!(row.category in globalData.listOfValuesRel.ACCOUNT_CATEGORY);
                },
                required:function(row){
                    return false;
                },
            },
            phone:{
                label:'Phone',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            language:{
                label:'Language',
                ui_type:'text',
                default_value_new:'English',
                default_value_get:null
            },
            status:{
                label:'Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'Active',
                default_value_get:null
            },
            website:{
                label:'Website',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            business_id:{
                label:'Business Id',
                ui_type:'text',
                null_to:'',
                required:true,
                default_value_new:'',
                default_value_get:'',
            },
            payment_terms:{
                label:'Payment Terms (days)',
                ui_type:'number',
                //null_to:'',
                empty_to:null,
                default_value_new:7,
                default_value_get:0,
                required:true,
            },
            type:{
                label:'Type',
                null_to:[],
                default_value_new:[],
                default_value_get:[],
            },
            bank_account:{
                label:'Bank Account',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            bank_bic:{
                label:'Bank BIC',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            excise_number:{
                label:'Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            warehouse_excise_number:{
                label:'Warehouse Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            palpa_free:{
                label:'Palpa Free',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            no_invoicing:{
                label:'No Invoicing',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            export:{
                label:'Export',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            tax_free:{
                label:'Alcohol Tax Free',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
                drive_dynamic_required:true,
                events:{
                    onChange:function(value,handleChangeList){
                        switch(value){
                            case false:
                                handleChangeList(null,'tax_free_reason_code');
                                break;
                            default:
                                break;
                        }
                    }
                },
                readonly:function(row){
                    return row.export;
                },
            },
            tax_free_reason_code:{
                label:'Tax Free Reason Code',
                ui_type:'text',
                default_value_new:null,
                default_value_get:null,
                required:function(row){
                    return row.tax_free;
                },
                readonly:function(row){
                    return !row.tax_free;;
                },
            },
            invoice_channel:{
                label:'Invoice Channel',
                ui_type:'text',
                default_value_new:null,
                default_value_get:null,
                required:true,
                drive_dynamic_required:true,
                events:{
                    onChange:function(value,handleChangeList){
                        switch(value){
                            case "E-Mail":
                                handleChangeList('','invoice_e_number');
                                handleChangeList('','invoice_e_operator');
                                break;
                            case "Mail":
                            case "No Sending":
                                handleChangeList('','invoice_email');
                                handleChangeList('','invoice_e_number');
                                handleChangeList('','invoice_e_operator');
                                break;
                            case "E-Invoice":
                                handleChangeList('','invoice_email');
                                break;
                            default:
                                break;
                        }
                    }
                }
            },
            invoice_email:{
                label:'Invoice Email',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                readonly:function(row){
                    return row.invoice_channel!=='E-Mail';
                },
                required:function(row){
                    return row.invoice_channel==='E-Mail'&&row.invoice_email==='';
                },
            },
            invoice_e_number:{
                label:'E-Invoice Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                readonly:function(row){
                    return row.invoice_channel!=='E-Invoice';
                },
                required:function(row){
                    return row.invoice_channel==='E-Invoice'&&row.invoice_e_number==='';
                },
            },
            invoice_e_operator:{
                label:'E-Invoice Operator',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                readonly:function(row){
                    return row.invoice_channel!=='E-Invoice';
                },
                required:function(row){
                    return row.invoice_channel==='E-Invoice'&&row.invoice_e_number==='';
                },
            },
            invoice_e_edi_id:{
                label:'E-Invoice EDI',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            /*
            delivery_start_time:{
                label:'Delivery Start Time',
                default_value_new:default_delivery_start_time,
                default_value_get:null,
                value__ui:time_to_date_ui(default_delivery_start_time),
            },
            delivery_end_time:{
                label:'Delivery End Time',
                default_value_new:default_delivery_end_time,
                default_value_get:null,
                value__ui:time_to_date_ui(default_delivery_end_time),
            },
            */
            delivery_method:{
                label:'Delivery Method',
                ui_type:'text',
                default_value_new:'CollicoCargo',
                default_value_get:null,
                required:true
            },
            delivery_contact_name:{
                label:'Delivery Contact Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:false
            },
            delivery_contact_phone_country:{
                label:'Delivery Contact Phone Country',
                ui_type:'text',
                default_value_new:'Finland',
                default_value_get:null,
                required:true
            },
            delivery_contact_phone_country_code:{
                label:'Delivery Contact Phone Country Code',
                ui_type:'text',
                null_to:'',
                default_value_new:'+358',
                default_value_get:'',
                required:true
            },
            delivery_contact_phone:{
                label:'Delivery Contact Phone (Only numbers)',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            delivery_contact_email:{
                label:'Delivery Contact Email',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_specifier:{
                label:'Specifier',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_street:{
                label:'Street',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_comments:{
                label:'Comments',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_zip:{
                label:'Zip',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_city:{
                label:'City',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_subdivision:{
                label:'Sub Division',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_country:{
                label:'Country',
                ui_type:'text',
                default_value_new:'Finland',
                default_value_get:null,
            },
            shipping_addr_specifier:{
                label:'Specifier',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_street:{
                label:'Street',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            shipping_addr_comments:{
                label:'Delivery Instructions',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_zip:{
                label:'Zip',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            shipping_addr_city:{
                label:'City',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            shipping_addr_subdivision:{
                label:'Sub Division',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_country:{
                label:'Country',
                ui_type:'text',
                default_value_new:'Finland',
                default_value_get:null,
                required:true
            },
            description:{
                label:'Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
        },
        multi_relationship:["orders"]
    },
    product:{
        fields:{
            id:{
                label:'Id',
                default_value_get:'',
                null_to:''
            },
            name:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            type:{
                label:'Type',
                ui_type:'text',
                //null_to:'',
                default_value_new:'Beer',
                default_value_get:null,
                required:true,
                drive_dynamic_required:true,
                events:{
                    onChange:function(row,value,handleChangeList,handleChange){
                        let event={target:{name:'abv',value:0}}
                        handleChange(event);
                        let tax_group_code=null;
                        let batch_req_for_storage_order=true;
                        if(value==='Beer'){
                            tax_group_code='NAA: Alcoholic Beverages';
                        }
                        else if (value==='Soft Drink'){
                            tax_group_code='NVA: Soft Drinks';
                        }
                        else if (value==='Merchandise'){
                            batch_req_for_storage_order=false;
                        }
                        handleChangeList(tax_group_code,'tax_group_code');
                        handleChangeList(null,'yeast_type');
                        handleChangeList(null,'product_group_code');
                        handleChangeList(null,'customs_tariff_code');
                        
                        event={target:{name:'batch_req_for_storage_order',value:batch_req_for_storage_order}}
                        handleChange(event);
                    }
                }, 
            },
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            status:{
                label:'Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'Active',
                default_value_get:null
            },
            yeast_type:{
                label:'Yeast Type',
                ui_type:'text',
                default_value_new:'Ale',
                default_value_get:null,
                required:function(row){
                    return row.type==='Beer';
                },
                readonly:function(row){
                    return row.type!=='Beer';
                },
            },
            tax_group_code:{
                label:'Tax Group Code of the Excise',
                ui_type:'text',
                default_value_new:'NAA: Alcoholic Beverages',
                default_value_get:null,
                required:function(row){
                    return row.type==='Beer'||row.type==='Soft Drink';
                },
                readonly:function(row){
                    return row.type==='Merchandise';
                },
                events:{
                    onChange:function(row,value,handleChangeList,handleChange){
                        handleChangeList(null,'product_group_code');
                    }
                }, 
            },
            product_group_code:{
                label:'Product Group Code of the Excise',
                ui_type:'text',
                default_value_new:null,
                default_value_get:null,
                required:function(row){
                    return row.type==='Beer'||row.type==='Soft Drink';
                },
                readonly:function(row){
                    return row.type==='Merchandise';
                },
            },
            customs_tariff_code:{
                label:'Custom Tariff Code',
                ui_type:'text',
                default_value_new:'2203: Beer made from malt',
                default_value_get:null,
                required:function(row){
                    return row.type==='Beer'||row.type==='Soft Drink';
                },
                readonly:function(row){
                    return row.type==='Merchandise';
                },
            },
            abv:{
                label:'ABV',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:0,
                default_value_get:'',
                events:{
                    onChange:function(row,value,handleChangeList,handleChange){
                        if(row.type==='Beer'){
                            if(value==null||value==''||value==undefined){
                                handleChangeList(null,'product_group_code');
                            }
                            else if(value>3.5){
                                handleChangeList('1294: Beers above 3.5% ABV, microbreweries with 50% excise discount','product_group_code');
                            }
                            else{
                                handleChangeList('1194: Beers bellow/equal 3.5% ABV, microbreweries with 50% excise discount','product_group_code');
                            }
                        }
                    }
                },
                required:function(row){
                    return row.type==='Beer'||row.type==='Soft Drink';
                },
                readonly:function(row){
                    return row.type==='Merchandise';
                },
            },
            description:{
                label:'Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            description_internal:{
                label:'Internal Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            temperature_min:{
                label:'Min Temperature (°C)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:2,
                default_value_get:'',
                required:true,
            },
            temperature_max:{
                label:'Max Temperature (°C)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:12,
                default_value_get:'',
                required:true,
            },
            min_usage_days:{
                label:'Minimum Usage Days',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:60,
                default_value_get:'',
                required:true,
            },
            track_batches:{
                label:'Track Batches',
                null_to:false,
                default_value_new:true,
                default_value_get:false
            },
            track_expiration_date:{
                label:'Track Expiration Date',
                null_to:false,
                default_value_new:true,
                default_value_get:false
            },
            batch_req_for_storage_order:{
                label:'Batch Required for Storage Orders',
                null_to:false,
                default_value_new:true,
                default_value_get:false,
            },
            categories:{
                label:'Categories',
                null_to:[],
                default_value_new:[],
                default_value_get:[]
            },
            tags:{
                label:'Tags',
                null_to:[],
                default_value_new:[],
                default_value_get:[],
            },
            account_id:{
                default_value_new:'',
                default_value_get:'',
                empty_to:null,
                required:true,
            },
            skus:{
                default_value_get:[]
            },
            batches:{
                default_value_get:[]
            },
            vat:{
                label:'VAT (%)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'25.50',
                default_value_get:'',
                required:true,
            },
        },
        joins:{
            account:{
                name:'account',
                fields:{name:{label:'Manufacturer'}}
            }
        },
        multi_relationship:["skus","batches"]
    },
    sku:{
        style:{
            package_color:color_blue,
            package_background_color:background_color_blue,
            storage_color:color_green,
            storage_background_color:background_color_green,
            palpa_color:color_gold,
            palpa_background_color:background_color_gold,
            price_color:color_orange,
            price_background_color:background_color_orange,
            barcode_color:color_red,
            barcode_background_color:background_color_red,
        },
        /*
        useStyles:makeStyles((theme)=>({
            package:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_blue
                  },
                  "&::after":{
                    borderTop:"solid "+color_blue
                  }
                }
            },
            storage:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_green
                  },
                  "&::after":{
                    borderTop:"solid "+color_green
                  }
                }
            },
            palpa:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_gold
                  },
                  "&::after":{
                    borderTop:"solid "+color_gold
                  }
                }
            },
            price:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_orange
                  },
                  "&::after":{
                    borderTop:"solid "+color_orange
                  }
                }
            },
            barcode:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_red
                  },
                  "&::after":{
                    borderTop:"solid "+color_red
                  }
                }
            }
        })),
        */
        fields:{
            id:{
                label:'Id',
            },
            name:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            type:{
                label:'Type',
                ui_type:'text',
                null_to:'',
                default_value_new:null,
                default_value_get:'',
                required:true,
            },
            status:{
                label:'Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'Active',
                default_value_get:null
            },
            description:{
                label:'Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            description_internal:{
                label:'Internal Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            pack_type:{
                label:'Package Type',
                ui_type:'text',
                default_value_new:null,
                default_value_get:'',
                required:true,
                events:{
                    onChange:function(value,handleChangeList,row){
                        if(row.type==='Single'&&value==='Keg'){
                            handleChangeList('Other','palpa');
                        }
                    }
                },
            },
            /*
            pack_size:{
                label:'Package Size (Quantity)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            */
            pack_unit:{
                label:'Package Unit',
                ui_type:'text',
                default_value_new:null,
                default_value_get:'',
                required:true,
            },
            pack_amount:{
                label:'Package Amount',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
                events:{
                    onChange:function(value,handleChangeList,row){
                        if(row.type==='Multipack'){
                            let updated_value=value*parseFloat(row.parent_sku.palpa_value).toFixed(2)
                            handleChangeList(updated_value.toFixed(2),'palpa_value');
                        }
                    }
                },
            },
            width:{
                label:'Width (cm)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            height:{
                label:'Height (cm)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            length:{
                label:'Length (cm)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            weight:{
                label:'Weigth (Kg)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            price:{
                label:'Price',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            palpa:{
                label:'Palpa',
                ui_type:'text',
                default_value_new:null,
                default_value_get:'',
                drive_dynamic_required:true,
                events:{
                    onChange:function(value,handleChangeList,row){
                        switch(value){
                            case "NoExemption":
                                handleChangeList('0.00','palpa_value');
                                break;
                            case "DepositBasedReturnSystem":
                                let palpa_value='0.00'
                                if(row.pack_type==='Can'){
                                    palpa_value='0.15';
                                }
                                handleChangeList(palpa_value,'palpa_value');
                                break;
                            default:
                                break;
                        }
                    }
                },
                readonly:function(row){
                    return false;
                },
                required:function(row){
                    return true;
                },
                /*
                readonly:function(row){
                    return row.type==='Multipack';
                },
                required:function(row){
                    return row.type==='Single';
                },
                */
            },
            palpa_value:{
                label:'Palpa Value',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'0.00',
                default_value_get:'',
                required:function(row){
                    return true;
                },
                readonly:function(row){
                    return false;
                },
                /*
                required:function(row){
                    return row.palpa!=='NoExemption'&&row.type==='Single';
                },
                readonly:function(row){
                    return row.palpa==='NoExemption'||row.type==='Multipack';
                },
                */
            },
            currency_code:{
                label:'Currency (code)',
                ui_type:'text',
                null_to:'',
                default_value_new:'EUR',
                default_value_get:''
            },
            tags:{
                label:'Tags',
                null_to:[],
                default_value_new:[],
                default_value_get:[],
            },
            parent_sku_id:{
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
                required_bypass:function(row){
                    return row.type==='Single';
                }
            },
            product_id:{
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            gtin_id:{
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            stock_balance:{
                label:'Stock Balance',
                ui_type:'number',
                null_to:'',
                default_value_get:'',
            },
            quarantined_balance:{
                label:'Quarantined Balance',
                ui_type:'number',
                null_to:'',
                default_value_get:'',
            },
            sellable:{
                label:'Sellable',
                null_to:false,
                default_value_new:true,
                default_value_get:false
            },
        },
        joins:{
            gtin:{
                name:'gtin',
                fields:{name:{}}
            },
            product:{
                name:'product',
                fields:{name:{label:'Product'},temperature_min:{},temperature_max:{},track_batches:{},track_expiration_date:{},min_usage_days:{},categories:{},description:{},description_internal:{},tags:{},categories:{},id:{},vat:{}}
            },
            sku:{
                name:'parent_sku',
                fields:{name:{label:'Parent Single SKU'},palpa_value:{}}
            }
        }
    },
    gtin:{
        fields:{
            id:{
                label:'Id',
            },
            name:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            }
        }
    },
    sales_order:{
        style:{
            tax_color:color_green,
            tax_background_color:background_color_green,
            invoice_color:color_gold,
            invoice_background_color:background_color_gold,
            delivery_color:color_orange,
            delivery_background_color:background_color_orange,
            billing_address_color:color_red,
            billing_address_background_color:background_color_red,
            shipping_address_color:color_purple,
            shipping_address_background_color:background_color_purple
        },
        /*
        useStyles:makeStyles((theme)=>({
            tax:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_green
                  },
                  "&::after":{
                    borderTop:"solid "+color_green
                  }
                }
            },
            invoice:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_gold
                  },
                  "&::after":{
                    borderTop:"solid "+color_gold
                  }
                }
            },
            delivery:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_orange
                  },
                  "&::after":{
                    borderTop:"solid "+color_orange
                  }
                }
            },
            billing_address:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_red
                  },
                  "&::after":{
                    borderTop:"solid "+color_red
                  }
                }
            },
            shipping_address:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_purple
                  },
                  "&::after":{
                    borderTop:"solid "+color_purple
                  }
                }
            }
        })),
        */
        fields:{
            id:{
                label:'Id',
                default_value_get:'',
                null_to:''
            },
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            type:{
                label:'Type',
                ui_type:'text',
                null_to:'',
                default_value_new:'Sales',
                default_value_get:''
            },
            status:{
                label:'Order Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'Draft',
                default_value_get:''
            },
            payment_terms:{
                label:'Payment Terms (days)',
                ui_type:'number',
                //null_to:'',
                empty_to:null,
                default_value_new:7,
                default_value_get:0,
                required:true,
            },
            delivery_status:{
                label:'Delivery Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            account_id:{
                default_value_new:'',
                default_value_get:'',
                empty_to:null,
                required:true,
            },
            price:{
                label:'Total Price (Tax Free)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'0.00',
                default_value_get:''
            },
            price_included_vat:{
                label:'Total Price (VAT Included)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'0.00',
                default_value_get:''
            },
            palpa_value:{
                label:'Total Palpa',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'0.00',
                default_value_get:''
            },
            total_price:{
                label:'Final Price',
                tooltip:'Final Price = Total Price (Tax Free) + Total Palpa + Delivery Price',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'0.00',
                default_value_get:''
            },
            total_price_included_vat:{
                label:'Final Price',
                tooltip:'Final Price = Total Price (VAT Included) + Total Palpa + Delivery Price',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'0.00',
                default_value_get:''
            },
            delivery_price:{
                label:'Delivery Price',
                ui_type:'number',
                null_to:'0.00',
                empty_to:'0.00',
                default_value_new:'0.00',
                default_value_get:'',
                events:{
                    onChange:function(value,handleChangeList,row){
                        if(value===''||value===null||value===undefined){
                            handleChangeList(parseFloat('0.00').toFixed(2),'delivery_price');
                        }
                        else{
                            let delivery_price=(value===''||value===null||value===undefined)?parseFloat('0.00'):parseFloat(value);
                            let total_price=parseFloat(row.price)+delivery_price;
                            let total_price_included_vat=parseFloat(row.price_included_vat)+parseFloat(row.palpa_value)+delivery_price;
                            handleChangeList(parseFloat(total_price).toFixed(2),'total_price');
                            handleChangeList(parseFloat(total_price_included_vat).toFixed(2),'total_price_included_vat');
                        }
                    }
                }
            },
            excise_number:{
                label:'Excise Number',
                ui_type:'text',
                null_to:'',
                required:false,
                default_value_new:'',
                default_value_get:'',
            },
            warehouse_excise_number:{
                label:'Warehouse Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            palpa_free:{
                label:'Palpa Free',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            no_invoicing:{
                label:'No Invoicing',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            export:{
                label:'Export',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            tax_free:{
                label:'Alcohol Tax Free',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
                drive_dynamic_required:true,
                events:{
                    onChange:function(value,handleChangeList){
                        switch(value){
                            case false:
                                handleChangeList(null,'tax_free_reason_code');
                                break;
                            default:
                                break;
                        }
                    }
                },
                readonly:function(row){
                    return row.export;
                },
            },
            tax_free_reason_code:{
                label:'Tax Free Reason Code',
                ui_type:'text',
                default_value_new:null,
                default_value_get:null,
                readonly:function(row){
                    return !row.tax_free;
                },
                required:function(row){
                    return row.tax_free;
                },
            },
            /*
            delivery_start_time:{
                label:'Delivery Start Time',
                default_value_new:default_delivery_start_time,
                default_value_get:null,
                value__ui:time_to_date_ui(default_delivery_start_time),
            },
            delivery_end_time:{
                label:'Delivery End Time',
                default_value_new:default_delivery_end_time,
                default_value_get:null,
                value__ui:time_to_date_ui(default_delivery_end_time),
            },
            */
            date_of_arrival:{
                label:'Order Date',
                ui_type:'date',
                //default_value_new:generate_order_date(),
                default_value_new:'',
                default_value_get:null,
                required:true
            },
            delivery_method:{
                label:'Delivery Method',
                ui_type:'text',
                default_value_new:null,
                default_value_get:null,
                required:true
            },
            delivery_contact_name:{
                label:'Delivery Contact Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:false
            },
            delivery_contact_phone_country:{
                label:'Delivery Contact Phone Country',
                ui_type:'text',
                default_value_new:'Finland',
                default_value_get:null,
                required:true
            },
            delivery_contact_phone_country_code:{
                label:'Delivery Contact Phone Country Code',
                ui_type:'text',
                null_to:'',
                default_value_new:'+358',
                default_value_get:'',
                required:true
            },
            delivery_contact_phone:{
                label:'Delivery Contact Phone (Only numbers)',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            delivery_contact_email:{
                label:'Delivery Contact Email',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            invoice_channel:{
                label:'Invoice Channel',
                ui_type:'text',
                default_value_new:null,
                default_value_get:null,
                required:true,
                drive_dynamic_required:true,
                events:{
                    onChange:function(value,handleChangeList){
                        switch(value){
                            case "E-Mail":
                                handleChangeList('','invoice_e_number');
                                handleChangeList('','invoice_e_operator');
                                break;
                            case "Mail":
                            case "No Sending":
                                handleChangeList('','invoice_email');
                                handleChangeList('','invoice_e_number');
                                handleChangeList('','invoice_e_operator');
                                break;
                            case "E-Invoice":
                                handleChangeList('','invoice_email');
                                break;
                            default:
                                break;
                        }
                    }
                }
            },
            invoice_email:{
                label:'Invoice Email',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                readonly:function(row){
                    return row.invoice_channel!=='E-Mail';
                },
                required:function(row){
                    return row.invoice_channel==='E-Mail'&&row.invoice_email==='';
                },
            },
            invoice_e_number:{
                label:'E-Invoice Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                readonly:function(row){
                    return row.invoice_channel!=='E-Invoice';
                },
                required:function(row){
                    return row.invoice_channel==='E-Invoice'&&row.invoice_e_number==='';
                },
            },
            invoice_e_operator:{
                label:'E-Invoice Operator',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                readonly:function(row){
                    return row.invoice_channel!=='E-Invoice';
                },
                required:function(row){
                    return row.invoice_channel==='E-Invoice'&&row.invoice_e_number==='';
                },
            },
            order_number:{
                label:'Customer Order Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                readonly:function(row){
                    return false;
                },
                required:function(row){
                    return false;
                },
            },
            invoicing_number:{
                label:'Invoice Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            invoicing_date:{
                label:'Invoice Date',
                ui_type:'text',
                null_to:null,
                default_value_new:null,
                default_value_get:null,
            },
            delivery_date:{
                label:'Delivery Date',
                ui_type:'text',
                null_to:null,
                default_value_new:null,
                default_value_get:null,
            },
            logistics_id:{
                label:'logistics_id',
                ui_type:'text',
                null_to:null,
                default_value_new:null,
                default_value_get:null,
            },
            billing_addr_specifier:{
                label:'Specifier',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_street:{
                label:'Street',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:function(row){
                    return (row.invoice_channel==='E-Mail'||row.invoice_channel==='E-Invoice')&&row.billing_addr_street==='';
                },
            },
            billing_addr_comments:{
                label:'Comments',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_zip:{
                label:'Zip',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:function(row){
                    return (row.invoice_channel==='E-Mail'||row.invoice_channel==='E-Invoice')&&row.billing_addr_zip==='';
                },
            },
            billing_addr_city:{
                label:'City',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:function(row){
                    return (row.invoice_channel==='E-Mail'||row.invoice_channel==='E-Invoice')&&row.billing_addr_city==='';
                },
            },
            billing_addr_subdivision:{
                label:'Sub Division',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_country:{
                label:'Country',
                ui_type:'text',
                default_value_new:'Finland',
                default_value_get:null,
            },
            shipping_addr_specifier:{
                label:'Specifier',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_street:{
                label:'Street',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            shipping_addr_comments:{
                label:'Delivery Instructions',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_zip:{
                label:'Zip',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            shipping_addr_city:{
                label:'City',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true
            },
            shipping_addr_subdivision:{
                label:'Sub Division',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_country:{
                label:'Country',
                ui_type:'text',
                default_value_new:'Finland',
                default_value_get:null,
            },
            description:{
                label:'Description (this field is copied to the invoice)',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            account_name:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            account_legal_name:{
                label:'Account Legal Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            account_name_alt:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            excise_number_alt:{
                label:'Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            warehouse_excise_number_alt:{
                label:'Warehouse Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            account_business_id:{
                label:'Business Id',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            account_business_id_alt:{
                label:'Business Id',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
        },
        joins:{
            account:{
                name:'account',
                fields:{name:{label:'Customer'},business_id:{label:'Customer Business Id'}}
            }
        },
    },
    sales_order_item:{
        fields:{
            id:{
                label:'Id',
            },
            sku_id:{
                default_value_new:'',
                default_value_get:'',
                empty_to:null,
            },
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            status:{
                label:'Item Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'Draft',
                default_value_get:''
            },
            quantity:{
                label:'Quantity Ordered',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:1,
                default_value_get:''
            },
            quantity_delivered:{
                label:'Quantity Delivered',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:0,
                default_value_get:'',
                tooltip:'Quantity Delivered at the end of the Order'
            },
            unit_price:{
                label:'Unit Price',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:''
            },
            total_price:{
                label:'Total Price',
                tooltip: 'Quantity x Unit Price',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:''
            },
            final_price:{
                label:'Final Price',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:''
            },
            final_price_included_vat:{
                label:'Final Price (incl. VAT)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:''
            },
            description:{
                label:'Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            sku_name:{
                label:'SKU'
            },
            sku_type:{
                label:'SKU Type'
            },
            vat:{
                label:'VAT (%)',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                tooltip:'Value defined on the Product'
            },
            vat_price:{
                label:'VAT Price',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                tooltip:'Final Price x VAT'
            },
            sku_unit_palpa_value:{
                label:'Palpa/SKU',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                tooltip:'Palpa value per SKU unit'
            },
            sku_total_palpa_value:{
                label:'Palpa Total',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:'',
                default_value_get:'',
                tooltip:'Palpa/SKU x Quantity'
            },
        },
        joins:{
            sku:{
                name:'sku',
                fields:{name:{label:'SKU'}}
            }
        },
    },
    storage_order:{
        style:{
            tax_color:color_green,
            tax_background_color:background_color_green,
            invoice_color:color_gold,
            invoice_background_color:background_color_gold,
        },
        /*
        useStyles:makeStyles((theme)=>({
            tax:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_green
                  },
                  "&::after":{
                    borderTop:"solid "+color_green
                  }
                }
            },
            invoice:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_gold
                  },
                  "&::after":{
                    borderTop:"solid "+color_gold
                  }
                }
            },
            delivery:{
                "&.MuiDivider-root":{
                  "&::before":{
                    borderTop:"solid "+color_orange
                  },
                  "&::after":{
                    borderTop:"solid "+color_orange
                  }
                }
            },
        })),
        */
        fields:{
            id:{
                label:'Id',
                default_value_get:'',
                null_to:''
            },
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            type:{
                label:'Type',
                ui_type:'text',
                null_to:'',
                default_value_new:'Storage',
                default_value_get:''
            },
            status:{
                label:'Order Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'Draft',
                default_value_get:''
            },
            delivery_status:{
                label:'Delivery Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            account_id:{
                default_value_new:'',
                default_value_get:'',
                empty_to:null,
                required:true,
            },
            excise_number:{
                label:'Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            warehouse_excise_number:{
                label:'Warehouse Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            tax_free:{
                label:'Tax Free',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            pre_sales:{
                label:'Pre Sales',
                null_to:false,
                default_value_new:false,
                default_value_get:false,
            },
            /*
            tax_free_reason_code:{
                label:'Tax Free Reason Code',
                ui_type:'text',
                default_value_new:null,
                default_value_get:null,
                readonly:function(row){
                    return !row.tax_free;
                },
                required:function(row){
                    return row.tax_free;
                },
            },
            */
            description:{
                label:'Internal Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            date_of_arrival:{
                label:'Estimated Arrival Date',
                ui_type:'date',
                default_value_new:'',
                default_value_get:null,
                required:true
            },
            billing_addr_specifier:{
                label:'Specifier',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_street:{
                label:'Street',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_zip:{
                label:'Zip',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            billing_addr_city:{
                label:'City',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_specifier:{
                label:'Specifier',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_street:{
                label:'Street',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_zip:{
                label:'Zip',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            shipping_addr_city:{
                label:'City',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            account_name:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            account_name_alt:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            excise_number_alt:{
                label:'Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            warehouse_excise_number_alt:{
                label:'Warehouse Excise Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            account_business_id:{
                label:'Business Id',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
            account_business_id_alt:{
                label:'Business Id',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
            },
        },
        joins:{
            account:{
                name:'account',
                fields:{name:{label:'Supplier'}}
            }
        },
    },
    storage_order_item:{
        fields:{
            id:{
                label:'Id',
            },
            sku_id:{
                default_value_new:'',
                default_value_get:'',
                empty_to:null,
            },
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            status:{
                label:'Item Status',
                ui_type:'text',
                null_to:'',
                default_value_new:'Draft',
                default_value_get:''
            },
            quantity:{
                label:'Send Quantity',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:1,
                default_value_get:''
            },
            quantity_delivered:{
                label:'Quantity Delivered',
                ui_type:'number',
                null_to:'',
                empty_to:null,
                default_value_new:0,
                default_value_get:'',
                tooltip:'Quantity Delivered at the end of the Storage Order'
            },
            batch_number:{
                label:'Batch Number',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            production_date:{
                label:'Production Date',
                default_value_new:'',
                default_value_get:null,
            },
            expiration_date:{
                label:'Expiration Date',
                default_value_new:'',
                default_value_get:null,
            },
            description:{
                label:'Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            sku_name:{
                label:'SKU'
            },
            sku_type:{
                label:'SKU Type'
            },
        },
        joins:{
            sku:{
                name:'sku',
                fields:{name:{label:'SKU'}}
            }
        },
    },
    user:{
        fields:{
            id:{
                label:'Id',
            },
            name:{
                label:'Name',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            login:{
                label:'Login',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            email:{
                label:'E-Mail',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:'',
                required:true,
            },
            phone:{
                label:'Phone',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            }
        }
    },
    report:{
        fields:{
            type:{
                label:'Report Type',
                ui_type:'text',
                default_value_new:'Palpa',
                default_value_get:null
            },
            start_date:{
                label:'Start Date (Greater than or Equal)',
                ui_type:'date',
                default_value_new:dayjs(new Date()).subtract(1,'month').startOf('month'),
            },
            end_date:{
                label:'End Date (Lower than)',
                ui_type:'date',
                default_value_new:dayjs(new Date()).startOf('month'),
            },
        }
    },
    batch:{
        fields:{
            reference:{
                label:'Reference',
                ui_type:'text',
                null_to:'',
                default_value_new:'(auto generated)',
                default_value_get:''
            },
            quantity:{
                label:'Quantity',
                    ui_type:'number',
                    null_to:'',
                    empty_to:null,
                    default_value_new:0,
                    default_value_get:''
            },
            production_date:{
                label:'Production Date',
                default_value_new:'',
                default_value_get:null,
            },
            expiration_date:{
                label:'Expiration Date',
                default_value_new:'',
                default_value_get:null,
            },
            description:{
                label:'Description',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
        }
    },
    error_log:{
        fields:{
            id:{
                label:'Id',
            },
            created_at:{
                label:'Date Time',
            },
            created_by:{
                label:'User',
            },
            source:{
                label:'Source',
            },
            level:{
                label:'Level',
            },
            status:{
                label:'Status',
            },
            message:{
                label:'Description',
            },
            stack_trace:{
                label:'Strack Trace',
            },
            request_data:{
                label:'Integration Data',
            },
        }
    },
    alcohol_license_refresh_info:{
        fields:{
            id:{
                label:'Id',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
            created_at:{
                label:'Date',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:null,
            },
            refresh_information:{
                label:'Refresh Information',
                ui_type:'text',
                null_to:'',
                default_value_new:'',
                default_value_get:''
            },
        }
    },
}

export const country_code_3={
'Afghanistan':'AFG',
'Alland Islands':'ALA',
'Albania':'ALB',
'Algeria':'DZA',
'American Samoa':'ASM',
'Andorra':'AND',
'Angola':'AGO',
'Anguilla':'AIA',
'Antarctica':'ATA',
'Antigua and Barbuda':'ATG',
'Argentina':'ARG',
'Armenia':'ARM',
'Aruba':'ABW',
'Australia':'AUS',
'Austria':'AUT',
'Azerbaijan':'AZE',
'Bahamas':'BHS',
'Bahrain':'BHR',
'Bangladesh':'BGD',
'Barbados':'BRB',
'Belarus':'BLR',
'Belgium':'BEL',
'Belize':'BLZ',
'Benin':'BEN',
'Bermuda':'BMU',
'Bhutan':'BTN',
'Bolivia':'BOL',
'Bosnia and Herzegovina':'BIH',
'Botswana':'BWA',
'Bouvet Island':'BVT',
'Brazil':'BRA',
'British Indian Ocean Territory':'IOT',
'British Virgin Islands':'VGB',
'Brunei Darussalam':'BRN',
'Bulgaria':'BGR',
'Burkina Faso':'BFA',
'Burundi':'BDI',
'Cambodia':'KHM',
'Cameroon':'CMR',
'Canada':'CAN',
'Cape Verde':'CPV',
'Cayman Islands':'CYM',
'Central African Republic':'CAF',
'Chad':'TCD',
'Chile':'CHL',
'China':'CHN',
'Christmas Island':'CXR',
'Cocos (Keeling) Islands':'CCK',
'Colombia':'COL',
'Comoros':'COM',
'Congo, Democratic Republic of the':'COD',
'Congo, Republic of the':'COG',
'Cook Islands':'COK',
'Costa Rica':'CRI',
"Cote d'Ivoire":'CIV',
'Croatia':'HRV',
'Cuba':'CUB',
'Curacao':'CUW',
'Cyprus':'CYP',
'Czech Republic':'CZE',
'Denmark':'DNK',
'Djibouti':'DJI',
'Dominica':'DMA',
'Dominican Republic':'DOM',
'Ecuador':'ECU',
'Egypt':'EGY',
'El Salvador':'SLV',
'Equatorial Guinea':'GNQ',
'Eritrea':'ERI',
'Estonia':'EST',
'Ethiopia':'ETH',
'Falkland Islands (Malvinas)':'FLK',
'Faroe Islands':'FRO',
'Fiji':'FJI',
'Finland':'FIN',
'France':'FRA',
'French Guiana':'GUF',
'French Polynesia':'PYF',
'French Southern Territories':'ATF',
'Gabon':'GAB',
'Gambia':'GMB',
'Georgia':'GEO',
'Germany':'DEU',
'Ghana':'GHA',
'Gibraltar':'GIB',
'Greece':'GRC',
'Greenland':'GRL',
'Grenada':'GRD',
'Guadeloupe':'GLP',
'Guam':'GUM',
'Guatemala':'GTM',
'Guernsey':'GGY',
'Guinea':'GIN',
'Guinea-Bissau':'GNB',
'Guyana':'GUY',
'Haiti':'HTI',
'Heard Island and McDonald Islands':'HMD',
'Holy See (Vatican City State)':'VAT',
'Honduras':'HND',
'Hong Kong':'HKG',
'Hungary':'HUN',
'Iceland':'ISL',
'India':'IND',
'Indonesia':'IDN',
'Iran, Islamic Republic of':'IRN',
'Iraq':'IRQ',
'Ireland':'IRL',
'Isle of Man':'IMN',
'Israel':'ISR',
'Italy':'ITA',
'Jamaica':'JAM',
'Japan':'JPN',
'Jersey':'JEY',
'Jordan':'JOR',
'Kazakhstan':'KAZ',
'Kenya':'KEN',
'Kiribati':'KIR',
"Korea, Democratic People's Republic of":'PRK',
'Korea, Republic of':'KOR',
'Kuwait':'KWT',
'Kyrgyzstan':'KGZ',
"Lao People's Democratic Republic":'LAO',
'Latvia':'LVA',
'Lebanon':'LBN',
'Lesotho':'LSO',
'Liberia':'LBR',
'Libya':'LBY',
'Liechtenstein':'LIE',
'Lithuania':'LTU',
'Luxembourg':'LUX',
'Macao':'MAC',
'Macedonia, the Former Yugoslav Republic of':'MKD',
'Madagascar':'MDG',
'Malawi':'MWI',
'Malaysia':'MYS',
'Maldives':'MDV',
'Mali':'MLI',
'Malta':'MLT',
'Marshall Islands':'MHL',
'Martinique':'MTQ',
'Mauritania':'MRT',
'Mauritius':'MUS',
'Mayotte':'MYT',
'Mexico':'MEX',
'Micronesia, Federated States of':'FSM',
'Moldova, Republic of':'MDA',
'Monaco':'MCO',
'Mongolia':'MNG',
'Montenegro':'MNE',
'Montserrat':'MSR',
'Morocco':'MAR',
'Mozambique':'MOZ',
'Myanmar':'MMR',
'Namibia':'NAM',
'Nauru':'NRU',
'Nepal':'NPL',
'Netherlands':'NLD',
'New Caledonia':'NCL',
'New Zealand':'NZL',
'Nicaragua':'NIC',
'Niger':'NER',
'Nigeria':'NGA',
'Niue':'NIU',
'Norfolk Island':'NFK',
'Northern Mariana Islands':'MNP',
'Norway':'NOR',
'Oman':'OMN',
'Pakistan':'PAK',
'Palau':'PLW',
'Palestine, State of':'PSE',
'Panama':'PAN',
'Papua New Guinea':'PNG',
'Paraguay':'PRY',
'Peru':'PER',
'Philippines':'PHL',
'Pitcairn':'PCN',
'Poland':'POL',
'Portugal':'PRT',
'Puerto Rico':'PRI',
'Qatar':'QAT',
'Reunion':'REU',
'Romania':'ROU',
'Russian Federation':'RUS',
'Rwanda':'RWA',
'Saint Barthelemy':'BLM',
'Saint Helena':'SHN',
'Saint Kitts and Nevis':'KNA',
'Saint Lucia':'LCA',
'Saint Martin (French part)':'MAF',
'Saint Pierre and Miquelon':'SPM',
'Saint Vincent and the Grenadines':'VCT',
'Samoa':'WSM',
'San Marino':'SMR',
'Sao Tome and Principe':'STP',
'Saudi Arabia':'SAU',
'Senegal':'SEN',
'Serbia':'SRB',
'Seychelles':'SYC',
'Sierra Leone':'SLE',
'Singapore':'SGP',
'Sint Maarten (Dutch part)':'SXM',
'Slovakia':'SVK',
'Slovenia':'SVN',
'Solomon Islands':'SLB',
'Somalia':'SOM',
'South Africa':'ZAF',
'South Georgia and the South Sandwich Islands':'SGS',
'South Sudan':'SSD',
'Spain':'ESP',
'Sri Lanka':'LKA',
'Sudan':'SDN',
'Suriname':'SUR',
'Svalbard and Jan Mayen':'SJM',
'Sweden':'SWE',
'Switzerland':'CHE',
'Syrian Arab Republic':'SYR',
'Taiwan':'TWN',
'Tajikistan':'TJK',
'United Republic of Tanzania':'TZA',
'Thailand':'THA',
'Timor-Leste':'TLS',
'Togo':'TGO',
'Tokelau':'TKL',
'Tonga':'TON',
'Trinidad and Tobago':'TTO',
'Tunisia':'TUN',
'Turkey':'TUR',
'Turkmenistan':'TKM',
'Turks and Caicos Islands':'TCA',
'Tuvalu':'TUV',
'Uganda':'UGA',
'Ukraine':'UKR',
'United Arab Emirates':'ARE',
'United Kingdom':'GBR',
'US Virgin Islands':'VIR',
'United States':'USA',
'Uruguay':'URY',
'Uzbekistan':'UZB',
'Vanuatu':'VUT',
'Venezuela':'VEN',
'Vietnam':'VNM',
'Wallis and Futuna':'WLF',
'Western Sahara':'ESH',
'Yemen':'YEM',
'Zambia':'ZMB',
'Zimbabwe':'ZWE',
}
