import {useRef,useEffect,useState} from "react";
import {Dialog,Button,DialogTitle,DialogContent,DialogActions,Box,Stack,TextField,Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
//import Paper from "@material-ui/core/Paper";
import Paper from "@mui/material/Paper";
//import Draggable from 'react-draggable';
import ReactToPrint from "react-to-print";
import {adjustDateLocale,DefaultConfig} from '../../utilities/Utilities.js';
import Table from '@mui/material/Table';
import {getDBList,getDBItem} from '../../integration/DatabaseManager.js';
import TableBody from '@mui/material/TableBody';
import TableCell,{tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell=styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.head}`]:{
        backgroundColor:'#d3d3d3b5',
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]:{
      fontSize:14,
    },
}));
  
const StyledTableRow=styled(TableRow)(({theme})=>({
    '&:nth-of-type(odd)':{
        backgroundColor:theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th':{
      border:0,
    },
}));

const LABEL={
    header_1:'SAATEASIAKIRJA (VEROTON SIIRTO)',
    header_2:'SAATEASIAKIRJA VALMISTEVEROTTOMIIN VARASTOSIIRTOIHIN',
    reference:'Ref. No',
    printed_date:'Printed',
    sender:'LÄHETTÄJÄ / SENDER',
    name_address:'NIMI JA OSOITE / NAME AND ADDRESS',
    receiver:'VASTAANOTTAJA / RECEIVER',
    business_id:'Y-TUNNUS / BUSINESS ID',
    excise_number:'VALMISTEVERONUMERO / EXCISE NUMBER',
    warehouse_excise_number:'VARASTONUMERO / AUTHORISED WAREHOUSE KEEPER NUMBER',
    product:'TUOTE / PRODUCT',
    gtin:'EAN-KOODI',
    quantity:'kpl /pcs',
    pallets:'LAVAA / PALLETS',
    total:'YHTEENSÄ / TOTAL',
    header_sender_sign:'LÄHETETTY Viikkista',
    header_receiver_sign:'VASTAANOTETTU KERAVALLA',
    signatura_date:'(signature and date)',
    footer:'Vastaanottajaa pyydetään säilyttämään tämä dokumentti'
};

const rowstest=(n)=>{
    let data=[];
    for(let i=1;i<=n;i++){
        data.push({id:i,product:'product '+i,gtin:'0000000000'+i,quantity:i});
    }
    return data;
}

const PaperComponent=(props)=>{
    const nodeRef=useRef(null);
    return (
        <Paper style={{height:'95vh'}} ref={nodeRef} {...props} />
    );
  }

const StorageOrderShippingReport=({shippingReportCallback,row})=>{
    const ref=useRef();
    const [rows,setRows]=useState([]);
    const [total,setTotal]=useState(0);
    
    const handleClickClose=()=>{
        shippingReportCallback();
    }

    const handleClickPrint=()=>{
        shippingReportCallback();
    }

    useEffect(()=>{
        if(row&&Object.keys(row).length>0){
            getRecords();
        }
    },[]);

    const getRecords=()=>{
        let consolidated_row=[];
        let total_temp=0;
        getDBList({entityType:'OrderItem',entityParams:['order_id='+row.id]})
        .then((data)=>{
            if('error' in data){console.log('error',data.error);} 
            else{
                if(data.length>0){
                    console.log('data',data);
                    data.forEach(element=>{
                        //element.gtin_name=element.sku_gtin_name;
                        if(element.sku_type==='Multipack'){
                            getDBItem({entityType:'SKU',entityId:element.sku_parent_sku_id})
                            .then((data)=>{
                                if('error' in data){
                                    console.log('error',data.error);
                                }
                                else{
                                    if(data!=null){
                                        console.log('data parent',JSON.parse(JSON.stringify(data)));
                                        //lement.gtin_name=data.gtin.name;
                                        element.quantity=parseInt(element.sku_pack_amount)*element.quantity;
                                        consolidated_row.push(element);
                                        total_temp=total_temp+element.quantity;
                                        setTotal(old=>total_temp);
                                        setRows(old=>consolidated_row);
                                    }
                                    else{
                                        console.log('Record not found or not accessible: ',element.sku_parent_sku_id);
                                    }
                                }
                            });
                        }
                        else{
                            //element.gtin_name=element.sku_gtin_name;
                            consolidated_row.push(element);
                            total_temp=total_temp+element.quantity;
                            setTotal(old=>total_temp);
                            setRows(old=>consolidated_row);
                        }
                    });
                    
                }
                else{
                    setRows(old=>[]);
                }
            }
        });
    };

    return (
        <Dialog fullWidth={true} maxWidth={'lg'} open={true} PaperComponent={PaperComponent} onClose={handleClickClose}>
            <div style={{marginLeft:"20px",marginRight:"20px",marginTop:"20px",marginBottom:"20px"}} ref={ref}>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off">
                        <Stack direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                            <Typography style={{width:"70%"}} variant="h5">{LABEL.header_1}</Typography>
                            <TextField
                                style={{width:"15%"}}
                                InputProps={{readOnly:true}}
                                id="reference"
                                name="reference"
                                fullWidth
                                size="small"
                                label={LABEL.reference}
                                value={row.reference}
                                margin="normal"/>
                            <TextField
                                style={{width:"15%"}}
                                InputProps={{readOnly:true}}
                                id="reference"
                                name="reference"
                                fullWidth
                                size="small"
                                label={LABEL.printed_date}
                                value={adjustDateLocale(new Date()).split(" ")[0]}
                                margin="normal"/>
                        </Stack>
                        <Stack style={{marginTop:"10px"}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                            <Typography variant="h6">{LABEL.header_2}</Typography>
                        </Stack>
                        <Stack style={{marginTop:"10px"}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                            <Stack style={{marginTop:"10px",width:"50%"}} direction={{xs:'column',sm:'column'}} spacing={{xs:1,sm:1,md:1}}>
                                <Typography variant="h6">{LABEL.sender}</Typography>
                                <TextField
                                    InputProps={{readOnly:true}}
                                    multiline={true}
                                    rows={3}
                                    id="sender"
                                    name="sender"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.name_address}
                                    value={row.account_name+'\n'+row.billing_addr_specifier+', '+row.billing_addr_street+'\n'+row.billing_addr_zip+', '+row.billing_addr_city}
                                    margin="normal"/>
                                <TextField
                                    style={{marginTop:"15px"}}
                                    sx={{label:{fontSize:'0.85rem'}}}
                                    InputProps={{readOnly:true}}
                                    id="sender_business_id"
                                    name="sender_business_id"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.business_id}
                                    value={row.account_business_id}
                                    margin="normal"/>
                                <TextField
                                    style={{marginTop:"15px"}}
                                    sx={{label:{fontSize:'0.85rem'}}}
                                    InputProps={{readOnly:true}}
                                    id="sender_excise_number"
                                    name="sender_excise_number"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.excise_number}
                                    value={row.excise_number}
                                    margin="normal"/>
                                <TextField
                                    style={{marginTop:"15px"}}
                                    sx={{label:{fontSize:'0.85rem'}}}
                                    InputProps={{readOnly:true}}
                                    id="sender_warehouse_excise_number"
                                    name="sender_warehouse_excise_number"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.warehouse_excise_number}
                                    value={row.warehouse_excise_number}
                                    margin="normal"/>
                            </Stack>
                            <Stack style={{marginTop:"10px",width:"50%"}} direction={{xs:'column',sm:'column'}} spacing={{xs:1,sm:1,md:1}}>
                                <Typography variant="h6">{LABEL.receiver}</Typography>
                                <TextField
                                    InputProps={{readOnly:true}}
                                    multiline={true}
                                    rows={3}
                                    id="receiver"
                                    name="receiver"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.name_address}
                                    value={row.account_name_alt+'\n'+row.shipping_addr_street+', '+row.shipping_addr_specifier+'\n'+row.shipping_addr_zip+', '+row.shipping_addr_city}
                                    margin="normal"/>
                                <TextField
                                    style={{marginTop:"15px"}}
                                    sx={{label:{fontSize:'0.85rem'}}}
                                    InputProps={{readOnly:true}}
                                    id="receiver_business_id"
                                    name="receiver_business_id"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.business_id}
                                    value={row.account_business_id_alt}
                                    margin="normal"/>
                                <TextField
                                    style={{marginTop:"15px"}}
                                    sx={{label:{fontSize:'0.85rem'}}}
                                    InputProps={{readOnly:true}}
                                    id="receiver_excise_number"
                                    name="receiver_excise_number"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.excise_number}
                                    value={row.excise_number_alt}
                                    margin="normal"/>
                                <TextField
                                    style={{marginTop:"15px"}}
                                    sx={{label:{fontSize:'0.85rem'}}}
                                    InputProps={{readOnly:true}}
                                    id="receiver_warehouse_excise_number"
                                    name="receiver_warehouse_excise_number"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label={LABEL.warehouse_excise_number}
                                    value={row.warehouse_excise_number_alt}
                                    margin="normal"/>
                            </Stack>
                        </Stack>
                        <TableContainer style={{marginTop:"10px"}} component={Paper}>
                            <Table sx={{minWidth:650}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{LABEL.product}</StyledTableCell>
                                        <StyledTableCell>{LABEL.gtin}</StyledTableCell>
                                        <StyledTableCell>{LABEL.quantity}</StyledTableCell>
                                        <StyledTableCell>{LABEL.pallets}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.length>0&&rows.map((row)=>{
                                        return <StyledTableRow key={row.id}sx={{'&:last-child td, &:last-child th':{border:0}}}>
                                            <StyledTableCell component="th" scope="row">{row.sku_name}</StyledTableCell>
                                            <StyledTableCell>{row.sku_gtin_name}</StyledTableCell>
                                            <StyledTableCell>{row.quantity}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </StyledTableRow>;
                                    })}
                                    <TableRow>
                                        <TableCell style={{fontWeight:"bold"}} colSpan={2}>{LABEL.total}</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>{total}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack style={{marginTop:"10px"}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                            <Stack style={{marginTop:"10px",width:"50%"}} direction={{xs:'column',sm:'column'}} spacing={{xs:1,sm:1,md:1}}>
                                <Typography variant="h6">{LABEL.header_sender_sign}</Typography>
                                <Typography style={{fontSize:"0.85rem",marginLeft:"10px"}} variant="subtitle1">{row.account_name+' '+LABEL.signatura_date}</Typography>
                                <Typography style={{height:"25px",borderBottom:"1px solid black",marginLeft:"10px",marginRight:"25px"}} variant="subtitle1"></Typography>
                            </Stack>
                            <Stack style={{marginTop:"10px",width:"50%"}} direction={{xs:'column',sm:'column'}} spacing={{xs:1,sm:1,md:1}}>
                                <Typography variant="h6">{LABEL.header_receiver_sign}</Typography>
                                <Typography style={{fontSize:"0.85rem",marginLeft:"10px"}} variant="subtitle1">{row.account_name_alt+' '+LABEL.signatura_date}</Typography>
                                <Typography style={{height:"25px",borderBottom:"1px solid black",marginLeft:"10px",marginRight:"25px"}} variant="subtitle1"></Typography>
                            </Stack>
                        </Stack>
                        <Stack style={{marginTop:"35px"}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:1,md:1}}>
                            <Typography variant="subtitle2">{LABEL.footer}</Typography>
                        </Stack>
                    </Box>
                </DialogContent>
            </div>
            <DialogActions>
                <ReactToPrint
                    bodyClass="print-agreement"
                    content={()=>ref.current}
                    trigger={()=>(<Button className="btn" onClick={handleClickPrint} variant="contained">Print</Button>)}/>
                <Button className="btn" onClick={handleClickClose} variant="contained">Close</Button>
             </DialogActions>
        </Dialog>
    );
}

export default StorageOrderShippingReport;
