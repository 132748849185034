import {Link} from "react-router-dom";
import LinkMUI from '@mui/material/Link';
import {GridRowModes,GridActionsCellItem,useGridApiContext,GRID_DATE_COL_DEF,GRID_DATETIME_COL_DEF,DataGrid,getGridDateOperators} from '@mui/x-data-grid';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {parseISO} from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';    
import {styled} from '@mui/material/styles';
import {status_color,country_code_3,entity_object} from './DataDictionary';
import {dateFns_to_ui,adjustDateLocale} from './Utilities.js';
const GO_TO_RECORD='Go To Record Details';
const COLUMN_ACTION='Action';

function dateColumnType(handleUpdateFromColumn){
    function handleUpdate(id,fieldName,fieldValue){
        handleUpdateFromColumn(id,fieldName,fieldValue);
    };
    return{
        renderEditCell:(params)=>{
            return <GridEditDateCell handleUpdate={handleUpdate} {...params}/>;
        },
        filterOperators:getGridDateOperators(false).map((item)=>({
            ...item,
            InputComponent:GridFilterDateInput,
            InputComponentProps:{showTime:false},
        })),
        valueFormatter:(value)=>{
            if(value){
                return dateFns_to_ui(value);
            }
            return '';
        },
    }
};

const renderHeaderEditable=(label,editable)=>{
    if(editable){
        return (<strong style={{color:'darkblue'}}>{label+' '}<span style={{color:'darkblue'}} role="img" aria-label="editable">✎</span></strong>);
    }
    else{
        return (<strong>{label}</strong>);
    }
}

const renderHeaderReportTotal=(label,total)=>{
    let total_text=total===undefined?"0":total;
    total_text='Total: '+total_text;
    return(<strong style={{lineHeight:'1.25em'}}>{label}<span style={{color:'darkblue'}}><br/>{total_text}</span></strong>)
}

function getDateFilterOperators(showTime=false){
    return[
        {
            value:'is',
            getApplyFilterFn:(filterItem)=>{
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1,value2)=>value1===value2,
                    showTime,
                );
            },
            InputComponent:GridFilterDateInput,
            InputComponentProps:{showTime},
        },
        {
            value:'not',
            getApplyFilterFn:(filterItem)=>{
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1,value2)=>value1!==value2,
                    showTime,
                );
            },
            InputComponent:GridFilterDateInput,
            InputComponentProps:{showTime},
        },
        {
            value:'after',
            getApplyFilterFn:(filterItem)=>{
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1,value2)=>value1>value2,
                    showTime,
                );
            },
            InputComponent:GridFilterDateInput,
            InputComponentProps:{showTime},
        },
        {
            value:'onOrAfter',
            getApplyFilterFn:(filterItem)=>{
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1,value2)=>value1>=value2,
                    showTime,
                );
            },
            InputComponent:GridFilterDateInput,
            InputComponentProps:{showTime},
        },
        {
            value:'before',
            getApplyFilterFn:(filterItem)=>{
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1,value2)=>value1<value2,
                    showTime,
                );
            },
            InputComponent:GridFilterDateInput,
            InputComponentProps:{showTime},
        },
        {
            value:'onOrBefore',
            getApplyFilterFn:(filterItem)=>{
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1,value2)=>value1<=value2,
                    showTime,
                );
            },
            InputComponent:GridFilterDateInput,
            InputComponentProps:{showTime},
        },
        {
            value:'isEmpty',
            getApplyFilterFn:()=>{
                return({value})=>{
                    return value==null;
                };
            },
            requiresFilterValue:false,
        },
        {
            value:'isNotEmpty',
            getApplyFilterFn:()=>{
                return({value})=>{
                    return value!=null;
                };
            },
            requiresFilterValue: false,
        },
    ];
}

function GridFilterDateInput(props){
    const {item,showTime,applyValue,apiRef}=props;
    const Component=showTime?DateTimePicker:MobileDatePicker;
    const handleFilterChange=(newValue)=>{
        applyValue({...item,value:newValue});
    };

    return(
        <Component
            value={item.value||null}
            autoFocus
            label={apiRef.current.getLocaleText('filterPanelInputLabel')}
            slotProps={{
                textField:{variant:'standard',},
                    inputAdornment:{
                        sx:{'& .MuiButtonBase-root':{
                            marginRight: -1,
                        },
                    },
                },
            }}
            onChange={handleFilterChange}/>
    );
}

function GridEditDateCell({id,field,value,colDef,handleUpdate}){
    const apiRef=useGridApiContext();
    const Component=colDef.type==='dateTime'?DateTimePicker:MobileDatePicker;
    const handleChange=(newValue)=>{
        handleUpdate(id,field,newValue);
        apiRef.current.setEditCellValue({id,field,value:newValue});
    };
    return(
        <Component
            value={value}
            autoFocus
            onChange={handleChange}
            slots={{textField:WrappedGridEditDateInput}}/>
    );
}

const GridEditDateInput=styled(InputBase)({
    fontSize:'inherit',
    padding:'0 9px',
});
  
function WrappedGridEditDateInput(props){
    const objCopy={...props};
    if(props.focused){
        objCopy.focused="True";//boolean not supported anymore
    }
    const {InputProps,...other}=objCopy;
    return <GridEditDateInput fullWidth {...InputProps} {...other}/>;
}

export const columns={
    accounts:function(list_properties){
        return([
            {field:'name',headerName:entity_object.account.fields.name.label,width:list_properties?.name?.width??400,editable:false,renderCell:(params)=><Link to={'/account/'+params.row.id+'/sales_orders'} style={{textDecoration:"none"}}>{params.row.name}</Link>},
            {field:'legal_name',headerName:entity_object.account.fields.legal_name.label,width:list_properties?.legal_name?.width??400,editable:false},
            {field:'status',headerName:entity_object.account.fields.status.label,width:list_properties?.status?.width??250,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'business_id',headerName:entity_object.account.fields.business_id.label,width:list_properties?.business_id?.width??200,editable:false},
        ]);
    },
    audit:function(list_properties){
        return([
            {field:'entity_column_ui',headerName:'Field',width:list_properties?.entity_column_ui?.width??300,editable:false},
            {field:'value_old',headerName:'Old Value',width:list_properties?.value_old?.width??300,editable:false},
            {field:'value_new',headerName:'New Value',width:list_properties?.value_new?.width??300,editable:false},
            {field:'created_at_ui',headerName:'Date Time',width:list_properties?.created_at_ui?.width??300,editable:false},
            {field:'created_by',headerName:'User',width:list_properties?.created_by?.width??300,editable:false},
        ]);
    },
    error_log:function(handleClickShowDetail,list_properties){
        function processHandleClickShowDetail(e,row){
            e.preventDefault();
            e.stopPropagation();
            handleClickShowDetail(row);
        }
        return([
            {field:'id',headerName:COLUMN_ACTION,width:list_properties?.id?.width??100,editable:false,renderCell:(params)=><LinkMUI component="button" variant="body2" onClick={(e)=>{processHandleClickShowDetail(e,params.row)}}>See Details</LinkMUI>},
            {field:'created_at_ui',headerName:'Date Time',width:list_properties?.created_at_ui?.width??180,editable:false},
            {field:'created_by',headerName:'User',width:list_properties?.created_by?.width??120,editable:false},
            {field:'source',headerName:'Source',width:list_properties?.source?.width??300,editable:false},
            {field:'level',headerName:'Level',width:list_properties?.level?.width??100,editable:false},
            {field:'status',headerName:'Status',width:list_properties?.status?.width??100,editable:false},
            {field:'message',headerName:'Description',width:list_properties?.message?.width??300,editable:false},
            {field:'stack_trace',headerName:'Strack Trace',width:list_properties?.stack_trace?.width??300,editable:false},
            {field:'request_data',headerName:'Integration Data',width:list_properties?.request_data?.width??300,editable:false},
        ]);
    },
    account_picklist:function(picklistLinkCallback,list_properties){
        return([
            {field:'name',headerName:entity_object.account.fields.name.label,width:list_properties?.name?.width??400,editable:false,renderCell:(params)=><LinkMUI component="button" variant="body2" onClick={()=>{picklistLinkCallback(params.row)}}>{params.row.name}</LinkMUI>},
            {field:'legal_name',headerName:entity_object.account.fields.legal_name.label,width:list_properties?.legal_name?.width??400,editable:false},
            {field:'status',headerName:entity_object.account.fields.status.label,width:list_properties?.status?.width??200,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'business_id',headerName:entity_object.account.fields.business_id.label,width:list_properties?.business_id?.width??200,editable:false},
            {field:'id',headerName:COLUMN_ACTION,width:list_properties?.id?.width??400,editable:false,renderCell:(params)=><Link to={'/account/'+params.row.id+'/sales_orders'} style={{textDecoration:"none"}} target="_blank">{GO_TO_RECORD}</Link>}
        ])
    },
    storage_orders:function(list_properties){
        return([
            {field:'reference',headerName:entity_object.storage_order.fields.reference.label,width:list_properties?.reference?.width??150,editable:false,renderCell:(params)=><Link to={'/storage_order/'+params.row.id+'/items'} style={{textDecoration:"none"}}>{params.row.reference}</Link>},
            {field:'status',headerName:entity_object.storage_order.fields.status.label,width:list_properties?.status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'delivery_status',headerName:entity_object.storage_order.fields.delivery_status.label,width:list_properties?.delivery_status?.width??150,editable:false},
            {field:'created_by',headerName:'Created By',width:list_properties?.created_by?.width??150,editable:false},
            {field:'created_at',headerName:'Created On',width:list_properties?.created_at?.width??180,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.created_at)}</span>},
        ]);
    },
    sales_orders:function(list_properties){
        return([
            {field:'reference',headerName:entity_object.sales_order.fields.reference.label,width:list_properties?.reference?.width??120,editable:false,renderCell:(params)=><Link to={'/sales_order/'+params.row.id+'/items'} style={{textDecoration:"none"}}>{params.row.reference}</Link>},
            {field:'name',headerName:'Customer Name',width:list_properties?.name?.width??300,editable:false,renderCell:(params)=><Link to={'/account/'+params.row.account_id+'/sales_orders'} style={{textDecoration:"none"}}>{params.row.name}</Link>},
            {field:'created_at',headerName:'Order Date',width:list_properties?.created_at?.width??180,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.created_at)}</span>},
            {field:'delivery_date',headerName:entity_object.sales_order.fields.delivery_date.label,width:list_properties?.delivery_date?.width??180,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.delivery_date)}</span>},
            {field:'status',headerName:entity_object.sales_order.fields.status.label,width:list_properties?.status?.width??250,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'delivery_status',headerName:entity_object.sales_order.fields.delivery_status.label,width:list_properties?.delivery_status?.width??150,editable:false},
            {field:'delivery_price',headerName:entity_object.sales_order.fields.delivery_price.label,width:list_properties?.delivery_price?.width??150,editable:false},
            {field:'total_price',headerName:'Final Price',width:list_properties?.total_price?.width??150,editable:false,renderCell:(params)=><span>{(params.row.export||!params.row.tax_free)?params.row.total_price_included_vat:params.row.total_price}</span>},
            {field:'invoicing_number',headerName:entity_object.sales_order.fields.invoicing_number.label,width:list_properties?.invoicing_number?.width??150,editable:false},
            {field:'created_by',headerName:'Sales Person',width:list_properties?.created_by?.width??150,editable:false},
        ]);
    },
    account_sales_orders:function(list_properties){
        return([
            {field:'reference',headerName:entity_object.sales_order.fields.reference.label,width:list_properties?.reference?.width??150,editable:false,renderCell:(params)=><Link to={'/sales_order/'+params.row.id+'/items'} style={{textDecoration:"none"}}>{params.row.reference}</Link>},
            {field:'status',headerName:entity_object.sales_order.fields.status.label,width:list_properties?.status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'delivery_status',headerName:entity_object.sales_order.fields.delivery_status.label,width:list_properties?.delivery_status?.width??150,editable:false},
            {field:'price',headerName:'Total Price',width:list_properties?.price?.width??150,editable:false,renderCell:(params)=><span>{(params.row.export||!params.row.tax_free)?params.row.price_included_vat:params.row.price}</span>},
            {field:'palpa_value',headerName:'Total Palpa',width:list_properties?.palpa_value?.width??150,editable:false},
            {field:'total_price',headerName:'Final Price',width:list_properties?.total_price?.width??150,editable:false,renderCell:(params)=><span>{(params.row.export||!params.row.tax_free)?params.row.total_price_included_vat:params.row.total_price}</span>}
        ])
    },
    sku_sales_order_item:function(list_properties){
        return([
            {field:'order_reference',headerName:'Order Reference',width:list_properties?.order_reference?.width??120,editable:false,renderCell:(params)=><Link to={'/sales_order/'+params.row.order_id+'/items'} style={{textDecoration:"none"}}>{params.row.order_reference}</Link>},
            {field:'order_status',headerName:'Order Status',width:list_properties?.order_status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.order_status]}}>{params.row.order_status}</span>},
            {field:'order_delivery_status',headerName:'Order Delivery Status',width:list_properties?.order_delivery_status?.width??150,editable:false},
            {field:'quantity',headerName:'Quantity',width:list_properties?.quantity?.width??150,editable:false,type:'number',align:"left",headerAlign:"left"},
        ])
    },
    sales_order_items:function(readOnly,tax_free,palpa_free,list_properties){
        let columns=[
            {field:'reference',headerName:entity_object.sales_order_item.fields.reference.label,width:list_properties?.reference?.width??150,editable:false},
            {field:'sku_name',headerName:entity_object.sales_order_item.fields.sku_name.label,width:list_properties?.sku_name?.width??300,editable:false,renderCell:(params)=><Link to={'/sku/'+params.row.sku_id+'/salesorderitem'} style={{textDecoration:"none"}}>{params.row.sku_name}</Link>},
            {field:'quantity',headerName:entity_object.sales_order_item.fields.quantity.label,width:list_properties?.quantity?.width??150,editable:!readOnly,type:'number',align:"left",headerAlign:"left",renderHeader:()=>(renderHeaderEditable(entity_object.sales_order_item.fields.quantity.label,!readOnly)),},
            {field:'unit_price',headerName:entity_object.sales_order_item.fields.unit_price.label,width:list_properties?.unit_price?.width??120,editable:!readOnly,type:'number',align:"left",headerAlign:"left",renderHeader:()=>(renderHeaderEditable(entity_object.sales_order_item.fields.unit_price.label,!readOnly)),},
            {field:'final_price',headerName:entity_object.sales_order_item.fields.final_price.label,width:list_properties?.final_price?.width??120,editable:false,type:'number',align:"left",headerAlign:"left"},
        ]
        if(!tax_free){
            const index_tax_free=5;
            columns=[
                ...columns.slice(0,index_tax_free),
                {field:'vat',headerName:entity_object.sales_order_item.fields.vat.label,width:list_properties?.vat?.width??100,editable:false,type:'number',align:"left",headerAlign:"left",description:entity_object.sales_order_item.fields.vat.tooltip},
                {field:'vat_price',headerName:entity_object.sales_order_item.fields.vat_price.label,width:list_properties?.vat_price?.width??120,editable:false,type:'number',align:"left",headerAlign:"left",description:entity_object.sales_order_item.fields.vat_price.tooltip},
                {field:'final_price_included_vat',headerName:entity_object.sales_order_item.fields.final_price_included_vat.label,width:list_properties?.final_price_included_vat?.width??200,editable:false,type:'number',align:"left",headerAlign:"left"},
                ...columns.slice(index_tax_free)
            ];
        };
        if(!palpa_free){
            columns.push(
                {field:'sku_unit_palpa_value',headerName:entity_object.sales_order_item.fields.sku_unit_palpa_value.label,width:list_properties?.sku_unit_palpa_value?.width??120,editable:false,type:'number',align:"left",headerAlign:"left",description:entity_object.sales_order_item.fields.sku_unit_palpa_value.tooltip},
                {field:'sku_total_palpa_value',headerName:entity_object.sales_order_item.fields.sku_total_palpa_value.label,width:list_properties?.sku_total_palpa_value?.width??120,editable:false,type:'number',align:"left",headerAlign:"left",description:entity_object.sales_order_item.fields.sku_total_palpa_value.tooltip},
            )
        }
        if(readOnly){
            columns.push({field:'quantity_delivered',headerName:entity_object.sales_order_item.fields.quantity_delivered.label,width:list_properties?.quantity_delivered?.width??150,editable:false,type:'number',align:"left",headerAlign:"left",renderCell:(params)=><span style={{color:(params.row.quantity!==params.row.quantity_delivered)?'red':''}}>{params.row.quantity_delivered}</span>})
        }
        columns.push({field:'description',headerName:entity_object.sales_order_item.fields.description.label,width:list_properties?.description?.width??200,editable:!readOnly,renderHeader:()=>(renderHeaderEditable(entity_object.sales_order_item.fields.description.label,!readOnly)),},);
        return(columns);
    },
    skus:function(list_properties){
        return([
            {field:'gtin_name',headerName:'Barcode',width:list_properties?.gtin_name?.width??150,editable:false,renderCell:(params)=><span>{'gtin_name' in params.row?params.row.gtin_name:params.row.gtin.name}</span>},
            {field:'name',headerName:entity_object.sku.fields.name.label,width:list_properties?.name?.width??400,editable:false,renderCell:(params)=><Link to={'/sku/'+params.row.id+'/salesorderitem'} style={{textDecoration:"none"}}>{params.row.name}</Link>},
            {field:'stock_balance',headerName:'Bal',width:list_properties?.stock_balance?.width??80,editable:false,description:'Total Existing on Collico'},
            {field:'reserved_quantity',headerName:'Resr',width:list_properties?.reserved_quantity?.width??80,editable:false,description:'Reserved for Sales Order in Draft or Submitted with Delivery Status New or InProgress (not deducted from Collico yet)'},
            {field:'stock_available',headerName:'Av',width:list_properties?.stock_available?.width??80,editable:false,description:'Available on Brainiac (Balance - Reserved)',renderCell:(params)=><span style={{color:params.row.stock_balance-params.row.reserved_quantity>=0?'blue':'red'}}>{params.row.stock_balance-params.row.reserved_quantity}</span>},
            {field:'pre_sales_quantity',headerName:'PS Bal',description:'Pre Sales Total',width:list_properties?.pre_sales_quantity?.width??80,editable:false},
            {field:'pre_sales_available',headerName:'PS Av',description:'Pre Sales Available (Total - Reserved)',width:list_properties?.pre_sales_available?.width??80,editable:false,renderCell:(params)=><span style={{color:params.row.pre_sales_quantity+(params.row.stock_balance-params.row.reserved_quantity)>=0?'blue':'red'}}>{(params.row.pre_sales_quantity!==null)?(params.row.pre_sales_quantity+(params.row.stock_balance-params.row.reserved_quantity)):null}</span>},
            {field:'quarantined_balance',headerName:entity_object.sku.fields.quarantined_balance.label,width:list_properties?.quarantined_balance?.width??150,editable:false,renderCell:(params)=><span style={{backgroundColor:(params.row.quarantined_balance!==''&&params.row.quarantined_balance!==undefined&&params.row.quarantined_balance!==0)?'pink':''}}>{params.row.quarantined_balance}</span>},
            {field:'type',headerName:entity_object.sku.fields.type.label,width:list_properties?.type?.width??100,editable:false},
            {field:'status',headerName:entity_object.sku.fields.status.label,width:list_properties?.status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>}
        ]);
    },
    skus_picklist:function(picklistLinkCallback,list_properties){
        return([
            {field:'name',headerName:entity_object.sku.fields.name.label,width:list_properties?.name?.width??300,editable:false,renderCell:(params)=><LinkMUI component="button" variant="body2" onClick={()=>{picklistLinkCallback(params.row)}}>{params.row.name}</LinkMUI>},
            {field:'stock_balance',headerName:'Bal',width:list_properties?.stock_balance?.width??80,editable:false,description:'Total Existing on Collico'},
            {field:'reserved_quantity',headerName:'Resr',width:list_properties?.reserved_quantity?.width??80,editable:false,description:'Reserved for Sales Order in Draft or Submitted with Delivery Status New or InProgress (not deducted from Collico yet)'},
            {field:'stock_available',headerName:'Av',width:list_properties?.stock_available?.width??80,editable:false,description:'Available on Brainiac (Balance - Reserved)',renderCell:(params)=><span style={{color:params.row.stock_balance-params.row.reserved_quantity>=0?'blue':'red'}}>{params.row.stock_balance-params.row.reserved_quantity}</span>},
            {field:'pre_sales_quantity',headerName:'PS Bal',description:'Pre Sales Total',width:list_properties?.pre_sales_quantity?.width??80,editable:false},
            {field:'pre_sales_available',headerName:'PS Av',description:'Pre Sales Available (Total - Reserved)',width:list_properties?.pre_sales_available?.width??80,editable:false,renderCell:(params)=><span style={{color:params.row.pre_sales_quantity+(params.row.stock_balance-params.row.reserved_quantity)>=0?'blue':'red'}}>{(params.row.pre_sales_quantity!==null)?(params.row.pre_sales_quantity+(params.row.stock_balance-params.row.reserved_quantity)):null}</span>},
            {field:'status',headerName:entity_object.sku.fields.status.label,width:list_properties?.status?.width??100,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'id',headerName:COLUMN_ACTION,width:list_properties?.id?.width??400,editable:false,renderCell:(params)=><Link to={'/sku/'+params.row.id+'/salesorderitem'} style={{textDecoration:"none"}} target="_blank">{GO_TO_RECORD}</Link>},
        ])
    },
    sku_multipacks:function(list_properties){
        return([
            {field:'reference',headerName:entity_object.sku.fields.reference.label,width:list_properties?.reference?.width??120,editable:false},
            {field:'name',headerName:entity_object.sku.fields.name.label,width:list_properties?.name?.width??400,editable:false,renderCell:(params)=><Link to={'/sku/'+params.row.id+'/salesorderitem'} style={{textDecoration:"none"}}>{params.row.name}</Link>},
            {field:'type',headerName:entity_object.sku.fields.type.label,width:list_properties?.type?.width??100,editable:false},
            {field:'status',headerName:entity_object.sku.fields.status.label,width:list_properties?.status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>}
        ]);
    },
    sku_single:function(list_properties){
        return([
            {field:'reference',headerName:entity_object.sku.fields.reference.label,width:list_properties?.reference?.width??120,editable:false},
            {field:'name',headerName:entity_object.sku.fields.name.label,width:list_properties?.name?.width??400,editable:false,renderCell:(params)=><Link to={'/sku/'+params.row.id+'/salesorderitem'} style={{textDecoration:"none"}}>{params.row.name}</Link>},
            {field:'type',headerName:entity_object.sku.fields.type.label,width:list_properties?.type?.width??100,editable:false},
            {field:'status',headerName:entity_object.sku.fields.status.label,width:list_properties?.status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>}
        ]);
    },
    products:function(list_properties){
        return([
            {field:'reference',headerName:entity_object.product.fields.reference.label,width:list_properties?.reference?.width??120,editable:false},
            {field:'name',headerName:entity_object.product.fields.name.label,width:list_properties?.name?.width??300,editable:false,renderCell:(params)=><Link to={'/product/'+params.row.id+'/skus'} style={{textDecoration:"none"}}>{params.row.name}</Link>},
            {field:'type',headerName:entity_object.product.fields.type.label,width:list_properties?.type?.width??150,editable:false},
            {field:'status',headerName:entity_object.product.fields.status.label,width:list_properties?.status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>}
        ]);
    },
    products_picklist:function(picklistLinkCallback,list_properties){
        return([
            {field:'reference',headerName:entity_object.product.fields.reference.label,width:list_properties?.reference?.width??120,editable:false},
            {field:'name',headerName:entity_object.product.fields.name.label,width:list_properties?.name?.width??300,editable:false,renderCell:(params)=><LinkMUI component="button" variant="body2" onClick={()=>{picklistLinkCallback(params.row)}}>{params.row.name}</LinkMUI>},
            {field:'type',headerName:entity_object.product.fields.type.label,width:list_properties?.type?.width??150,editable:false},
            {field:'status',headerName:entity_object.product.fields.status.label,width:list_properties?.status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'id',headerName:COLUMN_ACTION,width:list_properties?.id?.width??400,editable:false,renderCell:(params)=><Link to={'/product/'+params.row.id+'/skus'} style={{textDecoration:"none"}} target="_blank">{GO_TO_RECORD}</Link>}
        ])
    },
    list_of_values:function(setRowModesModel,rowModesModel,rows,setRows,deleteRecord,lovValues,list_properties){
        const handleEditClick=(id)=>()=>{
            setRowModesModel({...rowModesModel,[id]:{mode:GridRowModes.Edit}});
        };
        const handleDeleteClick=(id)=>()=>{
            rows.forEach(element=>{
                if(id===element.id){deleteRecord(id);}
            });
            setRows(rows.filter((row)=>row.id_temp!==id));
        };
        const handleSaveClick=(params)=>()=>{
            setRowModesModel({ ...rowModesModel,[params.row.id_temp]:{mode:GridRowModes.View}});
        };
        const handleCancelClick=(id)=>()=>{
            setRowModesModel({
                ...rowModesModel,
                [id]:{mode:GridRowModes.View,ignoreModifications:true},
            });
            const editedRow=rows.find((row)=>row.id_temp===id);
            if('isNew' in editedRow){
                if(editedRow.isNew){
                    setRows(rows.filter((row)=>row.id_temp!==id));
                }
            }
        };
        return [
        {field:'id',headerName:'Id',width:list_properties?.id?.width??80,editable:false},
        {field:'type',headerName:'Type',width:list_properties?.type?.width??300,editable:true,type:'singleSelect',valueOptions:lovValues.LIST_OF_VALUE_TYPE},
        {field:'value',headerName:'Value',width:list_properties?.value?.width??500,editable:true},
        {field:'status',headerName:'Status',width:list_properties?.status?.width??100,editable:true,type:'singleSelect',valueOptions:lovValues.LIST_OF_VALUE_STATUS,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
        {field:'sorting_order',headerName:'Sorting Order',type:'number',align:"left",headerAlign:"left",width:list_properties?.sorting_order?.width??150,editable:true},
        {
            field:'actions',
            type:'actions',
            headerName:'Actions',
            width:list_properties?.actions?.width??100,
            cellClassName:'actions',
            getActions:(params)=>{
                const isInEditMode=rowModesModel[params.row.id_temp]?.mode===GridRowModes.Edit;
                if(isInEditMode){
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{color:'primary.main',}}
                            onClick={handleSaveClick(params)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(params.row.id_temp)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(params.row.id_temp)}
                    color="inherit"
                    />,
                    <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(params.row.id_temp)}
                    color="inherit"
                    />,
                ];
            },
        },
    ]},
    package_types:function(setRowModesModel,rowModesModel,rows,setRows,deleteRecord,lovValues,list_properties,listOfParentSingle){
        const handleEditClick=(id)=>()=>{
            setRowModesModel({...rowModesModel,[id]:{mode:GridRowModes.Edit}});
        };
        const handleDeleteClick=(id)=>()=>{
            rows.forEach(element=>{
                if(id===element.id){deleteRecord(id);}
            });
            setRows(rows.filter((row)=>row.id_temp!==id));
        };
        const handleSaveClick=(params)=>()=>{
            setRowModesModel({ ...rowModesModel,[params.row.id_temp]:{mode:GridRowModes.View}});
        };
        const handleCancelClick=(id)=>()=>{
            setRowModesModel({
                ...rowModesModel,
                [id]:{mode:GridRowModes.View,ignoreModifications:true},
            });
            const editedRow=rows.find((row)=>row.id_temp===id);
            if('isNew' in editedRow){
                if(editedRow.isNew){
                    setRows(rows.filter((row)=>row.id_temp!==id));
                }
            }
        };
        return [
        {field:'id',headerName:'Id',width:list_properties?.id?.width??80,editable:false},
        {field:'name',headerName:'Name',width:list_properties?.name?.width??200,editable:true},
        {field:'type',headerName:'Type',width:list_properties?.type?.width??200,editable:true,type:'singleSelect',valueOptions:lovValues.PACKAGE_TYPE},
        {field:'unit',headerName:'Unit',width:list_properties?.unit?.width??200,editable:true,type:'singleSelect',valueOptions:lovValues.PACKAGE_UNIT},
        {field:'amount',headerName:'Amount',width:list_properties?.amount?.width??200,editable:true,type:'number',align:"left",headerAlign:"left"},
        {field:'width',headerName:'Width (cm)',width:list_properties?.width?.width??200,editable:true,type:'number',align:"left",headerAlign:"left"},
        {field:'height',headerName:'Height (cm)',width:list_properties?.height?.width??200,editable:true,type:'number',align:"left",headerAlign:"left"},
        {field:'length',headerName:'Length (cm)',width:list_properties?.length?.width??200,editable:true,type:'number',align:"left",headerAlign:"left"},
        {field:'weight',headerName:'Weight (Kg)',width:list_properties?.weight?.width??200,editable:true,type:'number',align:"left",headerAlign:"left"},
        {field:'status',headerName:'Status',width:list_properties?.status?.width??100,editable:true,type:'singleSelect',valueOptions:lovValues.PACKAGE_TYPE_STATUS,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
        {field:'sku_type',headerName:'SKU Type',width:list_properties?.sku_type?.width??200,editable:true,type:'singleSelect',valueOptions:lovValues.SKU_TYPE},
        {field:'parent_name',headerName:'Parent (Single)',width:list_properties?.parent_name?.width??200,editable:true,type:'singleSelect',valueOptions:listOfParentSingle},
        {field:'product_creation_default',headerName:'Default Product Creation',width:list_properties?.product_creation_default?.width??300,editable:true,type:'boolean'},
        
        {
            field:'actions',
            type:'actions',
            headerName:'Actions',
            width:list_properties?.actions?.width??100,
            cellClassName:'actions',
            getActions:(params)=>{
                const isInEditMode=rowModesModel[params.row.id_temp]?.mode===GridRowModes.Edit;
                if(isInEditMode){
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{color:'primary.main',}}
                            onClick={handleSaveClick(params)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(params.row.id_temp)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(params.row.id_temp)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(params.row.id_temp)}
                        color="inherit"
                    />,
                ];
            },
        },
    ]},
    package_types_picklist:function(picklistLinkCallback,list_properties){
        return([
            {field:'id',headerName:'Id',width:list_properties?.id?.width??80,editable:false},
            {field:'name',headerName:'Name',width:list_properties?.name?.width??200,editable:false,renderCell:(params)=><LinkMUI component="button" variant="body2" onClick={()=>{picklistLinkCallback(params.row)}}>{params.row.name}</LinkMUI>},
            {field:'type',headerName:'Type',width:list_properties?.type?.width??200,editable:false},
            {field:'unit',headerName:'Unit',width:list_properties?.unit?.width??200,editable:false},
            {field:'amount',headerName:'Amount',width:list_properties?.amount?.width??200,editable:false,type:'number',align:"left",headerAlign:"left"},
            {field:'width',headerName:'Width (cm)',width:list_properties?.width?.width??200,editable:false,type:'number',align:"left",headerAlign:"left"},
            {field:'height',headerName:'Height (cm)',width:list_properties?.height?.width??200,editable:false,type:'number',align:"left",headerAlign:"left"},
            {field:'length',headerName:'Length (cm)',width:list_properties?.length?.width??200,editable:false,type:'number',align:"left",headerAlign:"left"},
            {field:'weight',headerName:'Weight (Kg)',width:list_properties?.weight?.width??200,editable:false,type:'number',align:"left",headerAlign:"left"},
            {field:'sku_type',headerName:'SKU Type',width:list_properties?.sku_type?.width??200,editable:false},
            {field:'status',headerName:'Status',width:list_properties?.status?.width??100,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
        ]);
    },
    barcodes:function(setRowModesModel,rowModesModel,rows,setRows,deleteRecord,lovValues,list_properties){
        const handleEditClick=(id)=>()=>{
            setRowModesModel({...rowModesModel,[id]:{mode:GridRowModes.Edit}});
        };
        const handleDeleteClick=(id)=>()=>{
            rows.forEach(element=>{
                if(id===element.id){deleteRecord(id);}
            });
            setRows(rows.filter((row)=>row.id_temp!==id));
        };
        const handleSaveClick=(params)=>()=>{
            setRowModesModel({ ...rowModesModel,[params.row.id_temp]:{mode:GridRowModes.View}});
        };
        const handleCancelClick=(id)=>()=>{
            setRowModesModel({
                ...rowModesModel,
                [id]:{mode:GridRowModes.View,ignoreModifications:true},
            });
            const editedRow=rows.find((row)=>row.id_temp===id);
            if('isNew' in editedRow){
                if(editedRow.isNew){
                    setRows(rows.filter((row)=>row.id_temp!==id));
                }
            }
        };
        return [
        {field:'id',headerName:'Id',width:list_properties?.id?.width??80,editable:false},
        {field:'name',headerName:'Name',width:list_properties?.name?.width??200,editable:true},
        {field:'status',headerName:'Status',width:list_properties?.status?.width??150,editable:true,type:'singleSelect',valueOptions:lovValues.BARCODE_STATUS,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
        {field:'comments',headerName:'Comments',width:list_properties?.comments?.width??300,editable:true},
        {field:'product_sku_name',headerName:'SKU',width:list_properties?.product_sku_name?.width??200,editable:false},
        {
            field:'actions',
            type:'actions',
            headerName:'Actions',
            width:list_properties?.actions?.width??100,
            cellClassName:'actions',
            getActions:(params)=>{
                const isInEditMode=rowModesModel[params.row.id_temp]?.mode===GridRowModes.Edit;
                if(isInEditMode){
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{color:'primary.main',}}
                            onClick={handleSaveClick(params)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(params.row.id_temp)}
                            color="inherit"
                        />,
                    ];
                }
      
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(params.row.id_temp)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(params.row.id_temp)}
                        color="inherit"
                    />,
                ];
            },
        },
    ]},
    barcodes_picklist:function(picklistLinkCallback,list_properties){
        return([
            {field:'id',headerName:'Id',width:list_properties?.id?.width??80,editable:false},
            {field:'name',headerName:'Name',width:list_properties?.name?.width??200,editable:false,renderCell:(params)=><LinkMUI component="button" variant="body2" onClick={()=>{picklistLinkCallback(params.row)}}>{params.row.name}</LinkMUI>},
            {field:'status',headerName:'Status',width:list_properties?.status?.width??100,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
            {field:'comments',headerName:'Comments',width:list_properties?.comments?.width??300,editable:false},
            {field:'product_sku_name',headerName:'SKU',width:list_properties?.product_sku_name?.width??200,editable:false}
        ]);
    },
    storage_order_items:function(readOnly,list_properties){
        function handleUpdateFromColumn(id,fieldName,fieldValue){};
        let columns=[
            {field:'reference',headerName:entity_object.storage_order_item.fields.reference.label,width:list_properties?.reference?.width??200,editable:false},
            {field:'sku_name',headerName:entity_object.storage_order_item.fields.sku_name.label,width:list_properties?.sku_name?.width??300,editable:false,renderCell:(params)=><Link to={'/sku/'+params.row.sku_id+'/storageorderitem'} style={{textDecoration:"none"}}>{params.row.sku_name}</Link>},
            {field:'quantity',headerName:entity_object.storage_order_item.fields.quantity.label,width:list_properties?.quantity?.width??150,editable:!readOnly,type:'number',align:"left",headerAlign:"left",renderHeader:()=>(renderHeaderEditable(entity_object.storage_order_item.fields.quantity.label,!readOnly)),},
            {field:'batch_number',headerName:entity_object.storage_order_item.fields.batch_number.label,width:list_properties?.batch_number?.width??200,editable:!readOnly,renderHeader:()=>(renderHeaderEditable(entity_object.storage_order_item.fields.batch_number.label,!readOnly)),},
            {field:'production_date',...dateColumnType(handleUpdateFromColumn),headerName:entity_object.storage_order_item.fields.production_date.label,width:list_properties?.production_date?.width??200,editable:!readOnly,renderHeader:()=>(renderHeaderEditable(entity_object.storage_order_item.fields.production_date.label,!readOnly)),},
            {field:'expiration_date',...dateColumnType(handleUpdateFromColumn),headerName:entity_object.storage_order_item.fields.expiration_date.label,width:list_properties?.expiration_date?.width??200,editable:!readOnly,renderHeader:()=>(renderHeaderEditable(entity_object.storage_order_item.fields.expiration_date.label,!readOnly)),},
        ];
	    if(readOnly){
            columns.push({field:'quantity_delivered',headerName:entity_object.storage_order_item.fields.quantity_delivered.label,width:list_properties?.quantity_delivered?.width??150,editable:false,type:'number',align:"left",headerAlign:"left",renderCell:(params)=><span style={{color:(params.row.quantity!==params.row.quantity_delivered)?'red':''}}>{params.row.quantity_delivered}</span>})
        }
	    columns.push({field:'description',headerName:entity_object.storage_order_item.fields.description.label,width:list_properties?.description?.width??200,editable:!readOnly,renderHeader:()=>(renderHeaderEditable(entity_object.storage_order_item.fields.description.label,!readOnly)),});
	    return columns;
    },
    sku_storage_order_item:function(list_properties){
        return([
            {field:'order_reference',headerName:'Order Reference',width:list_properties?.order_reference?.width??120,editable:false,renderCell:(params)=><Link to={'/storage_order/'+params.row.order_id+'/items'} style={{textDecoration:"none"}}>{params.row.order_reference}</Link>},
            {field:'order_status',headerName:'Order Status',width:list_properties?.order_status?.width??150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.order_status]}}>{params.row.order_status}</span>},
            {field:'quantity',headerName:entity_object.storage_order_item.fields.quantity.label,width:list_properties?.quantity?.width??150,editable:false,type:'number',align:"left",headerAlign:"left"},
        ])
    },
    report:function(type,detail,total,list_properties){
        if(type==='Palpa'){
            let columns=[
                {field:'sku_name',headerName:'Product name',width:list_properties?.sku_name?.width??200,editable:false},
                {field:'gtin',headerName:'Barcode',width:list_properties?.gtin?.width??200,editable:false},
                {field:'sku_palpa',headerName:'Amount of Palpa per unit',width:list_properties?.sku_palpa?.width??200,editable:false,renderHeader:()=>(renderHeaderReportTotal('Amount of Palpa per unit',total.sku_palpa)),},
                {field:'units_delivered',headerName:'Units Delivered',width:list_properties?.units_delivered?.width??200,editable:false,renderHeader:()=>(renderHeaderReportTotal('Units Delivered',total.units_delivered)),},
                {field:'total_palpa_value',headerName:'Total Palpa of the Product',width:list_properties?.total_palpa_value?.width??200,editable:false,renderHeader:()=>(renderHeaderReportTotal('Total Palpa of the Product',total.total_palpa_value)),},
            ];
            if(detail){
                let columns_detail=[
                    {field:'export',headerName:'Export',width:list_properties?.export?.width??100,editable:false},
                ]
                columns=columns.concat(columns_detail);
            }
            return columns;
        }
        else if(type==='Valvira'){
            let columns=[
                {field:'excise_number',headerName:'Lupanumero',width:list_properties?.excise_number?.width??200,editable:false},
                {field:'id_number',headerName:'Tunnusnumero',width:list_properties?.id_number?.width??200,editable:false},
                {field:'country',headerName:'Maa',width:list_properties?.country?.width??200,editable:false,renderCell:(params)=><span>{params.row.country in country_code_3?country_code_3[params.row.country]:params.row.country}</span>},
                {field:'gtin',headerName:'Tuotekoodi',width:list_properties?.gtin?.width??200,editable:false},
                {field:'litres',headerName:'Määrä',width:list_properties?.litres?.width??200,editable:false,renderHeader:()=>(renderHeaderReportTotal('Määrä',total.litres)),},
                {field:'price',headerName:'Hinta',width:list_properties?.price?.width??200,editable:false,renderHeader:()=>(renderHeaderReportTotal('Hinta',total.price)),},
            ];
            if(detail){
                let columns_detail=[
                    {field:'account_name',headerName:'Account Name',width:list_properties?.account_name?.width??400,editable:false},
                    {field:'account_business_id',headerName:'Account Business Id',width:list_properties?.account_business_id?.width??200,editable:false},
                    {field:'sku_name',headerName:'SKU Name',width:list_properties?.sku_name?.width??400,editable:false},
                ]
                columns=columns.concat(columns_detail);
            }
            return columns;
        }
        else if(type==='Sales'){
            return [
                {field:'date',headerName:'Date',width:list_properties?.date?.width??200,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.date)}</span>},
                {field:'sales_person',headerName:'Sales Person',width:list_properties?.sales_person?.width??150,editable:false},
                {field:'sales_order',headerName:'Sales Order',width:list_properties?.sales_order?.width??150,editable:false},
                {field:'customer',headerName:'Customer',width:list_properties?.customer?.width??200,editable:false},
                {field:'business_id',headerName:'Business Id',width:list_properties?.business_id?.width??120,editable:false},
                {field:'category',headerName:'Category',width:list_properties?.category?.width??150,editable:false},
                {field:'sub_category',headerName:'Sub Category',width:list_properties?.sub_category?.width??150,editable:false},
                {field:'order_amount',headerName:'Order Amount',width:list_properties?.order_amount?.width??150,editable:false,renderHeader:()=>(renderHeaderReportTotal('Order Amount',total.order_amount)),},
            ];
        }
        else if(type==='Sales by Product Account'){
            return [
                {field:'sku_name',headerName:'Product',width:list_properties?.sku_name?.width??300,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color,fontWeight:params.row.account_name===null?700:400}}>{params.row.account_name===null?params.row.sku_name:''}</span>},
                {field:'gtin',headerName:'Barcode',width:list_properties?.gtin?.width??175,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color,fontWeight:params.row.account_name===null?700:400}}>{params.row.account_name===null?params.row.gtin:''}</span>},
                {field:'account_name',headerName:'Account',width:list_properties?.account_name?.width??200,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color}}>{params.row.account_name}</span>},
                {field:'account_business_id',headerName:'Business Id',width:list_properties?.account_business_id?.width??200,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color}}>{params.row.account_business_id}</span>},
                {field:'total_quantity',headerName:'Quantity',width:list_properties?.total_quantity?.width??100,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color,fontWeight:params.row.account_name===null?700:400}}>{params.row.total_quantity}</span>},
                {field:'total_litres',headerName:'Litres',width:list_properties?.total_litres?.width??100,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color,fontWeight:params.row.account_name===null?700:400}}>{params.row.total_litres}</span>},
            ];
        }
        else if(type==='Sales by Category'){
            return [
                {field:'category',headerName:'Category',width:list_properties?.category?.width??150,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color,fontWeight:params.row.sales_person===null?700:400}}>{params.row.sales_person===null?params.row.category:''}</span>},
                {field:'sub_category',headerName:'Sub Category',width:list_properties?.sub_category?.width??150,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color,fontWeight:params.row.sales_person===null?700:400}}>{params.row.sales_person===null?params.row.sub_category:''}</span>},
                {field:'date',headerName:'Order Date',width:list_properties?.date?.width??200,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color}}>{params.row.sales_person===null?'':adjustDateLocale(params.row.date)}</span>},
                {field:'sales_person',headerName:'Sales Person',width:list_properties?.sales_person?.width??200,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color}}>{params.row.sales_person}</span>},
                {field:'sales_order',headerName:'Sales Order',width:list_properties?.sales_order?.width??200,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color}}>{params.row.sales_order}</span>},
                {field:'customer',headerName:'Customer',width:list_properties?.customer?.width??200,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color}}>{params.row.customer}</span>},
                {field:'business_id',headerName:'Business Id',width:list_properties?.business_id?.width??200,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color}}>{params.row.business_id}</span>},
                {field:'total_order_amount',headerName:'Quantity',width:list_properties?.total_order_amount?.width??150,editable:false,sortable:false,renderCell:(params)=><span style={{color:params.row.color,fontWeight:params.row.sales_person===null?700:400}}>{params.row.total_order_amount}</span>},
            ];
        }
        else {
            return [];
        }
    },
    product_duplicated_name:[
        {field:'reference',headerName:entity_object.product.fields.reference.label,width:120,editable:false},
        {field:'name',headerName:entity_object.product.fields.name.label,width:350,editable:false,renderCell:(params)=><Link to={'/product/'+params.row.id+'/skus'} style={{textDecoration:"none"}} target="_blank">{params.row.name}</Link>},
        {field:'type',headerName:entity_object.product.fields.type.label,width:150,editable:false},
        {field:'status',headerName:entity_object.product.fields.status.label,width:150,editable:false,renderCell:(params)=><span style={{color:status_color[params.row.status]}}>{params.row.status}</span>},
        {field:'similarity_score',headerName:"Score (%)",width:150,editable:false,renderCell:(params)=><span>{(params.row.similarity_score*100).toFixed(2)}</span>}
    ],
    stockbalance:function(list_properties){
        return([
            {field:'amount',headerName:'Amount',width:list_properties?.amount?.width??120,editable:false,type:'number',align:"left"},
            {field:'quarantined',headerName:'Quarantined',width:list_properties?.quarantined?.width??120,editable:false,renderCell:(params)=><span style={{color:params.row.quarantined?'darkred':'darkgreen'}}>{params.row.quarantined?'Y':'N'}</span>},
            {field:'expiryDate',headerName:'Expiry Date',width:list_properties?.expiryDate?.width??200,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.expiryDate,true)}</span>},
            {field:'batchCode',headerName:'Batch',width:list_properties?.batchCode?.width??200,editable:false},
        ]);
    },
    alcohol_license_detail:function(list_properties){
        return([
            {field:'id',headerName:'Id',width:list_properties?.id?.width??100,editable:false},
            {field:'refresh_information',headerName:'Refresh Information',width:list_properties?.refresh_information?.width??400,editable:false},
            {field:'created_at',headerName:'Created At',width:list_properties?.created_at?.width??250,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.created_at,false)}</span>},
        ]);
    },
    excise_order:function(picklistLinkCallback){
        return([
            {field:'license_id',headerName:'Excise Number',width:150,editable:false,renderCell:(params)=><LinkMUI component="button" variant="body2" onClick={()=>{picklistLinkCallback(params.row)}}>{params.row.license_id}</LinkMUI>},
            {field:'business_name',headerName:'Business Name',width:200,editable:false},
            {field:'business_legal_name',headerName:'Business Legal Name',width:200,editable:false},
            {field:'business_addr_city',headerName:'City',width:200,editable:false},
            {field:'business_addr_street',headerName:'Street',width:300,editable:false},
            {field:'business_addr_zip',headerName:'Zip',width:100,editable:false},
            {field:'license_type',headerName:'Excise Type',width:200,editable:false},
            {field:'validity_starting_date',headerName:'Validity Start',width:250,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.validity_starting_date,true)}</span>},
            {field:'validity_end_date',headerName:'Validity End',width:250,editable:false,renderCell:(params)=><span>{adjustDateLocale(params.row.validity_end_date,true)}</span>},
        ]);
    },
};
