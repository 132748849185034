import {useEffect,useState} from "react";
import {useNavigate,useLocation} from 'react-router-dom';
import {Tooltip,Autocomplete,Switch,FormGroup,FormControlLabel,Divider,Box,Backdrop,CircularProgress,TextField,Button,Stack,Typography,ButtonGroup,Snackbar,Alert,InputAdornment,IconButton} from '@mui/material';
import {AccountCircle,ArrowCircleUp,ArrowCircleDown,ArrowCircleRight} from '@mui/icons-material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
dayjs.locale('fi')
import 'dayjs/locale/fi';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
//import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import AccountPickList from "../Account/AccountPickList.js";
import {createDBItem,getDBList} from '../../integration/DatabaseManager.js';
import {status_color,entity_object} from '../../utilities/DataDictionary.js';
import Country from '../Country.js'
import {DefaultConfig,prepareSetData,prepareDefaultData,validate_required_fields,prepareErrorFields,time_to_date_ui,time_to_ui,sales_order_delivery_price,dayjs_to_db,get_order_date} from '../../utilities/Utilities.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {enUS} from '@mui/x-date-pickers/locales';
const readOnlyBackgroundColor=DefaultConfig.readOnlyBackgroundColor;
const localeText=enUS.components.MuiLocalizationProvider.defaultProps.localeText
let alertMessage='';
let alertSeverity='success';
const required_field_error='Required Field';
const dataError=prepareErrorFields('sales_order');
//let order_text='Tilaus toimitetaan klo';
const dataPreparedNew=prepareDefaultData('default_value_new','sales_order');
const DEFAULT_ACCOUNT_REFERENCE=process.env.COOLHEAD_BREW_REFERENCE||'V-391';

const SalesOrderNew=({authMenuSubItem,userDataAuthorization})=>{
    const [row,setRow]=useState(dataPreparedNew);
    const [error,setError]=useState(dataError.error);
    const [errorMessage,setErrorMessage]=useState(dataError.message);
    const [label,setLabel]=useState('-');
    const [openBackdrop,setOpenBackdrop]=useState(false);
    const [snackOpened,setSnackOpened]=useState(false);
    const [pickControl,setPickControl]=useState({account_name:false});
    const location=useLocation();
    const navigate=useNavigate();
    const [showMoreInfo,setShowMoreInfo]=useState(true);
    //const classes=entity_object.sales_order.useStyles();
    const {globalData}=useContext(ApplicationContext);
    const formatDateToYMD = (date) => date.format('YYYY-MM-DD');
    useEffect(()=>{
        if(Object.keys(authMenuSubItem).length>0){
            setLabel(authMenuSubItem.label);
            getDBList({entityType:'Account',entityParams:['reference='+DEFAULT_ACCOUNT_REFERENCE]})
            .then((data)=>{
                console.log('getDBList CoolHead',data);
                if('error' in data){
                    console.log('error',data.error);
                }
                else{
                    if(data!=null&&data.length>0){
                        get_order_date().then((res)=>{
                            const dayjsDate=dayjs(res);
                            const dayjsDateNoTime=dayjsDate.startOf('day');
                            const formattedDate=formatDateToYMD(dayjsDateNoTime);
                            setRow(prevState=>({...prevState,
                                account_business_id_alt:data[0].business_id,
                                account_name_alt:data[0].name,
                                excise_number_alt:data[0].excise_number,
                                warehouse_excise_number_alt:data[0].warehouse_excise_number,
                                date_of_arrival:formattedDate,
                            }));
                        });
                    }
                    else{
                        alertSeverity='error'
                        alertMessage='Manufacturer "CoolHead Brew" (reference "'+DEFAULT_ACCOUNT_REFERENCE+'") not found or not accessible';
                        setSnackOpened(true);
                    }
                }
            });
            if('state' in location){
                if((location.state)&&('account' in location.state)){
                    console.log('location.state.account',location.state.account);
                    setRow(prevState=>({...prevState,
                        account_id:location.state.account.id,
                        account:location.state.account,
                        tax_free:location.state.account.tax_free,
                        no_invoicing:location.state.account.no_invoicing,
                        palpa_free:location.state.account.palpa_free,
                        export:location.state.account.export,
                        tax_free_reason_code:location.state.account.tax_free_reason_code,
                        excise_number:location.state.account.excise_number,
                        warehouse_excise_number:location.state.account.warehouse_excise_number,
                        billing_addr_specifier:location.state.account.billing_addr_specifier,
                        billing_addr_street:location.state.account.billing_addr_street,
                        billing_addr_comments:location.state.account.billing_addr_comments,
                        billing_addr_zip:location.state.account.billing_addr_zip,
                        billing_addr_city:location.state.account.billing_addr_city,
                        billing_addr_subdivision:location.state.account.billing_addr_subdivision,
                        billing_addr_country:location.state.account.billing_addr_country,
                        shipping_addr_specifier:location.state.account.shipping_addr_specifier,
                        shipping_addr_street:location.state.account.shipping_addr_street,
                        shipping_addr_comments:location.state.account.shipping_addr_comments,
                        shipping_addr_zip:location.state.account.shipping_addr_zip,
                        shipping_addr_city:location.state.account.shipping_addr_city,
                        shipping_addr_subdivision:location.state.account.shipping_addr_subdivision,
                        shipping_addr_country:location.state.account.shipping_addr_country,
                        payment_terms:location.state.account.payment_terms,
                        invoice_channel:location.state.account.invoice_channel,
                        invoice_email:location.state.account.invoice_email,
                        invoice_e_number:location.state.account.invoice_e_number,
                        invoice_e_operator:location.state.account.invoice_e_operator,
                        delivery_method:location.state.account.delivery_method||'Collico',
                        delivery_contact_name:location.state.account.delivery_contact_name,
                        delivery_contact_phone_country:location.state.account.delivery_contact_phone_country,
                        delivery_contact_phone_country_code:location.state.account.delivery_contact_phone_country_code,
                        delivery_contact_phone:location.state.account.delivery_contact_phone,
                        delivery_contact_email:location.state.account.delivery_contact_email,
                        account_name:location.state.account.name,
                        account_legal_name:location.state.account.legal_name,
                        account_business_id:location.state.account.business_id,
                    }));
                    //handleChangeTime(location.state.account.delivery_start_time,'delivery_start_time');
                    //handleChangeTime(location.state.account.delivery_end_time,'delivery_end_time');
                    //updateDescription(time_to_ui(location.state.account.delivery_start_time),time_to_ui(location.state.account.delivery_end_time));
                }
            }
        }
    },[authMenuSubItem]);

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const handleShowMoreInfo=()=>{
        setShowMoreInfo(!showMoreInfo);
    }

    /*
    const handleChangeTime=(fieldValue,fieldName)=>{
        if(fieldValue&&!isNaN(fieldValue.$H)&&!isNaN(fieldValue.$m)){
            let time=time_to_ui(fieldValue);
            setRow(prevState=>({...prevState,
                [fieldName+'__ui']:time_to_date_ui(time),
                [fieldName]:time,
            }));
            let start=fieldName==='delivery_start_time'?time:row.delivery_start_time;
            let end=fieldName==='delivery_end_time'?time:row.delivery_end_time;
            updateDescription(start,end);
        }
    }
    */

    /*
    const updateDescription=(start,end)=>{
        let description=row.shipping_addr_comments;
        let newText=description;
        let startMarker='***';
        let endMarker='***';
        let newDynamicValue=order_text+' '+start+'-'+end;
        console.log('description',description);
        if(description!==undefined&&description.length>0){
            let startMarker_find='\\*\\*\\*';
            let endMarker_find='\\*\\*\\*';
            let dynamicPartPattern=new RegExp(`${startMarker_find}(.*?)${endMarker_find}`);
            if(dynamicPartPattern.test(description)){
                newText=description.replace(dynamicPartPattern,`${startMarker} ${newDynamicValue} ${endMarker}`);
            }
            else{
                newText=startMarker+' '+newDynamicValue+' '+endMarker+'\n'+description;
            }
        }
        else{
            newText=startMarker+' '+newDynamicValue+' '+endMarker;
        }
        handleChangeList(newText,'shipping_addr_comments');
    }
    */

    const createRecord=()=>{
        setOpenBackdrop(true);
        let row_create=prepareSetData(JSON.parse(JSON.stringify(row)),'sales_order');
        console.log('row_create',row_create);
        createDBItem({entityType:authMenuSubItem.query,entityData:row_create})
        .then((data)=>{
            setOpenBackdrop(false);
            let newId=null;
            console.log('data',data);
            if('error' in data){
                alertSeverity='error'
                alertMessage=data.error;
                if('id' in data){newId=data.id;}
            }
            else{
                newId=data.id;
                alertSeverity='success';
                alertMessage='Draft Sales Order created with success!!!';
            }
            if(newId){
                let message={alertSeverity:alertSeverity,alertMessage:alertMessage};
                navigate('/sales_order/'+newId+'/items',{state:{message:message}});
            }
            else{
                setSnackOpened(true);
            }
        });
    };

    const handleClickCalculateDeliveryPrice=()=>{
        let delivery_price_info=sales_order_delivery_price(row.total_price,row.shipping_addr_city);
        alertSeverity='info'
        alertMessage=delivery_price_info.message;
        setSnackOpened(true);
        handleChangeList(delivery_price_info.price,'delivery_price');
    }

    const handleClickSave=()=>{
        if(validate_required_fields(row,'sales_order',setError,setErrorMessage,dataError,required_field_error)){
            createRecord();
            /*
            if(row.excise_number.length<4){
                setError(prevState=>({...prevState,excise_number:true}));
                setErrorMessage(prevState=>({...prevState,excise_number:'This fields should have at least 4 characters!'}));
                alertSeverity='error'
                alertMessage='Please verify validations on fields';
                setSnackOpened(true);
            }
            else{
            }
            */
        }
        else{
            alertSeverity='error'
            alertMessage='Please populate the required fields';
            setSnackOpened(true);
        }
    }

    const handleClickCancel=()=>{
        navigate(-1);
    }

    const handleChange=(event)=>{
        setRow(prevState=>({...prevState,[event.target.name]:event.target.value}));
        if(event.target.name in error){
            if(error[event.target.name]){
                setError(prevState=>({...prevState,[event.target.name]:event.target.value==''}));
                setErrorMessage(prevState=>({...prevState,[event.target.name]:event.target.value==''?required_field_error:''}));
            }
        }
    };

    const handleChangeCountry=(fieldValue,fieldName)=>{
        if(fieldValue){
            handleChangeList(fieldValue.country,fieldName);
            handleChangeList(fieldValue.phone,'delivery_contact_phone_country_code');
        }
    }

    const handleChangeList=(fieldValue,fieldName)=>{
        let event={target:{name:fieldName,value:fieldValue}}
        handleChange(event);

        if('events' in entity_object.sales_order.fields[fieldName]){
            if('onChange' in entity_object.sales_order.fields[fieldName].events){
                entity_object.sales_order.fields[fieldName].events.onChange(fieldValue,handleChangeList,row);
            }
        }

        if('drive_dynamic_required' in entity_object.sales_order.fields[fieldName]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[fieldName]=fieldValue;
            for(const [key,value] of Object.entries(entity_object.sales_order.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.sales_order.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
    };

    const handleChangeDate=(fieldValue,fieldName)=>{
        console.log('fieldValue',fieldValue);
        if(fieldValue&&!isNaN(fieldValue.$D)){
            setRow(prevState=>({...prevState,[fieldName]:fieldValue}));
            if(error[fieldName]){
                setError(prevState=>({...prevState,[fieldName]:false}));
                setErrorMessage(prevState=>({...prevState,[fieldName]:''}));
            }
        }
        else{
            setRow(prevState=>({...prevState,[fieldName]:'NaN-NaN-NaN'})); 
        }
    }

    const handleChangeSwitch=(event)=>{
        const {checked}=event.target;
        handleChange(event={target:{name:event.target.name,value:event.target.checked}});
        if('events' in entity_object.account.fields[event.target.name]){
            if('onChange' in entity_object.account.fields[event.target.name].events){
                entity_object.account.fields[event.target.name].events.onChange(event.target.value,handleChangeList);
            }
        }
        if('drive_dynamic_required' in entity_object.account.fields[event.target.name]){
            let row_update=JSON.parse(JSON.stringify(row));
            row_update[event.target.name]=event.target.value;
            for(const [key,value] of Object.entries(entity_object.account.fields)){
                if('required' in value){
                    if(typeof value.required==='function'){
                        let required=entity_object.account.fields[key].required(row_update);
                        setError(prevState=>({...prevState,[key]:required}));
                        setErrorMessage(prevState=>({...prevState,[key]:required?required_field_error:''}));
                    }
                }
            }
        }
        if(event.target.name==='export'&&checked){
            if(!row.tax_free){
                handleChangeSwitch(event={target:{name:'tax_free',checked:true}});
            }
        }
    }


    const handleClickPick=(picklist_name)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:true}));
    };

    const pickListCallback=(picklist_name,picklist_record)=>{
        setPickControl(prevState=>({...prevState,[picklist_name]:false}));
        console.log('picklist_record',picklist_record);
        if(Object.keys(picklist_record).length!==0){
            if(picklist_name==='account_name'){
                let updated_row=JSON.parse(JSON.stringify(row));
                if(updated_row.account_id!==picklist_record.id){
                    updated_row.account_id=picklist_record.id;
                    updated_row.account=picklist_record;
                    updated_row.palpa_free=picklist_record.palpa_free;
                    updated_row.export=picklist_record.export;
                    updated_row.no_invoicing=picklist_record.no_invoicing;
                    updated_row.tax_free=picklist_record.tax_free;
                    updated_row.tax_free_reason_code=picklist_record.tax_free_reason_code;
                    updated_row.excise_number=picklist_record.excise_number;
                    updated_row.warehouse_excise_number=picklist_record.warehouse_excise_number;
                    updated_row.billing_addr_specifier=picklist_record.billing_addr_specifier;
                    updated_row.billing_addr_street=picklist_record.billing_addr_street;
                    updated_row.billing_addr_comments=picklist_record.billing_addr_comments;
                    updated_row.billing_addr_zip=picklist_record.billing_addr_zip;
                    updated_row.billing_addr_city=picklist_record.billing_addr_city;
                    updated_row.billing_addr_subdivision=picklist_record.billing_addr_subdivision;
                    updated_row.billing_addr_country=picklist_record.billing_addr_country;
                    updated_row.shipping_addr_specifier=picklist_record.shipping_addr_specifier;
                    updated_row.shipping_addr_street=picklist_record.shipping_addr_street;
                    updated_row.shipping_addr_comments=picklist_record.shipping_addr_comments;
                    updated_row.shipping_addr_zip=picklist_record.shipping_addr_zip;
                    updated_row.shipping_addr_city=picklist_record.shipping_addr_city;
                    updated_row.shipping_addr_subdivision=picklist_record.shipping_addr_subdivision;
                    updated_row.shipping_addr_country=picklist_record.shipping_addr_country;
                    updated_row.payment_terms=picklist_record.payment_terms;
                    updated_row.invoice_channel=picklist_record.invoice_channel;
                    updated_row.invoice_email=picklist_record.invoice_email;
                    updated_row.invoice_e_number=picklist_record.invoice_e_number;
                    updated_row.invoice_e_operator=picklist_record.invoice_e_operator;
                    updated_row.delivery_method=picklist_record.delivery_method||'Collico';
                    updated_row.delivery_contact_name=picklist_record.delivery_contact_name;
                    updated_row.delivery_contact_phone=picklist_record.delivery_contact_phone;
                    updated_row.delivery_contact_phone_country=picklist_record.delivery_contact_phone_country;
                    updated_row.delivery_contact_phone_country_code=picklist_record.delivery_contact_phone_country_code;
                    updated_row.delivery_contact_email=picklist_record.delivery_contact_email;
                    updated_row.account_name=picklist_record.name;
                    updated_row.account_legal_name=picklist_record.legal_name;
                    updated_row.account_business_id=picklist_record.business_id;
                    setRow(old=>(updated_row));
                    //handleChangeTime(time_to_date_ui(picklist_record.delivery_start_time),'delivery_start_time');
                    //handleChangeTime(time_to_date_ui(picklist_record.delivery_end_time),'delivery_end_time');
                    //updateDescription(picklist_record.delivery_start_time,picklist_record.delivery_end_time);
                    setError(prevState=>({...prevState,account_id:false}));
                    setErrorMessage(prevState=>({...prevState,account_id:''}));
                }
            }
        }
    };

    return (
        <div className="page">
            {pickControl.account_name&&<AccountPickList pickListCallback={pickListCallback} authMenuSubItem={authMenuSubItem} label={'Customers'} globalData={globalData} userDataAuthorization={userDataAuthorization}></AccountPickList>}
            <Snackbar open={snackOpened} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Backdrop sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}} open={openBackdrop}><CircularProgress color="inherit"/></Backdrop>
            <Box sx={{display:'flex',flexDirection:'flex-end','& > *':{m:1,},}}>
                <Typography variant="h6" style={{margin:'5px'}}>{label}</Typography>
            </Box>
            <div className="page-content">
                <Box component="form" noValidate autoComplete="off">
                    <ButtonGroup size="small">
                        <Button name="save" variant="outlined" onClick={handleClickSave}>Save</Button>
                        <Button name="cancel" variant="outlined" onClick={handleClickCancel}>Cancel</Button>
                        <Button name="calculate_delivery_price" variant="outlined" onClick={handleClickCalculateDeliveryPrice}>Calculate Delivery Price</Button>
                    </ButtonGroup>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="reference"
                            name="reference"
                            type={entity_object.sales_order.fields.reference.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.reference.label}
                            value={row.reference}
                            margin="normal"/>
                        {/*<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="type"
                            name="type"
                            type={entity_object.sales_order.fields.type.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.type.label}
                            value={row.type}
                            margin="normal"/>*/}
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{color:status_color[row.status],backgroundColor:readOnlyBackgroundColor}}}
                            id="status"
                            name="status"
                            type={entity_object.sales_order.fields.status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.status.label}
                            value={row.status}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="delivery_status"
                            name="delivery_status"
                            type={entity_object.sales_order.fields.delivery_status.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_status.label}
                            value={row.delivery_status}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{
                                readOnly:true,                                    
                                endAdornment:(
                                    row.id!==''&&
                                    <InputAdornment position="start">
                                        <IconButton onClick={(e)=>handleClickPick('account_name')}>
                                            <AccountCircle/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required={entity_object.sales_order.fields.account_id.required}
                            error={error.account_id}
                            helperText={errorMessage.account_id}
                            id="account_name"
                            name="account_name"
                            type={entity_object.account.fields.name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.joins.account.fields.name.label}
                            value={row.account.name}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        {!(row.export||!row.tax_free)&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="price"
                            name="price"
                            type={entity_object.sales_order.fields.price.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.price.label}
                            value={row.price}
                            margin="normal"/>}
                        {(row.export||!row.tax_free)&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="price_included_vat"
                            name="price_included_vat"
                            type={entity_object.sales_order.fields.price_included_vat.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.price_included_vat.label}
                            value={row.price_included_vat}
                            margin="normal"/>}
                        {!row.palpa_free&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="palpa_value"
                            name="palpa_value"
                            type={entity_object.sales_order.fields.palpa_value.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.palpa_value.label}
                            value={row.palpa_value}
                            margin="normal"/>}
                        {row.palpa_free&&<TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="palpa_value"
                            name="palpa_value"
                            type={entity_object.sales_order.fields.palpa_value.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.palpa_value.label}
                            value={'0.00'}
                            margin="normal"/>}
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="delivery_price"
                            name="delivery_price"
                            type={entity_object.sales_order.fields.delivery_price.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_price.label}
                            value={row.delivery_price}
                            margin="normal"
                            onChange={(e)=>handleChangeList(e.target.value,e.target.name)}/>
                        {!(row.export||!row.tax_free)&&<Tooltip title={entity_object.sales_order.fields.total_price.tooltip}>
                            <TextField
                                style={{marginTop:"0px",marginBottom:"8px"}}
                                InputProps={{readOnly:true}}
                                sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                                id="total_price"
                                name="total_price"
                                type={entity_object.sales_order.fields.total_price.ui_type}
                                fullWidth
                                variant="outlined"
                                size="small"
                                label={entity_object.sales_order.fields.total_price.label}
                                value={row.total_price}
                                margin="normal"/>
                            </Tooltip>}
                        {(row.export||!row.tax_free)&&<Tooltip title={entity_object.sales_order.fields.total_price_included_vat.tooltip}>
                            <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="total_price_included_vat"
                            name="total_price_included_vat"
                            type={entity_object.sales_order.fields.total_price_included_vat.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.total_price_included_vat.label}
                            value={row.total_price_included_vat}
                            margin="normal"/>
                            </Tooltip>}
                    </Stack>
                    <Button startIcon={showMoreInfo?<ArrowCircleUp/>:<ArrowCircleDown/>} onClick={handleShowMoreInfo}>{showMoreInfo?'Hide More Info':'Show More Info'}</Button>
                    {showMoreInfo&&<>
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            inputProps={{maxLength:500}}
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.sales_order.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.tax}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.tax_color} gutterBottom variant="body1">Order Tax</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={false}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            //required={entity_object.sales_order.fields.excise_number.required}
                            //error={error.excise_number}
                            //helperText={errorMessage.excise_number}
                            id="excise_number"
                            name="excise_number"
                            type={entity_object.sales_order.fields.excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.excise_number.label}
                            value={row.excise_number}
                            margin="normal"
                            //onChange={handleChange}
                            />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="warehouse_excise_number"
                            name="warehouse_excise_number"
                            type={entity_object.sales_order.fields.warehouse_excise_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.warehouse_excise_number.label}
                            value={row.warehouse_excise_number}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.tax_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='export' checked={row.export} onChange={handleChangeSwitch}/>} label={entity_object.sales_order.fields.export.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='palpa_free' checked={row.palpa_free} onChange={handleChangeSwitch} disabled={true}/>} label={entity_object.sales_order.fields.palpa_free.label}/>
                        </FormGroup>
                        <FormGroup style={{paddingLeft:'10px',width:'33%'}}>
                            <FormControlLabel control={<Switch name='tax_free' checked={row.tax_free} onChange={handleChangeSwitch} disabled={(entity_object.sales_order.fields.tax_free.readonly(row))}/>} label={entity_object.sales_order.fields.tax_free.label}/>
                        </FormGroup>
                        <Autocomplete
                            style={{marginTop:"0px",width:'50%'}}
                            fullWidth
                            readOnly={(entity_object.sales_order.fields.tax_free_reason_code.readonly(row))}
                            options={globalData.listOfValues.TAX_FREE_REASON_CODE||[]}
                            getOptionLabel={(option)=>option}
                            id="tax_free_reason_code"
                            name="tax_free_reason_code"
                            type={entity_object.sales_order.fields.tax_free_reason_code.ui_type}
                            size="small"
                            value={row.tax_free_reason_code}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'tax_free_reason_code');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    sx={{div:{backgroundColor:(entity_object.account.fields.tax_free_reason_code.readonly(row))?readOnlyBackgroundColor:""}}}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.account.fields.tax_free_reason_code.required(row)}
                                    error={error.tax_free_reason_code}
                                    helperText={errorMessage.tax_free_reason_code}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sales_order.fields.tax_free_reason_code.label}
                                    margin="normal"
                                />
                            )}
                        />
                    </Stack>
                    <Divider /*className={classes.invoice}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.invoice_color} gutterBottom variant="body1">Order Invoicing</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.invoice_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.payment_terms.required}
                            error={error.payment_terms}
                            helperText={errorMessage.payment_terms}
                            id="payment_terms"
                            name="payment_terms"
                            type={entity_object.sales_order.fields.payment_terms.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.payment_terms.label}
                            value={row.payment_terms}
                            margin="normal"
                            onChange={handleChange}/>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            options={globalData.listOfValues.INVOICE_CHANNEL||[]}
                            getOptionLabel={(option)=>option}
                            id="invoice_channel"
                            name="invoice_channel"
                            type={entity_object.sales_order.fields.invoice_channel.ui_type}
                            size="small"
                            value={row.invoice_channel}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'invoice_channel');}}
                            renderInput={(params)=>(
                                <TextField {...params} 
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sales_order.fields.invoice_channel.required}
                                    error={error.invoice_channel}
                                    helperText={errorMessage.invoice_channel}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sales_order.fields.invoice_channel.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(entity_object.sales_order.fields.invoice_email.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(entity_object.sales_order.fields.invoice_email.readonly(row))}}
                            required={entity_object.sales_order.fields.invoice_email.required(row)}
                            error={error.invoice_email}
                            helperText={errorMessage.invoice_email}
                            id="invoice_email"
                            name="invoice_email"
                            type={entity_object.sales_order.fields.invoice_email.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoice_email.label}
                            value={row.invoice_email}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="invoicing_number"
                            name="invoicing_number"
                            type={entity_object.sales_order.fields.invoicing_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoicing_number.label}
                            value={row.invoicing_number}
                            margin="normal"/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="invoicing_date"
                            name="invoicing_date"
                            type={entity_object.sales_order.fields.invoicing_date.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoicing_date.label}
                            value={''}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.invoice_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <FormGroup style={{paddingLeft:'10px'}}>
                            <FormControlLabel control={<Switch name='no_invoicing' checked={row.no_invoicing} onChange={handleChangeSwitch}/>} label={entity_object.sales_order.fields.no_invoicing.label}/>
                        </FormGroup>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(entity_object.sales_order.fields.invoice_e_number.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(entity_object.sales_order.fields.invoice_e_number.readonly(row))}}
                            required={entity_object.sales_order.fields.invoice_e_number.required(row)}
                            error={error.invoice_e_number}
                            helperText={errorMessage.invoice_e_number}
                            id="invoice_e_number"
                            name="invoice_e_number"
                            type={entity_object.sales_order.fields.invoice_e_number.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoice_e_number.label}
                            value={row.invoice_e_number}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:(entity_object.sales_order.fields.invoice_e_operator.readonly(row))?readOnlyBackgroundColor:""}}}
                            InputProps={{readOnly:(entity_object.sales_order.fields.invoice_e_operator.readonly(row))}}
                            required={entity_object.sales_order.fields.invoice_e_operator.required(row)}
                            error={error.invoice_e_operator}
                            helperText={errorMessage.invoice_e_operator}
                            id="invoice_e_operator"
                            name="invoice_e_operator"
                            type={entity_object.sales_order.fields.invoice_e_operator.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.invoice_e_operator.label}
                            value={row.invoice_e_operator}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.delivery}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.delivery_color} gutterBottom variant="body1">Order Delivery</Typography></Divider>
                    {/*
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker 
                                sx={{width:'100%',marginTop:"0px",marginBottom:"8px"}}
                                ampm={false}
                                slotProps={{textField:{margin:"normal",size:"small",variant:"outlined"}}}
                                label={entity_object.sales_order.fields.delivery_start_time.label}
                                value={row.delivery_start_time__ui}
                                onChange={(fieldValue)=>handleChangeTime(fieldValue,'delivery_start_time')}/>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker 
                                sx={{width:'100%',marginTop:"0px",marginBottom:"8px"}}
                                ampm={false}
                                slotProps={{textField:{margin:"normal",size:"small",variant:"outlined"}}}
                                label={entity_object.sales_order.fields.delivery_end_time.label}
                                value={row.delivery_end_time__ui}
                                onChange={(fieldValue)=>handleChangeTime(fieldValue,'delivery_end_time')}/>
                        </LocalizationProvider>
                    </Stack>
                    */}
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi' localeText={localeText}>
                            <MobileDatePicker
                                sx={{width:'100%',marginTop:"0px",marginBottom:"0px",div:{marginBottom:"8px"}}}
                                slotProps={{textField:{
                                    required:entity_object.sales_order.fields.date_of_arrival.required,
                                    margin:"normal",
                                    size:"small",
                                    variant:"outlined",
                                    error:error.date_of_arrival,
                                    helperText:errorMessage.date_of_arrival}}}
                                label={entity_object.sales_order.fields.date_of_arrival.label}
                                value={(row.date_of_arrival===''||row.date_of_arrival===null||row.date_of_arrival===undefined)?null:dayjs(row.date_of_arrival)}
                                onChange={(fieldValue)=>handleChangeDate(fieldValue,'date_of_arrival')}/>
                        </LocalizationProvider>
                        <Autocomplete
                            style={{marginTop:"0px"}}
                            fullWidth
                            options={globalData.listOfValues.DELIVER_METHOD}
                            getOptionLabel={(option)=>option}
                            id="delivery_method"
                            name="delivery_method"
                            type={entity_object.account.fields.delivery_method.ui_type}
                            size="small"
                            value={row.delivery_method}
                            onChange={(event,newValue)=>{handleChangeList(newValue,'delivery_method');}}
                            renderInput={(params)=>(
                                <TextField {...params}
                                    style={{marginTop:"0px",marginBottom:"8px"}}
                                    required={entity_object.sales_order.fields.delivery_method.required}
                                    error={error.delivery_method}
                                    helperText={errorMessage.delivery_method}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={entity_object.sales_order.fields.delivery_method.label}
                                    margin="normal"
                                />
                            )}
                        />
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            //required={entity_object.sales_order.fields.delivery_contact_name.required}
                            //error={error.delivery_contact_name}
                            //helperText={errorMessage.delivery_contact_name}
                            id="delivery_contact_name"
                            name="delivery_contact_name"
                            type={entity_object.sales_order.fields.delivery_contact_name.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_name.label}
                            value={row.delivery_contact_name}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            InputProps={{readOnly:true}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            id="delivery_date"
                            name="delivery_date"
                            type={entity_object.sales_order.fields.delivery_date.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_date.label}
                            value={''}
                            margin="normal"/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <Country 
                            name={'delivery_contact_phone_country'}
                            value={row.delivery_contact_phone_country} 
                            label={entity_object.sales_order.fields.delivery_contact_phone_country.label}
                            readOnly={false} 
                            required={entity_object.sales_order.fields.delivery_contact_phone_country.required}
                            error={error.delivery_contact_phone_country}
                            helperText={errorMessage.delivery_contact_phone_country}
                            type={entity_object.sales_order.fields.delivery_contact_phone_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeCountry(newValue,'delivery_contact_phone_country');}}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            sx={{input:{backgroundColor:readOnlyBackgroundColor}}}
                            InputProps={{readOnly:true}}
                            id="delivery_contact_phone_country_code"
                            name="delivery_contact_phone_country_code"
                            type={entity_object.sales_order.fields.delivery_contact_phone_country_code.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_phone_country_code.label}
                            value={row.delivery_contact_phone_country_code}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.delivery_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.delivery_contact_phone.required}
                            error={error.delivery_contact_phone}
                            helperText={errorMessage.delivery_contact_phone}
                            id="delivery_contact_phone"
                            name="delivery_contact_phone"
                            type={entity_object.sales_order.fields.delivery_contact_phone.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_phone.label}
                            value={row.delivery_contact_phone}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="delivery_contact_email"
                            name="delivery_contact_email"
                            type={entity_object.sales_order.fields.delivery_contact_email.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.delivery_contact_email.label}
                            value={row.delivery_contact_email}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.billing_address}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.billing_address_color} gutterBottom variant="body1">Order Billing Address</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.billing_addr_street.required(row)}
                            error={error.billing_addr_street}
                            helperText={errorMessage.billing_addr_street}
                            id="billing_addr_street"
                            name="billing_addr_street"
                            type={entity_object.sales_order.fields.billing_addr_street.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_street.label}
                            value={row.billing_addr_street}
                            margin="normal"
                            onChange={handleChange}/>
                        <Country 
                            name={'billing_addr_country'}
                            value={row.billing_addr_country} 
                            label={entity_object.sales_order.fields.billing_addr_country.label}
                            readOnly={false} 
                            required={entity_object.sales_order.fields.billing_addr_country.required}
                            error={error.billing_addr_country}
                            helperText={errorMessage.billing_addr_country}
                            type={entity_object.sales_order.fields.billing_addr_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeList(newValue.country,'billing_addr_country');}}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="billing_addr_specifier"
                            name="billing_addr_specifier"
                            type={entity_object.sales_order.fields.billing_addr_specifier.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_specifier.label}
                            value={row.billing_addr_specifier}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.billing_addr_city.required(row)}
                            error={error.billing_addr_city}
                            helperText={errorMessage.billing_addr_city}
                            id="billing_addr_city"
                            name="billing_addr_city"
                            type={entity_object.sales_order.fields.billing_addr_city.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_city.label}
                            value={row.billing_addr_city}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.billing_addr_zip.required(row)}
                            error={error.billing_addr_zip}
                            helperText={errorMessage.billing_addr_zip}
                            id="billing_addr_zip"
                            name="billing_addr_zip"
                            type={entity_object.sales_order.fields.billing_addr_zip.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_zip.label}
                            value={row.billing_addr_zip}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="billing_addr_subdivision"
                            name="billing_addr_subdivision"
                            type={entity_object.sales_order.fields.billing_addr_subdivision.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_subdivision.label}
                            value={row.billing_addr_subdivision}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'20px',backgroundColor:entity_object.sales_order.style.billing_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            multiline={true}
                            rows={2}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="billing_addr_comments"
                            name="billing_addr_comments"
                            type={entity_object.sales_order.fields.billing_addr_comments.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.billing_addr_comments.label}
                            value={row.billing_addr_comments}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Divider /*className={classes.shipping_address}*/ style={{marginTop:"5px"}} textAlign="left"><Typography color={entity_object.sales_order.style.shipping_address_color} gutterBottom variant="body1">Order Shipping Address</Typography></Divider>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.shipping_addr_street.required}
                            error={error.shipping_addr_street}
                            helperText={errorMessage.shipping_addr_street}
                            id="shipping_addr_street"
                            name="shipping_addr_street"
                            type={entity_object.sales_order.fields.shipping_addr_street.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_street.label}
                            value={row.shipping_addr_street}
                            margin="normal"
                            onChange={handleChange}/>
                        <Country 
                            name={'shipping_addr_country'}
                            value={row.shipping_addr_country} 
                            label={entity_object.sales_order.fields.shipping_addr_country.label}
                            readOnly={false} 
                            required={entity_object.sales_order.fields.shipping_addr_country.required}
                            error={error.shipping_addr_country}
                            helperText={errorMessage.shipping_addr_country}
                            type={entity_object.sales_order.fields.shipping_addr_country.ui_type}
                            readOnlyBackgroundColor={readOnlyBackgroundColor}
                            onChange={(event,newValue)=>{handleChangeList(newValue.country,'shipping_addr_country');}}/>
                    </Stack>
                    <Stack style={{paddingTop:'10px',backgroundColor:entity_object.sales_order.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="shipping_addr_specifier"
                            name="shipping_addr_specifier"
                            type={entity_object.sales_order.fields.shipping_addr_specifier.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_specifier.label}
                            value={row.shipping_addr_specifier}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.shipping_addr_city.required}
                            error={error.shipping_addr_city}
                            helperText={errorMessage.shipping_addr_city}
                            id="shipping_addr_city"
                            name="shipping_addr_city"
                            type={entity_object.sales_order.fields.shipping_addr_city.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_city.label}
                            value={row.shipping_addr_city}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            required={entity_object.sales_order.fields.shipping_addr_zip.required}
                            error={error.shipping_addr_zip}
                            helperText={errorMessage.shipping_addr_zip}
                            id="shipping_addr_zip"
                            name="shipping_addr_zip"
                            type={entity_object.sales_order.fields.shipping_addr_zip.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_zip.label}
                            value={row.shipping_addr_zip}
                            margin="normal"
                            onChange={handleChange}/>
                        <TextField
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="shipping_addr_subdivision"
                            name="shipping_addr_subdivision"
                            type={entity_object.sales_order.fields.shipping_addr_subdivision.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_subdivision.label}
                            value={row.shipping_addr_subdivision}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    <Stack style={{paddingTop:'20px',backgroundColor:entity_object.sales_order.style.shipping_address_background_color}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            multiline={true}
                            rows={2}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="shipping_addr_comments"
                            name="shipping_addr_comments"
                            type={entity_object.sales_order.fields.shipping_addr_comments.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.shipping_addr_comments.label}
                            value={row.shipping_addr_comments}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    {/*
                    <Stack style={{paddingTop:'20px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                        <TextField
                            multiline={true}
                            rows={5}
                            style={{marginTop:"0px",marginBottom:"8px"}}
                            id="description"
                            name="description"
                            type={entity_object.sales_order.fields.description.ui_type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label={entity_object.sales_order.fields.description.label}
                            value={row.description}
                            margin="normal"
                            onChange={handleChange}/>
                    </Stack>
                    */}
                    </>}
                </Box>
            </div>
        </div>
    );
}

export default SalesOrderNew;
