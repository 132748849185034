import {useEffect,useState} from "react";
import {Stack,Snackbar,Alert} from '@mui/material';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {columns} from '../../utilities/Entities';
import {getDBList} from '../../integration/DatabaseManager.js';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
let alertMessage='';
let alertSeverity='error'
const list_name='sku_sales_order_item';

const SKUDetailSalesOrderItem=({parent,refreshPageTab,pageTabCallback,userDataAuthorization,globalData})=>{
    const [rows,setRows]=useState([]);
    const [loading,setLoading]=useState(true);
    const [snackOpened,setSnackOpened]=useState(false);
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);
    const [filterModel,setFilterModel]=useState(globalData.filterModel[list_name]);
    const [sortModel,setSortModel]=useState(globalData.sortModel[list_name]);

    const handleCloseSnack=()=>{
        setSnackOpened(false);
    }

    const getRecords=(isSubscribed)=>{
        let joinName='Order';
        let joinFilterField='type';
        let joinFilterValue='Sales';
        let searchExpressionJoin=['[joinName]='+joinName,'[joinFilterField]='+joinFilterField,'[joinFilterValue]='+joinFilterValue];
        getDBList({entityType:'OrderItem',entityParams:["SearchExpression=([sku_id]='"+parent.id+"')","SearchExpressionJoin="+searchExpressionJoin]})
        .then((data)=>{
            if(isSubscribed){
                if('error' in data){console.log('error',data.error);}
                else{
                    console.log('records',data);
                    if(data.length>0){
                        setRows(old=>data);
                    }
                    else{
                        setRows([]);
                    }
                }
                setLoading(false);
                if(refreshPageTab){
                    pageTabCallback('refreshed');
                }
            }
        });
    };

    useEffect(()=>{
        let isSubscribed=true;
        if(Object.keys(parent).length>0){
            if(parent.id!==undefined&&parent.id!==null&&parent.id!==''){
                getRecords(isSubscribed);
            }
        }
        return ()=>(isSubscribed=false);
    },[parent.id]);

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
        <div className="page-content">
            <Snackbar open={snackOpened} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{vertical:'bottom',horizontal:'center'}}>
                <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        editMode="row"
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        initialState={{
                            sorting:{
                                sortModel:[{field:'name',sort:'asc'}],
                            },
                        }}
                        rows={rows}
                        columns={columns[list_name](list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        filterModel={filterModel}
                            onFilterModelChange={(newFilterModel)=>{
                                setFilterModel(newFilterModel);
                                updateGlobalData({...globalData,filterModel:{...globalData.filterModel,[list_name]:newFilterModel}});
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel)=>{
                                setSortModel(newSortModel);
                                updateGlobalData({...globalData,sortModel:{...globalData.sortModel,[list_name]:newSortModel}});
                            }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </Stack>
        </div>);
}

export default SKUDetailSalesOrderItem;
