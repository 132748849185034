import {useEffect,useState} from "react";
import {ButtonGroup,Button,Stack} from '@mui/material';
import {DataGrid,GridToolbarContainer,GridToolbarQuickFilter} from '@mui/x-data-grid';
import {getDBList} from '../../integration/DatabaseManager.js';
import {columns} from '../../utilities/Entities.js';
import {adjustDateLocale,getTableNameFromEntityName} from '../../utilities/Utilities.js';
import ErrorLogDetail from './ErrorLogDetail.js';
import {saveUserDataGridParams,listGridUpdated} from '../../utilities/Utilities';
import {useContext} from 'react';
import {ApplicationContext} from '../ApplicationContext.js';
const list_name='error_log';

const ErrorLogList=({record_id,entity_name,globalData,userDataAuthorization})=>{
    const [loading,setLoading]=useState(true);
    const [rows,setRows]=useState([]);
    const [showDetail,setShowDetail]=useState(false);
    const [row,setRow]=useState({});
    const {globalData1,updateGlobalData}=useContext(ApplicationContext);

    const handleClickShowDetail=(row)=>{
        setRow(old=>row);
        setShowDetail(true);
    };

    const errorLogDetailCallback=()=>{
        setRow(old=>{});
        setShowDetail(false);
    };

    const getRecords=(isSubscribed,record_id)=>{
        getDBList({entityType:'ErrorLog',entityParams:['record_id='+record_id,'table_name='+getTableNameFromEntityName(entity_name)]})
        .then((data)=>{
            if(isSubscribed){
                if('error' in data){console.log('error',data.error);} 
                else{
                    if(data.length>0){
                        console.log('data',data);
                        data.forEach(e=>{
                            e.created_at_ui=adjustDateLocale(e.created_at);
                        });
                        setRows(old=>data);
                    }
                    else{
                        setRows(old=>[]);
                    }
                }
                setLoading(false);
            }
        });
    };

    useEffect(()=>{
        let isSubscribed=true;
        if(record_id!==undefined&&record_id!==null&&record_id!==''){
            setLoading(true);
            getRecords(isSubscribed,record_id);
        }
        return ()=>(isSubscribed=false);
    },[record_id]);

    const CustomToolbar=()=>{
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter style={{right:'0px'}}></GridToolbarQuickFilter>
          </GridToolbarContainer>
        );
    }

    return(
        <div className="page-content">
            {showDetail&&<ErrorLogDetail errorLogDetailCallback={errorLogDetailCallback} row={row}></ErrorLogDetail>}
            <Stack style={{paddingTop:'15px'}} direction={{xs:'column',sm:'row'}} spacing={{xs:1,sm:2,md:2}}>
                <div style={{height:'50vh',width:'100%'}}>
                    <DataGrid
                        sx={{'.MuiDataGrid-container--top [role=row]':{backgroundColor:'#f5f5f5'}}}
                        editMode="row"
                        rows={rows}
                        columns={columns.error_log(handleClickShowDetail,list_name in globalData.dataGridParams?globalData.dataGridParams[list_name]:{})}
                        loading={loading}
                        density="compact"
                        slots={{
                            toolbar:CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar:{
                                showQuickFilter:true,
                                quickFilterProps:{debounceMs:250},
                            },
                        }}
                        onColumnWidthChange={(params,event,details)=>{
                            let listGridUpdatedNew=listGridUpdated(globalData.dataGridParams,list_name,params.colDef.field,params.width);
                            updateGlobalData({...globalData,dataGridParams:listGridUpdatedNew});
                            saveUserDataGridParams(listGridUpdatedNew,userDataAuthorization.user_id);
                        }}
                    />
                </div>
            </Stack>
        </div>);
}

export default ErrorLogList;
